import React from "react";
import Button from "../button/Button";
import StarRatingSvg from "main-app/svgs/StarRatingSvg";

type Props = {
    selectedStar: number | null;
    onSelect: (start: number) => void;
};

const StarRatings = ({ onSelect, selectedStar }: Props) => {
    return (
        <div className="d-flex flex-1 mt-2 mt-md-0 justify-content-xs-center  justify-content-md-start">
            {Array.from({ length: 5 }, (_, i) => i + 1).map(star => (
                <Button
                    variant="default"
                    className="p-2"
                    key={star}
                    onClick={() => onSelect(star)}
                    data-testid={`star-rating-${star}`}
                >
                    <StarRatingSvg hasSelectedAnother={!!selectedStar} isCurrentSelected={selectedStar >= star} />
                </Button>
            ))}
        </div>
    );
};

export default StarRatings;
