import React from "react";
import Sidebar from "main-app/shared/coach-sidebar/Sidebar";
import classNames from "classnames";
import { Link, useLocation } from "react-router-dom";
import { CoachSettingsUrls } from "main-app/pages/coach-settings/constants";

const LeftSidebar = () => {
    const { pathname } = useLocation();

    return (
        <Sidebar className="pt-0">
            <ul className="menu-capitalized">
                <li className={classNames({ active: pathname.includes("/setup-calendar/availability") })}>
                    <Link to={CoachSettingsUrls.AVAILABILITY}>My Working Hours</Link>
                </li>
                <li className={classNames({ active: pathname.includes("/setup-calendar/calendars") })}>
                    <Link to={CoachSettingsUrls.CALENDARS}>My Calendars</Link>
                </li>
                <li className={classNames({ active: pathname.includes("/setup-calendar/zoom-account") })}>
                    <Link to={CoachSettingsUrls.ZOOM_ACCOUNT}>My Zoom Account</Link>
                </li>
            </ul>
        </Sidebar>
    );
};

export default LeftSidebar;
