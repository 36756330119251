import React from "react";
import { useTranslation } from "react-i18next";

import Button from "main-app/shared/button/Button";
import EditSvg from "main-app/svgs/EditSvg";

type Props = {
    fieldValues: {
        email?: string;
        first_name?: string;
        last_name?: string;
    };
    onAddClick: () => void;
};

export const ThreeWayAdd = ({ fieldValues, onAddClick }: Props) => {
    const { t } = useTranslation();
    const isFieldsValues = fieldValues?.email && fieldValues?.first_name && fieldValues?.last_name;

    return (
        <div className="tree-way__edit-container">
            {isFieldsValues ? (
                <>
                    <p>{fieldValues?.email}</p>
                    <span>|</span>
                    <p>
                        {fieldValues?.first_name} {fieldValues?.last_name}
                    </p>
                </>
            ) : (
                <p>{t("You have opted to proceed without a manager added")}</p>
            )}
            <Button variant="default" aria-label={t("Edit")} className="p-0" onClick={onAddClick}>
                <EditSvg />
            </Button>
        </div>
    );
};
