import React from "react";
import { useNavigate } from "react-router-dom";

import Button from "main-app/shared/button/Button";
import { useAuthContext } from "main-app/context/Auth";
import { useCalendarProfiles } from "main-app/entities/coach-calendar/api/use-calendar-profiles";
import { CoachCalendarStepUrls as CoachCalendarStep } from "main-app/pages/coach-settings/constants";
import AddIcon from "main-app/svgs/AddIconSvg";
import CalendarAccountItem from "./CalenarAccountItem";

import "./styles.scss";

type Props = {};

export const CalendarAccounts = (props: Props) => {
    const navigate = useNavigate();
    const { user } = useAuthContext();
    const { data } = useCalendarProfiles(user?.calendar_setup_confirmed);

    const onAddCalendarClick = () => {
        navigate(CoachCalendarStep.CALENDAR_ADD);
    };

    return (
        <div className="calendar-accounts">
            <div className="calendar-accounts__header">
                <p className="mb-0 text-center font-extrabold">{user.firstName}'s Calendar Accounts</p>
            </div>
            <div className="calendar-accounts__body">
                {data?.length > 0 ? (
                    data?.map(calendar => <CalendarAccountItem calendar={calendar} />)
                ) : (
                    <div className="calendar-accounts__item">
                        <div className="flex-1 text-center font-italic color-gray">No accounts lnked yet.</div>
                    </div>
                )}

                <div className="calendar-accounts__footer">
                    <Button
                        variant="default"
                        className="p-0 m-0 d-flex align-items-center"
                        onClick={onAddCalendarClick}
                    >
                        <AddIcon />
                        <span className="color-brand font-extrabold ml-2">Add Calendar Account</span>
                    </Button>
                </div>
            </div>
        </div>
    );
};
