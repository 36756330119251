import React, { useMemo } from "react";
import classNames from "classnames";
import useMediaQuery from "common/hooks/use-media-query";
import { useStoreContext } from "main-app/context/GlobalStore";
import { MAX_WIDTH_MOBILE_MEDIA } from "main-app/constants";

import "./styles.scss";

interface IProps {
    className?: string;
}

const Sidebar: React.FC<IProps> = ({ children, className }) => {
    const { isOpenBurgerMenu } = useStoreContext();
    const matches = useMediaQuery(MAX_WIDTH_MOBILE_MEDIA);

    const sideBarStyles = useMemo<React.CSSProperties>(() => {
        if (matches && isOpenBurgerMenu) {
            return {
                display: "block"
            };
        }

        if (matches) {
            return {
                display: "none"
            };
        }

        return {
            display: "block"
        };
    }, [isOpenBurgerMenu, matches]);

    return (
        <div className={classNames("sidebar-left", { [className]: className })} style={sideBarStyles}>
            {children}
        </div>
    );
};

export default Sidebar;
