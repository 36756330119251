import React, { ReactNode } from "react";
import classNames from "classnames";
import WarnIcon from "main-app/svgs/WarnIcon";

import "./styles.scss";

interface IProps {
    iconWidth?: number;
    iconHeigth?: number;
    className?: string;
    type?: "default" | "sm";
    variant?: "default" | "secondary" | "brand";
    children?: ReactNode;
}

const ErrorBox: React.FC<IProps> = ({ className, children, type = "default", variant = "default" }) => {
    return (
        <div className={classNames("error-box", variant, { [className]: className, "error-box-sm": type === "sm" })}>
            <div className="warn-icon-wrapper">
                <WarnIcon className="warn-icon-svg" />
            </div>
            <div>{children}</div>
        </div>
    );
};

export default ErrorBox;
