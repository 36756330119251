import React, { FunctionComponent, ReactElement, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { IConversationScenario, IConversationScenarioCoachee } from "Manager/chapter/models/types";
import Avatar from "main-app/shared/avatar/Avatar";
import { useCreateSimulabConversation } from "main-app/entities/simulab";
import { useAuthContext } from "main-app/context/Auth";
import { CompleteCheckMarkSvg } from "main-app/svgs";

type TTrackPracticeSimulabPanelProps = {
    scenarios: IConversationScenario[] | [];
    handleConversationScenarioNavigation(id: number): void;
};

const TrackPracticeSimulabPanel: FunctionComponent<TTrackPracticeSimulabPanelProps> = ({
    scenarios = [],
    handleConversationScenarioNavigation
}: TTrackPracticeSimulabPanelProps): ReactElement => {
    const { t } = useTranslation();

    const { user } = useAuthContext();

    const createSimulabConversationMutation = useCreateSimulabConversation();

    const handleOnClickConversation = useCallback(
        async ({ coacheeId, conversationId }: { coacheeId: number; conversationId: number }) => {
            conversationId
                ? handleConversationScenarioNavigation(conversationId)
                : createSimulabConversationMutation.mutate(
                      {
                          coacheeId,
                          participantId: user?.id
                      },
                      {
                          onSuccess: async data => handleConversationScenarioNavigation(data?.data?.id)
                      }
                  );
        },
        [createSimulabConversationMutation, user.id, handleConversationScenarioNavigation]
    );

    return (
        <div className="track-practice__simulab-panel">
            <div className="d-flex align-items-center justify-content-between mb-3">
                <h4 className="h4 m-0 font-extrabold">{t("chapter.trackPractice.simulabPanel.title")}</h4>
                <span className="counter font-sm">
                    {t("chapter.trackPractice.simulabPanel.scenariosCounter", {
                        startedCount: scenarios.filter((s: IConversationScenario) => s?.started).length,
                        completedCount: scenarios.filter((s: IConversationScenario) => s?.completed).length
                    })}
                </span>
            </div>
            <p className="font-sm mb-3">{t("chapter.trackPractice.simulabPanel.description")}</p>

            <div className="scenario-list">
                {scenarios?.map((s: IConversationScenario) => (
                    <CoachScenarioTemplate
                        key={s.id}
                        coacheeId={String(s?.coachee?.id)}
                        coacheePhoto={s?.coachee?.photo}
                        coacheeName={s?.coachee?.name}
                        scenarioDescription={s?.description}
                        conversationId={s?.conversation_id}
                        isConversationStarted={s?.started}
                        isConversationCompleted={s?.completed}
                        onClickConversationLink={handleOnClickConversation}
                        className={classNames(
                            { "one-itemed": scenarios.length === 1 },
                            { "two-itemed": scenarios.length === 2 }
                        )}
                    />
                ))}
            </div>
        </div>
    );
};

type TCombine = IConversationScenario & IConversationScenarioCoachee;
type TMap = {
    coacheeId: "id";
    coacheePhoto: "photo";
    coacheeName: "name";
    scenarioDescription: "description";
    conversationId: "conversation_id";
    isConversationStarted: "started";
    isConversationCompleted: "completed";
};

type TCoachScenarioTemplateProps = {
    [K in keyof TMap]: TCombine[TMap[K]];
} & { className: string; onClickConversationLink({ coacheeId, conversationId }): void };

const CoachScenarioTemplate: FunctionComponent<TCoachScenarioTemplateProps> = ({
    coacheeId,
    coacheePhoto,
    coacheeName,
    scenarioDescription,
    isConversationStarted,
    isConversationCompleted,
    conversationId,
    onClickConversationLink,
    className
}: TCoachScenarioTemplateProps): ReactElement => {
    const [isTextCollapsed, setIsCollapsed] = useState<boolean>(true);

    const handleOnClickText = () => setIsCollapsed(!isTextCollapsed);

    const { t } = useTranslation();

    return (
        <div
            className={classNames("scenario-list__item", !isTextCollapsed && "text-expanded", {
                [className]: className
            })}
        >
            <div className="d-flex align-items-center">
                <Avatar url={coacheePhoto} height={30} width={30} />
                <span className="ml-1 d-block font-sm name">{coacheeName}</span>
            </div>
            <p
                className={classNames("description mb-1", !isTextCollapsed ? "expanded" : "collapsed")}
                onClick={handleOnClickText}
            >
                {scenarioDescription}
            </p>
            <div
                className="scenario-list__item-link"
                onClick={() => onClickConversationLink({ coacheeId, conversationId })}
            >
                {!isConversationStarted && !isConversationCompleted ? (
                    <span className="underlined-link">{t("chapter.trackPractice.simulabPanel.goButtonLabel")}</span>
                ) : isConversationStarted && !isConversationCompleted ? (
                    <span className="underlined-link">{t("chapter.trackPractice.simulabPanel.resumeButtonLabel")}</span>
                ) : isConversationStarted && isConversationCompleted ? (
                    <p className="underlined-link d-flex align-items-center my-0 mb-0 mt-2">
                        <CompleteCheckMarkSvg variant="dark" height={20} width={20} />
                        <span className="d-block mb-0 ml-2 fs-14 color-gray font-extrabold">
                            {t("chapter.trackPractice.simulabPanel.completeButtonLabel")}
                        </span>
                    </p>
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
};

export { TrackPracticeSimulabPanel };
