import React from "react";
import { useTranslation } from "react-i18next";
import { sanitizeContent } from "main-app/utils/common";

import Modal from "main-app/shared/modal";

import "./styles.scss";

type Props = {
    show: boolean;
    categoryName: string;
    categoryDescription: string;
    onClose: () => void;
};

const CategoryModal = ({ show, onClose, categoryName, categoryDescription }: Props) => {
    const { t } = useTranslation();

    return (
        <Modal
            show={show}
            onClose={onClose}
            modalBodyClass="category-modal"
            modalOpenBtnLabel={categoryName}
            headerContent={
                <div className="p-3">
                    <h4 className="h4 font-extrabold m-0 text-center">
                        {t("What is {{categoryName}}", { categoryName: `"${categoryName}"` })}
                    </h4>
                </div>
            }
        >
            <div className="category-modal__body">
                <p
                    className="m-0 text-center"
                    dangerouslySetInnerHTML={{
                        __html: sanitizeContent(categoryDescription, {
                            allowedTags: []
                        })
                    }}
                />
            </div>
        </Modal>
    );
};

export default CategoryModal;
