import classNames from "classnames";
import React from "react";

import "./styles.scss";

type Props = {
    className?: string;
};

export const SimulabTipBadge = ({ className = "" }: Props) => {
    return <span className={classNames("simulab-tip-badge", className)}>TIP</span>;
};
