import React, { useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";

import ProductTypeLogo from "main-app/shared/ProductTypeLogo";
import Trash from "main-app/svgs/Trash";
import { ITrackPractice } from "Manager/chapter/models/types";
import TrackPracticeMobileColumnFields from "./TrackPracticeMobileColumnFields";
import TrackPracticeMobileEdit from "./TrackPracticeMobileEdit";
import DeleteModal from "./DeleteModal";
import useDisableBodyScroll from "common/hooks/user-disable-body-scroll";
import Div100vh from "common/components/Div100vh/Div100vh";
import TrackPracticeDescriptionBlock from "./TrackPracticeDescriptionBlock";
import { withTranslation } from "common/utils/lang";

import "./styles.scss";

interface IProps {
    component: ITrackPractice;
    show: boolean;
    fields: any[];
    phaseName: string;
    navBarTitle: string;
    addRows: () => void;
    onDeleteRow: (index: number) => void;
    onSubmit: (values: any) => void;
    handleSubmit: (any: any) => any;
}

const TrackPracticeMobile: React.FC<IProps> = ({
    show,
    component,
    addRows,
    phaseName,
    fields,
    onDeleteRow,
    onSubmit,
    handleSubmit,
    navBarTitle
}) => {
    const { t } = useTranslation();
    const [isEdit, setIsEdit] = useState(false);
    const [editableRowIndex, setEditableRowIndex] = useState(null);
    const [isActive, showIsActive] = useState(false);
    const contentRef = useRef<HTMLDivElement>();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState(null);

    useDisableBodyScroll(show);

    const { control } = useFormContext();

    const toggleAccordion = () => {
        showIsActive(prev => !prev);
    };

    const onEdit = (index: number) => {
        setEditableRowIndex(index);
        setIsEdit(true);
    };

    const onDoneEditing = () => {
        setEditableRowIndex(null);
        setIsEdit(false);
    };

    const onOpenDeleteModal = (index: number) => {
        setDeleteIndex(index);
        setShowDeleteModal(true);
    };

    const onCloseDeleteModal = () => {
        setShowDeleteModal(false);
        setDeleteIndex(null);
    };

    const onDelete = () => {
        onDeleteRow(deleteIndex);
        setShowDeleteModal(false);
    };

    const onAddRow = () => {
        addRows();
        setEditableRowIndex(fields.length);
        setIsEdit(true);
    };

    const date = DateTime.now().toFormat("LLL. dd, yyyy");

    return (
        <>
            <DeleteModal show={showDeleteModal} onClose={onCloseDeleteModal} onOk={onDelete} />
            <div className={classNames("track-practice-mobile", { show })}>
                <Div100vh>
                    <div className="track-practice-mobile-body">
                        <div className="track-practice-mobile-nav">
                            <div className="container">
                                <div className="track-practice-mobile-navbar">
                                    <Link to="/" className="home-link">
                                        <ProductTypeLogo width={26} heigth={26} />
                                    </Link>
                                    <span className="header-title mx-auto">{navBarTitle}</span>
                                </div>
                            </div>
                        </div>
                        <div className="track-practice-mobile-header">
                            <h5 className="m-0 mr-auto ml-auto font-extrabold text-center">{phaseName}</h5>
                            <div
                                className={classNames(
                                    "hide_assignment d-flex align-items-center justify-content-center cursor-pointer",
                                    { active: isActive }
                                )}
                                onClick={toggleAccordion}
                            >
                                <p className="font-sm font-extrabold">
                                    {isActive ? t("Hide") : t("Show")} {t("Practice Assignment")}
                                </p>
                                <svg
                                    width="13"
                                    height="9"
                                    viewBox="0 0 13 9"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M10.75 6.625L6.5 2.375L2.25 6.625"
                                        stroke="#FD4D00"
                                        strokeWidth="2"
                                        strokeLinecap="square"
                                    />
                                </svg>
                            </div>
                            <div
                                className="accordion-box"
                                ref={contentRef}
                                style={
                                    isActive ? { height: contentRef.current?.scrollHeight + "px" } : { height: "0px" }
                                }
                            >
                                <TrackPracticeDescriptionBlock component={component} showButton={false} size="sm" />
                            </div>
                        </div>
                        <div className="track-practice-mobile-content br-bottom-10">
                            <div className="track-practice-mobile-container">
                                {isEdit ? (
                                    <TrackPracticeMobileEdit
                                        rowIndex={editableRowIndex}
                                        onClose={() => setIsEdit(false)}
                                    />
                                ) : (
                                    fields.map((field, rowIndex) => (
                                        <div className="track-practice-mobile-column" key={field.uuid}>
                                            <div className="track-column-date br-top-10 d-flex justify-content-between align-items-center">
                                                <p className="font-sm">{date}</p>
                                                {fields.length > 1 && (
                                                    <button
                                                        className="trash"
                                                        onClick={() => onOpenDeleteModal(rowIndex)}
                                                    >
                                                        <Trash />
                                                    </button>
                                                )}
                                            </div>

                                            <div
                                                className="track-column-comment br-bottom-10"
                                                onClick={() => onEdit(rowIndex)}
                                            >
                                                {component?.columns?.map((col, colIndex) => (
                                                    <div key={col.uuid}>
                                                        <h4 className="h4 font-extrabold text-left font-sm">
                                                            {withTranslation(col.title)}
                                                        </h4>
                                                        <TrackPracticeMobileColumnFields
                                                            rowIndex={rowIndex}
                                                            colIndex={colIndex}
                                                            control={control}
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    ))
                                )}

                                {isEdit ? (
                                    <button
                                        className="btn btn-next btn--brand-outline d-block mx-auto mt-5"
                                        onClick={onDoneEditing}
                                    >
                                        {t("Done editing")}
                                    </button>
                                ) : (
                                    <>
                                        <div className="add_comment_wrapper">
                                            <button className="add_comment br-10" onClick={onAddRow}>
                                                <p className="font-extrabold">+</p>
                                            </button>
                                        </div>
                                        <button className="save" onClick={handleSubmit(onSubmit)}>
                                            {t("Save & Close")}
                                        </button>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </Div100vh>
            </div>
        </>
    );
};

export default TrackPracticeMobile;
