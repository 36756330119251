import React, { memo } from "react";

type Props = {
    className?: string;
    width?: number;
    height?: number;
} & React.SVGProps<SVGSVGElement>;

const PlusIcon = (props: Props) => {
    const { className = "", width = 13, height = 13, ...rest } = props;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            width={width}
            height={height}
            viewBox="0 0 13 13"
            data-name="plus-icon"
            fill="#fff"
            {...rest}
        >
            <path d="M6.5 0.5L6.5 12.5" stroke="#FD4D00" strokeWidth="1.875" />
            <path d="M0.5 6.5H12.5" stroke="#FD4D00" strokeWidth="1.875" />
        </svg>
    );
};

export default memo(PlusIcon);
