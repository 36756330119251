import { Role } from "main-app/constants";
import { SimulabMessage } from "../models";

type Options = {
    message: SimulabMessage;
    messageIndex: number;
    badMessage: boolean;
    messages: SimulabMessage[];
    isUnselectedMessage: boolean;
    selectedMessage: number | null;
};

export const isRecentOrActiveMessage = ({
    message,
    messageIndex,
    badMessage,
    messages,
    isUnselectedMessage,
    selectedMessage
}: Options) => {
    const isParticipantLastMessage = message.authorRole === Role.Participant && messages.length - 1 === messageIndex;

    const lastParticipantMessage = message.authorRole === Role.Participant && messages?.length - 2 === messageIndex;

    const isSelectedMessage = selectedMessage === message.id;

    const recentOrActiveMessage =
        isParticipantLastMessage || isSelectedMessage || (lastParticipantMessage && !selectedMessage);

    return recentOrActiveMessage && !isUnselectedMessage;
};
