import { ReflectionComponentType } from "main-app/constants";
import { ColorNps, NpsAPI, OptionNps, QuestionDataApi } from "./api/nps";
import { TranslateField } from "./types";

export class Nps {
    id: number;
    questionKey: string;
    questionData: QuestionData;
    productType: number;
    freeResponseVisible: boolean;
    freeResponseQuestion: string | null;
    freeResponsePrompt: string | null;
    constructor(data: NpsAPI) {
        this.id = data.id;
        this.questionKey = data.question_key;
        this.productType = data.product_type;
        this.questionData = new QuestionData(data.question_data);
        this.freeResponseVisible = data.free_response_visible;
        this.freeResponseQuestion = data.free_response_question;
        this.freeResponsePrompt = data.free_response_promt;
    }
}

class QuestionData {
    options: Option[];
    question: TranslateField;
    colorRange: ColorRange[];

    constructor(data: QuestionDataApi) {
        this.options = data.options.map(o => new Option(o));
        this.question = data.question;
        this.colorRange = data.color_range.map(c => new ColorRange(c));
    }
}

class Option {
    uuid: string;
    response: TranslateField;

    constructor(data: OptionNps) {
        this.uuid = data.uuid;
        this.response = data.response;
    }
}

class ColorRange {
    from: number;
    to: number;
    color: string;

    constructor(data: ColorNps) {
        this.from = data.from_option;
        this.to = data.to_option;
        this.color = data.color;
    }
}
