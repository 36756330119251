import React, { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

import { Session } from "main-app/models/session";
import { isBonusSession } from "main-app/utils/gates/session";
import { formatDateSessions, getTimezoneAbbreviation } from "main-app/utils/date";
import { CohortModalities } from "main-app/models/types";
import { ParticipantBase } from "main-app/models/participants";
import ProfileImageLetter from "common/components/ProfileImageLetter/ProfileImageLetter";
import GuestBadge from "main-app/svgs/GuestBadge";

import { useCroppedAndSortedParticipantList } from "../hooks";

interface IProps {
    session: Session;
}

const TableRow: React.FC<IProps> = ({ session }) => {
    const navigate = useNavigate();

    const { t } = useTranslation();

    const handleRedirect = useCallback(
        (session: Session): void => {
            if (session.cohortModality === CohortModalities.Individual || isBonusSession(session)) {
                navigate(`/session-participant/${session.participants[0].id}`);
            }

            if (session.cohortModality === CohortModalities.Group) {
                navigate(`/group-info/${session.id}`);
            }
        },
        [navigate]
    );

    const isSessionAvailable = useMemo(() => {
        const currentTime = DateTime.now();
        const sessionTime = DateTime.fromISO(session.sessionTime);
        const sessionAvailableTime = sessionTime.minus({ minutes: 15 });
        const sessionEndTime = sessionTime.plus({ minutes: session.sessionType.duration });

        return (
            currentTime.toMillis() >= sessionAvailableTime.toMillis() &&
            currentTime.toMillis() < sessionEndTime.toMillis() &&
            session?.joinUrl
        );
    }, [session.sessionTime, session.sessionType.duration, session?.joinUrl]);

    const isIndividualModality =
        session?.cohortModality === CohortModalities.Individual && session?.participants?.length === 1;

    const { sortedParticipantList, isParticipantsAmountExceedingViewLimit, exceedingParticipantsCount } =
        useCroppedAndSortedParticipantList({ participantList: session?.participants });

    return (
        <tr className="session-table-row" onClick={() => handleRedirect(session)}>
            <td className="text-left pl-0">
                <a className="d-flex align-items-center color-inherit">{session.cohort}</a>
            </td>
            <td className="d-none d-lg-table-cell">
                {formatDateSessions(session.sessionTime)}{" "}
                <span className="d-block mt-2 color-gray">{getTimezoneAbbreviation()}</span>
            </td>
            <td className="d-none d-lg-table-cell">
                {session.sessionType.name}
                <span className="d-block mt-2 color-gray">{session.sessionType.duration} min</span>{" "}
            </td>
            <td>
                <div className="d-flex align-items-center justify-content-lg-center mb-2">
                    {sortedParticipantList?.map((participant: ParticipantBase, index: number) => (
                        <React.Fragment key={participant.id}>
                            {participant.photo || participant.isGuest ? (
                                <div
                                    className="session-participant-img"
                                    title={`${participant.firstName} ${participant.lastName}`}
                                    style={{ zIndex: index + 1 }}
                                >
                                    {participant.isGuest ? (
                                        <GuestBadge width={27} height={27} className="guest-participant-avatar-badge" />
                                    ) : participant.photo ? (
                                        <img src={participant.photo} />
                                    ) : null}
                                </div>
                            ) : (
                                <div className="session-participant-img" style={{ zIndex: index + 1 }}>
                                    <ProfileImageLetter
                                        firstName={participant.firstName}
                                        lastName={participant.lastName}
                                        width={27}
                                        height={27}
                                    />
                                </div>
                            )}
                        </React.Fragment>
                    ))}
                    {isParticipantsAmountExceedingViewLimit && (
                        <span className="color-gray">
                            {t("coachDashboard.sessionsTab.participantCount", { count: exceedingParticipantsCount })}
                        </span>
                    )}
                </div>
                <span className="color-gray">
                    {isIndividualModality
                        ? `${session?.participants?.[0]?.firstName} ${session?.participants?.[0]?.lastName}`
                        : session.title}
                </span>
            </td>
            <td className="d-none d-lg-table-cell">
                {isSessionAvailable ? (
                    <a href={session?.joinUrl} target="_blank">
                        <button className="btn btn--brand table-btn" onClick={e => e.stopPropagation()}>
                            Join Session
                        </button>
                    </a>
                ) : null}
            </td>
        </tr>
    );
};

export default TableRow;
