import * as React from "react";

function ThreeWayPartyManagerAvatar(props: React.SVGProps<SVGSVGElement>) {
    const { width = 20, height = 20 } = props;

    return (
        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="50" height="50" rx="25" fill="#0404CB" />
            <path
                d="M24.9993 24.5463C27.8979 24.5463 30.2725 22.1715 30.2725 19.2731C30.2725 16.3747 27.8979 14 24.9993 14C22.1008 14 19.7262 16.3747 19.7262 19.2731C19.7262 22.1717 22.1008 24.5463 24.9993 24.5463ZM24.9993 15.7461C26.9549 15.7461 28.5264 17.3175 28.5264 19.2731C28.5264 21.2287 26.9549 22.8002 24.9993 22.8002C23.0437 22.8002 21.4723 21.2287 21.4723 19.2731C21.4723 17.3175 23.0437 15.7461 24.9993 15.7461Z"
                fill="white"
            />
            <path
                d="M25 36.0001C29.016 36.0001 33.1716 36.0001 33.1716 31.4952C33.1716 26.7808 29.435 22.7998 25 22.7998C20.565 22.7998 16.8284 26.7808 16.8284 31.4952C16.8284 36.0001 20.9841 36.0001 25 36.0001ZM25 24.5459C28.4223 24.5459 31.4256 27.7936 31.4256 31.4952C31.4256 33.7302 30.2382 34.254 25 34.254C19.7619 34.254 18.5745 33.7302 18.5745 31.4952C18.5745 27.7936 21.5777 24.5459 25 24.5459Z"
                fill="white"
            />
        </svg>
    );
}

export default React.memo(ThreeWayPartyManagerAvatar);
