import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { withTranslation } from "common/utils/lang";
import { IQuestionFreeResponse } from "Manager/chapter/models/types";
import { sanitizeContent } from "main-app/utils/common";
import NextButton from "./NextButton";

import "./styles.scss";

interface IProps {
    component: IQuestionFreeResponse;
    onNext: (component?, answer?) => void;
    isLast: boolean;
    answer: string;
    disabledBtn;
}

const ResultsFreeResponse: React.FC<IProps> = ({ component, onNext, isLast = false, answer, disabledBtn }) => {
    const { t } = useTranslation();
    const [isActive, setIsActive] = useState(false);
    const contentRef = useRef<HTMLDivElement>();

    const toggleAccordion = () => {
        setIsActive(prev => !prev);
    };

    const onClick = () => {
        if (isLast) {
            onNext(component, component.answer_data.answer);
        } else {
            onNext();
        }
    };

    return (
        <>
            <div className="container--phase text-center summary-block">
                <div className="px-3">
                    <h1 className="h2 font-extrabold font-italic">{withTranslation(component?.short_summary)}</h1>
                    <h2 className="h5 color-brand text-uppercase font-extrabold">
                        {withTranslation(component?.summary_subtitle)}
                    </h2>
                    <div
                        className="text-container text-container--sm content-text"
                        dangerouslySetInnerHTML={{ __html: sanitizeContent(withTranslation(component?.summary)) }}
                    />
                </div>
                <NextButton isLast={isLast} className="mt-3 mb-6" onClick={onClick} disabled={disabledBtn} />
            </div>
            <div className="container--phase-md">
                <button className="result-collapse-toggle" onClick={toggleAccordion}>
                    <span className="show-response-text">{t("Show My Responses")}</span>
                    <span className="result-collapse-toggle-icon">
                        <svg viewBox="0 0 24 24">
                            <path d="M20 14H14V20H10V14H4V10H10V4H14V10H20V14Z" fill="currentColor" />
                        </svg>
                    </span>
                </button>
                <div
                    className="free-response-results"
                    ref={contentRef}
                    style={isActive ? { height: contentRef.current?.scrollHeight + "px" } : { height: "0px" }}
                >
                    {isActive && (
                        <div className="result-collapse-container striped-gray">
                            <div className="result-collapse-item">
                                <h5 className="h5 font-extrabold mb-3">{withTranslation(component?.question)}</h5>
                                <p>{answer}</p>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default ResultsFreeResponse;
