import { useMutation, useQueryClient } from "@tanstack/react-query";
import { http } from "common/http";
import { QueryKey } from "../../constants";
import { Urls } from "../urls";

type Options = {
    conversationId: string;
};

export function useEndConversation() {
    const queryClient = useQueryClient();

    return useMutation(
        ({ conversationId }: Options) => http.post<{ id: number }>(Urls.endConversation(conversationId)),
        {
            onSuccess(data, variables) {
                queryClient.invalidateQueries([QueryKey.SimulabConversation, { id: variables.conversationId }]);
            }
        }
    );
}
