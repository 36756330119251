import React, { useRef } from "react";

import useUpdateEffect from "common/hooks/use-update-effect";

import { StageFeedbackAccordion } from "./StageFeedbackAccordion";
import { isActiveSimulabMessage, SimulabStage, useSimulabContext } from "main-app/entities/simulab";
import { DEFAULT_TRANSITION_TIME, Role } from "main-app/constants";
import { StageName } from "main-app/features/simulab-chat/ui/messages/StageName";

import "./styles.scss";

type Props = {
    stage: SimulabStage;
    showBubbleArrow: boolean;
};

export const ActiveStageItem = ({ stage, showBubbleArrow }: Props) => {
    const feedbackRefs = useRef({});
    const {
        simulabMessages,
        selectedMessage,
        badMessage,
        isMessageUnselected,
        unselectMessage,
        setReportData,
        selectMessage
    } = useSimulabContext();

    const messages = simulabMessages?.messages;

    useUpdateEffect(() => {
        if (selectedMessage && feedbackRefs.current[selectedMessage]) {
            feedbackRefs.current[selectedMessage].scrollIntoView({ block: "center" });
        }
    }, [selectedMessage]);

    const onSelectMessage = (messageId: number) => {
        if (selectedMessage === messageId) {
            selectMessage(null);
            unselectMessage(true);
            return;
        }

        if (selectedMessage !== messageId) {
            selectMessage(messageId);
            unselectMessage(false);
        }
    };

    const onTriggerScroll = (e: React.MouseEvent<HTMLDivElement> & { messageId: number }) => {
        if (feedbackRefs.current[e.messageId]) {
            setTimeout(() => {
                feedbackRefs.current[e.messageId].scrollIntoView({ block: "center" });
            }, DEFAULT_TRANSITION_TIME);
        }
    };

    const isCompleted = stage.completed;

    return (
        <div className="active-stage">
            <StageName
                name={stage.title}
                phase={stage.phase}
                completed={isCompleted}
                isFirstStage={false}
                isCurrentStage={stage.current}
                showLine={false}
                className="active-stage__name"
            />

            {messages?.map((message, index) =>
                message?.owlFeedback && stage.id === message.stageId ? (
                    <StageFeedbackAccordion
                        key={message.id}
                        selectedMessage={selectedMessage}
                        ref={el => (feedbackRefs.current[message.id] = el)}
                        feedback={message.owlFeedback}
                        isRecent={isActiveSimulabMessage({
                            message,
                            selectedMessage,
                            badMessage,
                            messageIndex: index,
                            messages,
                            isUnselectedMessage: isMessageUnselected
                        })}
                        isLastFeedback={messages.length - 2 === index && message?.authorRole === Role.Participant}
                        showBubbleArrow={showBubbleArrow}
                        onSelectMessage={onSelectMessage}
                        onReportClick={setReportData}
                        onToggle={onTriggerScroll}
                    />
                ) : null
            )}
        </div>
    );
};
