import React, { useMemo, useRef, useState, useEffect } from "react";
import { DateTime } from "luxon";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { CohortModalities, TrackPracticeModalData } from "main-app/models/types";
import { Phase as PhaseEntity } from "main-app/models/phase";
import PhaseCompleted from "main-app/svgs/PhaseCompleted";
import { DATE_FORMAT, Status } from "main-app/constants";
import ParticipantCaughtUp from "main-app/elements/participant-caught-up";
import LockedPhase from "main-app/svgs/LockedPhase";
import ScheduleSessionBox from "../session-schedule/ScheduleSessionBox";
import { withTranslation } from "common/utils/lang";
import PhaseChapter from "./PhaseChapter";

import "./styles.scss";

interface IProps {
    phase: PhaseEntity;
    canCollapse: boolean;
    phaseIndex: number;
    isActivePhase: boolean;
    phaseOrder: number;
    relatedSession?: any | null;
    phaseMaxRank: number;
    onScheduleButtonClick?: () => void;
    onTrackPracticeClick?: (trackPracticeModalData: TrackPracticeModalData) => void;
}

const Phase: React.FC<IProps> = ({
    phase,
    canCollapse,
    isActivePhase,
    phaseOrder,
    phaseIndex,
    relatedSession,
    phaseMaxRank,
    onScheduleButtonClick,
    onTrackPracticeClick
}) => {
    const { t } = useTranslation();
    const [collapsed, setCollapsed] = useState(false);
    const contentRef = useRef<HTMLDivElement>();

    useEffect(() => {
        setCollapsed(isActivePhase);
    }, [isActivePhase]);

    const toggleAccordion = () => {
        if (isActivePhase) return;

        if (canCollapse || isActivePhase) {
            setCollapsed(prev => !prev);
        }
    };

    const isCompletePhase = phase.status === Status.Completed;

    const isInQueue = phase.status === Status.Queued;

    const phaseDate = isCompletePhase
        ? DateTime.fromISO(phase.completedDate).toFormat(DATE_FORMAT.SHORT_MONTH_DOT_DAY)
        : DateTime.fromSQL(phase.dueDate).toFormat(DATE_FORMAT.SHORT_MONTH_DOT_DAY);

    const openDate = DateTime.fromSQL(phase.openDate).toFormat(DATE_FORMAT.SHORT_MONTH_DOT_DAY);

    const trackPractices = useMemo(() => {
        if (phase.status === Status.Completed) {
            return phase.chapters.flatMap((chapter, index) =>
                chapter.trackPracticeComponents.map(component => ({
                    ...component,
                    chapterId: chapter.id,
                    chapterIndex: index
                }))
            );
        }
        return null;
    }, [phase]);

    const onClickTrackPractice = e => {
        e.stopPropagation();
        onTrackPracticeClick({
            show: true,
            phaseName: withTranslation(phase.name),
            component: trackPractices[trackPractices.length - 1] as any
        });
    };

    const hasIcon = useMemo(() => {
        return phase?.chapters?.some(chapter => !!chapter.icon);
    }, [phase]);

    const showPhaseContent = () => {
        if (relatedSession && relatedSession?.cohort_modality === CohortModalities.Individual) {
            return (
                <ScheduleSessionBox
                    onClick={onScheduleButtonClick}
                    phaseMaxRank={phaseMaxRank}
                    coachingSessionName={relatedSession?.session_type?.name}
                />
            );
        }

        if (isInQueue) {
            return (
                <ParticipantCaughtUp
                    text={t("Check back here on {{openDate}} to start Phase {{phaseOrder}}", { openDate, phaseOrder })}
                />
            );
        }

        return (
            <ul className="list-steps m-0">
                {phase.chapters.map((chapter, index) => (
                    <PhaseChapter
                        key={chapter.id}
                        phaseIndex={phaseIndex}
                        chapter={chapter}
                        previousChapter={phase.chapters[index - 1]}
                        index={index}
                    />
                ))}
            </ul>
        );
    };

    const isInQueueOrInFuturePhase =
        isInQueue || DateTime.now().toMillis() < DateTime.fromISO(phase.openDate).toMillis();

    return (
        <div
            className={classNames("card", "position-relative", {
                "card-progress": isActivePhase && !isCompletePhase && !isInQueue,
                "card-future": isInQueueOrInFuturePhase
            })}
            data-testid={`phase`}
            onClick={toggleAccordion}
        >
            <div className={classNames("card-header", { active: collapsed })}>
                <div className="d-flex">
                    <button className="btn-accordion" type="button">
                        <PhaseCompleted className={classNames("phase__img", { show: isCompletePhase })} />
                        <LockedPhase
                            className={classNames("phase__img", {
                                show: isInQueueOrInFuturePhase
                            })}
                        />
                        {t("Phase {{phaseOrder}}", { phaseOrder })}
                        <span className="d-none d-lg-inline ">: {withTranslation(phase.name)}</span>
                    </button>

                    {trackPractices && trackPractices?.length > 0 ? (
                        <div className="track-practice-text flex-grow-1 flex-shrink-1">
                            <button onClick={onClickTrackPractice}>
                                <span className="color-red">+ </span>
                                <span>{t("Track Practice")}</span>
                            </button>
                        </div>
                    ) : (
                        <span className="complete-date flex-grow-1 flex-shrink-1">
                            {isCompletePhase ? t("Completed") : t("Due date")}: {phaseDate}
                        </span>
                    )}
                </div>
            </div>
            <div
                className={classNames("collapse-item", { active: collapsed, "has-icon": hasIcon })}
                ref={contentRef}
                style={
                    collapsed
                        ? {
                              height: `${contentRef.current?.scrollHeight}px`
                          }
                        : { height: "0px" }
                }
            >
                <div className="card-body">
                    <div className="fs-16-regular m-0">{showPhaseContent()}</div>
                </div>
            </div>
        </div>
    );
};

export default React.memo(Phase);
