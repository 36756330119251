import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { http } from "common/http";
import Urls from "main-app/api/urls";
import { QueryKey } from "../constants";

const getZoom = async () => {
    const { data } = await http.get(Urls.zoomAccount());
    return data;
};

export function useZoom() {
    return useQuery<any, AxiosError, any>([QueryKey.accountZoomLink], getZoom, {
        refetchOnMount: "always"
    });
}
