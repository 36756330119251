import { useCallback, useMemo, useRef } from "react";
import { getErrorMessages } from "common/utils/get-error-messages";
import useOnboardingSteps from "main-app/api/use-onboarding-steps";
import { OnboardingSteps } from "main-app/components/onboarding/constants";
import { onBoardingNavigationKeys } from "main-app/components/onboarding/constants";
import { OnboardingStep } from "main-app/models/onboarding";
import { isNullOrUndefined } from "common/utils/gates";

export default function useNavigationOnboarding() {
    const { data: onboardingSteps, isLoading, isSuccess, refetch } = useOnboardingSteps();
    const isFirstFetch = useRef(false);

    const getNavigationData = useCallback((stepId: OnboardingSteps) => {
        return (
            onBoardingNavigationKeys.find(key => key.id === stepId) ??
            onBoardingNavigationKeys[onBoardingNavigationKeys.length - 1]
        );
    }, []);

    const getStep = useCallback((data: OnboardingStep, activeStep?: OnboardingSteps) => {
        const { previousSteps, availableSteps } = data;
        const hasPreviousSteps = previousSteps.length > 0;
        const hasActiveStep = !isNullOrUndefined(activeStep);
        const isLastStep = activeStep === availableSteps[availableSteps.length - 2]; // - 2 because last step in availaibles steps if "finish"

        if (hasActiveStep && !isLastStep) {
            const activeStepIndex = availableSteps.findIndex(step => step === activeStep);
            const nextStep = availableSteps[activeStepIndex + 1];
            return getNavigationData(nextStep);
        }

        const currentStep = hasPreviousSteps ? previousSteps[previousSteps.length - 1] : availableSteps.find(Boolean);
        const availableStepIndex = availableSteps.findIndex(step => step === currentStep);
        const stepIndex = hasPreviousSteps ? availableStepIndex + 1 : availableStepIndex;
        const stepId = availableSteps[stepIndex] ?? currentStep;
        return getNavigationData(stepId);
    }, []);

    // updated every time steps data changes
    const currentStep = useMemo(() => {
        return onboardingSteps ? getStep(onboardingSteps) : null;
    }, [onboardingSteps]);

    // updates only once on mount
    const lastStep = useMemo(() => {
        let lastStep = null;
        if (!isLoading && isSuccess && onboardingSteps && !isFirstFetch.current) {
            lastStep = getStep(onboardingSteps);
            isFirstFetch.current = true;
        }
        return lastStep;
    }, [isLoading, isSuccess, onboardingSteps]);

    const getNextStepUrl = useCallback(
        async (activeStep?: OnboardingSteps) => {
            try {
                const { data: steps } = await refetch();
                if (steps) {
                    const nextStep = getStep(steps, activeStep);
                    return nextStep;
                }
            } catch (error) {
                const message = getErrorMessages(error).join();
                console.log(message);
            }
        },
        [getStep]
    );

    return useMemo(
        () => ({
            getNextStepUrl,
            refetchSteps: refetch,
            lastStep,
            onboardingSteps,
            currentStep
        }),
        [getNextStepUrl, lastStep, onboardingSteps]
    );
}
