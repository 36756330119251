import * as React from "react";
import CurrentModuleCircleSvg from "./CurrentModuleCircleSvg";

function QuestionMark(props: React.SVGProps<SVGSVGElement> & { isCurrent?: boolean }) {
    const { width = 19, height = 19, isCurrent = false } = props;

    return (
        <span className="module-svg-wrapper">
            <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
                <circle cx={9.5} cy={9.5} r={9.5} fill="#E1E1E1" />
                <circle cx={9.5} cy={9.5} r={4.5} stroke="#fff" strokeWidth={1.75} />
            </svg>
            {isCurrent && <CurrentModuleCircleSvg />}
        </span>
    );
}

export default React.memo(QuestionMark);
