import { useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import { http } from "common/http";
import { ServerStateKeys } from "main-app/constants";
import { Phase } from "main-app/models/phase";
import Urls from "main-app/api/urls";

export const getPhases = async ({ queryKey }) => {
    const [_key, { id }] = queryKey;
    const { data } = await http.get(Urls.phases(id)); // module id
    return data.results;
};

export default function usePhases(
    moduleId: number,
    options?: { refecthOnMount: boolean | "always"; enabled?: boolean }
) {
    return useQuery<Phase[]>([ServerStateKeys.Phases, { id: moduleId }], getPhases, {
        select: useCallback(data => {
            return data.map(phase => new Phase(phase)).sort((a, b) => a.openDate.localeCompare(b.openDate));
        }, []),
        enabled: !isNaN(moduleId),
        refetchOnMount: options?.refecthOnMount ?? true
    });
}
