import React, { type FC, type ReactElement, type ReactNode, useCallback, useMemo } from "react";

import { type ISwiperProps, Swiper } from "main-app/shared/swiper";
import { type IAvailableGuestSessionModel } from "main-app/api/use-get-available-guest-sessions";
import Coach from "main-app/models/coach";
import type { TEmptyCallback } from "main-app/shared/types/functions";
import useMediaQuery from "common/hooks/use-media-query";
import { MAX_WIDTH_MOBILE_MEDIA } from "main-app/constants";

import { getGroupedAvailableSessionData } from "../utils";
import type { IAvailableSessionDataGroupedByWeek } from "../types";
import { GuestParticipantSlide as Slide } from ".";

type TGuestParticipantSliderProps = {
    availableSessions: IAvailableGuestSessionModel[] | [];
    coach?: Coach;
    handleSetSuggestedSession(session: IAvailableGuestSessionModel): void;
    handleOpenSwapSessionModal: TEmptyCallback;
    originalSessionTime: string;
};

const GuestParticipantSlider: FC<TGuestParticipantSliderProps> = ({
    coach,
    availableSessions = [],
    handleOpenSwapSessionModal,
    handleSetSuggestedSession,
    originalSessionTime
}: TGuestParticipantSliderProps): ReactElement => {
    const groupedData: IAvailableSessionDataGroupedByWeek[] = useMemo(
        () => getGroupedAvailableSessionData({ sessionData: availableSessions, originalSessionTime }),
        [availableSessions]
    );

    const initialSlideIndex: number = useMemo(
        () => groupedData.findIndex(({ isPreferredWeek }: IAvailableSessionDataGroupedByWeek) => isPreferredWeek) ?? 0,
        [groupedData]
    );

    const renderSlideContent = useCallback(
        ({ weekRange, sessions, isPreferredWeek }: IAvailableSessionDataGroupedByWeek, coach: Coach): ReactElement => (
            <Slide
                key={`slide-${weekRange}`}
                isPreferredWeek={isPreferredWeek}
                handleSetSuggestedSession={handleSetSuggestedSession}
                handleOpenSwapSessionModal={handleOpenSwapSessionModal}
                coach={coach}
                title={weekRange}
                sessions={sessions}
            />
        ),
        []
    );

    const isMobile: boolean = useMediaQuery(MAX_WIDTH_MOBILE_MEDIA);

    const swiperProps: ISwiperProps = {
        withNavigation: true,
        withPagination: true,
        initialSlide: initialSlideIndex,
        withTouchableSlides: !isMobile,
        slides: groupedData?.map((s: IAvailableSessionDataGroupedByWeek) => ({
            children: renderSlideContent(s, coach) as ReactNode,
            className: "swap-session__slide"
        }))
    };

    return <Swiper {...swiperProps} />;
};

export { GuestParticipantSlider, type TGuestParticipantSliderProps };
