import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import SimpleLayout from "main-app/layouts/SimpleLayout";
import { StepsNavigator } from "./ui/StepNavigator";
import { CoachCalendarStepUrls } from "./constants";
import Heading from "common/components/Heading/Heading";
import DateWidget from "main-app/shared/DateWidget";
import Button from "main-app/shared/button/Button";
import { FullSpinner } from "main-app/shared/spinner";
import { useAvailabilityConfirmRules } from "main-app/entities/coach-calendar/api/use-confirm";
import { getErrorMessages } from "common/utils/get-error-messages";
import { http } from "common/http";
import Urls from "main-app/api/urls";
import { WarningMessage } from "main-app/components/onboarding/components/WarningMessage";
import { useAuthContext } from "main-app/context/Auth";
import CronofyWidget from "common/components/CronofyWidgets/CronofyWidget";
import { CRONOFY_WIDGET } from "common/constants";
import { useOrderNavigationCalendar } from "main-app/entities/coach-calendar/hooks/use-order-navigation-calendar";

type Props = {
    order?: number;
};

const ConfirmAvailabilityStep = ({ order }: Props) => {
    const { navigateByOrder } = useOrderNavigationCalendar(order);
    const { getUserData } = useAuthContext();
    const { data, isLoading, isError, error: apiError } = useAvailabilityConfirmRules();
    const [error, setError] = useState(null);

    const onConfirmClick = async () => {
        try {
            await http.post(Urls.confirmSetupCalendar());
            getUserData();
            navigateByOrder();
        } catch (error) {
            setError(getErrorMessages(error));
        }
    };

    const config = {
        ...(data ?? {}),
        config: { ...data?.config, interval: 15 },
        callback: () => {}
    };

    return (
        <SimpleLayout
            header={<DateWidget className="coach-date-title" />}
            headerVariant="gray"
            showBackBtn
            showProfilePhoto
        >
            <div className="coach-calendar-wrapper mx-auto pb-5">
                <div className="d-flex justify-content-center mt-5">
                    <StepsNavigator activeStep={CoachCalendarStepUrls.AVAILABILITY_CONFIRM_STEP} />
                </div>
                <Heading textAlign="center" tag="h6" fontSize={16} className="mb-40 mt-5 color-brand">
                    Step {order}
                </Heading>
                <Heading tag="h1" textAlign="center" className="mb-2" fontSize={36}>
                    Confirm Your Availability
                </Heading>

                <p className="mb-0 color-gray fs-14 text-center">
                    <span className="font-extrabold">You must confirm</span> or else your schedule will not be saved.
                    Check to make sure that everything looks right.
                </p>

                <p className="mb-1 color-gray fs-14 text-center">
                    This is the availability that will be visible to your 1:1 participants, as well as our Wiser Care
                    Team.
                </p>

                <WarningMessage message={error || getErrorMessages(apiError?.response?.data)?.join("")} />
                <div className="text-center mt-50 mb-3">
                    <Button onClick={onConfirmClick}>Confirm & Save</Button>
                </div>

                <p className="text-center color-gray fs-14 mb-40">
                    Something went wrong?{" "}
                    <span className="color-brand font-extrabold">
                        <Link to={CoachCalendarStepUrls.AVAILABILITY_STEP}>Back to Edit</Link>
                    </span>
                </p>

                {isLoading ? (
                    <FullSpinner />
                ) : (
                    <CronofyWidget widget={CRONOFY_WIDGET.AvailabilityViewer} id="av-viewer" options={config} />
                )}
            </div>
        </SimpleLayout>
    );
};

export default ConfirmAvailabilityStep;
