import React from "react";
import { Controller } from "react-hook-form";
import classNames from "classnames";
import AttentionSvg from "main-app/svgs/AttentionSvg";

import "./styles.scss";

type IProps = {
    control: any;
    name: string;
    placeholder?: string;
    label?: string;
    errors?: any;
    errorText?: any;
    groupClassName?: string;
    className?: string;
    Icon?: React.ReactNode;
    max?: number;
    min?: number;
    isError?: boolean;
    mask?: (value: string) => string;
} & React.InputHTMLAttributes<HTMLInputElement>;

const NumberInput: React.FC<IProps> = ({
    control,
    name,
    label = "",
    errors,
    errorText,
    groupClassName,
    placeholder = "",
    className,
    children,
    mask,
    min,
    max,
    isError,
    ...rest
}) => {
    const errorMessages = errors[name];
    const hasError = !!(errors && errorMessages);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, onChange) => {
        const { value } = e.target;

        if (Number.isNaN(parseInt(value))) {
            onChange("");
            return;
        }

        if (mask) {
            onChange(mask(value));
        } else {
            onChange(parseInt(value));
        }
    };

    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { onChange, value, name } }) => (
                <div className={classNames("form-group", { "has-error": hasError || isError }, groupClassName)}>
                    <input
                        type="text"
                        placeholder={placeholder}
                        onChange={e => handleChange(e, onChange)}
                        name={name}
                        maxLength={max}
                        value={value}
                        className={classNames(className, "text-input")}
                        aria-label={label}
                        id={name}
                        {...rest}
                    />
                    {children}
                    {hasError && (
                        <div className="input-error">
                            <AttentionSvg />
                            <span>{errors[name]?.message}</span>
                        </div>
                    )}
                </div>
            )}
        />
    );
};

export default NumberInput;
