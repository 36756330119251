import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import sanitizeHtml from "sanitize-html";

import ArrowDown from "main-app/svgs/ArrowDown";
import Coach from "main-app/models/coach";
import { COACH_IMAGE_SIZE } from "./constants";

import "./styles.scss";
import useMediaQuery from "common/hooks/use-media-query";
import { MAX_WIDTH_SMALL_MOBILE_MEDIA } from "main-app/constants";

type Props = {
    coach: Coach;
    actionBtn?: React.ReactNode | null;
    className?: string;
};

const CoachBlock = ({ coach, actionBtn = null, className = "" }: Props) => {
    const { t } = useTranslation();
    const [showMore, setShowMore] = useState(false);
    const [hasMoreText, setHasMoreText] = useState(false);
    const isMobile = useMediaQuery(MAX_WIDTH_SMALL_MOBILE_MEDIA);

    const coachText = useMemo(() => {
        if (coach) {
            if (coach.bio.length > 500 && isMobile) {
                setHasMoreText(true);
                if (showMore) {
                    return coach.bio;
                }
                return coach.bio.substring(0, 500) + "...";
            }
            return coach.bio;
        }
    }, [coach, showMore]);

    const toggleShowMore = () => {
        setShowMore(prev => !prev);
    };

    return (
        <article className={classNames("coach-info", { [className]: className })} data-testid="coach-block">
            <div className="coach-info-left p-3">
                <img src={coach.photo} width={COACH_IMAGE_SIZE} height={COACH_IMAGE_SIZE} alt="coach-photo" />
                <p className="coach-info-left__name">
                    {coach.firstName} {coach.lastName}
                </p>
                <span className="coach-info-left__degree">{coach.certification}</span>
                <span className="coach-info-left__location">
                    {coach.location}, {coach.country}
                </span>
                {actionBtn ? <div className="mt-3">{actionBtn}</div> : null}
            </div>
            <div className="coach-info-right">
                <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(coachText) }} data-testid="coach-bio-text" />
                {hasMoreText && isMobile && (
                    <a className="color-brand cursor-pointer" onClick={toggleShowMore}>
                        {showMore ? t("Hide") : t("Show More")}
                        <ArrowDown className={classNames("ml-8", { rotate: showMore })} />
                    </a>
                )}
            </div>
        </article>
    );
};

export default CoachBlock;
