import React, { memo } from "react";
import classNames from "classnames";
import ParticipantLayoutHeader from "main-app/elements/participant-layout-header/ParticipantLayoutHeader";
import { useStoreContext } from "main-app/context/GlobalStore";
import { FullSpinner } from "main-app/shared/spinner";

import "./styles.scss";

interface IProps {
    leftSidebar?: JSX.Element | null;
    header?: JSX.Element | React.ReactNode | null;
    closeMenu?: boolean;
    className?: string;
    isLoading?: boolean;
    showLogoOnMobile?: boolean;
    noPadding?: boolean;
    title?: React.ReactNode;
    children?: React.ReactNode;
}

const ParticipantLayout: React.FC<IProps> = ({
    children,
    leftSidebar = null,
    header = null,
    closeMenu = false,
    isLoading = false,
    noPadding = false,
    showLogoOnMobile = true,
    title = null,
    className = ""
}) => {
    const { isOpenBurgerMenu, isOpenSidebar } = useStoreContext();

    return (
        <>
            {leftSidebar}
            <section
                className={classNames("participant-layout", className, {
                    "show-spacing": !noPadding && !!header,
                    "gray-bg": isOpenBurgerMenu,
                    "closed-sidebar": closeMenu || !isOpenSidebar
                })}
            >
                <ParticipantLayoutHeader header={header} showLogo={showLogoOnMobile} />
                {title && <div className="participant-layout-page-title">{title}</div>}
                {isLoading ? <FullSpinner /> : children}
            </section>
            {/* <div className="registration-bottom registration-bottom__visible"></div> */}
        </>
    );
};

export default memo(ParticipantLayout);
