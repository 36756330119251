import React, { useMemo } from "react";
import { withTranslation } from "common/utils/lang";
import { IQuestionLikerGroup } from "Manager/chapter/models/types";
import LikertResultsItem from "main-app/shared/likert/LikertResultsItem";

interface IProps {
    component: IQuestionLikerGroup;
    participantName?: string;
}

const QuestionLikertResponses: React.FC<IProps> = ({ component, participantName }) => {
    const answersList = useMemo(() => {
        return component?.answer_data?.answer_list?.reduce((answers, answer) => {
            component?.question_list.forEach(question => {
                if (answer.question_uuid === question.uuid) {
                    answers.push({
                        uuid: question.uuid,
                        question: withTranslation(question.question),
                        option_uuid: answer.option_uuid,
                        options: question.options,
                        allow_free_response: answer?.free_response?.length > 0,
                        free_response: answer.free_response,
                        not_applicable_input: answer.not_applicable_input
                    });
                }
            });

            return answers;
        }, []);
    }, [component]);

    return (
        <div className="text-center component-item">
            <div className="question-responses">
                {answersList?.length > 0 ? <span>{participantName}'s Responses</span> : "No Responses"}
            </div>
            {
                <>
                    {answersList?.map((answer, index) => (
                        <LikertResultsItem
                            questionsCount={answersList?.length}
                            questionNumber={index + 1}
                            answer={answer}
                            key={answer.uuid || answer.question_uuid}
                        />
                    ))}
                </>
            }
        </div>
    );
};

export default QuestionLikertResponses;
