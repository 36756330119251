import { DateTime } from "luxon";
import React from "react";
import { useTranslation } from "react-i18next";

import useSpotCoaching from "main-app/api/use-spot-coaching";
import { DATE_FORMAT } from "main-app/constants";
import { UserEnrollModule } from "main-app/models/user";

interface IProps {
    module: UserEnrollModule;
}

const ModuleQueuedStatus: React.FC<IProps> = ({ module }) => {
    const { t } = useTranslation();
    const phaseStartDate = DateTime.fromISO(module?.firstPhaseStart).toFormat(DATE_FORMAT.SHORT_MONTH_DOT_DAY);
    const { data: productType } = useSpotCoaching();

    return (
        <div className="module-status-box">
            <h3 className="brand-color-text">
                {t("This module in your {{title}} journey cannot be started yet.", { title: productType?.titleData })}
            </h3>
            <p className="m-0">
                {t("It opens on {{phaseStartDate}} — check back then to get started on Phase 1!", { phaseStartDate })}
            </p>
        </div>
    );
};

export default ModuleQueuedStatus;
