import React, { useState } from "react";
import LikertRadio from "./LikertRadio";
import { ColorRange, LikertColorType } from "main-app/models/types";
import { ReflectionComponentType } from "main-app/constants";
import getColor from "main-app/utils/get-color";
import { withTranslation } from "common/utils/lang";

interface LikertOption {
    uuid: string;
    response: {
        en: string;
    };
    [key: string]: any;
}

type Props = {
    options: LikertOption[];
    colorRange?: ColorRange[];
    likertColorType: LikertColorType;
    likertType: ReflectionComponentType.LIKERT_GROUP | ReflectionComponentType.COLORED_LIKERT_GROUP;
    register: any;
    control: any;
    name: any;
    className?: string;
    showOddLabels?: boolean;
};

const LikertRow = ({
    options,
    likertColorType,
    likertType,
    colorRange = [],
    register,
    control,
    name,
    className = "",
    showOddLabels = false
}: Props) => {
    const isDefaultLikert = likertType === ReflectionComponentType.LIKERT_GROUP;
    const [hoveredId, setHoveredId] = useState(null);

    const likertColor = (index: number) => {
        if (isDefaultLikert) return "";
        return getColor(index, colorRange);
    };

    return (
        <fieldset
            className={`mb-4 level-choice level-choice--${likertColorType} ${className} d-flex justify-content-center`}
        >
            {options?.map((checkbox, index, options) => (
                <LikertRadio
                    name={name}
                    register={register}
                    control={control}
                    label={withTranslation(checkbox.response)}
                    id={checkbox.uuid}
                    hoveredId={hoveredId}
                    setHovered={setHoveredId}
                    showHover={likertColorType === "blank"}
                    value={checkbox.uuid}
                    isFirstOption={index === 0}
                    key={checkbox.uuid}
                    color={likertColor(index + 1)}
                    showLabel={showOddLabels && options?.length % 2 !== 0 ? (index + 1) % 2 !== 0 : true}
                    isLastOption={index === options.length - 1}
                />
            ))}
        </fieldset>
    );
};

export default LikertRow;
