import { useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import { http } from "common/http";
import { ServerStateKeys } from "main-app/constants";
import Urls from "main-app/api/urls";

const getOffboarding = async () => {
    const { data } = await http.get(Urls.offboardingJourneyReflection());
    return data;
};

export default function useOffBoarding() {
    return useQuery([ServerStateKeys.Offboarding], getOffboarding, {
        select: useCallback(data => {
            return data;
        }, [])
    });
}
