import React from "react";
import { FlagIconSvg } from "main-app/svgs/FlagIcon";
import Button from "./Button";
import classNames from "classnames";

import "./styles.scss";

type Props = {
    className?: string;
    onFlagClick?: () => void;
};

export const FlagReportBtn = ({ className = "", onFlagClick }: Props) => {
    return (
        <Button
            variant="default"
            className={classNames("p-0 flag-report-btn", className)}
            onClick={onFlagClick}
            data-testid="flag-report-btn"
        >
            <FlagIconSvg />
        </Button>
    );
};
