import React from "react";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

import Coach from "main-app/models/coach";
import RedCheckMark from "main-app/svgs/RedCheckMark";
import Avatar from "main-app/shared/avatar/Avatar";
import Button from "main-app/shared/button/Button";
import { DATE_FORMAT } from "main-app/constants";
import { formatDateSessions } from "main-app/utils/date";
import { BookedTime } from "./types";
import Heading from "common/components/Heading/Heading";

type Props = {
    coach: Coach;
    bookedTime: BookedTime;
    onClose: () => void;
};

const CoachTimeBooked = ({ coach, onClose, bookedTime }: Props) => {
    const { t } = useTranslation();
    const formattedLocationCountry = [coach.location, coach.country].filter(Boolean).join(", ");

    return (
        <section className="coach-time-booked">
            <div className="coach-time-booked__msg">
                <RedCheckMark className="mr-2" size="sm" />
                <p className="color-gray m-0 font-extrabold">{t("You're booked!")}</p>
            </div>
            <div className="coach-time-booked__coach">
                <Avatar url={coach.photo} width={50} height={50} className="mb-20" alt="coach-photo" />
                <Heading className="mb-0" tag="h2" fontSize={16}>
                    {coach.firstName} {coach.lastName}
                </Heading>
                <p className="color-gray font-sm mb-0">{coach.certification}</p>
                <p className="color-gray m-0 font-sm mb-0">{formattedLocationCountry}</p>
            </div>
            <div className="gray-line"></div>
            <div className="coach-time-booked__schedule">
                <p className="mb-0">{formatDateSessions(bookedTime.time, false)}</p>
                <p className="color-gray font-sm">
                    {DateTime.fromISO(bookedTime.time, { zone: bookedTime.tz }).toFormat(
                        DATE_FORMAT.TIMEZONE_ABBREVIATED
                    )}
                </p>
            </div>
            <Button variant="outline" onClick={onClose} className="coach-time-booked__btn">
                {t("Close")}
            </Button>
        </section>
    );
};

export default CoachTimeBooked;
