import { useState, useEffect } from "react";
const isMobileDevice = () => {
    const userAgent = navigator.userAgent;
    const isMobileUA = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);

    const isScreenWidthMobile = window.matchMedia("only screen and (max-width: 768px)").matches;

    return isMobileUA || isScreenWidthMobile;
};

const useIsMobileLandscape = () => {
    const [isLandscape, setIsLandscape] = useState(window.innerWidth > window.innerHeight && isMobileDevice());

    useEffect(() => {
        const checkOrientation = () => {
            setIsLandscape(window.innerWidth > window.innerHeight && isMobileDevice());
        };

        window.addEventListener("resize", checkOrientation);
        window.addEventListener("orientationchange", checkOrientation);

        return () => {
            window.removeEventListener("resize", checkOrientation);
            window.removeEventListener("orientationchange", checkOrientation);
        };
    }, []);

    return isLandscape;
};

export default useIsMobileLandscape;
