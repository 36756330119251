import React from "react";
import classNames from "classnames";

type Props = {
    phase: string;
    completed: boolean;
};

export const SimulabStageIndicator = ({ phase, completed }: Props) => {
    return <div className={classNames("simulab-stage-indicator", { completed })}>{phase?.charAt(0)}</div>;
};
