import React from "react";

import WiserLogo from "main-app/svgs/WiserLogo";
import "main-app/assets/header.scss";

const Header = () => {
    return (
        <header className="header">
            <WiserLogo width={26} height={26} />
        </header>
    );
};

export default Header;
