import React from "react";
import SimpleLayout from "main-app/layouts/SimpleLayout";
import { useTranslation } from "react-i18next";
import { SelfEnrollmentBlock } from "main-app/features/self-enrollment";

const AssignModulePage = () => {
    const { t } = useTranslation();

    return (
        <SimpleLayout showProfilePhoto withFooter={false}>
            <p className="fs-36 text-center mb-30 mt-4">
                <span className="font-extrabold">{t("Module:")}</span>{" "}
                <span className="font-italic">{t("Unassigned")}</span>
            </p>

            <SelfEnrollmentBlock />
        </SimpleLayout>
    );
};

export default AssignModulePage;
