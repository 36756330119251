import { DateTime } from "luxon";
import { ProductType, Role, Status, allUserRoles } from "main-app/constants";
import { Modality } from "./types";
import { TranslationObj } from "common/utils/lang";
import { Language } from "./state";
import { isEmptyObject } from "common/utils/gates";
import { findFutureModule, findPastModule } from "main-app/utils/module";

export interface UserApi {
    id: number;
    email: string;
    first_name: string;
    last_name: string;
    enrolled_modules: EnrolledModule[];
    onboarding_finished: boolean;
    offboarding_passed: boolean;
    journey_length: number;
    user_role?: Role;
    calendar_setup_confirmed?: boolean;
    cohort_exists?: boolean;
    group_exists?: boolean;
    cohort_modality: Modality;
    cohort?: string;
    organization?: string;
    uuid: string;
    job_title?: string;
    department?: string;
    office_location?: string;
    manager_email?: string;
    manager_first_name?: string;
    manager_last_name?: string;
    module_availability?: boolean;
    months_in_current_role?: number;
    years_in_current_role?: number;
    direct_reports_number?: number;
    language_code?: Language;
    cohort_available_languages?: {
        [langKey: string]: string;
    };
    available_coaching_plan: boolean;
    product_type?: ProductType;
    photo?: string;
    reflection_popup_init_data: {
        coaching_session: number;
        module: number;
        rank: number;
    };
    browser_session_ttl: number | null;
    unexpected_oauth: boolean;
    organization_slug: string;
    coach_matching_finished: boolean;
    practice_lab: boolean;
    eligible_select_module: boolean;
    enrollments_allowed?: boolean;
    is_overdue: boolean;
    current_module?: EnrolledModule;
    guest_sessions_enabled?: boolean;
}

export interface EnrolledModule {
    id: number;
    name: TranslationObj;
    version_id: number;
    version_name: string;
    status: Status;
    first_phase_start: string;
    last_phase_end: string;
    module_reflection_passed: boolean;
    rank?: number;
    show_post_module_reflection: boolean;
    related_session: any;
}

export class UserEnrollModule {
    id: number;
    name: TranslationObj;
    versionId: number;
    versionName: string;
    status?: Status;
    firstPhaseStart: string;
    lastPhaseEnd: string;
    moduleReflectionPassed: boolean;
    rank?: number;
    showPostModuleReflection?: boolean;
    relatedSession: any;

    constructor(data: EnrolledModule) {
        this.id = data?.id;
        this.name = data?.name;
        this.versionId = data?.version_id;
        this.versionName = data?.version_name;
        this.status = data?.status;
        this.firstPhaseStart = data?.first_phase_start;
        this.lastPhaseEnd = data?.last_phase_end;
        this.moduleReflectionPassed = data?.module_reflection_passed;
        this.rank = data?.rank;
        this.showPostModuleReflection = data?.show_post_module_reflection;
        this.relatedSession = data?.related_session;
    }
}

class User {
    id: number;
    email: string;
    firstName: string;
    lastName: string;
    module?: UserEnrollModule | null;
    onboardingFinished?: boolean;
    offboardingPassed?: boolean;
    journeyLength?: number;
    role: Role;
    enrolledModules?: UserEnrollModule[];
    enrollmentsAllowed?: boolean;
    calendar_setup_confirmed?: boolean;
    cohortExists?: boolean;
    groupExists?: boolean;
    photo?: string | null;
    cohortModality: Modality;
    organization?: string | null;
    cohort?: string | null;
    uuid: string;
    availableCoachingPlan: boolean;
    productType?: ProductType;
    jobTitle?: string;
    department?: string;
    officeLocation?: string;
    managerEmail?: string;
    managerFirstName?: string;
    managerLastName?: string;
    modulesAvailable?: boolean;
    monthInCurrentRole?: number;
    yearsInCurrentRole?: number;
    directReportsNumber?: number;
    lang?: Language;
    cohortAvailableLanguages?: {
        [langKey: string]: string;
    };
    browserSessionTtl: number | null;
    unexpected: boolean;
    organizationSlug: string;
    guestSessionsEnabled?: boolean;

    reflectionPopupData: {
        coachingSessionId: number;
        moduleId?: number;
        rank?: number;
    } | null;

    coachMatchingFinished: boolean;
    practiceLab: boolean;
    eligibleSelectModule: boolean;
    isOverdue: boolean;

    constructor(data) {
        const userData: Partial<UserApi> = this.getUserData(data);
        const userRole = this.defineUserRole(data);
        this.id = userData?.id ?? data?.id;
        this.email = userData?.email ?? data?.email;
        this.firstName = userData?.first_name ?? data?.first_name;
        this.lastName = userData?.last_name ?? data?.last_name;
        this.uuid = userData?.uuid ?? data?.uuid;
        this.role = userRole;
        this.journeyLength = userData?.journey_length ?? data?.journey_length;
        this.onboardingFinished = userData?.onboarding_finished;
        this.offboardingPassed = userData?.offboarding_passed;
        this.module =
            userRole === Role.Participant && userData?.current_module
                ? new UserEnrollModule(userData?.current_module)
                : null;
        this.enrolledModules =
            userRole === Role.Participant
                ? userData?.enrolled_modules?.map(module => new UserEnrollModule(module))
                : [];
        this.calendar_setup_confirmed = userData?.calendar_setup_confirmed ?? data?.calendar_setup_confirmed;
        this.cohortExists = userData?.cohort_exists ?? data?.cohort_exists;
        this.groupExists = userData?.group_exists ?? data?.group_exists;
        this.photo = userData?.photo;
        this.cohortModality = userData.cohort_modality;
        this.cohort = userData.cohort ?? data.cohort;
        this.organization = userData.organization ?? data.organization;
        this.productType = userData?.product_type;
        this.jobTitle = userData?.job_title;
        this.department = userData?.department;
        this.officeLocation = userData?.office_location;
        this.managerEmail = userData?.manager_email;
        this.managerFirstName = userData?.manager_first_name;
        this.managerLastName = userData?.manager_last_name;
        this.monthInCurrentRole = userData?.months_in_current_role;
        this.yearsInCurrentRole = userData?.years_in_current_role;
        this.directReportsNumber = userData?.direct_reports_number;
        this.cohortAvailableLanguages = userData?.cohort_available_languages;
        this.photo = userData?.photo;
        this.lang = userData?.language_code;
        this.availableCoachingPlan = userData?.available_coaching_plan;
        this.reflectionPopupData =
            isEmptyObject(userData?.reflection_popup_init_data) || !("reflection_popup_init_data" in userData)
                ? null
                : {
                      coachingSessionId: userData?.reflection_popup_init_data?.["coaching_session"],
                      moduleId: userData?.reflection_popup_init_data?.["module"],
                      rank: userData?.reflection_popup_init_data?.["rank"]
                  };
        this.browserSessionTtl = userData?.browser_session_ttl;
        this.unexpected = userData?.unexpected_oauth;
        this.organizationSlug = userData?.organization_slug;
        this.coachMatchingFinished = userData?.coach_matching_finished || false;
        this.practiceLab = userData?.practice_lab || false;
        this.eligibleSelectModule = userData?.eligible_select_module || false;
        this.isOverdue = userData?.is_overdue || false;
        this.modulesAvailable = !!userData?.module_availability;
        this.guestSessionsEnabled = !!userData.guest_sessions_enabled;
        this.enrollmentsAllowed = !!userData.enrollments_allowed;
    }

    static defineUserModule?(enrolledModules: EnrolledModule[]) {
        const now = DateTime.now();
        const currentModule = enrolledModules?.find(module => {
            const firstPhaseStart = DateTime.fromISO(module.first_phase_start);
            const lastPhaseEnd = DateTime.fromISO(module.last_phase_end);

            return firstPhaseStart < now && now < lastPhaseEnd;
        });
        if (currentModule) {
            return new UserEnrollModule(currentModule);
        }

        const futureModules = findFutureModule(enrolledModules);
        if (futureModules?.length) {
            return new UserEnrollModule(futureModules[0]);
        }

        const pastModules = findPastModule(enrolledModules);
        if (pastModules?.length) {
            return new UserEnrollModule(pastModules[0]);
        }

        return enrolledModules?.length ? new UserEnrollModule(enrolledModules[enrolledModules.length - 1]) : null;
    }

    private defineUserRole?(data) {
        if ("user_role" in data) {
            return data.user_role;
        }

        return Object.keys(data).find(key => allUserRoles.includes(key)) as Role;
    }

    private getUserData?(data) {
        const user = Object.entries(data).find(([key]) => allUserRoles.includes(key));
        if (!user) {
            return data;
        }

        const [_, userData] = user;
        return userData;
    }
}

export default User;
