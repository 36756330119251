import React, { useMemo } from "react";
import classNames from "classnames";

import { useAuthContext } from "main-app/context/Auth";
import { useStoreContext } from "main-app/context/GlobalStore";
import ProductTypeLogo from "main-app/shared/ProductTypeLogo";
import BackButton from "main-app/shared/back-btn/BackButton";

import ParticipantLayoutHeader from "main-app/elements/participant-layout-header/ParticipantLayoutHeader";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import useMediaQuery from "common/hooks/use-media-query";
import UserHeaderDropdown from "main-app/elements/participant-layout-header/UserHeaderDropdown";
import { MAX_WIDTH_MOBILE_MEDIA } from "main-app/constants";

import "./styles.scss";

interface IProps {
    header?: React.ReactNode;
    pageTitle?: React.ReactNode;
    withProfileHeader?: boolean;
    withoutHeader?: boolean;
    headerVariant?: "default" | "gray";
    showBackBtn?: boolean;
    showProfilePhoto?: boolean;
    isSimpleHeader?: boolean;
    showAvatarOnMobile?: boolean;
    withFooter?: boolean;
    children: React.ReactNode;
    withUserDropdownWithoutMargin?: boolean;
}

const SimpleLayout: React.FC<IProps> = ({
    children,
    header = null,
    pageTitle = null,
    withProfileHeader = false,
    withoutHeader = false,
    headerVariant = "default",
    showBackBtn = false,
    isSimpleHeader,
    showAvatarOnMobile = false,
    withFooter = true,
    showProfilePhoto = false,
    withUserDropdownWithoutMargin = false
}) => {
    const { t } = useTranslation();
    const { logout, user } = useAuthContext();
    const { resetState } = useStoreContext();
    const isMobile = useMediaQuery(MAX_WIDTH_MOBILE_MEDIA);

    const handleLogout = async e => {
        e.preventDefault();

        logout();
    };

    const navItem = useMemo(() => {
        if (isSimpleHeader) {
            return <ProductTypeLogo width={26} heigth={34} />;
        }

        if (withProfileHeader) {
            return <ParticipantLayoutHeader withBurgerMenu={false} header={header} fullWidth showLogo={false} />;
        }

        if ((showProfilePhoto && !isMobile) || (showProfilePhoto && showAvatarOnMobile)) {
            return (
                <UserHeaderDropdown
                    handleLogout={handleLogout}
                    user={user}
                    className={classNames("simple-layout-user-dropdown", {
                        "ml-auto": !withProfileHeader && !withUserDropdownWithoutMargin
                    })}
                />
            );
        }

        if (!showProfilePhoto && (!withProfileHeader || isMobile)) {
            return (
                <div>
                    <a href="#" className="text-dark" onClick={handleLogout}>
                        {t("Sign Out")}
                    </a>
                </div>
            );
        }
    }, [withProfileHeader, showProfilePhoto, showAvatarOnMobile, isSimpleHeader, isMobile]);

    return (
        <div className={"wrapper flex-column"}>
            <header className={classNames("header-sticky", { "bg-simple-gray": headerVariant === "gray" })}>
                <div className="container">
                    <nav className={classNames("navbar navbar-expand-lg navbar-simple")}>
                        {!withProfileHeader && !withoutHeader && (
                            <Link to={"/"} className="home-link d-none-mobile">
                                <ProductTypeLogo width={26} heigth={34} />
                            </Link>
                        )}
                        {header && <span className="header-center header-center-mobile mx-auto">{header}</span>}
                        {navItem}
                    </nav>
                </div>
            </header>
            <main className="main w-100">
                <div className="container">
                    {pageTitle && (
                        <div className="page-title">
                            <h1 className="h1 font-extrabold text-center mb-6">{pageTitle}</h1>
                        </div>
                    )}
                    {children}
                </div>
            </main>
            {withFooter && <footer className="footer"></footer>}
            {showBackBtn && <BackButton className="color-brand back-btn-float" />}
        </div>
    );
};

export default SimpleLayout;
