import React from "react";
import classNames from "classnames";
import { Chevron } from "main-app/svgs";

import "./styles.scss";

type Props = {
    active: boolean;
    width?: number;
    height?: number;
    variant?: "brand" | "light" | "accent";
};

const ChevronToggle = ({ active, variant = "brand", width, height }: Props) => {
    return (
        <div className={classNames("chevron-toggle", { active })}>
            <Chevron variant={variant} width={width} height={height} />
        </div>
    );
};

export default ChevronToggle;
