import React from "react";
import { Navigate, useParams } from "react-router-dom";

import ParticipantLayout from "main-app/layouts/ParticipantLayout";
import { useAuthContext } from "main-app/context/Auth";
import LeftSidebar from "main-app/components/dashboard/LeftSidebar";
import { Simulab } from "main-app/widgets/simulab";
import { SimulabContextProvider, SimulabHeader } from "main-app/entities/simulab";
import { MAX_WIDTH_MOBILE_MEDIA } from "main-app/constants";
import useMediaQuery from "common/hooks/use-media-query";
import { SimulabCareLink } from "main-app/features/simulab-chat";

import "./styles.scss";

export const SimulabChatPage = () => {
    const { user } = useAuthContext();
    const isMobile = useMediaQuery(MAX_WIDTH_MOBILE_MEDIA);
    const params = useParams();

    if (!user?.practiceLab) {
        return <Navigate to="/404" replace />;
    }

    return (
        <SimulabContextProvider conversationId={params.conversationId}>
            <ParticipantLayout
                className="simulab-layout"
                noPadding
                showLogoOnMobile={false}
                leftSidebar={<LeftSidebar closedMenu />}
                title={
                    !isMobile && (
                        <SimulabHeader showCoacheesLink withFeedbackReport withTooltip statusTagVariant="outlined" />
                    )
                }
                header={isMobile && <SimulabHeader withFeedbackReport withTooltip statusTagVariant="outlined" />}
            >
                <Simulab />
                <SimulabCareLink />
            </ParticipantLayout>
        </SimulabContextProvider>
    );
};
