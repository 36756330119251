import React from "react";
import { useNavigate } from "react-router-dom";
import sanitizeHtml from "sanitize-html";
import { useTranslation } from "react-i18next";

import SimpleLayout from "main-app/layouts/SimpleLayout";
import { CARE_TEAM_EMAIL, JS_CONF, MAX_WIDTH_MOBILE_MEDIA } from "main-app/constants";
import useMediaQuery from "common/hooks/use-media-query";
import useWaitingListText from "main-app/api/use-waiting-list-text";
import { useAuthContext } from "main-app/context/Auth";

import { onboardingProfileSetupURL } from "../onboarding/constants";
import WaitingListHeader from "./WaitingListHeader";

import "./styles.scss";

interface IProps {}

const WaitingListPage: React.FC<IProps> = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { user } = useAuthContext();
    const { data } = useWaitingListText();
    const isMobile = useMediaQuery(MAX_WIDTH_MOBILE_MEDIA);

    if (user?.cohortExists) {
        navigate(onboardingProfileSetupURL);
    }

    const isUnexpectedUser = user?.unexpected;

    return (
        <SimpleLayout
            header={!isMobile ? <WaitingListHeader /> : null}
            showProfilePhoto
            showAvatarOnMobile
            withUserDropdownWithoutMargin
        >
            <div className="waiting-list__container text-center">
                <div className="waiting-list__content">
                    <h1 className="waiting-list__title m-3 h1 font-extrabold">
                        {isUnexpectedUser ? t("Welcome, we weren’t expecting you!") : data?.textTitleData}
                    </h1>

                    <p
                        className="waiting-list__text color-gray"
                        dangerouslySetInnerHTML={{
                            __html: sanitizeHtml(
                                isUnexpectedUser
                                    ? t(
                                          "Click the button below to contact Wiser’s Care Team. Once you reach out to us, we’ll get in touch with your program’s sponsor to figure out why your account hasn’t been created in our system yet."
                                      )
                                    : data?.text,
                                { allowedTags: [] }
                            )
                        }}
                    />

                    {!isUnexpectedUser ? (
                        <p className="m-2 fs-14 color-gray">
                            {data?.textSubtitleData}{" "}
                            <a href={`mailto:${data?.contactEmail}`} className="waiting-list__help-link fs-14">
                                {data?.textInfoEmailData}
                            </a>
                        </p>
                    ) : (
                        <div className="btn btn--brand">
                            <a href={CARE_TEAM_EMAIL} className="color-inherit">
                                {t("Contact Care")}
                            </a>
                        </div>
                    )}
                </div>
                <img
                    alt={t("Big blinking orange owl")}
                    src={JS_CONF?.static + "img/owl-wiser.gif"}
                    className="waiting-list__image"
                />
            </div>
        </SimpleLayout>
    );
};

export default WaitingListPage;
