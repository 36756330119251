import { useCallback } from "react";
import { useQuery } from "@tanstack/react-query";

import { http } from "common/http";
import { ServerStateKeys } from "main-app/constants";
import Urls from "main-app/api/urls";
import { OnboardingStep } from "main-app/models/onboarding";
import { useAuthContext } from "main-app/context/Auth";
import { isParticipant } from "main-app/utils/gates/user";

const getSteps = async () => {
    const { data } = await http.get(Urls.onboardingSteps());
    return data;
};

export default function useOnboardingSteps() {
    const { user } = useAuthContext();

    return useQuery([ServerStateKeys.onboardingSteps], getSteps, {
        select: useCallback(data => {
            return new OnboardingStep(data);
        }, []),
        enabled: isParticipant(user)
    });
}
