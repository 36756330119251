import { ModuleStatus } from "main-app/constants";
import { isEmptyObject } from "main-app/utils/common";
import { Cohort, CohortApi } from "./cohort";
import { CoachSession, Invitation, InvitationApi } from "./session";
import { AttendanceStatus, TranslateField } from "./types";
import { EnrolledModule, UserEnrollModule } from "./user";

interface IParticipantBase {
    first_name: string;
    last_name: string;
    id: number;
    photo: string | null;
    is_guest: boolean;
}

interface ParticipantApi extends IParticipantBase {
    cohorts: CohortApi;
    current_module: Partial<{ version_id: number; version_name: TranslateField; status: ModuleStatus }>;
    upcoming_sessions: any[];
}

interface GroupSessionParticipantApi extends IParticipantBase {
    assigned_next_module: EnrolledModule;
    enrolled_modules: EnrolledModule[];
    attendance_status: AttendanceStatus; // TODO make type of statuses;
    invitation: InvitationApi;
    journey_length: number;
    is_guest: boolean;
}

class ParticipantBase {
    firstName: string;
    lastName: string;
    id: number;
    photo: string | null;
    isGuest: boolean;
    constructor(data: IParticipantBase) {
        this.firstName = data?.first_name;
        this.lastName = data?.last_name;
        this.id = data?.id;
        this.photo = data?.photo;
        this.isGuest = data?.is_guest || false;
    }
}

class Participant extends ParticipantBase {
    cohort: Cohort;
    currentModule: {
        status: ModuleStatus;
        versionId: number;
        versionName: TranslateField;
    };
    upcomingSessions?: CoachSession[];

    constructor(data: ParticipantApi) {
        super(data);
        this.cohort = !isEmptyObject(data.cohorts) ? new Cohort(data.cohorts) : null;
        this.currentModule = {
            status: data.current_module.status,
            versionId: data.current_module.version_id,
            versionName: data.current_module.version_name
        };
        this.upcomingSessions = data?.upcoming_sessions?.map(session => new CoachSession(session)) ?? [];
    }
}

class GroupSessionParticipant extends ParticipantBase {
    assignedNextModule: UserEnrollModule | null;
    enrolledModules: UserEnrollModule[];
    invitation: Invitation;
    journeyLength: number;
    attendanceStatus: AttendanceStatus;

    constructor(data: GroupSessionParticipantApi) {
        super(data);
        this.assignedNextModule = data.assigned_next_module ? new UserEnrollModule(data.assigned_next_module) : null;
        this.enrolledModules = data?.enrolled_modules?.map(module => new UserEnrollModule(module)) || [];
        this.invitation = new Invitation(data.invitation);
        this.journeyLength = data?.journey_length;
        this.attendanceStatus = data?.attendance_status;
    }
}

export { Participant, ParticipantBase, GroupSessionParticipant, GroupSessionParticipantApi, ParticipantApi };
