import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { useQueryClient } from "@tanstack/react-query";

import { getErrorMessages } from "common/utils/get-error-messages";
import { http } from "common/http";
import { HTTPStatus } from "common/constants";
import useIsMobileLandscape from "common/hooks/use-is-mobile-landscape";

import {
    CoachUrls,
    PRODUCT_TYPE_WITH_ENROLLMENTS,
    ParticipantUrls,
    userRoles,
    ServerStateKeys
} from "main-app/constants";
import useNavigationOnboarding from "main-app/utils/hooks/use-navigation-onboarding";
import useSsoEmail from "main-app/api/mutations/use-sso-email";
import { useAuthContext } from "main-app/context/Auth";
import User, { UserApi } from "main-app/models/user";
import Urls from "main-app/api/urls";
import { loginSchema } from "main-app/validation";
import WarningToast from "main-app/elements/warning-toast/WarningToast";
import { getIndexPage } from "common/utils/get-index-page";
import { SsoOrganization } from "main-app/models/api/sso";
import TextInput from "main-app/shared/input/TextInput";
import { matchingRouteByRole } from "main-app/utils/common";

import { CopywriteText } from "main-app/shared/copywrite/CopywriteText";
import { WarningMessage } from "../onboarding/components/WarningMessage";
import SsoModal from "./SsoModal";
import EmailModal from "./EmailModal";
import VerificationModal from "./VerificationModal";

import "main-app/assets/auth.scss";

interface IProps {}

type LocationState = { showMessage: boolean; from: string };

const showDashboard = (user: User) => {
    return PRODUCT_TYPE_WITH_ENROLLMENTS.includes(user.productType);
};

const Login: React.FC<IProps> = () => {
    const { t } = useTranslation();
    const {
        register,
        handleSubmit,
        getValues,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(loginSchema)
    });
    const queryClient = useQueryClient();
    const location = useLocation();
    const { getNextStepUrl } = useNavigationOnboarding();
    const { setAuthUser, user, setLoading } = useAuthContext();
    const [errorsMsg, setErrorsMsg] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [showSsoModal, setShowSsoModal] = useState(false);
    const [ssoData, setSsoData] = useState<SsoOrganization>(null);
    const checkSSOEmail = useSsoEmail();
    const [verifyModal, setVerifyModal] = useState({ show: false, email: null, tfa_key: null });
    const isLandscape = useIsMobileLandscape();

    const locationState = location.state as LocationState;
    const showToastMessage = locationState?.showMessage;
    const currentUrlPath = locationState?.from;

    const navigate = useCallback((url: string): void => window.location.replace(url), []);

    useEffect(() => {
        if (!user) return;

        navigateUser(user);
    }, [user]);

    const onLoginActions = (data: UserApi & { token: string }) => {
        const currentUser = new User(data);
        setAuthUser(currentUser);
        setLoading(false);
        navigateUser(currentUser);
    };

    const onSubmitLogin = async values => {
        try {
            const result = await http.post(Urls.login(), values);
            queryClient.invalidateQueries([ServerStateKeys.ProductType]);
            if ("tfa_key" in result.data) {
                setVerifyModal({
                    show: true,
                    email: values.email,
                    tfa_key: result.data.tfa_key
                });
            } else {
                onLoginActions(result.data);
            }
        } catch (error) {
            const errorStatus = error?.response?.status;
            if (errorStatus === HTTPStatus.BAD_REQUEST || errorStatus === HTTPStatus.TOO_MANY_REQUESTS) {
                setErrorsMsg(getErrorMessages(error));
            }
            console.log(error);
        }
    };

    const navigateUser = (user: User) => {
        const isCoach = userRoles.coach.includes(user.role);

        if (!user.cohortExists && !isCoach) {
            navigate(ParticipantUrls.WAITING_LIST);
            return;
        }

        const hasPermissionOnRoute = currentUrlPath ? matchingRouteByRole(user.role, currentUrlPath) : false;

        if (hasPermissionOnRoute) {
            navigate(currentUrlPath);
            return;
        }

        if (isCoach) {
            navigate(CoachUrls.SESSIONS_LIST);
        } else {
            const isOnboardingCompleted = user.onboardingFinished;

            if (isOnboardingCompleted) {
                const page = getIndexPage(user, showDashboard(user));
                navigate(page);
                return;
            }
            getNextStepUrl().then(step => navigate(step.url));
        }
    };

    const checkSsoUser = async () => {
        const email: string = getValues("email");
        const { data } = await checkSSOEmail.mutateAsync(email);

        if (data) {
            setSsoData(data);
            setShowSsoModal(true);
        }
    };

    return (
        <>
            <section
                className={classNames("registration-section welcome-section reset-section login vh-100-xs", {
                    "message-above": showToastMessage,
                    "is-landscape": isLandscape
                })}
            >
                <h3 className="registration-heading__bold mb-reg__heading">{t("Welcome to Wiser!")}</h3>
                <form className="registration-form mb-auto" onSubmit={handleSubmit(onSubmitLogin)}>
                    <WarningMessage message={errorsMsg} align="start" />
                    <TextInput
                        name="email"
                        type="text"
                        groupClassName="mb-2"
                        placeholder={t("Email Address")}
                        register={register}
                        onBlur={checkSsoUser}
                        errors={errors}
                        isError={errorsMsg}
                    />
                    <TextInput
                        name="password"
                        type="password"
                        placeholder={t("Password")}
                        groupClassName="mb-2"
                        register={register}
                        errors={errors}
                        isError={errorsMsg}
                    />
                    <div className="forgot-password">
                        <span>{t("Forgot your password?")}</span>
                        <button type="button" className="reset-modal font-extrabold" onClick={() => setOpenModal(true)}>
                            {t("Reset")}
                        </button>
                    </div>
                    <div className="form-group mt-input-reset mt-input-signin mg-auto d-block text-center">
                        <button
                            className="registration-input__register registration-input__mobile registration-input__reset registration-input__register btn-flip"
                            data-back={t("Sign in")}
                            data-front={t("Sign in")}
                            aria-label={t("Sign in")}
                            type="submit"
                        ></button>
                    </div>
                </form>
            </section>

            <CopywriteText
                className={classNames("text-center copywrite-text-bottom", { "is-landscape": isLandscape })}
            />
            <EmailModal active={openModal} setActive={setOpenModal} />
            {showToastMessage && (
                <WarningToast top="70px" left="50%">
                    <b>{t("Oops!")}</b>{" "}
                    {t("Looks like you’ve already registered with that email. Please sign in below")}
                </WarningToast>
            )}
            <VerificationModal
                email={verifyModal.email}
                show={verifyModal.show}
                tfa_key={verifyModal.tfa_key}
                onClose={() => {}}
                onSubmit={onLoginActions}
            />
            <SsoModal active={showSsoModal} ssoData={ssoData} onClose={() => setShowSsoModal(false)} />
        </>
    );
};

export default Login;
