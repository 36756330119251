import React, { useState } from "react";
import { useForm } from "react-hook-form";

import Skeleton from "common/components/Skeleton/Skeleton";
import useNps from "main-app/api/use-nps";
import LikertRadio from "main-app/shared/likert/LikertRadio";
import { http } from "common/http";
import TextAreaOffboarding from "main-app/shared/input/TextAreaOffboarding";
import Urls from "main-app/api/urls";
import { getErrorMessages } from "common/utils/get-error-messages";
import DividerLine from "main-app/shared/divider/DividerLine";

import { DEFAULT_LIKERT_COLOR_VAR, ERROR_TEXT } from "main-app/constants";
import { WarningMessage } from "main-app/components/onboarding/components/WarningMessage";
import QuestionRow from "../diagnostic-question/QuestionRow";
import { withTranslation } from "common/utils/lang";

type Props = {
    errorMsg: any;
    showDividerLine?: boolean;
    order?: number | null;
    onSubmitNps: () => void;
};

const NpsQuestion = ({ onSubmitNps, errorMsg, showDividerLine = true, order = 2 }: Props) => {
    const { data, isLoading } = useNps();
    const { register, control, getValues } = useForm();
    const [showFreeResponse, setShowFreeResponse] = useState(false);
    const [npsData, setNpsData] = useState(null);
    const [submitError, setSubmitError] = useState(null);

    const getColor = index => {
        const foundColorPallete = data.questionData.colorRange.find(
            colorItem => index >= colorItem.from && index <= colorItem.to
        );
        return foundColorPallete ? foundColorPallete.color : DEFAULT_LIKERT_COLOR_VAR;
    };

    const { onChange, ...registerData } = register("rating");

    const onChangeLikert = (e, color) => {
        onChange(e);
        sendNps(color);
        setShowFreeResponse(true);
    };

    const sendNps = async color => {
        const submitData = {
            rating: +getValues("rating"),
            color,
            question: data.id
        };

        try {
            if (npsData) {
                await http.patch(Urls.sendNps(npsData.id), submitData);
            } else {
                const { data } = await http.post(Urls.sendNps(), submitData);
                setNpsData(data);
            }
            onSubmitNps();
        } catch (error) {
            console.log(getErrorMessages(error));
            setSubmitError(ERROR_TEXT);
        }
    };

    const sendFreeResponse = async () => {
        try {
            await http.patch(Urls.sendNps(npsData.question), {
                ...npsData,
                free_response: getValues("free_response_prompt")
            });
        } catch (error) {
            console.log(getErrorMessages(error));
            setSubmitError(ERROR_TEXT);
        }
    };

    return (
        <div className="nps-questions">
            {isLoading && !data ? (
                <Skeleton rows={2} height={40} />
            ) : (
                <>
                    <QuestionRow
                        question={withTranslation(data?.questionData?.question)}
                        order={order}
                        isRequired
                        className="pl-3"
                    />
                    <WarningMessage message={submitError || errorMsg} />
                    <div className={`mb-4 level-choice level-choice--colored d-flex justify-content-center`}>
                        {data?.questionData?.options?.map((checkbox, index, options) => {
                            const color = getColor(index + 1);

                            return (
                                <LikertRadio
                                    name="rating"
                                    value={`${index + 1}`}
                                    id={`${index + 1}`}
                                    control={control}
                                    label={withTranslation(checkbox?.response)}
                                    isFirstOption={index === 0}
                                    isLastOption={index === options.length - 1}
                                    register={() => ({ ...registerData, onChange: e => onChangeLikert(e, color) })}
                                    key={checkbox.uuid}
                                    color={color}
                                />
                            );
                        })}
                    </div>
                    {showFreeResponse && data?.freeResponseVisible ? (
                        <TextAreaOffboarding
                            name="free_response_prompt"
                            className="mt-40 text-left"
                            register={register}
                            placeholder={data?.freeResponsePrompt}
                            rows={2}
                            onBlur={sendFreeResponse}
                            headerText={data?.freeResponseQuestion}
                        />
                    ) : null}
                    {withTranslation(data?.questionData?.question) && showDividerLine ? <DividerLine /> : null}
                </>
            )}
        </div>
    );
};

export default NpsQuestion;
