import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

type Props = {
    content: string;
    className?: string;
};

const MarkdownRenderer = ({ content, className = "" }: Props) => {
    return (
        <ReactMarkdown
            children={content}
            className={className}
            components={{ strong: ({ node, ...props }) => <strong style={{ fontWeight: 700 }} {...props} /> }}
            remarkPlugins={[remarkGfm]}
        />
    );
};

export default MarkdownRenderer;
