import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

import { coachCalendarSetupRoutes } from "main-app/routes";
import { CoachCalendarStepUrls } from "./../constants/index";

import "./styles.scss";

type Props = {
    activeStep: CoachCalendarStepUrls;
};

export const StepsNavigator = ({ activeStep }: Props) => {
    const sortedNavigation = useMemo(() => {
        return [...coachCalendarSetupRoutes].sort((a, b) => a.order - b.order);
    }, [coachCalendarSetupRoutes]);

    const activeStepOrder = useMemo(() => {
        return sortedNavigation.find(item => item.path === activeStep)?.order;
    }, [sortedNavigation]);

    return (
        <div className="step-holder steps-container d-flex justify-content-center">
            <ul className="step-bar coach-step-bar">
                {sortedNavigation?.map((item, index) => (
                    <li
                        key={item.path}
                        className={classNames({
                            active: item.path === activeStep,
                            passed: activeStepOrder > item.order
                        })}
                    >
                        {item.path === activeStep ? (
                            <span
                                className={classNames("step-item", {
                                    "no-line": coachCalendarSetupRoutes.length === 1
                                })}
                            ></span>
                        ) : (
                            <Link
                                to={item.path}
                                onClick={event => {
                                    if (index + 1 > item.order + 1) {
                                        event.preventDefault();
                                    }
                                }}
                                className={classNames("step-item text-hide", {
                                    "no-line": coachCalendarSetupRoutes.length === 1
                                })}
                            >
                                {item.path}
                            </Link>
                        )}
                    </li>
                ))}
            </ul>
        </div>
    );
};
