import React from "react";

import { ITrackPractice } from "Manager/chapter/models/types";
import TrackPracticeColumns from "./TrackPractice/TrackPracticeColumns";
import useMediaQuery from "common/hooks/use-media-query";
import { MAX_WIDTH_MOBILE_MEDIA } from "main-app/constants";
import TrackPracticeColumnsMobile from "./TrackPractice/TrackPracticeColumnsMobile";
import TrackPracticeDescription from "./TrackPractice/TrackPracticeDescription";
import { withTranslation } from "common/utils/lang";

interface IProps {
    component: ITrackPractice;
    participantName: string;
}

const TrackPracticeResponses: React.FC<IProps> = ({ component, participantName }) => {
    const matches = useMediaQuery(MAX_WIDTH_MOBILE_MEDIA);

    return (
        <div className="component-item component-container">
            {participantName && (
                <h1 className="question-responses-text text-center">{participantName}'s Practice Tracker</h1>
            )}
            {matches && <h6 className="text-center">{withTranslation(component?.title)}</h6>}
            <TrackPracticeDescription description={withTranslation(component?.assignment_description)} />
            {matches ? (
                <TrackPracticeColumnsMobile columns={component?.columns} rows={component?.answer_data?.rows} />
            ) : (
                <TrackPracticeColumns columns={component.columns} rows={component?.answer_data?.rows} />
            )}
        </div>
    );
};

export default TrackPracticeResponses;
