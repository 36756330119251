export class Resource {
    name: {
        en: string;
    };
    duration: null | string;
    order: number;
    source: string;
    url: string;

    constructor(data) {
        this.name = data.name_data;
        this.duration = data.duration;
        this.order = data.order;
        this.source = data.source;
        this.url = data.url;
    }
}
