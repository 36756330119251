import { TranslationObj } from "common/utils/lang";
import { AnswerOption, AnswerOptionApi } from "main-app/entities/pairing-question";
import { SURVEY_STAGE, SURVEY_STATUS } from "../constants";

export type SurveyStage = (typeof SURVEY_STAGE)[keyof typeof SURVEY_STAGE];
export type SurveyStatus = (typeof SURVEY_STATUS)[keyof typeof SURVEY_STATUS];

export interface SurveySubmitData {
    question: number;
    answer: number | null;
    free_response: string | null;
}

export interface SurveyTemplateApi {
    id: number;
    internal_name: string;
    header_text: string;
    body_text: string | null;
}

export interface SurveyParticipantApi {
    id: number;
    first_name: string;
    last_name: string;
}

export interface SurveyQuestionOptionApi {
    option_text: TranslationObj;
}

export interface SurveyQuestionPreviosAnswer {
    [key: number]: string;
}

export interface SurveyQuestionApi {
    id: number;
    question_data: TranslationObj;
    is_free_response: boolean;
    free_response_prompt: TranslationObj;
    answer_options: AnswerOptionApi | null;
    previous_answer: SurveyQuestionPreviosAnswer;
}

export interface SurveyDataApi {
    id: number;
    survey_template: SurveyTemplateApi;
    participant: SurveyParticipantApi;
    respondent_first_name: string;
    respondent_last_name: string;
    respondent_to_participant_relationship: string;
    survey_stage: SurveyStage;
    open_stamp: string;
    due_stamp: string;
    started_stamp: string;
    completed_stamp: string | null;
    questions: SurveyQuestionApi[];
    message?: SurveyStatus;
    is_self_assessment?: boolean;
}

export class SurveyTemplate {
    id: number;
    internalName: string;
    headerText: string;
    bodyText: string | null;

    constructor(data: SurveyTemplateApi) {
        this.id = data.id;
        this.internalName = data.internal_name;
        this.headerText = data.header_text;
        this.bodyText = data.body_text;
    }
}

export class SurveyParticipant {
    id: number;
    firstName: string;
    lastName: string;

    constructor(data: SurveyParticipantApi) {
        this.id = data.id;
        this.firstName = data.first_name;
        this.lastName = data.last_name;
    }
}

export class SurveyQuestion {
    id: number;
    questionData: TranslationObj;
    isFreeResponse: boolean;
    freeResponsePrompt: TranslationObj;
    answerOptions: AnswerOption | null;
    previousAnswer: SurveyQuestionPreviosAnswer;

    constructor(data: SurveyQuestionApi) {
        this.id = data.id;
        this.questionData = data.question_data;
        this.isFreeResponse = data.is_free_response;
        this.freeResponsePrompt = data.free_response_prompt;
        this.answerOptions = data.answer_options ? new AnswerOption(data.answer_options) : null;
        this.previousAnswer = data.previous_answer;
    }
}

export class Survey {
    id: number;
    surveyTemplate: SurveyTemplate;
    participant: SurveyParticipant;
    respondentFirstName: string;
    respondentLastName: string;
    respondentToParticipantRelationship: string;
    surveyStage: SurveyStage;
    openStamp: string;
    dueStamp: string;
    startedStamp: string;
    completedStamp: string | null;
    questions: SurveyQuestion[];
    message?: SurveyStatus;

    constructor(data: SurveyDataApi) {
        this.id = data?.id;
        this.message = data?.message || null;
        this.surveyTemplate = data?.survey_template ? new SurveyTemplate(data.survey_template) : null;
        this.participant = data?.participant ? new SurveyParticipant(data.participant) : null;
        this.respondentFirstName = data?.respondent_first_name || "";
        this.respondentLastName = data?.respondent_last_name || "";
        this.respondentToParticipantRelationship = data?.respondent_to_participant_relationship || "";
        this.surveyStage = data?.survey_stage ?? null;
        this.openStamp = data?.open_stamp ?? null;
        this.dueStamp = data?.due_stamp ?? null;
        this.startedStamp = data?.started_stamp ?? null;
        this.completedStamp = data?.completed_stamp ?? null;
        this.questions = data?.questions?.map(question => new SurveyQuestion(question)) || [];
    }
}
