import React, { memo } from "react";
import Heading from "common/components/Heading/Heading";

type Props = {
    Icon: React.ReactNode;
    title: string;
    description: string | React.ReactNode;
};

export const SimulabDetailItem = memo(({ Icon, title, description }: Props) => {
    return (
        <div className="simulab-detail-item">
            <div className="simulab-detail-item__icon">{Icon}</div>
            <div className="simulab-detail-item__body">
                <Heading tag="h2" fontSize={16} textAlign="center" className="simulab-detail-item__title">
                    {title}
                </Heading>
                <p className="simulab-detail-item__description">{description}</p>
            </div>
        </div>
    );
});
