import React, { forwardRef } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useWatch } from "react-hook-form";

import useMediaQuery from "common/hooks/use-media-query";
import { MAX_WIDTH_MOBILE_MEDIA } from "main-app/constants";

interface IProps {
    label: string;
    name: string;
    isLastOption?: boolean;
    isFirstOption?: boolean;
    control: any;
    value: string;
    previousAnswer?: string;
    id: string;
    showLabel?: boolean;
    isFocusOnLabel: boolean;
    ref: any;
}

const LikertLabel = forwardRef<HTMLLabelElement, IProps>(
    (
        {
            label,
            isLastOption,
            isFirstOption,
            name,
            control,
            value,
            showLabel = true,
            isFocusOnLabel,
            id,
            previousAnswer
        },
        ref
    ) => {
        const { t } = useTranslation();
        const isMobileView = useMediaQuery(MAX_WIDTH_MOBILE_MEDIA);
        const answered = useWatch({ control, name });
        const isCurrentAnsweredOption = answered?.[name] === value;
        const firstOrLastOption = (isFirstOption || isLastOption) && !answered?.[name];
        const isNotEmptyLabel = label.trim().length;
        const show = showLabel && (!isMobileView || firstOrLastOption || isCurrentAnsweredOption);

        return (
            <label
                htmlFor={id}
                className={classNames("position-relative", { outline: isFocusOnLabel })}
                aria-label={isNotEmptyLabel ? label : t("Definition scale")}
                ref={ref}
            >
                {show && isNotEmptyLabel ? <span>{label}</span> : null}
                {previousAnswer && !answered && (
                    <>
                        <div className="likert-previous-answer-circle" />
                        <div className="likert-previous-answer-vertical-line" />
                        <p
                            className={classNames("color-accent likert-previous-answer-text", {
                                right: isLastOption,
                                left: isFirstOption
                            })}
                        >
                            You previously responded{" "}
                            {previousAnswer.length && previousAnswer !== " " ? (
                                <span className="font-extrabold text-capitalize">“{previousAnswer}“</span>
                            ) : (
                                ""
                            )}
                        </p>
                    </>
                )}
            </label>
        );
    }
);

export default LikertLabel;
