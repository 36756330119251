import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import classNames from "classnames";

import User, { UserEnrollModule } from "main-app/models/user";
import { dashboardURL } from "main-app/components/onboarding/constants";
import Button from "main-app/shared/button/Button";
import useMediaQuery from "common/hooks/use-media-query";
import { MAX_WIDTH_MOBILE_MEDIA } from "main-app/constants";
import { withTranslation } from "common/utils/lang";
import { useKeyPress } from "common/hooks/use-key-press";

type Props = {
    module: UserEnrollModule;
    user: User;
};

const ModuleArrowNavigation = ({ module, user }: Props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const params = useParams();
    const isMobileView = useMediaQuery(MAX_WIDTH_MOBILE_MEDIA);
    const onClickModuleNavArrow = (goTo: number) => {
        const moduleRank = goTo === 1 ? +params.moduleId + 1 : +params.moduleId - 1;

        if (moduleRank > 0 && moduleRank <= user?.journeyLength) {
            navigate(dashboardURL + `/${moduleRank}`);
        }
    };

    const modules = useMemo(() => Array.from({ length: user?.journeyLength }, (_, i) => i + 1), [user]);

    const isFirstModule = +params?.moduleId === modules?.[0];
    const isLastModule = +params?.moduleId === modules?.[modules?.length - 1];

    return (
        <div className="d-flex align-items-center justify-content-center" data-testid="module-arrow-navigation">
            <Button
                variant="default"
                className={classNames("mr-2 mr-md-3 p-1 circle-btn arrow-btn", { invisible: isFirstModule })}
                data-testid="previous-button"
                onClick={() => onClickModuleNavArrow(-1)}
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="8" height="13" viewBox="0 0 8 13" fill="none">
                    <path d="M7.125 1.25L1.875 6.5L7.125 11.75" stroke="#6F6F6F" strokeWidth="1.875" />
                </svg>
            </Button>
            <h3 className="main-subheader-module-title m-0" data-testid="module-arrow-navigation-name">
                {!isMobileView ? t("Module") : ""} {params?.moduleId}:{" "}
                {withTranslation(module?.name) || module?.versionName || t("Unassigned")}
            </h3>
            <Button
                variant="default"
                className={classNames("ml-2 ml-md-3 p-1 circle-btn arrow-btn", { invisible: isLastModule })}
                data-testid="next-button"
                onClick={() => onClickModuleNavArrow(1)}
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="8" height="13" viewBox="0 0 8 13" fill="none">
                    <path d="M0.875 11.75L6.125 6.5L0.875 1.25" stroke="#6F6F6F" strokeWidth="1.875" />
                </svg>
            </Button>
        </div>
    );
};

export default ModuleArrowNavigation;
