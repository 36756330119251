import React, { useState } from "react";
import { useTranslation } from "react-i18next";

interface IProps {
    onSort: (order: boolean) => void;
}

const TableHeader: React.FC<IProps> = ({ onSort }) => {
    const { t } = useTranslation();
    const [active, setActive] = useState(false);

    const toggleActive = () => {
        setActive(prev => !prev);
        onSort(active);
    };

    return (
        <thead>
            <tr>
                <th className="text-left">
                    <span>Cohort</span>
                </th>
                <th className="sortable sort-desc pl-0">
                    <span className="cursor-pointer" onClick={toggleActive}>
                        <span>Session Time</span>
                        <svg className="svg-16 ml-2">
                            {active ? (
                                <path d="m 1,9.2071068 7,-7 7,7" fill="none" stroke="currentColor" strokeWidth="2" />
                            ) : (
                                <path d="M1 1.5L8 8.5L15 1.5" fill="none" stroke="currentColor" strokeWidth="2" />
                            )}
                        </svg>
                    </span>
                </th>

                <th className="d-none d-lg-table-cell">
                    <span>Type</span>
                </th>
                <th className="">
                    <span>Participant(s)</span>
                </th>
                <th className="d-lg-table-cell"></th>
            </tr>
        </thead>
    );
};

export default TableHeader;
