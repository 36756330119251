import { DateTime } from "luxon";
import { ReflectionComponentType } from "main-app/constants";
import User from "main-app/models/user";

const isLikertComponent = (type: string) =>
    type === ReflectionComponentType.COLORED_LIKERT_GROUP || type === ReflectionComponentType.LIKERT_GROUP;
const isColoredLikertComponent = (type: string) => type === ReflectionComponentType.COLORED_LIKERT_GROUP;
const isLikertDefaultComponent = item => "options" in item;
const isFreeResponse = (type: string) => type === ReflectionComponentType.FREE_RESPONSE;

const isJourneyEndReached = (user: User) => {
    const lastModule = user?.enrolledModules?.[user?.enrolledModules?.length - 1];
    const lastSessionStarted =
        DateTime.fromISO(lastModule?.relatedSession?.session_time).toMillis() < DateTime.now().toMillis();

    return lastSessionStarted && lastModule?.id === user?.module.id;
};

export { isLikertComponent, isFreeResponse, isJourneyEndReached, isColoredLikertComponent, isLikertDefaultComponent };
