import React from "react";
import sanitizeHtml from "sanitize-html";
import { useTranslation } from "react-i18next";

import { WarningMessage } from "main-app/components/onboarding/components/WarningMessage";
import { withTranslation } from "common/utils/lang";
import QuestionRow from "../diagnostic-question/QuestionRow";
import TextInput from "main-app/shared/input/TextInput";

interface IProps {
    item: any; // TODO add typings
    error: any;
    register: any;
    name: string;
    order: number;
    className?: string;
}

const ReflectionQuestion: React.FC<IProps> = ({ item, register, name, error, order, className }) => {
    const { t } = useTranslation();

    return (
        <section className={className}>
            <QuestionRow
                order={order}
                question={sanitizeHtml(withTranslation(item.question), {
                    allowedTags: ["b", "i", "em", "strong", "a"]
                })}
            />
            <WarningMessage message={error} />
            <TextInput
                className="min-heigth-52"
                register={register}
                placeholder={withTranslation(item?.prompt) || t("Start typing...")}
                name={name}
                variant="rect"
            />
        </section>
    );
};

export default ReflectionQuestion;
