import { useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import { http } from "common/http";

import Urls from "main-app/api/urls";
import { ServerStateOnbordingKeys } from "../constants";

const getData = async () => {
    const { data } = await http.get(Urls.onboardingStep4());
    return data;
};

export default function useDiagnosticResults() {
    return useQuery([ServerStateOnbordingKeys.DiagnosticResults], getData, {
        select: useCallback(data => {
            return data; // TODO handle data by model
        }, [])
    });
}
