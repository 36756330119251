import React, { useCallback, useState } from "react";
import { Navigate, useParams } from "react-router-dom";

import DiagnosticSummaryQuestions from "main-app/elements/diagnostic-summary-questions/DiagnosticSummaryQuestions";
import useOnboardingResults from "main-app/api/use-onboarding-results";
import useBoolean from "common/hooks/use-boolean";
import DiagnosticResponsesModal from "main-app/elements/diagnostic-responses-modal/DiagnosticResponsesModal";
import { useTranslation } from "react-i18next";
import { WarningMessage } from "../onboarding/components/WarningMessage";
import { getErrorMessages } from "common/utils/get-error-messages";
import { HTTPStatus } from "common/constants";
import CategoryModal from "main-app/elements/category-modal/CategoryModal";
import { withTranslation } from "common/utils/lang";

interface IProps {
    participantName: string;
}

const OnboardingResults: React.FC<IProps> = ({ participantName }) => {
    const { t } = useTranslation();
    const params = useParams();
    const { data: diagnosticSummary, isLoading, isError, error } = useOnboardingResults(params.id);

    const { value: showDiagnosticResponseModal, setValue: setShowDiagnosticResponseModal } = useBoolean(false);
    const { value: showCategoryModal, setValue: setShowCategoryModal } = useBoolean();
    const [selectedCategory, setSelectedCategory] = useState(null);

    const onCloseDiagnostic = useCallback(() => {
        setShowDiagnosticResponseModal(false);
    }, []);

    const onOpenDiagnostic = useCallback(() => {
        setShowDiagnosticResponseModal(true);
    }, []);

    const onClickCategoryModalTooltip = useCallback(category => {
        setSelectedCategory(category);
        setShowCategoryModal(true);
    }, []);

    const onCloseCategoryModal = useCallback(() => {
        setShowCategoryModal(false);
        setSelectedCategory(null);
    }, []);

    if (isError && error?.response?.status === HTTPStatus.FORBIDDEN) {
        return <Navigate to={"/forbidden"} replace />;
    }

    return (
        <div>
            <h1 className="h1 font-extrabold text-center mb-4">{participantName}'s Onboarding</h1>
            <DiagnosticSummaryQuestions
                participantName={participantName}
                diagnosticSummary={diagnosticSummary}
                showQuestions
                isLoading={isLoading}
                showFullDiagnostic
                onFullDiagnosticeClick={onOpenDiagnostic}
                onTooltipClick={onClickCategoryModalTooltip}
            />
            <DiagnosticResponsesModal
                show={showDiagnosticResponseModal}
                onClose={onCloseDiagnostic}
                participantName={`${participantName}'s`}
                preProgramDiagnostics={diagnosticSummary?.pre_program_diagnostic}
            />
            <CategoryModal
                show={showCategoryModal}
                onClose={onCloseCategoryModal}
                categoryDescription={withTranslation(selectedCategory?.description)}
                categoryName={withTranslation(selectedCategory?.name)}
            />
            {isError ? (
                <WarningMessage
                    message={error ? getErrorMessages(error)?.join("") : t("An error occurred, please try again.")}
                />
            ) : null}
        </div>
    );
};

export default OnboardingResults;
