import React, { FunctionComponent, MutableRefObject, ReactElement } from "react";
import classNames from "classnames";

interface ISwiperPaginationProps {
    paginationRef: MutableRefObject<HTMLDivElement>;
    isVisible?: boolean;
}

const SwiperPagination: FunctionComponent<ISwiperPaginationProps> = ({
    paginationRef,
    isVisible
}: ISwiperPaginationProps): ReactElement => (
    <div ref={paginationRef} className={classNames("swiper-pagination", { "d-none": !isVisible })} />
);

export { SwiperPagination, type ISwiperPaginationProps };
