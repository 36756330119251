import React from "react";
import TrackPracticeMobileBoxes from "./TrackPracticeMobileBoxes";

interface IProps {
    onClose: () => void;
    rowIndex: number;
}

const TrackPracticeMobileEdit: React.FC<IProps> = ({ rowIndex }) => {
    return (
        <>
            <div className="track-practice-mobile-edit-column">
                <TrackPracticeMobileBoxes rowIndex={rowIndex} />
            </div>
        </>
    );
};

export default TrackPracticeMobileEdit;
