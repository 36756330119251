import { ProductType } from "main-app/constants";
import { GroupCohort, GroupCohortApi } from "./cohort";
import { GroupSessionParticipant, GroupSessionParticipantApi, ParticipantBase } from "./participants";
import { InvitationStatus, Modality } from "./types";
import { COACHING_SESSION_TYPE_SLUG } from "main-app/constants";
import { ThreeWayManagerSession, ThreeWayManagerSessionApi } from "main-app/entities/three-way/model/three-way";

interface InvitationApi {
    comment: null;
    status: InvitationStatus;
}

interface GroupSessionApi {
    id: number;
    cohort: GroupCohortApi;
    cohort_modality: Modality;
    enrollment_rank: number;
    join_url: string | null;
    journey_length: number;
    session_id: number;
    session_time: string;
    session_type: SessionType;
    participants_info: GroupSessionParticipantApi[];
    product_type_internal_name: ProductType;
    product_type_id: number;
    extra_parties_info: ThreeWayManagerSessionApi[];
    is_three_way_session: boolean;
}

class Invitation {
    comment: null;
    status: InvitationStatus;

    constructor(data: InvitationApi) {
        this.comment = data.comment;
        this.status = data.status;
    }
}

class SessionType {
    duration: number;
    name: string;
    slug: (typeof COACHING_SESSION_TYPE_SLUG)[keyof typeof COACHING_SESSION_TYPE_SLUG];

    constructor(data) {
        this.duration = data.duration;
        this.name = data.name;
        this.slug = data.slug;
    }
}

class SessionBaseClass {
    id: number;
    cohort: string;
    sessionTime: string;
    sessionType: SessionType;
    cohortModality: Modality;
    joinUrl: string;
    sessionId: number;
    enrollmentRank: number;

    constructor(data) {
        this.id = data?.id;
        this.cohort = data.cohort;
        this.sessionTime = data.session_time;
        this.sessionType = new SessionType(data.session_type);
        this.cohortModality = data.cohort_modality;
        this.joinUrl = data.join_url;
        this.sessionId = data.session_id;
        this.enrollmentRank = data.enrollment_rank;
    }
}

class Session extends SessionBaseClass {
    participants?: ParticipantBase[];
    title: string;

    constructor(data) {
        super(data);
        this.participants = data?.participants_info?.participants?.map(participant => new ParticipantBase(participant));
        this.title = data?.participants_info?.title;
    }
}

class CoachSession extends SessionBaseClass {
    schedulingWindowStart: string;
    schedulingWindowEnd: string;
    coachId: number;
    allowReschedule: boolean;

    constructor(data) {
        super(data);
        this.schedulingWindowStart = data.scheduling_window_start;
        this.schedulingWindowEnd = data.scheduling_window_end;
        this.coachId = data.coach_id;
        this.allowReschedule = data.allow_reschedule;
    }
}

class GroupSession {
    id: number;
    cohort: GroupCohort;
    cohortModality: Modality;
    enrollmentRank: number;
    joinUrl: string | null;
    sessionId: number;
    sessionTime: string;
    sessionType: SessionType;
    participants: GroupSessionParticipant[];
    productTypeName: ProductType;
    extraPartiesInfo: ThreeWayManagerSession[];
    isThreeWaySession: boolean;

    constructor(data: GroupSessionApi) {
        this.id = data.id;
        this.cohort = new GroupCohort(data.cohort);
        this.cohortModality = data.cohort_modality;
        this.enrollmentRank = data.enrollment_rank;
        this.joinUrl = data.join_url;
        this.sessionId = data.session_id;
        this.sessionTime = data.session_time;
        this.sessionType = new SessionType(data.session_type);
        this.participants = data?.participants_info?.map(participant => new GroupSessionParticipant(participant)) ?? [];
        this.productTypeName = data.product_type_internal_name;
        this.extraPartiesInfo = data?.extra_parties_info?.map(party => new ThreeWayManagerSession(party)) ?? [];
        this.isThreeWaySession = data.is_three_way_session;
    }
}

export { Session, Invitation, InvitationApi, GroupSession, GroupSessionApi, CoachSession };
