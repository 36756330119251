import React from "react";
import { useTranslation } from "react-i18next";

import Button from "main-app/shared/button/Button";

import "./styles.scss";

type Props = {
    onClick: () => void;
    phaseMaxRank: number;
    coachingSessionName: string;
};

const ScheduleSessionBox = ({ onClick, phaseMaxRank, coachingSessionName }: Props) => {
    const { t } = useTranslation();

    return (
        <div className="schedule-session-box text-center">
            <p className="mb-40">
                {t(
                    "Before you start this module, please schedule your Phase {{phaseMaxRank}} {{coachingSessionName}}.",
                    { phaseMaxRank, coachingSessionName }
                )}
            </p>
            <Button onClick={onClick}>{t("Schedule Now")}</Button>
        </div>
    );
};

export default ScheduleSessionBox;
