export class Urls {
    static api = "/api";

    static modulesByParticipant = (participantId: number) => `${Urls.api}/modules/by-participant/${participantId}/`;

    static modulesByParticipantTranslated = ({ participantId, lang }: { participantId: string; lang: string }) =>
        `${Urls.api}/modules/by-participant/${participantId}/?sort_language=${lang}`;

    static submitModuleEnrollment = (participantId: number) =>
        `${Urls.api}/modules/by-participant/${participantId}/submit/`;
}
