import React from "react";

import useWaitingListText from "main-app/api/use-waiting-list-text";

import "./styles.scss";

interface IProps {}

const WaitingListHeader = () => {
    const { data } = useWaitingListText();

    return (
        <a href={data?.websiteLink} target="_blank" className="text-center m-0">
            <span className="waiting-list__btn btn btn--brand-hover">{data?.textInfoButtonData}</span>
        </a>
    );
};

export default WaitingListHeader;
