import React, { useState } from "react";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Avatar from "main-app/shared/avatar/Avatar";
import useRoles from "main-app/utils/hooks/use-roles";

interface IProps {
    participants: any[];
}

const GroupParticipants: React.FC<IProps> = ({ participants }) => {
    const { t } = useTranslation();
    const { isCoaches } = useRoles();
    const navigate = useNavigate();
    const [selectedHover, setSelectedHover] = useState(-1);

    const onClickParticipant = id => {
        navigate(`/participant-works/${id}`);
    };

    return (
        <div className={classNames("group-participants", { one: participants?.length === 1 })}>
            {participants?.map((participant, index) => (
                <div
                    className={classNames("group-participants__item", { hover: selectedHover === index })}
                    key={participant.id}
                    onMouseEnter={() => isCoaches && setSelectedHover(index)}
                    onMouseLeave={() => isCoaches && setSelectedHover(-1)}
                    onClick={() => isCoaches && onClickParticipant(participant.id)}
                >
                    <Avatar url={participant.photo} className="participant-image" />
                    <p className="m-0 mt-2">
                        {participant.first_name} {isCoaches && participant.last_name}
                    </p>
                    {isCoaches && (
                        <span
                            className={classNames("color-brand font-bold fs-14 visible", {
                                invisible: selectedHover !== index
                            })}
                        >
                            {t("view work")}
                        </span>
                    )}
                </div>
            ))}
        </div>
    );
};

export default GroupParticipants;
