import { useMutation } from "@tanstack/react-query";
import { http } from "common/http";
import { Urls } from "../urls";

type Options = {
    coacheeId: number;
    participantId: number;
};

export function useCreateConversation() {
    return useMutation(({ coacheeId, participantId }: Options) =>
        http.post<{ id: number }>(Urls.conversation(), { coachee: coacheeId, participant: participantId })
    );
}
