import { useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { http } from "common/http";
import { NEW_MESSAGES_REFETCH_INTERVAL, QueryKey } from "../constants";
import { Urls } from "./urls";
import { SimulabConversationFeedbackApi, SimulaConversationFeedback } from "../models/conversation";

const getSimulabConversationFeedback = async ({ queryKey }) => {
    const [_key, { id }] = queryKey;
    const { data } = await http.get(Urls.conversationFeedback(id));
    return data;
};

export function useSimulabConversationFeedback(conversationId: string, enabled = false) {
    return useQuery<SimulabConversationFeedbackApi, AxiosError, SimulaConversationFeedback>(
        [QueryKey.ConversationFeedback, { id: conversationId }],
        getSimulabConversationFeedback,
        {
            select: useCallback(data => new SimulaConversationFeedback(data), []),
            enabled: enabled,
            refetchInterval: NEW_MESSAGES_REFETCH_INTERVAL
        }
    );
}
