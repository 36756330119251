import React from "react";
import Modal from "main-app/shared/modal";
import Heading from "common/components/Heading/Heading";
import Button from "main-app/shared/button/Button";
import { Link } from "react-router-dom";
import { CoachCalendarStepUrls } from "main-app/pages/coach-settings/constants";
import CoachSetupListStep from "./CoachSetupListStep";

import "./styles.scss";

type Props = {
    show: boolean;
    onClose: () => void;
};

const CoachSetupScheduleModal = ({ show, onClose }: Props) => {
    return (
        <Modal
            show={show}
            onClose={onClose}
            headerContent={
                <Heading className="text-center mb-0 p-3" fontSize={24}>
                    Set Up Your Schedule
                </Heading>
            }
            modalBodyClass="coach-setup-schedule-modal"
        >
            <div className="coach-setup-schedule-modal__body">
                <div className="text-center">
                    <CoachSetupListStep />
                    <div className="text-center mt-50">
                        <Link to={CoachCalendarStepUrls.LINK_CALENDAR_STEP} className="color-inherit">
                            <Button>Get Started</Button>
                        </Link>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default CoachSetupScheduleModal;
