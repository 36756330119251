import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { ScrollToTop } from "common/components/ScrollToTop";
import { useAuthContext } from "main-app/context/Auth";
import { authRoutes, coachRoutes, participantRoutes, unanothorizedRoutes } from "main-app/routes";
import { ERROR_TEXT, ParticipantUrls, userRoles } from "main-app/constants";
import { NotFoundPage } from "main-app/shared/error-page/NotFoundPage";
import { ForbiddenComponent } from "main-app/shared/error-page/ForbiddenComponent";
import PostReflectionModalWrapper from "main-app/elements/reflection-modals/PostReflectionModalWrapper";
import { ErrorPage } from "main-app/shared/error-page/ErrorPage";
import TestErrorsPage from "main-app/shared/error-page/TestErrorsPage";
import { setLuxonLocale } from "common/utils/lang";
import WaitingListPage from "./waiting-list/WaitingListPage";
import useUserActivity from "common/hooks/use-user-activity";
import { useLanguage } from "common/hooks/use-language";
import { makePing } from "main-app/api/mutations/use-ping";
import { isNullOrUndefined } from "common/utils/gates";
import ReloadAppModal from "main-app/shared/reload-app-modal/ReloadAppModal";
import { PrivacyPolicyPage } from "main-app/pages/privacy-policy";
import ProtectedRoute from "main-app/routes/protected-route";

import Header from "./Header";
import Footer from "./Footer";

import "main-app/assets/main-app.scss";

type Props = {};

setLuxonLocale();

const Main = (props: Props) => {
    const { user, isLoading } = useAuthContext();

    useUserActivity({
        onInactivity: () => {
            console.info("%c Info: user is inactive", "background: #222; color: #bada55");
            window.location.reload();
        },
        onActivity: () => {
            makePing();
        },
        enabled: !!user,
        inactivityTime: !isNullOrUndefined(user?.browserSessionTtl) ? user?.browserSessionTtl * 1000 : undefined
    });

    useLanguage(user);

    return (
        <>
            <ScrollToTop />
            <Routes>
                <Route path="/" element={<ProtectedRoute user={user} isAllowed={true} isLoading={isLoading} />} />

                {authRoutes.map(({ path, Component }) => (
                    <Route
                        key={path}
                        path={path}
                        element={
                            <>
                                <Header />
                                <Component />
                                <Footer />
                            </>
                        }
                    />
                ))}

                {participantRoutes.map(({ path, Component }) => (
                    <Route
                        key={path}
                        path={path}
                        element={
                            <ProtectedRoute
                                redirectPath="/forbidden"
                                isAllowed={!!user && userRoles.participant.includes(user?.role)}
                                user={user}
                                isLoading={isLoading}
                            >
                                <Component />
                            </ProtectedRoute>
                        }
                    />
                ))}

                {coachRoutes.map(({ path, Component, ...rest }) => (
                    <Route
                        key={path}
                        path={path}
                        element={
                            <ProtectedRoute
                                redirectPath="/forbidden"
                                isAllowed={!!user && userRoles.coach.includes(user?.role)}
                                user={user}
                                isLoading={isLoading}
                            >
                                <Component {...rest} />
                            </ProtectedRoute>
                        }
                    />
                ))}

                {unanothorizedRoutes.map(({ path, Component }) => (
                    <Route path={path} element={<Component />} key={path} />
                ))}

                <Route path={ParticipantUrls.WAITING_LIST} element={<WaitingListPage />} />
                <Route path={ParticipantUrls.WAITING_LIST_SSO} element={<WaitingListPage />} />
                <Route path={"/privacy"} element={<PrivacyPolicyPage />} />

                <Route path="/error" element={<ErrorPage errorContent={ERROR_TEXT} />} />
                <Route path="/internal-error" element={<ErrorPage errorContent={ERROR_TEXT} />} />
                <Route path="/test-error-page" element={<TestErrorsPage />} />

                <Route
                    path={ParticipantUrls.END_OF_JOURNEY_PAGE}
                    element={<Navigate to={ParticipantUrls.OFFBOARDING} replace />}
                />

                <Route path="/forbidden" element={<ForbiddenComponent />} />
                <Route path="404" element={<NotFoundPage />} />

                <Route path="*" element={<NotFoundPage />} />
            </Routes>

            <PostReflectionModalWrapper />
            <ReloadAppModal />
        </>
    );
};

export default Main;
