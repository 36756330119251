export class PreprogramDiagnostic {
    type: string;
    module: { id: number; title_data: { en: string } };
    question_list: any[];
    summary_list: any[];
    answer_data: any;

    constructor(data) {
        this.type = data.type;
        this.module = data.module;
        this.question_list = data.question_list;
        this.summary_list = data.summary_list;
        this.answer_data = data.answer_data;
    }
}
