import React, { SVGProps } from "react";

import "main-app/assets/svgs.scss";

interface Props extends SVGProps<SVGSVGElement> {}

const ThreeDotsSvg = (props: Props) => {
    return (
        <svg
            className="three-dot"
            width="15"
            height="23"
            viewBox="0 0 15 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="7.5" cy="2.5" r="2.5" fill="#6F6F6F" />
            <circle cx="7.5" cy="11.5" r="2.5" fill="#6F6F6F" />
            <circle cx="7.5" cy="20.5" r="2.5" fill="#6F6F6F" />
        </svg>
    );
};

export default ThreeDotsSvg;
