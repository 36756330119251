import React, { useState, useMemo, useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import PlusIcon from "main-app/shared/assets/icon/PlusIcon";
import { GoalsSortType } from "main-app/shared/types/sort";
import Button from "main-app/shared/button/Button";
import {
    CoachingGoalList,
    CoachingGoalModel,
    CoachingGoalQueryKey,
    useCoachingGoalApi
} from "main-app/entities/coaching-goal";
import { GoalsSortSelector } from "main-app/features/goals-sort-selector/ui/GoalsSortSelector";
import { Option } from "common/models/option";
import { ArchiveModal, isEnabledArchiveModal } from "main-app/entities/archive-goal";
import { useAuthContext } from "main-app/context/Auth";
import Skeleton from "common/components/Skeleton/Skeleton";
import { getErrorMessages } from "common/utils/get-error-messages";
import useUpdateEffect from "common/hooks/use-update-effect";
import { WarningMessage } from "main-app/components/onboarding/components/WarningMessage";
import { isEmptyString, isNullOrUndefined } from "common/utils/gates";
import Heading from "common/components/Heading/Heading";

import "./styles.scss";

type Props = {};

type FormValues = {
    goals: CoachingGoalModel[];
};

const CoachingGoal = ({}: Props) => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const { user } = useAuthContext();
    const params = useParams();
    const userId = !isNullOrUndefined(params?.id) ? +params?.id : user?.id;

    const [sort, setSort] = useState<GoalsSortType>("active");
    const [isOpenArchiveModal, setIsOpenArchiveModal] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [archiveText, setArchiveText] = useState({ text: "", index: null, id: null });

    const {
        coachingGoal,
        isLoadingCoachingGoal,
        coachingGoalError,
        archivedGoals,
        archiveError,
        createCoachingGoal,
        editCoachingGoal,
        archiveGoal,
        unarchiveGoal,
        sortGoals
    } = useCoachingGoalApi({ userId, sort });

    const methods = useForm<FormValues>({
        defaultValues: {
            goals: coachingGoal ?? []
        }
    });

    const { prepend, replace, remove, fields } = useFieldArray({
        control: methods.control,
        keyName: "uuid",
        name: `goals`
    });

    const hasArchived = useMemo(() => {
        return archivedGoals?.length > 0;
    }, [archivedGoals]);

    useEffect(() => {
        if (!isLoadingCoachingGoal && coachingGoal) {
            replace(coachingGoal);
        }
    }, [coachingGoal, isLoadingCoachingGoal]);

    useUpdateEffect(() => {
        if (!hasArchived && sort === "archived") {
            setSort("active");
        }
    }, [hasArchived]);

    const onArchiveCardClick = async (index: number, id: number) => {
        const archivedGoal = archivedGoals.includes(id);

        if (archivedGoal) {
            try {
                await unarchiveGoal.mutateAsync(id);
                invalidateCoachingGoals();
                setErrorMsg(null);
            } catch (error) {
                setErrorMsg(getErrorMessages(error));
            }
            return;
        }

        setArchiveText({ text: methods.getValues(`goals.${index}.description`), index, id });

        if (isEnabledArchiveModal()) {
            setIsOpenArchiveModal(true);
        } else {
            onArchiveClick(id);
        }
    };

    const onArchiveClick = async (id?: number) => {
        setIsOpenArchiveModal(false);
        try {
            await archiveGoal.mutateAsync(id ?? archiveText.id);
            invalidateCoachingGoals();
            setErrorMsg(null);
        } catch (error) {
            setErrorMsg(getErrorMessages(error));
        }
        setArchiveText({ text: "", index: null, id: null });
    };

    const onCloseArchiveModal = () => {
        setIsOpenArchiveModal(false);
        setArchiveText({ text: "", index: null, id: null });
    };

    const onAddCoachingGoal = () => {
        const hasEmptyDescription = methods.getValues("goals").some(goal => isEmptyString(goal.description));

        const isNewAddedItem = fields.length !== coachingGoal.length;

        const hasEmptyAddedGoal = hasEmptyDescription && fields.length !== 0;

        if (hasEmptyAddedGoal || isNewAddedItem) {
            return;
        }

        prepend({ description: "", order: 0, id: Date.now(), participantId: userId }, { shouldFocus: true });
    };

    const onChangeSort = (sort: Option) => {
        setSort(sort.value as GoalsSortType);
    };

    const invalidateCoachingGoals = () => {
        queryClient.invalidateQueries([CoachingGoalQueryKey.CoachingGoal]);
    };

    const onBlur = async (coachingGoalIndex: number) => {
        const { description, id } = methods.getValues(`goals.${coachingGoalIndex}`);

        if (isEmptyString(description)) {
            remove(coachingGoalIndex);
            return;
        }

        createEditCoachingGoal({ description, order: coachingGoalIndex + 1 }, id);
    };

    const createEditCoachingGoal = async (goal: Pick<CoachingGoalModel, "description" | "order">, id: number) => {
        const goals = methods.getValues("goals");

        try {
            if (goals.length !== coachingGoal.length) {
                const { data } = await createCoachingGoal.mutateAsync(goal);
                makeSorting({ oldId: id, newId: data.id });
            } else {
                await editCoachingGoal.mutateAsync({ ...goal, id });
                invalidateCoachingGoals();
                setErrorMsg(null);
            }
        } catch (error) {
            setErrorMsg(getErrorMessages(error));
        }
    };

    const makeSorting = async ({ oldId, newId }: { oldId: number; newId: number }) => {
        const goals = methods.getValues("goals");
        const ids = goals.map(goal => (goal.id === oldId ? newId : goal.id));

        try {
            await sortGoals.mutateAsync({ ids });
            invalidateCoachingGoals();
            setErrorMsg(null);
        } catch (error) {
            setErrorMsg(getErrorMessages(error));
        }
    };

    if (isLoadingCoachingGoal) {
        return (
            <div className="mb-2">
                <Skeleton rows={1} height={50} width="200px" className="mb-1" />
                <Skeleton rows={3} height={70} />
            </div>
        );
    }

    if (coachingGoalError || archiveError) {
        const error = coachingGoalError ?? archiveError;
        const msg = getErrorMessages(error?.message)?.join() ?? t("An error occurred, please try again.");
        return <WarningMessage message={msg} />;
    }

    return (
        <>
            <FormProvider {...methods}>
                <section className="mb-3">
                    <div className="mb-10 d-flex flex-column flex-md-row justify-content-md-between align-items-md-center">
                        <div className="d-flex flex-column flex-row-md align-items-md-center flex-md-row">
                            <Heading tag="h2" fontSize={16} className="mb-3 m-0 mr-4 mb-md-0">
                                {t("Coaching Goal")}
                            </Heading>
                            <div>
                                <Button variant="outline-rect" onClick={onAddCoachingGoal}>
                                    <PlusIcon />
                                    <span className="d-inline-block ml-2">{t("Add Coaching Goal")}</span>
                                </Button>
                            </div>
                        </div>
                        {hasArchived && (
                            <GoalsSortSelector
                                className="align-self-md-end mt-3 m-md-0"
                                onChangeSelect={onChangeSort}
                                sort={sort}
                            />
                        )}
                    </div>
                    {fields?.length === 0 ? (
                        <p className="m-0 color-gray font-italic font-sm">
                            {t("What are you hoping to accomplish in this coaching engagement?")}
                        </p>
                    ) : null}
                </section>
                <WarningMessage message={errorMsg} />
                <CoachingGoalList
                    sort={sort}
                    archivedGoals={archivedGoals}
                    goals={fields}
                    onArchiveClick={onArchiveCardClick}
                    onBlur={onBlur}
                />
                <ArchiveModal
                    archiveTitle={t("Coaching Goal")}
                    isOpen={isOpenArchiveModal}
                    onClose={onCloseArchiveModal}
                    onArchive={onArchiveClick}
                    archiveText={archiveText.text}
                />
            </FormProvider>
        </>
    );
};

export default CoachingGoal;
