import React, { useMemo } from "react";
import classNames from "classnames";

import ActiveCheckMarkSvg from "main-app/svgs/ActiveCheckMarkSvg";
import AppleIcon from "main-app/svgs/AppleIconSvg";
import DisabledCrossMarkSvg from "main-app/svgs/DisabledCrossMarkSvg";
import GoogleIcon from "main-app/svgs/GoogleIcon";
import OutLookIcon from "main-app/svgs/OutLookIcon";
import PendingQuestionMarkSvg from "main-app/svgs/PendingQuestionMarkSvg";
import { CalendarStatus } from "../constants";
import { CalendarAccount } from "../models";

type Props = {
    calendar: CalendarAccount;
};

const CalendarAccountItem = ({ calendar }: Props) => {
    const calendarIcons = useMemo(
        () => ({
            google: <GoogleIcon />,
            live_connect: <OutLookIcon />,
            apple: <AppleIcon />
        }),
        []
    );

    const statusIcons = useMemo(
        () => ({
            active: <ActiveCheckMarkSvg />,
            pending: <PendingQuestionMarkSvg />,
            disabled: <DisabledCrossMarkSvg />
        }),
        []
    );

    return (
        <div className="calendar-accounts__item" key={calendar.profileId}>
            <div className="flex-1 text-left">
                <span className="mr-2">{calendarIcons[calendar.providerName]}</span> {calendar.profileName}
            </div>
            <div className="d-flex align-items-center">
                <span
                    className={classNames("mr-2 text-capitalize", {
                        "color-brand font-extrabold": calendar.status === CalendarStatus.Active,
                        "color-gray": calendar.status !== CalendarStatus.Active
                    })}
                >
                    {calendar.status}
                </span>{" "}
                {statusIcons[calendar.status]}
            </div>
        </div>
    );
};

export default CalendarAccountItem;
