import React, { type FC, type NamedExoticComponent, type ReactElement, memo } from "react";
import { useTranslation } from "react-i18next";

import Heading from "common/components/Heading/Heading";

const GuestParticipantHeadingComponent: FC = (): ReactElement => {
    const { t } = useTranslation();

    return (
        <Heading textAlign="center" tag={"h2"} className="swap-session__heading">
            {t("participantSide.swapSessionsScreen.title")}
        </Heading>
    );
};

const GuestParticipantHeading: NamedExoticComponent = memo(GuestParticipantHeadingComponent);

export { GuestParticipantHeading };
