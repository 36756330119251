import React, { useState, useMemo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { useAuthContext } from "main-app/context/Auth";
import useNextStep from "./api/mutations/use-next-step";
import Button from "main-app/shared/button/Button";
import { isNullOrUndefined } from "common/utils/gates";
import OnboardingLayout from "./layout/OnboardingLayout";
import { StepsNavigator } from "./components/StepsNavigator";
import { OnboardingSteps } from "./constants";
import useNavigationOnboarding from "main-app/utils/hooks/use-navigation-onboarding";
import { isColoredLikertComponent, isFreeResponse, isLikertComponent } from "main-app/utils/gates/utilts";
import Heading from "common/components/Heading/Heading";
import { withTranslation } from "common/utils/lang";
import LikertComponent from "main-app/shared/likert/LikertComponent";

import { HTTPStatus } from "../../../common/constants";
import { getErrorMessages } from "../../../common/utils/get-error-messages";
import useReflectionObjective from "./api/use-reflection-objective";
import { WarningMessage } from "./components/WarningMessage";
import FreeResponseComponent from "./components/FreeResponseComponent";

import "main-app/assets/onboarding.scss";

interface IProps {}

const ReflectionObjectiveStep: React.FC<IProps> = () => {
    const { t } = useTranslation();
    const { user } = useAuthContext();
    const navigate = useNavigate();
    const [errorsMsg, setErrorsMsg] = useState(null);
    const [isBusy, setIsBusy] = useState(false);
    const { data, refetch } = useReflectionObjective();
    const { getNextStepUrl } = useNavigationOnboarding();
    const handleNextStep = useNextStep();

    let answer_data = [];

    const question_list = data?.reduce((acc, currentItem, currentIndex) => {
        acc[currentIndex] = [currentItem.question_data];
        const currentAnswer =
            { ...currentItem.answer_data, component_type: currentItem?.question_data?.component_type } || [];
        answer_data = [...answer_data, ...[currentAnswer]];

        return acc.flat();
    }, []);

    const defaultValues = answer_data?.reduce((acc, currentItem) => {
        if (currentItem.question_uuid) {
            acc[currentItem.question_uuid] = isLikertComponent(currentItem?.component_type)
                ? currentItem?.option_uuid
                : currentItem?.answer;
        }
        return acc;
    }, {});

    const shapeWithDynamicFields = question_list
        ? question_list?.reduce((obj, question) => {
              obj[question.uuid] = question?.response_required
                  ? yup.string().required(t("Answer is required")).nullable(true)
                  : yup.string().notRequired().nullable(true);
              return obj;
          }, {})
        : null;

    const {
        register,
        control,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm({
        resolver: yupResolver(yup.object(shapeWithDynamicFields)),
        defaultValues: useMemo(() => {
            return defaultValues;
        }, [data])
    });

    useEffect(() => {
        refetch();
    }, []);

    useEffect(() => {
        reset(defaultValues);
    }, [data]);

    const onSubmit = values => {
        const answer_list = getAnswerList(values);

        const data = {
            step: OnboardingSteps.REFLECTION_OBJECTIVE_STEP,
            step_data: [
                {
                    answer_list,
                    question_list,
                    type: "reflection_objective"
                }
            ]
        };

        submitData(data);
    };

    const getAnswerList = values => {
        return data
            .map(({ question_data: question }) => {
                const answer = values[question.uuid];
                return getComponentAnswer(question, answer);
            })
            .filter(answer => !isNullOrUndefined(answer));
    };

    const getComponentAnswer = (question, answer) => {
        if (isLikertComponent(question.component_type)) {
            const likertComponetAnswer = {
                question_uuid: question.uuid,
                option_uuid: answer,
                answer:
                    withTranslation(question.options.find(option => option.uuid === answer)?.response) || answer || "",
                rank: question.order
            };

            if (isColoredLikertComponent(question.component_type)) {
                likertComponetAnswer["color_data"] = question.color_range;
            }
            if (isNullOrUndefined(likertComponetAnswer.option_uuid)) {
                delete likertComponetAnswer["option_uuid"];
            }
            return likertComponetAnswer;
        }

        if (isFreeResponse(question.component_type)) {
            const freeResponseComponentAnswer = {
                question_uuid: question.uuid,
                rank: question.order,
                answer: answer ?? ""
            };
            return freeResponseComponentAnswer;
        }

        return null;
    };

    const submitData = async data => {
        setIsBusy(true);
        try {
            await handleNextStep.mutateAsync(data);
            const nextStep = await getNextStepUrl(OnboardingSteps.REFLECTION_OBJECTIVE_STEP);
            navigate(nextStep.url);
        } catch (error) {
            const errorMessage = getErrorMessages(error);
            if (error?.response?.status === HTTPStatus.BAD_REQUEST) {
                setErrorsMsg(errorMessage);
            }
            console.log(errorMessage);
        } finally {
            setIsBusy(false);
        }
    };

    return (
        <OnboardingLayout
            header={<StepsNavigator activeStep={{ id: OnboardingSteps.REFLECTION_OBJECTIVE_STEP, order: 5 }} />}
        >
            <div className="container container-max-md">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-5 mt-6 text-center">
                        <Heading className="step5-title" textAlign="center">
                            {t("Self Assessment")}
                        </Heading>
                    </div>
                    <WarningMessage message={errorsMsg} />
                    {data?.map((item: any, indexQuestion) => (
                        <>
                            {isLikertComponent(item.question_data.component_type) && (
                                <LikertComponent
                                    className={classNames({ "mb-60": indexQuestion !== data?.length - 1 })}
                                    questionNumber={indexQuestion + 1}
                                    register={register}
                                    item={item.question_data}
                                    control={control}
                                    errors={errors}
                                    color="blank"
                                    rowClassName="onboarding-likert"
                                    questionStyle="one-line"
                                    showOddLabels
                                />
                            )}
                            {isFreeResponse(item.question_data.component_type) && (
                                <FreeResponseComponent
                                    questionNumber={indexQuestion + 1}
                                    register={register}
                                    item={item.question_data}
                                    errors={errors}
                                    questionStyle="one-line"
                                />
                            )}
                        </>
                    ))}
                    <div className="mt-50 text-center">
                        <Button type="submit" isBusy={isBusy} className="font-weight-800 btn-200">
                            {!user?.groupExists ? t("Next") : t("Finish")}
                        </Button>
                    </div>
                </form>
            </div>
        </OnboardingLayout>
    );
};

export default ReflectionObjectiveStep;
