import React, { forwardRef } from "react";
import classNames from "classnames";

import { Spinner } from "../spinner";

interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    className?: string;
    variant?:
        | "brand"
        | "rounded"
        | "default"
        | "outline"
        | "outline-secondary"
        | "black"
        | "disabled"
        | "disabled-grey"
        | "outline-rect"
        | "outline-blank"
        | "disabled-dark-grey";
    disabled?: boolean;
    isBusy?: boolean;
    type?: "button" | "submit" | "reset";
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const btnTypeClasses = {
    brand: "btn--brand",
    rounded: "btn--rounded",
    disabled: "btn--disabled",
    "disabled-grey": "btn--disabled-grey",
    black: "btn--black",
    outline: "btn--brand-outline",
    "outline-secondary": "btn--brand-outline-2",
    "outline-blank": "btn--brand-outline-3",
    "outline-rect": "btn--brand-outline-rect",
    "disabled-dark-grey": "btn--disabled-dark-grey",
    default: "btn--default"
};

const Button = forwardRef<HTMLButtonElement, IProps>(
    (
        {
            className = "",
            variant = "brand",
            disabled = false,
            isBusy = false,
            type = "button",
            onClick,
            children,
            ...rest
        },
        ref
    ) => {
        const classes = classNames("btn", btnTypeClasses[variant], className, { "disabled-btn": disabled });

        return (
            <button className={classes} type={type} onClick={onClick} disabled={isBusy || disabled} ref={ref} {...rest}>
                {isBusy ? (
                    <div className="w-100 h-100">
                        <Spinner width={25} height={25} variant="secondary" size="small" />
                    </div>
                ) : (
                    children
                )}
            </button>
        );
    }
);

export { type IProps as IButtonProps };

export default Button;
