export interface OldBeliefApi {
    id: number;
    description: string;
    order: number;
    participant_id: number;
    title: string;
}

export class OldBelief {
    id: number;
    title: string;
    description: string;
    order: number;
    participantId: number;

    constructor(data: OldBeliefApi) {
        this.id = data.id;
        this.description = data.description;
        this.title = data.title;
        this.order = data.order;
        this.participantId = data.participant_id;
    }
}
