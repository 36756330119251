import React, { useState } from "react";
import { FormProvider } from "react-hook-form";

import Heading from "common/components/Heading/Heading";
import { getErrorMessages } from "common/utils/get-error-messages";

import SimpleLayout from "main-app/layouts/SimpleLayout";
import DateWidget from "main-app/shared/DateWidget";
import Button from "main-app/shared/button/Button";
import { useCalendarLinkSetting } from "main-app/entities/coach-calendar/hooks/use-calendar-link-setting";
import { WarningMessage } from "main-app/components/onboarding/components/WarningMessage";
import { CalendarAccounts, CalendarPrimary, CalendarSyncAvailability } from "main-app/entities/coach-calendar";

import { CoachCalendarStepUrls } from "./constants";
import { StepsNavigator } from "./ui/StepNavigator";
import { useOrderNavigationCalendar } from "main-app/entities/coach-calendar/hooks/use-order-navigation-calendar";

type Props = {
    order?: number;
};

const LinkCalendarStep = ({ order }: Props) => {
    const { navigateByOrder } = useOrderNavigationCalendar(order);
    const { api, onSubmit, hookForm: methods } = useCalendarLinkSetting();
    const [error, setError] = useState(null);
    const onNextClick = async values => {
        try {
            await onSubmit(values);
            navigateByOrder();
        } catch (e) {
            console.log(e);
        }
    };

    const hasLinkedAccount = api?.data?.length > 0;

    const errorMsg = [
        methods?.formState?.errors?.calendars?.message,
        methods?.formState?.errors?.primaryCalendar?.message
    ]
        .filter(Boolean)
        .join(", ");

    return (
        <SimpleLayout header={<DateWidget className="coach-date-title" />} headerVariant="gray" showProfilePhoto>
            <div className="coach-calendar-wrapper mx-auto pb-5 text-center">
                <div className="d-flex justify-content-center mt-5">
                    <StepsNavigator activeStep={CoachCalendarStepUrls.LINK_CALENDAR_STEP} />
                </div>
                <Heading textAlign="center" tag="h6" fontSize={16} className="mb-30 mt-5 color-brand">
                    Step {order}
                </Heading>
                <Heading tag="h1" textAlign="center" className="mb-2" fontSize={36}>
                    Link Your Calendar(s)
                </Heading>
                <p className="mb-1 color-gray fs-14">
                    Choose which calendar(s) you’d like to sync to Wiser to inform your availability.
                </p>
                <p className="color-gray fs-14 mb-20">
                    You will be able to manually tweak your availability later as needed.
                </p>
                <CalendarAccounts />

                {hasLinkedAccount && (
                    <>
                        <Heading tag="h4" textAlign="center" className="mb-2 mt-60" fontSize={16}>
                            Sync Availability with Sub-calendars
                        </Heading>
                        <p className="color-gray fs-14 mb-4 text-center">
                            Each of your calendars likely has sub-calendars which you may or may not want to use to
                            inform your availability. Common examples of sub-calendars include: work, personal, and
                            national holidays. Choose which sub-calendars to sync below.
                        </p>
                        <FormProvider {...methods}>
                            <CalendarSyncAvailability data={api?.data} />

                            <Heading tag="h4" textAlign="center" className="mb-2 mt-60" fontSize={16}>
                                Select a Primary Calendar
                            </Heading>
                            <p className="color-gray fs-14 mb-0 text-center">
                                Your primary calendar will be used to send Wiser session invites to your participants.
                            </p>
                            <p className="color-gray fs-14 mb-4 text-center">
                                This is the calendar that any Wiser-related events will appear on.
                            </p>
                            <CalendarPrimary data={api?.data} />
                        </FormProvider>
                        <WarningMessage
                            message={error || errorMsg || getErrorMessages(api?.error?.response?.data)?.join("")}
                        />
                        <div className="text-center mt-60">
                            <Button onClick={methods.handleSubmit(onNextClick)} className="btn-200">
                                Next
                            </Button>
                        </div>
                    </>
                )}
            </div>
        </SimpleLayout>
    );
};

export default LinkCalendarStep;
