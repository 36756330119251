import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Session } from "main-app/models/session";
import { formatDateSessions, getTimezoneAbbreviation } from "main-app/utils/date";
import ProfileImageLetter from "common/components/ProfileImageLetter/ProfileImageLetter";
import GuestBadge from "main-app/svgs/GuestBadge";

import { useCroppedAndSortedParticipantList } from "../hooks";

interface IProps {
    session: Session;
}

const TableRow: React.FC<IProps> = ({ session }) => {
    const navigate = useNavigate();

    const { t } = useTranslation();

    const handleRedirect = useCallback(
        (sessionId?: number): void => {
            navigate(`/group-info/${sessionId}`);
        },
        [navigate]
    );

    const { sortedParticipantList, isParticipantsAmountExceedingViewLimit, exceedingParticipantsCount } =
        useCroppedAndSortedParticipantList({ participantList: session?.participants });

    return (
        <tr className="session-table-row" onClick={() => handleRedirect(session?.id)}>
            <td className="text-left pl-0">
                <a className="d-flex align-items-center color-inherit">{session.cohort}</a>
            </td>
            <td className="text-left d-none d-lg-table-cell">{session?.title}</td>
            <td className="text-left d-none d-lg-table-cell">
                {session.sessionType.name}
                <span className="d-block mt-2 color-gray">{session.sessionType.duration} min</span>{" "}
            </td>
            <td className="text-left d-none d-lg-table-cell">
                {formatDateSessions(session.sessionTime)}{" "}
                <span className="d-block mt-2 color-gray">{getTimezoneAbbreviation()}</span>
            </td>
            <td>
                <div className="d-flex align-items-center justify-content-lg-center mb-2">
                    {sortedParticipantList?.map((participant, index) => (
                        <React.Fragment key={participant.id}>
                            {participant.photo || participant.isGuest ? (
                                <div
                                    className="session-participant-img"
                                    title={`${participant.firstName} ${participant.lastName}`}
                                    style={{ zIndex: index + 1 }}
                                >
                                    {participant.isGuest ? (
                                        <GuestBadge width={27} height={27} className="guest-participant-avatar-badge" />
                                    ) : participant.photo ? (
                                        <img src={participant.photo} />
                                    ) : null}
                                </div>
                            ) : (
                                <div className="session-participant-img" style={{ zIndex: index + 1 }}>
                                    <ProfileImageLetter
                                        firstName={participant.firstName}
                                        lastName={participant.lastName}
                                        width={27}
                                        height={27}
                                    />
                                </div>
                            )}
                        </React.Fragment>
                    ))}
                    {isParticipantsAmountExceedingViewLimit && (
                        <span className="color-gray">
                            {t("coachDashboard.sessionsTab.participantCount", { count: exceedingParticipantsCount })}
                        </span>
                    )}
                </div>
                <span className="color-gray">
                    {session?.participants?.length === 1
                        ? `${session?.participants?.[0]?.firstName} ${session?.participants?.[0]?.lastName}`
                        : session.title}
                </span>
            </td>
        </tr>
    );
};

export default TableRow;
