import React from "react";
import classNames from "classnames";
import { WarningMessage } from "main-app/components/onboarding/components/WarningMessage";
import TextArea from "main-app/shared/input/TextArea";
import QuestionRow from "./QuestionRow";
import DiagnosticPreviousAnswer from "./DiagnosticPreviousAnswer";

type Props = {
    register: any;
    placeholder?: string;
    name: string;
    className?: string;
    hasFocus?: boolean;
    error?: any;
    question: string;
    order: number;
    isRequired?: boolean;
    previosAnswer: any;
    onFocus?: () => void;
    onBlur?: () => void;
};

const DiagnosticFreeResponseOffboarding = ({
    className = "",
    register,
    placeholder,
    name,
    hasFocus,
    error,
    onFocus,
    question,
    order,
    onBlur,
    previosAnswer,
    isRequired = false
}: Props) => {
    return (
        <div className={classNames("freeresponse-offboarding", { [className]: className })}>
            <QuestionRow question={question} order={order} isRequired={isRequired} className="pl-3" />
            <DiagnosticPreviousAnswer previosAnswer={previosAnswer} />
            <WarningMessage message={error} />
            <TextArea
                register={register}
                onBlur={onBlur}
                onFocus={onFocus}
                placeholder={placeholder}
                name={name}
                className="w-100 freeresponse-offboarding__textarea"
                hasFocus={hasFocus}
            />
        </div>
    );
};

export default DiagnosticFreeResponseOffboarding;
