import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

import useCoach from "main-app/api/use-coach";
import { withTranslation } from "common/utils/lang";
import useCoachRating from "main-app/api/use-coach-rating";
import { CoachRatingAskedFrom, CoachRatingSource, ProductType } from "main-app/constants";
import { useAuthContext } from "main-app/context/Auth";
import QuestionRow from "../diagnostic-question/QuestionRow";
import Avatar from "main-app/shared/avatar/Avatar";
import StarRatings from "main-app/shared/rating/StarRatings";
import Skeleton from "common/components/Skeleton/Skeleton";
import { getErrorMessages } from "common/utils/get-error-messages";
import DividerLine from "main-app/shared/divider/DividerLine";
import { WarningMessage } from "main-app/components/onboarding/components/WarningMessage";
import { http } from "common/http";
import Urls from "main-app/api/urls";
import Coach from "main-app/models/coach";
import { isEmptyString } from "common/utils/gates";
import TextInputBox from "main-app/shared/input/TextInputBox";

import "./styles.scss";

type Props = {
    askedFrom: CoachRatingSource;
    coachSessionId?: number;
    coachData?: Coach;
    errorMessage?: string;
    sendFreeResponse?: boolean;
    showDividerLine?: boolean;
    orderNumber?: number | null;
    textInputBgVariant?: "dark" | "light";
    onStarSelect?: (data: any) => void;
    saveCoachRating?: (coachRatingId: number, freeResponse: string) => void;
    onSubmitCoachRating?: (source: CoachRatingSource) => void;
};

const CoachRating = ({
    askedFrom,
    coachSessionId,
    coachData,
    errorMessage = "",
    sendFreeResponse = true,
    showDividerLine = true,
    orderNumber = 1,
    textInputBgVariant = "dark",
    onSubmitCoachRating,
    onStarSelect,
    saveCoachRating
}: Props) => {
    const { t } = useTranslation();
    const { user } = useAuthContext();
    const { data, isLoading: isLoadingRating } = useCoachRating(askedFrom);
    const { data: coach, isLoading: isLoadingCoach } = useCoach();
    const [selectedStar, setSelectedStar] = useState(0);
    const [coachRating, setCoachRating] = useState(null);
    const [error, setError] = useState(null);
    const { register, getValues } = useForm();

    const sendRating = async star => {
        const submitData = {
            participant: user.id,
            coach: coachData?.id ?? coach?.coachId,
            rating: star,
            question: data?.id,
            free_response: coachRating?.free_response ?? ""
        };

        if (askedFrom === CoachRatingAskedFrom.POST_MODULE_REFLECTION_POPUP) {
            Object.assign(submitData, { coaching_session: coachSessionId });
        }

        if (onStarSelect) {
            const { oneStarQuestion, twoStarQuestion, threeStarQuestion, fourStarQuestion, fiveStarQuestion } =
                data.questionData;

            const responseByStar = {
                1: oneStarQuestion,
                2: twoStarQuestion,
                3: threeStarQuestion,
                4: fourStarQuestion,
                5: fiveStarQuestion
            };

            onStarSelect(submitData);
            setCoachRating({ rating_question: withTranslation(responseByStar[star]), ...submitData });
            return;
        }

        try {
            const { data: response } = await http.post(Urls.submitRating(), submitData);
            setCoachRating(response);
            if (saveCoachRating) {
                const freeResponse = getValues("free_response_prompt");
                saveCoachRating(response.id, !isEmptyString(freeResponse) ? freeResponse : "");
            }
            if (onSubmitCoachRating) {
                onSubmitCoachRating(askedFrom);
            }
        } catch (error) {
            setError(getErrorMessages(error));
            console.log(error);
        }
    };

    const onSelectStar = (star: number) => {
        setSelectedStar(star);
        sendRating(star);
    };

    const onBlurFreeResponseField = useCallback(async () => {
        const freeResponse = getValues("free_response_prompt");
        if (!freeResponse) {
            return;
        }

        if (saveCoachRating) {
            saveCoachRating(coachRating.id, freeResponse);
        }

        if (sendFreeResponse) {
            try {
                await http.patch(Urls.submitRating(coachRating.id), { free_response: freeResponse });
            } catch (error) {
                setError(getErrorMessages(error));
            }
        }
    }, [coachRating]);

    const isLoading = isLoadingCoach && isLoadingRating;

    const showRating = user?.productType !== ProductType.SPOT_COACHING;

    return (
        <>
            {showRating ? (
                <div className="coach-rating" data-testid="coach-rating">
                    {isLoading ? (
                        <Skeleton rows={2} height={40} />
                    ) : (
                        <>
                            <QuestionRow
                                question={data?.mainQuestionTranslated}
                                order={orderNumber}
                                isRequired={data?.required}
                            />
                            <div className="d-flex align-items-center flex-wrap">
                                <Avatar
                                    url={coachData?.photo ?? coach?.photo}
                                    width={40}
                                    height={40}
                                    alt="coach-photo"
                                />
                                <p className="m-0 mx-2">{coachData?.firstName ?? coach?.firstName}:</p>
                                <StarRatings selectedStar={selectedStar} onSelect={onSelectStar} />
                            </div>
                            <WarningMessage message={error || errorMessage} />
                            {coachRating ? (
                                <div className="px-0 px-md-3">
                                    <TextInputBox
                                        name="free_response_prompt"
                                        register={register}
                                        backgroudVariant={textInputBgVariant}
                                        labelText={coachRating?.rating_question}
                                        placeholder={data?.freeResponsePrompt || t("Start typing...")}
                                        onBlur={onBlurFreeResponseField}
                                        className="mt-30 coach-rating-input-box"
                                    />
                                </div>
                            ) : null}
                            {showDividerLine && <DividerLine className="divider-coach show-mobile" />}
                        </>
                    )}
                </div>
            ) : null}
        </>
    );
};

export default CoachRating;
