import React, { useMemo, useState } from "react";
import { v4 as uuid } from "uuid";

import useChapters from "main-app/api/use-chapters";
import { TrackPracticeModalData } from "main-app/models/types";
import { ITrackPractice } from "Manager/chapter/models/types";
import { IChapterApi } from "main-app/models/chapter";
import useChapterComplete from "main-app/api/mutations/use-chapter-complete";
import TrackPracticeForm from "../chapter/TrackPractice/TrackPracticeForm";
import { useAuthContext } from "main-app/context/Auth";
import { withTranslation } from "common/utils/lang";

interface IProps {
    trackPracticeModalData: TrackPracticeModalData;
    onClose: () => void;
}

const TrackPracticeModalContainer: React.FC<IProps> = ({ trackPracticeModalData, onClose }) => {
    const { user } = useAuthContext();
    const { data } = useChapters(trackPracticeModalData?.component?.chapterId);
    const completeChapter = useChapterComplete(trackPracticeModalData?.component?.chapterId);

    const defaultFormValues = useMemo(() => {
        if (data) {
            const trackPractice = data?.components?.find(
                component => component.uuid === trackPracticeModalData.component.uuid
            ) as ITrackPractice;

            return {
                rows: trackPractice?.answer_data?.rows.map(row => {
                    const { columns } = row;
                    return {
                        uuid: uuid(),
                        columns: columns.reduce((cols, answeredCol) => {
                            trackPractice.columns.forEach((col, index) => {
                                if (col.uuid === answeredCol.column_uuid) {
                                    cols.push({
                                        ...answeredCol,
                                        title: withTranslation(col.title),
                                        subtitle: withTranslation(col.subtitle),
                                        typing_prompt: withTranslation(col.typing_prompt),
                                        order: col.order || index + 1
                                    });
                                }
                            });

                            return cols;
                        }, [])
                    };
                })
            };
        }
    }, [data]);

    const onSaveTrackPractice = async value => {
        const columns = value.rows.map(row => ({
            columns: row.columns.map((col, index) => ({
                answer: col?.answer,
                column_uuid: col?.column_uuid,
                title: col?.title,
                subtitle: col?.subtitle,
                typing_prompt: col?.typing_prompt,
                order: col?.order || index + 1
            }))
        }));

        const chapter: IChapterApi = {
            editable_after_finish: data?.editableAfterFinish,
            show_summary_after_finish: data?.showSummaryAfterFinish,
            chapter_summary: {
                summary: data?.chapterSummary?.summary,
                summary_subtitle: data?.chapterSummary?.summarySubtitle,
                summary_title: data?.chapterSummary?.summaryTitle
            },
            enrollment: data.enrollment,
            phase_progress: data.phaseProgress,
            uuid: data.uuid,
            title: data.title,
            status: data.status,
            components: data.components.reduce((components, current) => {
                if (current.uuid === trackPracticeModalData.component.uuid) {
                    return [...components, { ...trackPracticeModalData.component, answer_data: { rows: columns } }];
                }

                if ("answer_data" in current) {
                    return [...components, current];
                }

                return components;
            }, [])
        };

        completeChapter.mutate(chapter, {
            onSuccess: () => {
                onClose();
            }
        });
    };

    const title = `${withTranslation(user?.module?.name)}:  ${trackPracticeModalData.phaseName ?? ""}`;

    return (
        <>
            {defaultFormValues && (
                <TrackPracticeForm
                    defaultFormValues={defaultFormValues}
                    navBarTitle={title}
                    show={trackPracticeModalData.show}
                    onClose={onClose}
                    onSubmit={onSaveTrackPractice}
                    component={trackPracticeModalData.component}
                    phaseName={trackPracticeModalData.phaseName}
                />
            )}
        </>
    );
};

export default TrackPracticeModalContainer;
