import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { AxiosError } from "axios";
import { useQueryClient } from "@tanstack/react-query";

import Heading from "common/components/Heading/Heading";
import { useSimulabContext } from "main-app/entities/simulab";
import Modal from "main-app/shared/modal";
import { QueryKey } from "main-app/entities/simulab/constants";
import Button from "main-app/shared/button/Button";
import { useEndConversation } from "main-app/entities/simulab/api";
import { getErrorMessages } from "common/utils/get-error-messages";
import { WarningMessage } from "main-app/components/onboarding/components/WarningMessage";

import "./../styles.scss";

export const SimulabEndConversationModal = () => {
    const queryClient = useQueryClient();
    const { t } = useTranslation();
    const { showEndConversationModal, conversation, setShowEndConversationModal } = useSimulabContext();
    const [error, setError] = useState(null);
    const endConversation = useEndConversation();

    const onEndConversationClick = useCallback(() => {
        endConversation.mutate(
            { conversationId: `${conversation?.id}` },
            {
                onSuccess() {
                    queryClient.invalidateQueries([QueryKey.SimulabConversation, { id: conversation?.id }]);
                    setShowEndConversationModal(false);
                },
                onError(error: AxiosError) {
                    setError(getErrorMessages(error));
                }
            }
        );
    }, [conversation, setShowEndConversationModal, endConversation]);

    const onCloseModal = useCallback(() => {
        setShowEndConversationModal(false);
    }, [setShowEndConversationModal]);

    return (
        <Modal
            show={showEndConversationModal && conversation.status !== "completed"}
            onClose={onCloseModal}
            modalBodyClass="simulab-end-conversation-modal"
            headerContent={
                <div className="p-1">
                    <Heading tag="h2" className="text-center m-0" fontSize={36}>
                        {t("Congrats, You Finished D.I.G.!")}
                    </Heading>
                </div>
            }
        >
            <section className="simulab-end-conversation-modal__body">
                <p className="font-extrabold mb-20 text-center">
                    {t("We detected a ‘Plan’ Question. Are you done ‘Digging’?")}
                </p>
                <p className="mb-30 text-center">
                    {t(
                        "The SimuLab focuses exclusively on D.I.G., since that’s where we see the greatest opportunity to stimulate coachee growth (and encounter new challenges!). Ending the conversation will generate a feedback summary that provides analysis of your strengths and growth areas."
                    )}
                </p>
                <WarningMessage message={error} />
                <div className="text-center mb-1">
                    <Button onClick={onEndConversationClick}>{t("Yes, End Conversation")}</Button>
                </div>
                <div className="text-center">
                    <Button variant="default" className="font-extrabold" onClick={onCloseModal}>
                        <span className="color-accent fs-14">{t("No, I want to ask another D.I.G. question")}</span>
                    </Button>
                </div>
            </section>
        </Modal>
    );
};
