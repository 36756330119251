import { DateTime } from "luxon";
import { EnrolledModule } from "main-app/models/user";

export const findFutureModule = (modules: EnrolledModule[]) => {
    const now = DateTime.now();

    return modules
        ?.filter(module => DateTime.fromISO(module.first_phase_start) > now)
        .sort(
            (a, b) =>
                DateTime.fromISO(a.first_phase_start).toMillis() - DateTime.fromISO(b.first_phase_start).toMillis()
        );
};

export const findPastModule = (modules: EnrolledModule[]) => {
    const now = DateTime.now();

    return modules
        ?.filter(module => DateTime.fromISO(module.last_phase_end) < now)
        .sort((a, b) => DateTime.fromISO(b.last_phase_end).toMillis() - DateTime.fromISO(a.last_phase_end).toMillis());
};
