import React, { useState, useRef, useEffect } from "react";
import { Transition } from "react-transition-group";

export const DynamicHeightTransition = ({ children }: { children: React.ReactNode }) => {
    const [contentHeight, setContentHeight] = useState(0);
    const contentRef = useRef(null);

    const updateContentHeight = () => {
        setContentHeight(contentRef.current?.getBoundingClientRect().height ?? 0);
    };

    useEffect(() => {
        const resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
                updateContentHeight();
            }
        });

        if (contentRef.current) {
            resizeObserver.observe(contentRef.current);
        }

        return () => {
            if (contentRef.current) {
                resizeObserver.unobserve(contentRef.current);
            }
        };
    }, [contentRef.current]);

    const defaultStyle = {
        transition: `height 300ms ease-in-out`,
        overflow: "hidden"
    };

    const transitionStyles = {
        entering: { height: `${contentHeight}px` },
        entered: { height: `${contentHeight}px` },
        exiting: { height: 0 },
        exited: { height: 0 }
    };

    return (
        <Transition in={true} timeout={300} appear onEnter={updateContentHeight}>
            {state => (
                <div
                    style={{
                        ...defaultStyle,
                        ...transitionStyles[state]
                    }}
                >
                    <div ref={contentRef}>{children}</div>
                </div>
            )}
        </Transition>
    );
};
