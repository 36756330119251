import React from "react";
import { Navigate } from "react-router-dom";
import { FullSpinner } from "main-app/shared/spinner";
import SimpleLayout from "main-app/layouts/SimpleLayout";
import { useAuthContext } from "main-app/context/Auth";
import { ParticipantUrls } from "main-app/constants";

type Props = {};

const PostJourneyRedirect = (props: Props) => {
    const { user, isLoading } = useAuthContext();

    return (
        <SimpleLayout withProfileHeader>
            {isLoading && !user ? (
                <div className="d-flex align-items-center justify-content-center h-100">
                    <FullSpinner />
                </div>
            ) : (
                <Navigate to={ParticipantUrls.END_OF_JOURNEY_PAGE} replace />
            )}
        </SimpleLayout>
    );
};

export default PostJourneyRedirect;
