export interface CoachApi {
    bio_pref_lang: string;
    certification: string;
    country: string;
    email: string;
    first_name: string;
    id: number;
    last_name: string;
    location: string;
    photo: string;
    calendar_setup_confirmed: boolean;
    coach_id?: number;
}

class Coach {
    certification: string;
    country: string;
    email: string;
    firstName: string;
    id: number;
    lastName: string;
    location: string;
    photo: string;
    bio: string;
    calendar_setup_confirmed: boolean;
    coachId?: number;

    constructor(data: CoachApi) {
        this.id = data.id;
        this.certification = data.certification;
        this.country = data.country;
        this.firstName = data.first_name;
        this.email = data.email;
        this.lastName = data.last_name;
        this.photo = data.photo;
        this.location = data.location;
        this.bio = data.bio_pref_lang;
        this.calendar_setup_confirmed = data.calendar_setup_confirmed;
        this.coachId = data.coach_id;
    }
}

export default Coach;
