import React, { useMemo, useState } from "react";
import classNames from "classnames";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Option } from "common/models/option";
import WithMenuCard from "main-app/shared/card/WithMenuCard";
import { GoalsSortType } from "main-app/shared/types/sort";
import DragElement from "main-app/shared/drag-n-drop/DragElement";
import { isEmptyString } from "common/utils/gates";
import TextInput from "main-app/shared/input/TextInput";

import { BehavioralPracticeStatus } from "../../consts";
import BehavioralStatus from "./BehavioralStatus";
import { BehavioralPractice } from "../../model/behavioral-goal";

type Props = {
    index: number;
    sort: GoalsSortType;
    provider?: any;
    snapshot?: any;
    showDrag?: boolean;
    isArchived: boolean;
    previousValue: string;
    onArchiveClick: (index: number) => void;
    onBlurPractice: (index: number) => void;
    remove: (index: number) => void;
};

const ITEMS = [new Option({ value: "edit", label: "Edit" }), new Option({ value: "archive", label: "Archive" })];
const ARCHIVE = [new Option({ value: "unarchive", label: "Unarchive" })];

const BehavioralPracticeCard = ({
    index,
    isArchived,
    sort,
    provider,
    snapshot,
    previousValue,
    showDrag,
    onArchiveClick,
    onBlurPractice
}: Props) => {
    const { t } = useTranslation();

    const { register, setValue, getValues, setError, clearErrors, formState } = useFormContext<{
        practices: BehavioralPractice[];
    }>();
    const { status, description } = getValues(`practices.${index}`);
    const [practiceStatus, setPracticeStatus] = useState(status);
    const [isEdit, setIsEdit] = useState(!description && !isArchived);

    const onBlur = () => {
        const value = getValues(`practices.${index}.description`);
        const hasPreviousValue = !isEmptyString(previousValue);

        if (hasPreviousValue && isEmptyString(value)) {
            setError(`practices.${index}.description`, { message: t("Field is required") });
            return;
        }

        if (isEdit) {
            setIsEdit(false);
        }
        onBlurPractice(index);
    };

    const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Escape") {
            const value = getValues(`practices.${index}.description`);
            const hadInitialValue = !isEmptyString(previousValue) && value !== previousValue;
            setIsEdit(false);

            if (hadInitialValue) {
                setValue(`practices.${index}.description`, previousValue);
                return;
            }

            if (value && isEmptyString(previousValue)) {
                setValue(`practices.${index}.description`, "");
            }

            onBlurPractice(index);
            clearErrors();
            return;
        }

        if (e.key === "Enter") {
            onBlur();
        }
    };

    const onClickPracticeCard = () => {
        if (isArchived) {
            return;
        }

        if (!isEdit) {
            setIsEdit(true);
            clearErrors();
        }
    };

    const onClickStatus = () => {
        if (isArchived) {
            return;
        }
        const statuses = Object.values(BehavioralPracticeStatus);
        const currentIndex = statuses.findIndex(statusValue => statusValue === status);
        const newStatus = currentIndex === statuses.length - 1 ? statuses[0] : statuses[currentIndex + 1];
        setPracticeStatus(newStatus);
        setValue(`practices.${index}.status`, newStatus);
        onBlurPractice(index);
    };

    const onMenuItemClick = (item: Option) => {
        if (item.value === "archive" || item.value === "unarchive") {
            onArchiveClick(index);
        }

        if (!isArchived) {
            setIsEdit(true);
        }
    };

    const onClickOutside = () => {
        if (isEdit) {
            onBlur();
        }
    };

    const isArchivedFilter = sort === "archived";
    const isAllFilter = sort === "all";
    const isActiveFilter = sort === "active";

    const showPracticeCard = useMemo(() => {
        const shouldShowCard = (isArchived && isArchivedFilter) || (isActiveFilter && !isArchived) || isAllFilter;
        return shouldShowCard;
    }, [sort, isArchived]);

    return (
        <>
            {showPracticeCard && (
                <WithMenuCard
                    className="mb-2"
                    cardClassName={classNames("behavioral-practice-card", {
                        "bg-light-accent": isArchived,
                        "border-light": isArchived,
                        blurred: isArchived
                    })}
                    menuItems={!isArchived ? ITEMS : ARCHIVE}
                    showContextMenu={!isEdit}
                    showThreeDots={!isEdit || isArchived}
                    menuOffsets={{ right: 5 }}
                    onMenuItemClick={onMenuItemClick}
                    onClickOutside={onClickOutside}
                >
                    {showDrag && isActiveFilter && (
                        <DragElement
                            className="behavioral-practice-card-drag"
                            provider={provider}
                            snapshot={snapshot}
                        />
                    )}
                    <div className="d-flex align-items-center">
                        <BehavioralStatus
                            status={practiceStatus}
                            className={classNames("mr-md-4 mr-1", { archived: isArchived })}
                            onClick={onClickStatus}
                        />
                        <div onClick={onClickPracticeCard} className="text-truncate flex-1">
                            {isEdit && (isActiveFilter || isAllFilter) && !isArchived ? (
                                <TextInput
                                    groupClassName="m-0 w-100"
                                    variant="rect"
                                    placeholder={t("What small practice or experiment can you try?")}
                                    name={`practices.${index}.description`}
                                    autoFocus={true}
                                    textArea
                                    maxLength={1024}
                                    dynamicHeight
                                    register={register}
                                    onKeyDown={onKeyDown}
                                    errorClass="input-error-small"
                                    genericError={formState?.errors?.practices?.[index]?.description?.message}
                                />
                            ) : (
                                <p className={classNames("m-0 text-truncate behavioral-practice-card__text")}>
                                    {description}
                                </p>
                            )}
                        </div>
                    </div>
                </WithMenuCard>
            )}
        </>
    );
};

export default BehavioralPracticeCard;
