import React, { SVGProps } from "react";
import classNames from "classnames";

interface Props extends SVGProps<SVGSVGElement> {
    type?: "default" | "marked" | "unmarked";
    enableBorder?: boolean;
}

const CheckMarkAttendee = (props: Props) => {
    const { width = 36, height = 36, className = "", type = "default", enableBorder = false, ...rest } = props;

    const classes = classNames("check-mark-attendee", className, {
        "with-border": enableBorder,
        default: type === "default",
        marked: type === "marked",
        unmarked: type === "unmarked"
    });

    return (
        <svg
            width={width}
            height={height}
            className={classes}
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect x="0.5" y="0.5" width="35" height="35" rx="17.5" />
            <path d="M11.4946 18.1808L15.4702 22.5177L24.5055 13.4824" strokeWidth="2" strokeLinecap="square" />
        </svg>
    );
};

export { CheckMarkAttendee };
