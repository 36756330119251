import React from "react";
import { useTranslation } from "react-i18next";

import Heading from "common/components/Heading/Heading";
import Modal from "main-app/shared/modal";
import Button from "main-app/shared/button/Button";
import { SsoOrganization } from "main-app/models/api/sso";

import "./styles.scss";

type Props = {
    active: boolean;
    ssoData: SsoOrganization;
    onClose: () => void;
};

const SsoModal = ({ active, ssoData, onClose }: Props) => {
    const { t } = useTranslation();

    return (
        <Modal
            show={active}
            onClose={onClose}
            modalBodyClass="sso-modal"
            enableOutSideClick={false}
            closeOnEscapePress={false}
            showCloseIcon={false}
            headerContent={
                <div className="p-3">
                    <Heading tag="h2" className="text-center m-0" fontSize={24}>
                        {t("Authenticate with {{orgName}}", { orgName: ssoData?.organization })}
                    </Heading>
                </div>
            }
        >
            <div className="sso-modal__body">
                <p className="mb-1 color-gray text-center">
                    {t(
                        "We recognize your email as part of an organization that requires sign in via SSO (single sign-on)."
                    )}
                </p>
                <p className="mb-60 color-gray text-center">
                    {t("Click below to access your organization’s SSO portal.")}
                </p>
                <div className="text-center">
                    <Button>
                        <a href={ssoData?.redirect} className="color-inherit">
                            {t("Continue to Sign In")}
                        </a>
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default SsoModal;
