import * as React from "react";

function LockedModule(props: React.SVGProps<SVGSVGElement> & { isActive?: boolean }) {
    const { width = 10, height = 10, isActive = false } = props;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 11 11"
            fill="none"
            data-testid="locked-module-svg"
        >
            <path
                d="M7.38757 5.04208V3.68367C7.38757 2.72831 6.54284 1.95386 5.50033 1.95386C4.45802 1.95386 3.61308 2.72907 3.61308 3.68367V5.04208H3.23022C2.87314 5.04208 2.58367 5.34988 2.58367 5.72781V9.16027C2.58367 9.53901 2.87295 9.846 3.23022 9.846H7.77045C8.12752 9.846 8.41699 9.5382 8.41699 9.16027V5.72781C8.41699 5.34907 8.12772 5.04208 7.77045 5.04208H7.38757ZM7.58366 5.89991V9.02491H3.41699V5.89991H7.58366ZM4.45866 5.04208V3.68367C4.45866 3.12176 4.82467 2.77491 5.50033 2.77491C6.17644 2.77491 6.54199 3.12124 6.54199 3.68367V5.04208H4.45866ZM5.84346 7.02691C5.84346 6.83744 5.69117 6.68383 5.50033 6.68383C5.31083 6.68383 5.15719 6.8361 5.15719 7.02691V8.05646C5.15719 8.24593 5.30948 8.39954 5.50033 8.39954C5.68983 8.39954 5.84346 8.24727 5.84346 8.05646V7.02691Z"
                fill={isActive ? "#FD4D00" : "#6F6F6F"}
            />
        </svg>
    );
}

export default React.memo(LockedModule);
