import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";

import User from "main-app/models/user";
import { isParticipant } from "main-app/utils/gates/user";

export const setUserDataDog = (user: User) => {
    const userData = {
        id: `${user.id}`,
        name: `${user.firstName} ${user.lastName}`,
        email: user.email,
        cohort: isParticipant(user) ? user.cohort : user.role,
        organization: user.organization,
        version: JSAPP_CONF?.static ?? ""
    };

    datadogRum.setUser(userData);

    datadogLogs.setUser(userData);
};
