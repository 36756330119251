import React, { useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import Heading from "common/components/Heading/Heading";
import { GoalsWrapper } from "./ui/GoalsWrapper";
import { OldBeliefGoal } from "main-app/entities/old-belief-goal";
import CoachingGoal from "main-app/widgets/coaching-goal/ui/CoachingGoal";
import { BehavioralGoals } from "main-app/widgets/behavioral-goal/ui/BehavioralGoal";

import "./ui/styles.scss";

type Props = {};

export const CoachingPlanPageCoach = (props: Props) => {
    const { t } = useTranslation();
    const [isToggledOldBelief, setIsToggledOldBelief] = useState(false);

    return (
        <div className="goals-layout">
            <GoalsWrapper>
                <div className={classNames("goals-grid", { toggled: isToggledOldBelief })}>
                    <section className={classNames("goals-main", { expanded: isToggledOldBelief })}>
                        <Heading textAlign="center" fontSize={36}>
                            {t("Coaching Plan")}
                        </Heading>
                        <CoachingGoal />
                        <div className="mb-30" />
                        <BehavioralGoals />
                    </section>
                    <aside className={classNames("goal-aside", { toggled: isToggledOldBelief })}>
                        <OldBeliefGoal
                            toggle={() => setIsToggledOldBelief(!isToggledOldBelief)}
                            isToggled={isToggledOldBelief}
                        />
                    </aside>
                </div>
            </GoalsWrapper>
        </div>
    );
};
