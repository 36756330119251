import React from "react";
import classNames from "classnames";

import AssessmentResponseItem from "./AssessmentResponseItem";
import { withTranslation } from "common/utils/lang";

import "./styles.scss";

type diagnosticAnswer = {
    question: {
        en: string;
    };
    answer: string;
    [key: string]: any;
};

type Props = {
    title: string;
    diagnosticAnswers: diagnosticAnswer[];
    variant?: "offboarding" | "";
    className?: string;
};

const AssessmentResponses = ({ title, diagnosticAnswers, variant = "", className = "" }: Props) => {
    return (
        <div className={classNames("assessment-responses", variant, { [className]: className })}>
            <div className="assessment-responses-header">{title}</div>
            {diagnosticAnswers?.map(answer => (
                <AssessmentResponseItem question={withTranslation(answer.question)} answer={answer.answer} />
            ))}
        </div>
    );
};

export default AssessmentResponses;
