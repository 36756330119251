import React, { useCallback, useRef, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import Modal from "main-app/shared/modal";
import { ITrackPractice } from "Manager/chapter/models/types";
import { withTranslation } from "common/utils/lang";

import TrackPracticeBoxes from "./TrackPracticeBoxes";
import DeleteModal from "./DeleteModal";
import { TrackPracticeAssignmentSummary } from "./TrackPracticeAssignmentSummary";

interface IProps {
    onClose: () => void;
    show: boolean;
    component: ITrackPractice;
    fields: any[];
    phaseName: string;
    addRows: () => void;
    onDeleteRow: (index: number) => void;
    onSubmit: (values: any) => void;
    handleSubmit: (any: any) => any;
}

const TrackPracticeModal: React.FC<IProps> = ({
    onClose,
    show,
    component,
    addRows,
    fields,
    onDeleteRow,
    phaseName,
    onSubmit,
    handleSubmit
}) => {
    const { t } = useTranslation();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState(null);
    const [onAddRowFocus, setOnAddRowFocus] = useState(false);

    const onOpenDeleteModal = (index: number) => {
        setDeleteIndex(index);
        setShowDeleteModal(true);
    };

    const onCloseDeleteModal = () => {
        setShowDeleteModal(false);
        setDeleteIndex(null);
    };

    const onDelete = () => {
        onDeleteRow(deleteIndex);
        setShowDeleteModal(false);
    };

    const onAddRow = () => {
        setOnAddRowFocus(true);
        addRows();
    };

    const onCloseModal = useCallback((): void => (setOnAddRowFocus(false), onClose()), [onClose]);

    return (
        <>
            <Modal
                onClose={onCloseModal}
                show={show}
                modalBodyClass="modal_practice"
                enableOutSideClick={!showDeleteModal}
            >
                <div className="popup_header modal_practice_header br-top-10">
                    <h5 className="m-0 mr-auto ml-auto font-extrabold text-center">{phaseName}</h5>
                    <button onClick={onClose}>
                        <svg className="color-brand svg-12 cross" viewBox="0 0 16 14">
                            <path d="M1.5 0.816406L14.5 12.3238" fill="none" stroke="currentColor" strokeWidth="2" />
                            <path d="M14 0.816406L1 12.3238" fill="none" stroke="currentColor" strokeWidth="2" />
                        </svg>
                    </button>
                    <TrackPracticeAssignmentSummary component={component} />
                </div>
                <div className="modal_practice_content br-bottom-10">
                    <div
                        className={classNames("modal_practice_content_header", {
                            two: component.columns.length === 2,
                            three: component.columns.length === 3
                        })}
                    >
                        {component?.columns.map(col => (
                            <div key={col.uuid}>
                                <h4 className="h4 font-extrabold text-center">{withTranslation(col.title)}</h4>
                                <p className="h4 text-center p_none">{withTranslation(col?.subtitle)}</p>
                            </div>
                        ))}
                    </div>
                    <div
                        className={classNames("content_container", {
                            "_one-itemed": component.columns.length === 1,
                            "_two-itemed": component.columns.length === 2,
                            "_three-itemed": component.columns.length === 3
                        })}
                    >
                        <div className="review">
                            {fields.map((row, index) => (
                                <div
                                    className={classNames("track-row", {
                                        "_one-itemed": component.columns.length === 1
                                    })}
                                    key={row.uuid}
                                >
                                    <TrackPracticeBoxes
                                        rowIndex={index}
                                        onAddRowsFocus={onAddRowFocus}
                                        rowsLength={fields.length}
                                        onDeleteRow={onOpenDeleteModal}
                                    />
                                </div>
                            ))}
                        </div>

                        <button className="add_comment br-10" onClick={onAddRow}>
                            <p className="font-extrabold">+</p>
                        </button>
                        <button className="save" onClick={handleSubmit(onSubmit)}>
                            {t("Save & Close")}
                        </button>
                    </div>
                </div>
            </Modal>
            <DeleteModal show={showDeleteModal} onClose={onCloseDeleteModal} onOk={onDelete} />
        </>
    );
};

export default TrackPracticeModal;
