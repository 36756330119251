import React, { type FC, type ReactElement, useEffect } from "react";

import LeftSidebar from "main-app/components/dashboard/LeftSidebar";
import ParticipantLayout from "main-app/layouts/ParticipantLayout";
import BackButton from "main-app/shared/back-btn/BackButton";
import useMediaQuery from "common/hooks/use-media-query";
import { MAX_WIDTH_MOBILE_MEDIA } from "main-app/constants";
import { SIDEBAR_MENU_TOGGLER_ID } from "main-app/shared/sidebar/Sidebar";

import { GuestParticipantContainer } from "./GuestParticipantContainer";
import { GuestParticipantHeading } from "./GuestParticipantHeading";

import "./styles.scss";

const GuestParticipant: FC = (): ReactElement => {
    const isMobile: boolean = useMediaQuery(MAX_WIDTH_MOBILE_MEDIA);

    useEffect(() => () => document?.getElementById(SIDEBAR_MENU_TOGGLER_ID)?.click(), []);

    return (
        <>
            <ParticipantLayout
                className="h-100"
                leftSidebar={<LeftSidebar integrated closedMenu />}
                {...(!isMobile && {
                    title: (
                        <div className="swap-session__header">
                            <BackButton className="color-brand back-btn-float" />
                            <GuestParticipantHeading />
                        </div>
                    )
                })}
            >
                <GuestParticipantContainer />
            </ParticipantLayout>
        </>
    );
};

export default GuestParticipant;
