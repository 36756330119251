import React, { useCallback, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import SimpleLayout from "main-app/layouts/SimpleLayout";
import { StepsNavigator } from "./ui/StepNavigator";
import { CoachCalendarStepUrls } from "./constants";
import Heading from "common/components/Heading/Heading";
import DateWidget from "main-app/shared/DateWidget";
import Button from "main-app/shared/button/Button";
import { useAvailability } from "main-app/entities/coach-calendar/api/use-availavility-rules-attrs";
import CronofyWidget from "common/components/CronofyWidgets/CronofyWidget";
import { FullSpinner } from "main-app/shared/spinner";
import { http } from "common/http";
import Urls from "main-app/api/urls";
import { getErrorMessages } from "common/utils/get-error-messages";
import { WarningMessage } from "main-app/components/onboarding/components/WarningMessage";
import { CRONOFY_WIDGET } from "common/constants";
import { COACH_AVAILABILITY_TIME } from "main-app/constants";
import { coachCalendarSetupRoutes } from "main-app/routes";
import { useOrderNavigationCalendar } from "main-app/entities/coach-calendar/hooks/use-order-navigation-calendar";

type Props = {
    order?: number;
};

const AvailabilityStep = ({ order }: Props) => {
    const { navigateByOrder } = useOrderNavigationCalendar(order);
    const viewerRef = useRef<HTMLDivElement>();
    const { data, isLoading } = useAvailability();
    const [rules, setRules] = useState(null);
    const [error, setError] = useState(null);

    const onClick = async () => {
        try {
            const btn = viewerRef?.current?.querySelector(".av__submit") as HTMLButtonElement;
            btn?.click();
            if (rules) {
                await http.post(Urls.avRules(), rules.availability_rule);
            }
            navigateByOrder();
        } catch (error) {
            setError(getErrorMessages(error));
        }
    };

    const onAvailabilitySelect = useCallback(async data => {
        if (data) {
            try {
                setRules(data);
            } catch (error) {
                setError(getErrorMessages(error));
            }
        }
    }, []);

    const memoAvailabilityRules = useMemo(
        () => (
            <CronofyWidget
                ref={viewerRef}
                widget={CRONOFY_WIDGET.AvailabilityRules}
                id="availability-rules-calendar"
                options={{
                    ...data,
                    styles: {
                        prefix: "av"
                    },
                    callback: onAvailabilitySelect
                }}
            />
        ),
        [data]
    );

    return (
        <SimpleLayout
            header={<DateWidget className="coach-date-title" />}
            headerVariant="gray"
            showBackBtn
            showProfilePhoto
        >
            <div className="coach-calendar-wrapper mx-auto pb-5">
                <div className="d-flex justify-content-center mt-5">
                    <StepsNavigator activeStep={CoachCalendarStepUrls.AVAILABILITY_STEP} />
                </div>
                <Heading textAlign="center" tag="h6" fontSize={16} className="mb-40 mt-5 color-brand">
                    Step {order}
                </Heading>
                <Heading tag="h1" textAlign="center" className="mb-2" fontSize={36}>
                    Edit Your Working Hours
                </Heading>

                <p className="color-gray fs-14 text-center mb-0">You may now manually tweak your available hours.</p>
                <p className="color-gray fs-14 text-center mb-40">
                    Drag your mouse up & down across the grid to adjust your availability.
                </p>

                {isLoading ? <FullSpinner /> : memoAvailabilityRules}
                <WarningMessage message={error} />
                <div className="text-center mt-50">
                    <Button onClick={onClick} className="btn-200">
                        Next
                    </Button>
                </div>
            </div>
        </SimpleLayout>
    );
};

export default AvailabilityStep;
