import React, { useCallback, useState } from "react";
import classNames from "classnames";
import { Trans, useTranslation } from "react-i18next";
import sanitizeHtml from "sanitize-html";
import Button from "main-app/shared/button/Button";
import useRoles from "main-app/utils/hooks/use-roles";
import { withTranslation } from "common/utils/lang";
import { Chevron } from "main-app/svgs";

import QuestionMark from "main-app/svgs/QuestionMark";
import ResultsBar from "./ResultsBar";

import "./styles.scss";
import { SummaryChevron } from "./SummaryChevron";

interface IProps {
    summary: any;
    className?: string;
    selectedUserFirstName?: string;
    showQuestionTooltip?: boolean;
    onTooltipClick?: (category) => void;
}

const SummaryResult: React.FC<IProps> = ({
    summary,
    className = "",
    selectedUserFirstName = "",
    showQuestionTooltip = false,
    onTooltipClick
}) => {
    const { t } = useTranslation();
    const { isCoaches } = useRoles();
    const [collapsedModules, setCollapsedModules] = useState<number[]>([]);

    const onClickResults = useCallback(
        index => {
            setCollapsedModules(active => {
                if (active.includes(index)) {
                    return active.filter(item => item !== index);
                } else {
                    return [...active, index];
                }
            });
        },
        [collapsedModules]
    );

    return (
        <div className={classNames("summary-result", { [className]: className })} data-testid="summary-result">
            <div className="d-flex mb-1 align-items-center">
                <p className="font-extrabold m-0 mr-1">
                    <Trans i18nKey="<0>Skills for </0><1>{categoryName}</1>">
                        <span>Skills for </span>
                        <span className="color-brand">{{ categoryName: withTranslation(summary.category.name) }}</span>
                    </Trans>
                </p>
                {showQuestionTooltip && (
                    <Button
                        variant="default"
                        className="p-1 d-flex align-items-center justify-content-center"
                        data-testid="question-tooltip-button"
                        onClick={() => onTooltipClick && onTooltipClick(summary.category)}
                        aria-label={withTranslation(summary.category.name)}
                    >
                        <QuestionMark />
                    </Button>
                )}
            </div>
            <p className="mb-3">
                <span>{isCoaches ? selectedUserFirstName : t("My confidence levels")}:</span>
            </p>

            {summary.modules.map((module, index) => (
                <React.Fragment key={module.id}>
                    <div
                        role="button"
                        className="d-flex align-items-center mb-2 results-btn"
                        onClick={() => onClickResults(index)}
                        data-testid={`results-bar-${index}`}
                    >
                        <ResultsBar
                            max={module.max_rank}
                            current={module.rank}
                            title={withTranslation(module.title)}
                            key={module.id}
                            summaryValue={withTranslation(module.summary.short_summary)}
                            active={collapsedModules.includes(index)}
                            className="mr-2 flex-1"
                        />
                        <Button variant="default" className="p-0 chevron-btn">
                            <SummaryChevron active={collapsedModules.includes(index)} />
                        </Button>
                    </div>

                    {collapsedModules.includes(index) ? (
                        <div className="summary-result__module-descr mt-4" data-testid="summary-collapsed-text">
                            <h5 className="fs-14 font-extrabold">{withTranslation(module.title)}</h5>
                            <div
                                className="fs-14"
                                dangerouslySetInnerHTML={{
                                    __html: sanitizeHtml(withTranslation(module.long_description), { allowedTags: [] })
                                }}
                            />
                        </div>
                    ) : null}
                </React.Fragment>
            ))}
        </div>
    );
};

export default SummaryResult;
