import * as yup from "yup";

import { i18n } from "common/i18n";

export const registerSchema = yup.object({
    password: yup
        .string()
        .min(8, i18n.t("Ensure the password has at least {{number}} characters", { number: 8 }))
        .required(i18n.t("Password is required")),
    confirm_password: yup
        .string()
        .required(i18n.t("Password confirm is required"))
        .oneOf([yup.ref("password")], i18n.t("Passwords don't match."))
});
