import React from "react";

const EditSvg = () => (
    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.8" y="0.3" width="17.4" height="17.4" rx="8.7" stroke="#6F6F6F" strokeWidth="0.6" />
        <path d="M13.7 6.43881L12.061 4.7998L10.9253 5.93548L12.5643 7.57449L13.7 6.43881Z" fill="#6F6F6F" />
        <path
            d="M6.97998 11.5197L8.78296 11.3558L11.8855 8.25327L10.2465 6.61426L7.14395 9.7168L6.97998 11.5197Z"
            fill="#6F6F6F"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.7598 13.1994H5.2998V12.3594H10.7598V13.1994Z"
            fill="#6F6F6F"
        />
    </svg>
);

export default EditSvg;
