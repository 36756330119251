import React from "react";

import classNames from "classnames";
import "./styles.scss";

interface IProps {
    size?: "sm" | "md" | "max-md" | "default";
    children?: React.ReactNode;
}

const ParticipantWrapper: React.FC<IProps> = ({ children, size = "sm" }) => {
    return (
        <div
            className={classNames("participant-wrapper", {
                "participant-wrapper-md": size === "md",
                "participant-wrapper-sm": size === "default",
                "participant-wrapper-max-md": size === "max-md"
            })}
        >
            {children}
        </div>
    );
};

export default ParticipantWrapper;
