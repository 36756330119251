import React, { memo } from "react";
import classNames from "classnames";
import Avatar from "main-app/shared/avatar/Avatar";
import ThreeWayIconSvg from "main-app/svgs/ThreeWayIconSvg";
import ThreeWayPartyManagerAvatarSvg from "main-app/svgs/ThreeWayPartyManagerAvatarSvg";

import "./styles.scss";

type Props = {
    url: string | null;
    className?: string;
    width?: number;
    alt?: string;
    height?: number;
    circleFillColor?: string;
};

export const ThreeWayAvatar = memo(({ url, width = 40, height = 40, className, alt }: Props) => {
    return (
        <Avatar
            url={url}
            alt={alt}
            badgeIcon={<ThreeWayIconSvg />}
            className={classNames("avatar-image-sm three-way-avatar", className)}
            width={width}
            height={height}
        />
    );
});

export const ThreeWayManagerAvatar = memo(
    ({ className, badgeIcon }: { className?: string; badgeIcon?: React.ReactNode | null }) => {
        return (
            <div className={classNames("avatar-image", className)} data-testid="avatar-image">
                <ThreeWayPartyManagerAvatarSvg />
                {badgeIcon && <span className="badge-icon">{badgeIcon}</span>}
            </div>
        );
    }
);
