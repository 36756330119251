import React, { memo, useMemo, useRef, useState } from "react";
import classNames from "classnames";
import ContentLoader from "react-content-loader";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import AccordionSvg from "main-app/svgs/AccordionSvg";
import useMediaQuery from "common/hooks/use-media-query";

import { MAX_WIDTH_MOBILE_MEDIA } from "main-app/constants";
import useResources from "main-app/api/use-resources";
import { useAuthContext } from "main-app/context/Auth";

import ResourceLink from "./ResourceLink";

import "./styles.scss";
import { isNullOrUndefined } from "common/utils/gates";

interface IProps {}

const Resources: React.FC<IProps> = () => {
    const { t } = useTranslation();
    const params = useParams();
    const { user } = useAuthContext();
    const module = user?.enrolledModules?.find(module => module.rank === +params?.moduleId);
    const { data: resources, isLoading: isLoadingResources, isFetching } = useResources(module?.id);
    const matches = useMediaQuery(MAX_WIDTH_MOBILE_MEDIA);
    const [activeMobileAccordion, setActiveMovileAccordion] = useState(false);
    const contentRef = useRef<HTMLDivElement>();

    const toggleMobileAccordion = () => {
        if (!matches) {
            return;
        }

        setActiveMovileAccordion(prev => !prev);
    };

    const resourceNavStyle = useMemo<React.CSSProperties>(() => {
        if (matches && activeMobileAccordion) {
            return {
                height: contentRef.current?.scrollHeight + "px",
                visibility: "visible"
            };
        }

        if (matches) {
            return {
                height: "0px",
                visibility: "hidden"
            };
        }

        return {
            height: contentRef.current?.scrollHeight + "px",
            visibility: "visible"
        };
    }, [matches, activeMobileAccordion]);

    if (isLoadingResources && isFetching) {
        return (
            <div className="mt-47" data-testid="content-loader">
                {Array.from({ length: 5 }).map((_, index) => (
                    <div className="w-50" style={{ height: 30 }} key={index}>
                        <ContentLoader
                            speed={3}
                            height={"100%"}
                            width={"100%"}
                            backgroundColor="#f3f3f3"
                            foregroundColor="#ecebeb"
                        >
                            <rect x="7" y="14" rx="0" ry="0" width="100%" height="100%" />
                            <rect x="122" y="71" rx="0" ry="0" width="0" height="1" />
                        </ContentLoader>
                    </div>
                ))}
            </div>
        );
    }

    return (
        <>
            {resources?.length > 0 && !isNullOrUndefined(module) ? (
                <section className="resources w-100 mt-90 mb-87 mt-62-mobile" data-testid="resources">
                    <div className="d-flex position-relative resources-heading" onClick={toggleMobileAccordion}>
                        <h3 className="fs-20-bold mb-19 d-block w-100">{t("More Resources")}</h3>
                        <AccordionSvg
                            className={classNames("accordion-close d-block d-md-none", {
                                "toggle-mobile-close": activeMobileAccordion
                            })}
                        />
                    </div>
                    <nav className="resources-nav" style={resourceNavStyle} ref={contentRef}>
                        <ul className="resources-list">
                            {resources?.map(resource => <ResourceLink resource={resource} key={resource.order} />)}
                        </ul>
                    </nav>
                </section>
            ) : null}
        </>
    );
};

export default memo(Resources);
