import React, { useMemo } from "react";
import { useSimulabContext } from "main-app/entities/simulab";
import { Role } from "main-app/constants";
import Button from "main-app/shared/button/Button";
import { useStoreContext } from "main-app/context/GlobalStore";

import "./../styles.scss";

type Props = {
    onClickFeedback: () => void;
};

export const SimulabFeedbackMobile = ({ onClickFeedback }: Props) => {
    const { simulabMessages, selectedMessage } = useSimulabContext();
    const { isOpenBurgerMenu } = useStoreContext();

    const currentFeedback = useMemo(() => {
        const message = simulabMessages?.messages?.find(message => message?.id === selectedMessage)?.owlFeedback;

        if (!message && !selectedMessage) {
            const recentMessage = simulabMessages?.messages
                ?.slice(-3)
                .find(message => message.authorRole === Role.Participant);
            return recentMessage?.owlFeedback;
        }

        return message;
    }, [simulabMessages, selectedMessage]);

    return (
        !isOpenBurgerMenu &&
        simulabMessages?.messages?.length > 0 && (
            <Button className="p-0 simulab-feedback-mobile" variant="default" onClick={onClickFeedback}>
                <div className="simulab-feedback-mobile__body">
                    <p className="m-0 fs-14">{currentFeedback?.feedbackData?.feedbackSummary ?? ""}</p>
                </div>
            </Button>
        )
    );
};
