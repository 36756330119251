import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { AxiosError } from "axios";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import LikertComponent from "main-app/shared/likert/LikertComponent";
import Button from "main-app/shared/button/Button";
import OnboardingLayout from "./layout/OnboardingLayout";
import { StepsNavigator } from "./components/StepsNavigator";
import usePreprogramQuestions from "./api/use-preprogram-questions";
import { OnboardingSteps, StepUrls } from "./constants";
import { HTTPStatus } from "../../../common/constants";
import { getErrorMessages } from "../../../common/utils/get-error-messages";
import AttentionSvg from "../../svgs/AttentionSvg";
import useNextStep from "./api/mutations/use-next-step";
import { withTranslation } from "common/utils/lang";
import { ReflectionComponentType } from "main-app/constants";

interface IProps {}

const DiagnosticStep: React.FC<IProps> = () => {
    const { t } = useTranslation();
    const { data, refetch } = usePreprogramQuestions();
    const navigate = useNavigate();
    const [errorsMsg, setErrorsMsg] = useState(null);
    const [isBusy, setIsBusy] = useState(false);
    const [canFocus, setCanFocus] = useState(true);
    const handleNextStep = useNextStep();
    let flatQuestionIndex = 0;

    const diagnosticQuestions = data?.map(item => item.question_list)?.flat();

    const answer_data = data?.reduce((acc, currentItem) => {
        if (currentItem.answer_data) {
            acc.push(currentItem.answer_data);
        }

        return [...new Set(acc.flat())];
    }, []);

    let defaultValues = answer_data?.reduce((acc, currentItem) => {
        acc[currentItem.question_uuid] = currentItem.option_uuid;

        return acc;
    }, {});

    const shapeWithDynamicFields = diagnosticQuestions
        ? diagnosticQuestions?.reduce((obj, question) => {
              obj[question.uuid] = yup.string().required(t("Question is required")).nullable(true);
              return obj;
          }, {})
        : null;

    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
        reset
    } = useForm({
        resolver: yupResolver(yup.object(shapeWithDynamicFields)),
        shouldFocusError: false,
        defaultValues: useMemo(() => {
            return defaultValues;
        }, [data])
    });

    const onError = () => {
        setCanFocus(true);
    };

    useEffect(() => {
        if (errors && canFocus) {
            const elements = Object.keys(errors)
                .map(name => document.getElementsByName(name)[0])
                .filter(el => !!el);

            if (elements.length > 0) {
                let errorElement = elements[0];
                errorElement.scrollIntoView({ behavior: "smooth", block: "center" });
                errorElement.focus({ preventScroll: true });
                setCanFocus(false);
            }
        }
    }, [errors, canFocus]);

    useEffect(() => {
        refetch();
    }, []);

    useEffect(() => {
        reset(defaultValues);
    }, [data]);

    const findAnswerOptionResponse = (questionUuid: string, optionUuid: string) => {
        const question = diagnosticQuestions.find(item => item.uuid === questionUuid);

        const option = question?.options?.find(option => option.uuid === optionUuid);

        return withTranslation(option?.response);
    };

    const onSubmit = values => {
        const answer_list = Object.entries(values).reduce((acc: any[], current: any[]) => {
            const question_uuid = current[0];
            const option_uuid = current[1];
            const answer = findAnswerOptionResponse(question_uuid, option_uuid);
            const module = data?.find(item => item.question_list?.find(question => question.uuid === question_uuid))
                ?.module?.id;
            acc.push({ question_uuid, option_uuid, answer, module });

            return acc;
        }, []);

        const step_data = data?.map(item => {
            const responseAnswerList = answer_list
                .filter(answer => answer.module === item.module.id)
                .map(answer => {
                    delete answer.module;

                    return answer;
                });
            return {
                type: item.type,
                question_list: item.question_list,
                summary_list: item.summary_list,
                module: item.module.id,
                answer_list: responseAnswerList
            };
        });

        setIsBusy(true);
        handleNextStep.mutate(
            { step: OnboardingSteps.DIAGNOSTIC_STEP, step_data },
            {
                async onSuccess() {
                    navigate(StepUrls.DIAGNOSTIC_RESPONSE_STEPS);
                },
                onError(error: AxiosError) {
                    const errorMessage = getErrorMessages(error);
                    if (error?.response?.status === HTTPStatus.BAD_REQUEST) {
                        setErrorsMsg(errorMessage);
                    }
                    console.log(errorMessage);
                },
                onSettled() {
                    setIsBusy(false);
                }
            }
        );
    };

    return (
        <OnboardingLayout header={<StepsNavigator activeStep={{ id: OnboardingSteps.DIAGNOSTIC_STEP, order: 3 }} />}>
            <div className="container container-max-md">
                <div className="mb-60 mt-6 ">
                    <h1 className="fs-36-xs text-center">{t("Diagnostic")}</h1>
                </div>
                {errorsMsg && (
                    <div className="form-has-error">
                        <div className="input-error justify-content-center">
                            <AttentionSvg />
                            <span className="d-inline-block mb-sm-group">{errorsMsg}</span>
                        </div>
                    </div>
                )}
                <form onSubmit={handleSubmit(onSubmit, onError)}>
                    {data?.map(diagnosticData =>
                        diagnosticData?.question_list?.map(item => {
                            flatQuestionIndex += 1;

                            return (
                                <LikertComponent
                                    item={item}
                                    className={classNames({
                                        "mb-60": data.length * diagnosticData?.question_list?.length > flatQuestionIndex
                                    })}
                                    questionNumber={flatQuestionIndex}
                                    color="blank"
                                    register={register}
                                    control={control}
                                    errors={errors}
                                    questionStyle="one-line"
                                    likertType={ReflectionComponentType.LIKERT_GROUP}
                                />
                            );
                        })
                    )}
                    <div className="mt-50 text-center">
                        <Button type="submit" isBusy={isBusy} className="font-weight-800 btn-200">
                            {t("Next")}
                        </Button>
                    </div>
                </form>
            </div>
        </OnboardingLayout>
    );
};

export default DiagnosticStep;
