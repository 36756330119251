import React from "react";
import { useTranslation } from "react-i18next";

import LiveNowSvg from "main-app/svgs/LiveNowSvg";
import useCoachingSessions from "main-app/api/use-coaching-sessions";
import { formatDateSessions, getTimezoneAbbreviation } from "main-app/utils/date";
import useLiveSessionCheck from "main-app/utils/hooks/use-live-session-check";

import "./styles.scss";

interface IProps {}

const JoinLiveSessionWidget: React.FC<IProps> = () => {
    const { t } = useTranslation();
    const { data: sessions, refetch } = useCoachingSessions();

    const session = sessions?.upcoming_sessions.find(session => session.session_time);

    useLiveSessionCheck({ date: session?.session_time }, () => {
        refetch();
    });

    return (
        <>
            {sessions?.live_session && (
                <div className="join-session-widget mt-4" data-testid="join-session-widget">
                    <a className="join-session-widget-link" href={sessions?.live_session?.join_url} target="_blank">
                        <span className="join-session-widget-svg mr-2">
                            <LiveNowSvg />
                        </span>
                        <span className="color-brand font-extrabold mr-2">
                            {t("Join")} {sessions?.live_session?.session_type?.name}
                        </span>
                        <span className="color-black d-block d-lg-inline text-center mt-1 mt-lg-0">
                            {formatDateSessions(sessions?.live_session?.session_time)} {getTimezoneAbbreviation()}
                        </span>
                    </a>
                </div>
            )}
        </>
    );
};

export default JoinLiveSessionWidget;
