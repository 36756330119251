import { useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { http } from "common/http";
import { ServerStateKeys } from "../constants";
import { Survey, SurveyDataApi, SurveyStatus } from "../models";
import { Urls } from "./urls";

const getRespondentSurvey = async (accessCode: string): Promise<SurveyDataApi> => {
    const { data } = await http.get(Urls.surveyAssignment(accessCode));
    return data;
};

export function useRespondentSurvey(accessCode: string) {
    return useQuery<SurveyDataApi, AxiosError, Survey & { message?: SurveyStatus; isSelfAssessment?: boolean }>(
        [ServerStateKeys.RespondentSurvey],
        () => getRespondentSurvey(accessCode),
        {
            select: useCallback((data: SurveyDataApi & { message?: SurveyStatus; isSelfAssessment?: boolean }) => {
                const survey = new Survey(data);
                if (data.message) {
                    return { ...survey, message: data.message, isSelfAssessment: data.is_self_assessment };
                }
                return survey;
            }, [])
        }
    );
}
