import React, { memo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import classNames from "classnames";
import { ReactSVG } from "react-svg";
import { useTranslation } from "react-i18next";

import { ChapterPhase } from "main-app/models/phase";
import { Status } from "main-app/constants";
import LearnIcon from "common/chapter-svgs/LearnIcon";
import { useAuthContext } from "main-app/context/Auth";
import { withTranslation } from "common/utils/lang";

interface IProps {
    chapter: ChapterPhase;
    previousChapter: ChapterPhase;
    phaseIndex: number;
    index: number;
}

const PhaseChapter: React.FC<IProps> = ({ chapter, previousChapter, index, phaseIndex }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const params = useParams();
    const [hasLoadSvgError, setHasLoadSvgError] = useState(false);
    const isFirstAvailableChapter =
        (chapter.status === Status.InProgress || chapter.status === Status.Open) && index === 0;
    const { user } = useAuthContext();

    const isOpen =
        isFirstAvailableChapter ||
        (previousChapter?.status === Status.Completed &&
            (chapter.status === Status.Open || chapter.status === Status.InProgress));

    const isCompleted = chapter.status === Status.Completed;

    const showStatus = () => {
        if (isCompleted) {
            return t("Completed");
        }

        if (isOpen) {
            return t("Start");
        }

        return t("Locked");
    };

    const disableStatus = () => {
        if (isCompleted || isOpen) {
            return false;
        }

        return true;
    };

    const handleClickChapter = () => {
        if (disableStatus()) {
            return;
        }

        const module = user?.enrolledModules?.find(module => module.rank === +params?.moduleId);

        const moduleId = module?.id ?? user?.module?.id;

        const isFirstChapterAndOpen = index === 0 && chapter.status === Status.Open;

        navigate(
            `/chapter/learn${isFirstChapterAndOpen ? "/welcome" : ""}/${moduleId}/${phaseIndex}/${index}/${chapter.id}`
        );
    };

    return (
        <li className="items-step" key={chapter.id} onClick={handleClickChapter}>
            <span
                className={classNames("items-step-icon", {
                    active: isOpen
                })}
            >
                {chapter.icon && !hasLoadSvgError ? (
                    <ReactSVG
                        evalScripts="always"
                        httpRequestWithCredentials={true}
                        renumerateIRIElements={false}
                        src={chapter.icon}
                        fallback={() => <LearnIcon isActive={isOpen} />}
                        useRequestCache={true}
                    />
                ) : (
                    <LearnIcon isActive={isOpen} />
                )}
            </span>
            <span className="items-step__name">{withTranslation(chapter.name)}</span>

            <button className={classNames("items-step__start")} disabled={disableStatus()}>
                <span
                    className={classNames("items-step__btn-label", {
                        disabled: disableStatus(),
                        completed: isCompleted
                    })}
                >
                    {showStatus()}
                </span>
            </button>
        </li>
    );
};

export default memo(PhaseChapter);
