import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import Avatar from "main-app/shared/avatar/Avatar";
import { toBase64 } from "common/utils/functions";
import { WarningMessage } from "main-app/components/onboarding/components/WarningMessage";
import classNames from "classnames";

import "./style.scss";

type Props = {
    defaultPhoto?: string;
    disabled?: boolean;
    onChange: (photoBase64: string | ArrayBuffer) => void;
};

const ProfilePhotoHandler = ({ defaultPhoto = null, onChange, disabled = false }: Props) => {
    const { t } = useTranslation();
    const [profilePicture, setProfilePicture] = useState(defaultPhoto);
    const [error, setError] = useState<string | null>(null);
    const labelRef = useRef<HTMLLabelElement | null>(null);

    const handleProfilePhotoChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const imageFile = e.target.files[0];

        if (imageFile) {
            try {
                const base64 = await toBase64(imageFile);
                setProfilePicture(URL.createObjectURL(imageFile));
                onChange(base64);
            } catch {
                setError(t("An error occurred while uploading a photo, please try again later"));
            }
        }
    };

    const onInputFocus = () => {
        if (labelRef.current) {
            labelRef.current.classList.add("addOutline");
        }
    };
    const onInputBlur = () => {
        if (labelRef.current) {
            labelRef.current.classList.remove("addOutline");
        }
    };

    return (
        <label htmlFor="file" className={classNames({ "cursor-pointer": !disabled })} ref={labelRef}>
            <div className="form-group d-flex align-items-center addPhotoBlock">
                <Avatar width={80} height={80} url={profilePicture} />
                <div className="ml-4">
                    <input
                        type="file"
                        disabled={disabled}
                        accept="image/png, image/gif, image/jpeg"
                        id="file"
                        onChange={handleProfilePhotoChange}
                        onFocus={onInputFocus}
                        onBlur={onInputBlur}
                    />
                </div>
                <p className="brand-link font-extrabold font-sm"> + {t("Add Profile Image")}</p>
            </div>
            <WarningMessage message={error} />
        </label>
    );
};

export default ProfilePhotoHandler;
