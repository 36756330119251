import React from "react";
import { Controller } from "react-hook-form";
import { default as CheckBoxInput } from "./Checkbox";

interface IProps {
    control: any;
    name: string;
    label?: string;
    error?: any;
    errorText?: any;
    placeholder?: string;
}

const CheckBox: React.FC<IProps> = ({ control, name, label = "", error, errorText, placeholder }) => {
    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { onChange, value, name } }) => (
                <CheckBoxInput
                    checked={value}
                    onChange={onChange}
                    name={name}
                    label={label}
                    placeholder={placeholder}
                />
            )}
        />
    );
};

export default CheckBox;
