import React, { type FC, type ReactElement } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import Avatar from "main-app/shared/avatar/Avatar";
import { MAX_WIDTH_MOBILE_MEDIA } from "main-app/constants";
import useMediaQuery from "common/hooks/use-media-query";

import { GuestParticipantSessionTime as SessionTimeBlock } from ".";

type TGuestParticipantSessionBlockProps = {
    coachPhoto?: string;
    coachName?: string;
    coachLastName?: string;
    sessionTime?: string;
    isOriginal?: boolean;
};

const GuestParticipantSessionBlock: FC<TGuestParticipantSessionBlockProps> = ({
    coachPhoto,
    coachName,
    coachLastName,
    sessionTime,
    isOriginal = false
}: TGuestParticipantSessionBlockProps): ReactElement => {
    const { t } = useTranslation();

    const isMobile = useMediaQuery(MAX_WIDTH_MOBILE_MEDIA);

    const avatarSize: number = isMobile ? 40 : 50;

    return (
        <article className={classNames("swap-session__session-block", { original: isOriginal })}>
            {isOriginal && !isMobile && (
                <p className="color-black mb-2 swap-session__session-block__title">
                    {t("participantSide.swapSessionsScreen.originalSessionBlock.title")}
                </p>
            )}
            <div className="swap-session__session-block__body">
                {isOriginal && isMobile && (
                    <p className="color-black swap-session__session-block__title">
                        {t("participantSide.swapSessionsScreen.originalSessionBlock.title")}
                    </p>
                )}
                <Avatar url={coachPhoto} width={avatarSize} height={avatarSize} />

                {!isMobile && (
                    <p className="swap-session__session-block__name">
                        <span>{coachName}</span>
                        <span>{coachLastName}</span>
                    </p>
                )}

                <hr />

                {sessionTime && <SessionTimeBlock withTimezone withDays startTime={sessionTime} />}
            </div>
        </article>
    );
};

export { GuestParticipantSessionBlock as default, type TGuestParticipantSessionBlockProps };
