import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { GroupSessionParticipant } from "main-app/models/participants";
import { SessionType } from "main-app/constants";
import { CohortModalities } from "main-app/models/types";

import SessionAccordion from "./SessionAccordion";
import Button from "main-app/shared/button/Button";
interface IProps {
    type: "past" | "upcoming";
    cohort?: CohortModalities;
    sessions: any[];
    onClickRsvpNote?: (participant: GroupSessionParticipant) => void;
    onOpenAttendanceModal?: (sessionId: number) => void;
    onClickParticipant?: (id: number) => void;
}

const PAGE_SIZE = 3;

const PaginatedSessions: React.FC<IProps> = ({
    type,
    sessions,
    onOpenAttendanceModal,
    onClickParticipant,
    onClickRsvpNote,
    cohort = CohortModalities.Group
}) => {
    const { t } = useTranslation();
    const [numberOfItems, setNumberOfItems] = useState(PAGE_SIZE);

    const onShowMore = () => {
        if (numberOfItems + PAGE_SIZE < sessions.length) {
            setNumberOfItems(prev => prev + PAGE_SIZE);
        } else {
            setNumberOfItems(sessions.length);
        }
    };

    const items = sessions ? sessions?.slice(0, numberOfItems) : [];

    return (
        <>
            {items?.length > 0 ? (
                <>
                    {items?.map(session => (
                        <div className="mb-3" key={session.session_time}>
                            <SessionAccordion
                                isPast={type === SessionType.PAST}
                                isUpcoming={type === SessionType.UPCOMING}
                                onClickParticipant={onClickParticipant}
                                session={session}
                                onClickRsvpNote={onClickRsvpNote}
                                onOpenAttendanceModal={onOpenAttendanceModal}
                            />
                        </div>
                    ))}
                    {numberOfItems < sessions.length && (
                        <div className="text-center">
                            <Button variant="default" className="color-brand fs-14" onClick={onShowMore}>
                                {t("show more")}
                            </Button>
                        </div>
                    )}
                </>
            ) : (
                <p className="color-gray font-italic text-center">{t("No {{type}} sessions", { type })}</p>
            )}
        </>
    );
};

export default PaginatedSessions;
