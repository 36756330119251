import classNames from "classnames";
import * as React from "react";

function CurrentModuleCircleSvg(props: React.SVGProps<SVGSVGElement>) {
    const { width = 29, height = 29, className = "" } = props;

    return (
        <svg
            width={width}
            height={height}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={classNames("circle-svg", { [className]: className })}
            {...props}
        >
            <circle
                cx={14.5}
                cy={14.5}
                r={13.5}
                fill="#fff"
                stroke="#FD4D00"
                strokeWidth={2}
                strokeLinecap="round"
                strokeDasharray="3 6"
            />
        </svg>
    );
}

export default CurrentModuleCircleSvg;
