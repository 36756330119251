import React, { SVGProps } from "react";

import "./styles.scss";

interface Props extends SVGProps<SVGSVGElement> {}

const ChatSvg = (props: Props) => {
    const { width = 102, height = 43, className = "" } = props;

    return (
        <svg
            width={width}
            height={height}
            className={className}
            viewBox="0 0 102 43"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M1 5C1 2.51472 3.01472 0.5 5.5 0.5H96.5C98.9853 0.5 101 2.51472 101 5V13.5H1V5Z" fill="#F3F3F3" />
            <path
                d="M1 5C1 2.51472 3.01472 0.5 5.5 0.5H96.5C98.9853 0.5 101 2.51472 101 5V13.5H1V5Z"
                stroke="#E3E3E3"
            />
            <path d="M7.5 7H32.5" stroke="#B0B0B0" stroke-linecap="round" />
            <path d="M81.5 7H94.5" stroke="#0404CB" stroke-width="3" stroke-linecap="round" />
            <path
                d="M1 13.5H101V38C101 40.4853 98.9853 42.5 96.5 42.5H5.5C3.01472 42.5 1 40.4853 1 38V13.5Z"
                fill="white"
            />
            <path
                d="M1 13.5H101V38C101 40.4853 98.9853 42.5 96.5 42.5H5.5C3.01472 42.5 1 40.4853 1 38V13.5Z"
                stroke="#E3E3E3"
            />
            <rect x="89.5" y="32" width="6" height="6" rx="1" fill="#B0B0B0" />
        </svg>
    );
};

export default React.memo(ChatSvg);
