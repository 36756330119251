import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useAuthContext } from "main-app/context/Auth";
import ParticipantLayout from "main-app/layouts/ParticipantLayout";
import { TrackPracticeModalData } from "main-app/models/types";
import ParticipantWrapper from "main-app/elements/participant-wrapper";
import ParticipantSubHeader from "main-app/elements/participant-subheader";
import JoinLiveSessionWidget from "main-app/elements/live-session-join/JoinLiveSessionWidget";
import Phases from "main-app/elements/phases";
import Resources from "main-app/elements/resources";
import { ConfigKeys, SessionScheduleStatus, Status } from "main-app/constants";
import MyGroups from "main-app/elements/my-groups/MyGroups";

import CoachTimeScheduleModal from "main-app/elements/coach-time-schedule-modal/CoachTimeScheduleModal";
import useCoach from "main-app/api/use-coach";
import useIndividualSession from "main-app/api/mutations/use-individual-session-assign";
import { useStoreContext } from "main-app/context/GlobalStore";
import { isNullOrUndefined } from "common/utils/gates";

import TrackPracticeModalContainer from "./TrackPracticeModalContainer";
import { dashboardURL } from "../onboarding/constants";
import LeftSidebar from "./LeftSidebar";
import { SelfEnrollmentWidget } from "main-app/widgets/self-enrollment";

import "./styles.scss";

interface IProps {}

const Dashboard: React.FC<IProps> = () => {
    const params = useParams();
    const navigate = useNavigate();
    const { data: coach } = useCoach();
    const { user, getUserData } = useAuthContext();
    const { getConfig } = useStoreContext();
    const [trackPracticeModal, setShowTrackPracticeModal] = useState<TrackPracticeModalData>({
        show: false,
        component: null,
        phaseName: ""
    });
    const [showSessionScheduleModal, setShowSessionScheduleModal] = useState(false);

    const [shouldRedirect, setShouldRedirect] = useState<boolean>(false);
    const [navigationUrl, setNavigationUrl] = useState<string>("");

    useEffect(() => {
        getUserData();
        const showDashboard = getConfig(ConfigKeys.showDashboard);
        if (!showDashboard) {
            navigate("/");
        }
    }, []);

    const lastEnrolledModule = useMemo(
        () => user?.enrolledModules?.findLast(module => module.status !== Status.Unassigned),
        [user?.enrolledModules]
    );

    useEffect(() => {
        let isMounted = true;

        (() =>
            window.location.pathname === dashboardURL &&
            (user?.module
                ? setTimeout(
                      () =>
                          isMounted &&
                          (setShouldRedirect(true), setNavigationUrl(`${dashboardURL}/${user?.module?.rank}`))
                  )
                : !user?.module && lastEnrolledModule?.rank
                  ? setTimeout(
                        () =>
                            isMounted &&
                            (setShouldRedirect(true), setNavigationUrl(`${dashboardURL}/${lastEnrolledModule?.rank}`))
                    )
                  : void 0))();

        return (): void => {
            isMounted = false;
        };
    }, [user, params, lastEnrolledModule]);

    useEffect((): void => {
        shouldRedirect ? (setShouldRedirect(false), navigate(navigationUrl)) : void 0;
    }, [shouldRedirect, navigate, navigationUrl]);

    const onTrackPracticeClick = (modalData: TrackPracticeModalData) => {
        setShowTrackPracticeModal(modalData);
    };

    const onCloseTrackPracticeModal = () => {
        setShowTrackPracticeModal({ show: false, component: null, phaseName: "" });
    };

    const onSessionScheduleClick = () => {
        setShowSessionScheduleModal(true);
    };

    const onCloseSessionScheduleModal = (bookedStatus: SessionScheduleStatus) => {
        setShowSessionScheduleModal(false);

        if (bookedStatus === SessionScheduleStatus.SCHEDULED) {
            getUserData();
        }
    };

    const currentModule = user?.enrolledModules?.find(module => module.rank === +params?.moduleId);
    const makeScheduleSession = useIndividualSession(currentModule?.relatedSession?.session_id);

    const onSessionTimePick = notification => {
        const data = {
            session_datetime: notification.slot.start,
            coach: coach?.coachId,
            tz: notification.tzid
        };

        return makeScheduleSession.mutateAsync(data);
    };

    const isUnassignedModule = isNullOrUndefined(currentModule) || currentModule?.status === Status.Unassigned;

    return (
        <>
            <ParticipantLayout leftSidebar={<LeftSidebar />}>
                <ParticipantWrapper size="md">
                    <div className="d-flex justify-content-center">
                        <JoinLiveSessionWidget />
                    </div>

                    <ParticipantSubHeader user={user} />
                    {isUnassignedModule ? (
                        <SelfEnrollmentWidget />
                    ) : (
                        <>
                            <Phases
                                onTrackPracticeClick={onTrackPracticeClick}
                                relatedSession={currentModule?.relatedSession}
                                onSessionScheduleClick={onSessionScheduleClick}
                            />
                            <Resources />
                            <MyGroups />
                        </>
                    )}
                </ParticipantWrapper>
            </ParticipantLayout>

            <TrackPracticeModalContainer
                trackPracticeModalData={trackPracticeModal}
                onClose={onCloseTrackPracticeModal}
            />

            {showSessionScheduleModal && (
                <CoachTimeScheduleModal
                    show={showSessionScheduleModal}
                    coach={coach}
                    timePickRequest={onSessionTimePick}
                    sessionTime={currentModule?.relatedSession?.session_time}
                    session={currentModule?.relatedSession?.session_type}
                    title={currentModule?.relatedSession?.session_type?.name}
                    onClose={onCloseSessionScheduleModal}
                    showManagerInfoBlock={currentModule?.relatedSession?.is_three_way_session}
                    scheduledSession={currentModule?.relatedSession}
                    sessionId={currentModule?.relatedSession?.session_id}
                />
            )}
        </>
    );
};

export default Dashboard;
