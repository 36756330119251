import React, { useMemo } from "react";
import classNames from "classnames";

import { Phase } from "main-app/models/phase";
import { UserEnrollModule } from "main-app/models/user";
import { Status } from "main-app/constants";
import MobileResponseItem from "./MobileResponseItem";
import { withTranslation } from "common/utils/lang";
import Select from "main-app/shared/select-default/Select";

interface IProps {
    enrolledModules: UserEnrollModule[];
    phasesList: Phase[];
    selectedPhase: any;
    onSelectModule: (option) => void;
    onSelectPhase: (phase: Phase) => void;
}

const MobileModuleList: React.FC<IProps> = ({
    enrolledModules,
    onSelectModule,
    onSelectPhase,
    phasesList,
    selectedPhase
}) => {
    const optionsList = useMemo(() => {
        if (enrolledModules?.length) {
            return enrolledModules.map(module => ({
                id: module.id,
                label: withTranslation(module.name) || module.versionName,
                value: module.id
            }));
        }
    }, [enrolledModules]);

    const allComponents = useMemo(() => {
        return selectedPhase?.chapters?.flatMap(chapter => chapter.components);
    }, [selectedPhase]);

    return (
        <>
            <div className="responses-container-mobile">
                <div className="responses-select-section">
                    <Select optionsList={optionsList} onSelect={onSelectModule} className="mobile-modules-select" />
                    <div className="responses-select-phases">
                        {phasesList?.map((phase, index) => (
                            <div
                                className={classNames("responses-select-phases-item", {
                                    active: phase.id === selectedPhase?.id
                                })}
                                onClick={() => onSelectPhase(phase)}
                                key={phase.id}
                            >
                                Phase {index + 1}
                            </div>
                        ))}
                    </div>
                    {selectedPhase && (
                        <div className="mt-3">
                            <h5 className="font-italic">{withTranslation(selectedPhase.name)}</h5>
                        </div>
                    )}
                </div>
                {selectedPhase && selectedPhase?.status === Status.Completed ? (
                    allComponents?.map(component => <MobileResponseItem component={component} />)
                ) : selectedPhase ? (
                    <h5 className="text-center not-started">Not Yet Started</h5>
                ) : null}
            </div>
        </>
    );
};

export default MobileModuleList;
