export const BehavioralPracticeStatus = {
    Queued: "queued",
    Active: "active",
    Done: "done"
} as const;

export const QueryKey = {
    BehavioralChange: "behavioralChange",
    BehavioralPractice: "behavioralPractice"
} as const;
