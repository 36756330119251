import { useMemo } from "react";
import { useAuthContext } from "main-app/context/Auth";
import { isCoach, isParticipant, isProgramManager } from "../gates/user";
import { userRoles } from "main-app/constants";

function useRoles() {
    const { user } = useAuthContext();

    return useMemo(
        () => ({
            isCoach: isCoach(user),
            isParticipant: isParticipant(user),
            isProgramManager: isProgramManager(user),
            isCoaches: userRoles.coach.includes(user?.role) // TODO find better name for this
        }),
        [user]
    );
}

export default useRoles;
