import { useCallback } from "react";
import { AxiosError } from "axios";
import { useMutation, useQuery } from "@tanstack/react-query";

import { http } from "common/http";
import Urls from "main-app/api/urls";
import { BehavioralChange, BehavioralChangeApi } from "../model/behavioral-goal";
import { GoalsSortType } from "main-app/shared/types/sort";
import { QueryKey } from "../consts";

const getBehavioralChange = async ({ queryKey }): Promise<BehavioralChangeApi[]> => {
    const [_key, { id, query }] = queryKey;
    const { data } = await http.get(Urls.behavioralChange(id, query));
    return data;
};

export function useBehavioralChangeData(participantId: number, query: GoalsSortType) {
    return useQuery<BehavioralChangeApi[], AxiosError, BehavioralChange[]>(
        [QueryKey.BehavioralChange, { query, id: participantId }],
        getBehavioralChange,
        {
            select: useCallback(data => data.map(item => new BehavioralChange(item)), [])
        }
    );
}

export function useArchivedBehavioralChangeData(participantId: number) {
    return useQuery<BehavioralChangeApi[], AxiosError, number[]>(
        [QueryKey.BehavioralChange, { query: "archived", id: participantId }],
        getBehavioralChange,
        {
            select: useCallback(data => data.map(item => item.id), [])
        }
    );
}

export function useCreateBehavioralChange(participantId: number) {
    return useMutation(
        (
            behavioralChange: Pick<
                BehavioralChangeApi,
                "description" | "order" | "additional_notes" | "projected_impact" | "title"
            >
        ) => http.post<{ id: number }>(Urls.createBehavioralChange(participantId), behavioralChange)
    );
}

export function useEditBehavioralChange(participantId: number) {
    return useMutation(
        (
            behavioralChange: Pick<
                BehavioralChangeApi,
                "description" | "order" | "additional_notes" | "projected_impact" | "id" | "title"
            >
        ) => http.put(Urls.editBehavioralChange(participantId, behavioralChange.id), behavioralChange)
    );
}

export function useArchiveBehavioralChange(participantId: number) {
    return useMutation((behavioralChangeId: number) =>
        http.post(Urls.archiveBehavioralChange(participantId, behavioralChangeId))
    );
}

export function useUnarchiveBehavioralChange(participantId: number) {
    return useMutation((behavioralChangeId: number) =>
        http.post(Urls.unarchiveBehavioralChange(participantId, behavioralChangeId))
    );
}

export function useSortBehavioralChange(participantId: number) {
    return useMutation((sort: { ids: number[] }) => http.post(Urls.sortBehavioralChange(participantId), sort));
}
