import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { http } from "common/http";

import Urls from "main-app/api/urls";
import { ServerStateOnbordingKeys } from "../constants";

const getCoachTimeWidget = async (coachId: number, sessionId?: number | "") => {
    const { data } = await http.get(Urls.coachCalendarSetting(coachId, sessionId));
    return data;
};

export default function useCoachTimeWidget(coachId: number, session?: number | "") {
    return useQuery<any, AxiosError, any>(
        [ServerStateOnbordingKeys.CoachTimeWidget, { id: coachId, session }],
        () => getCoachTimeWidget(coachId, session),
        {
            enabled: !!coachId
        }
    );
}
