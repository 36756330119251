import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import Portal from "main-app/shared/portal/Portal";
import Modal from "main-app/shared/modal";
import { getErrorMessages } from "common/utils/get-error-messages";
import useGetAssignedModules from "../../api/use-get-assigned-modules";
import { formatDateSessions, getTimezoneAbbreviation } from "main-app/utils/date";
import Spinner from "main-app/shared/spinner/Spinner";
import ErrorBox from "main-app/shared/error-box/ErrorBox";
import useAssignModule from "main-app/api/mutations/use-assign-module";
import { PRODUCT_TYPE_WITH_ENROLLMENTS, ServerStateKeys } from "main-app/constants";
import { GroupSessionParticipant } from "main-app/models/participants";
import { AttendanceStatus, AttendanceStatuses, CohortModalities } from "main-app/models/types";
import useRoles from "main-app/utils/hooks/use-roles";
import Button from "main-app/shared/button/Button";
import { ThreeWayManagerSession } from "main-app/entities/three-way/model/three-way";
import AttendeeExtraPartyRow from "./AttendeeExtraPartyRow";
import { ThreeWayAvatar } from "main-app/entities/three-way";
import GroupIcon from "main-app/svgs/GroupIcon";
import Avatar from "main-app/shared/avatar/Avatar";

import AttendeeRow from "./AttendeeRow";

import "./styles.scss";
import { isEmptyObject } from "../../utils/common";

type Error = {
    [participantId: number]: boolean;
};

interface IProps {
    show: boolean;
    sessionId: number;
    onClose: () => void;
}

const AttendanceModal: React.FC<IProps> = ({ show, sessionId, onClose }) => {
    const { t } = useTranslation();
    const params = useParams();
    const queryClient = useQueryClient();
    const { data, isLoading, refetch: refetchAssignedModules } = useGetAssignedModules(sessionId);
    const assignModulesOnSubmit = useAssignModule(sessionId);
    const { isCoach, isProgramManager } = useRoles();
    const [assignedModules, setAssignedModules] = useState([]);
    const [attendanceList, setAttendance] = useState([]);
    const [errors, setErrors] = useState<Error>({});
    const [notMarkedAllAttendace, setNotMarkedAllAttendace] = useState(false);
    const [submitModules, setSubmitModules] = useState(false);
    const [submitErrors, setSubmitErrors] = useState(null);
    const [isBusyBtn, setIsBusyBtn] = useState(false);

    const canApplyModules = Boolean(data?.cohort?.modules?.length);

    const isEditAttendance = useMemo(() => {
        if (data?.isThreeWaySession) {
            return [...data?.participants, ...data?.extraPartiesInfo].some(
                participant => participant.attendanceStatus !== null
            );
        }

        return data?.participants.some(participant => participant.attendanceStatus !== null);
    }, [data]);

    const enrollmentRank = useMemo(() => {
        return data ? data.enrollmentRank : 0;
    }, [data]);

    useEffect(() => {
        refetchAssignedModules();
    }, []);

    useEffect(() => {
        if (isEditAttendance) {
            const modules = data?.participants
                .filter(participant => {
                    return (
                        canAssignModule(participant) && participant.attendanceStatus !== AttendanceStatuses.NOT_PRESENT
                    );
                })
                .map(participant => ({
                    module: null,
                    participant: participant.id,
                    cohort: data.cohort.id
                }));

            setAssignedModules(modules);
        }
    }, [isEditAttendance, data, setAssignedModules]);

    const onModuleSelect = newModule => {
        const hasAssignedModule = assignedModules.find(module => module.participant === newModule.participant);

        setAssignedModules(modules =>
            hasAssignedModule
                ? modules.map(module => (module.participant === newModule.participant ? newModule : module))
                : [...modules, newModule]
        );

        deleteErrors(newModule.participant);
    };

    const canAssignModule = (
        participant: (GroupSessionParticipant & { status: AttendanceStatus }) | GroupSessionParticipant
    ) => {
        const nextRankIndex =
            participant.enrolledModules.length === 1 && enrollmentRank === 0 ? 0 : data.enrollmentRank;
        const enrolledModule = participant.enrolledModules?.[nextRankIndex];
        const endOfJourneyReached =
            participant.enrolledModules.length === participant.journeyLength ||
            enrollmentRank > participant.journeyLength;

        return !enrolledModule && !endOfJourneyReached;
    };

    const deleteErrors = (participantId: number) => {
        if (errors?.[participantId]) {
            const { [participantId]: removed, ...submitErrors } = errors;
            setErrors(submitErrors);
        }
    };

    const addModules = (participant: GroupSessionParticipant & { status: AttendanceStatus }) => {
        const canAssign = canAssignModule(participant);

        if (!canAssign) {
            return;
        }

        if (participant.status === AttendanceStatuses.PRESENT) {
            const newModule = {
                module: null,
                participant: participant.id,
                cohort: data.cohort.id
            };
            setAssignedModules(modules => [...modules, newModule]);
        } else {
            setAssignedModules(modules => modules.filter(module => module.participant !== participant.id));
        }
    };

    const onMarkAttendance = (participant: GroupSessionParticipant & { status: AttendanceStatus }) => {
        const hasParticipant = attendanceList.find(item => item.participant === participant.id);

        const attendee = {
            participant: participant.id,
            attendance_status: participant.status
        };

        setAttendance(prev =>
            hasParticipant
                ? prev.map(item => (item.participant === participant.id ? attendee : item))
                : [...prev, attendee]
        );

        if (canApplyModules) {
            addModules(participant);
        }
    };

    const onMarkAttendanceExtraParty = (party: ThreeWayManagerSession) => {
        const hasParticipant = attendanceList.find(item => item.extra_party_email === party.email);

        const attendee = {
            extra_party_email: party.email,
            attendance_status: party.attendanceStatus
        };

        setAttendance(prev =>
            hasParticipant
                ? prev.map(item => (item.extra_party_email === party.email ? attendee : item))
                : [...prev, attendee]
        );
    };

    const checkAttendance = () => {
        if (isEditAttendance) {
            return false;
        }

        if (data?.isThreeWaySession) {
            return attendanceList?.length !== data?.participants.length + data?.extraPartiesInfo?.length;
        }

        return attendanceList.length !== data.participants.length;
    };

    const validateSubmit = () => {
        const notMarkedAttendance = checkAttendance();

        setNotMarkedAllAttendace(notMarkedAttendance);

        if (isProgramManager || !canApplyModules) {
            return {
                hasErrors: false,
                notMarkedAttendance
            };
        }

        const errors = assignedModules.reduce((err, { module, participant }) => {
            if (module === null) {
                err[participant] = true;
            }
            return err;
        }, {}) as Error;

        setErrors(errors);

        const hasErrors = Object.keys(errors).length > 0;

        return {
            hasErrors,
            notMarkedAttendance
        };
    };

    const onSubmit = () => {
        const { hasErrors, notMarkedAttendance } = validateSubmit();

        if (notMarkedAttendance) {
            return;
        }

        if (hasErrors && !submitModules) {
            setSubmitModules(true);
            return;
        }

        const submitData = assignedModules.filter(({ module }) => module !== null);
        setIsBusyBtn(true);
        assignModulesOnSubmit.mutate(
            { assign_modules: submitData, mark_attendance: attendanceList },
            {
                onSuccess: () => {
                    queryClient.invalidateQueries([ServerStateKeys.AssignedModules, sessionId]);
                    queryClient.invalidateQueries([ServerStateKeys.GroupInfo, params.id]);

                    onClose();
                },
                onError: err => {
                    setSubmitErrors(getErrorMessages(err));
                },
                onSettled: () => {
                    setIsBusyBtn(false);
                }
            }
        );
    };

    const hasErrors = useMemo(() => Object.keys(errors).length > 0, [errors]);

    const avatar = useMemo(() => {
        const isIndividual = data?.cohortModality === CohortModalities.Individual;

        if (isIndividual) {
            const isThreeWay = data?.isThreeWaySession;
            const participant = data?.participants?.find(participant => !!participant);

            return isThreeWay ? (
                <ThreeWayAvatar
                    url={participant?.photo}
                    alt={participant?.firstName}
                    width={40}
                    height={40}
                    className="mr-3"
                />
            ) : (
                <Avatar url={participant?.photo} alt={participant?.firstName} width={40} height={40} className="mr-3" />
            );
        }

        return <GroupIcon width={50} heigth={50} className="mr-3" />;
    }, [data]);

    return (
        <Portal>
            <Modal
                show={show}
                onClose={onClose}
                modalBodyClass={classNames("attendance-modal", {
                    "show-overflow": data?.participants?.length > 6 || window?.innerHeight < 800
                })}
                disableBodyScroll
                headerContent={
                    <div className="attendance-modal__header">
                        {data ? (
                            <div className="d-flex align-items-center">
                                {avatar}
                                <p className="mb-0 font-extrabold mr-4">{data?.cohort?.name}</p>
                                <p className="mb-0">
                                    {data?.sessionType?.name}: {formatDateSessions(data?.sessionTime)}{" "}
                                    {getTimezoneAbbreviation()}
                                </p>
                            </div>
                        ) : null}
                    </div>
                }
            >
                <div className="attendance-modal__body">
                    <div className="attedance-modal__table w-100">
                        {isLoading && !data ? (
                            <div
                                className="w-100 h-100 d-flex align-items-center justify-content-center mb-4"
                                data-testid="loader"
                            >
                                <Spinner />
                            </div>
                        ) : (
                            <>
                                <div className="attendance-modal__table-header row">
                                    <div className="col-4"></div>
                                    <div className="col-4 text-center">
                                        <span className="fs-14">{t("Attendance")}:</span>
                                    </div>
                                    <div className="col-4 text-center">
                                        {(isCoach || isProgramManager) &&
                                            canApplyModules &&
                                            data?.enrollmentRank !== -1 && (
                                                <span className="fs-14">{t("Next Module")}:</span>
                                            )}
                                    </div>
                                </div>
                                {data?.participants?.map(participant => (
                                    <AttendeeRow
                                        participant={participant}
                                        onModuleSelect={onModuleSelect}
                                        onMarkAttendance={onMarkAttendance}
                                        cohort={data.cohort}
                                        enrollmentRank={enrollmentRank}
                                        error={errors?.[participant.id]}
                                        canApplyModules={canApplyModules}
                                        key={participant.id}
                                    />
                                ))}
                                {data?.extraPartiesInfo?.map(participant => (
                                    <AttendeeExtraPartyRow
                                        participant={participant}
                                        onMarkAttendanceExtraParty={onMarkAttendanceExtraParty}
                                    />
                                ))}
                            </>
                        )}
                    </div>
                    {(hasErrors || notMarkedAllAttendace) && (
                        <div className="mt-50">
                            <ErrorBox
                                className={classNames("mx-auto font-sm", {
                                    "error-box-text": hasErrors && !notMarkedAllAttendace
                                })}
                                type={notMarkedAllAttendace ? "sm" : "default"}
                            >
                                {hasErrors && !notMarkedAllAttendace ? (
                                    <>
                                        <span className="font-bold">
                                            Are you sure you want to submit without assigning the remaining modules?
                                        </span>
                                        <span className="d-block">
                                            If you choose to proceed, the modules will be assigned manually by our
                                            admin.
                                        </span>
                                    </>
                                ) : (
                                    <span>You must finish marking attendance before submitting.</span>
                                )}
                            </ErrorBox>
                        </div>
                    )}
                    {submitErrors ? (
                        <div className="mt-50">
                            <ErrorBox className="mx-auto font-sm error-box-text">{submitErrors}</ErrorBox>
                        </div>
                    ) : null}
                    <div className="text-center mt-30">
                        <Button isBusy={isLoading || isBusyBtn} onClick={onSubmit}>
                            Submit
                        </Button>
                    </div>
                </div>
            </Modal>
        </Portal>
    );
};

export default AttendanceModal;
