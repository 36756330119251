import { useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { http } from "common/http";
import { ServerStateKeys } from "main-app/constants";
import { ParticipantData, ParticipantDataApi } from "main-app/models/participant-data-coach";
import Urls from "./urls";

const getParticipantData = async (id: string): Promise<ParticipantDataApi> => {
    const { data } = await http.get(Urls.participantDataByCoach(id));
    return data;
};

export default function useParticipantDataByCoach(id: string) {
    return useQuery<any, AxiosError, any>([ServerStateKeys.ParticipantData, id], () => getParticipantData(id), {
        select: useCallback(data => {
            return new ParticipantData(data);
        }, [])
    });
}
