import React from "react";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";

import useMediaQuery from "common/hooks/use-media-query";
import { useAuthContext } from "main-app/context/Auth";
import { SimulabCoacheeList } from "main-app/features/simulab-coachees";
import ParticipantLayout from "main-app/layouts/ParticipantLayout";
import { SimulabHeader } from "main-app/entities/simulab";
import LeftSidebar from "main-app/components/dashboard/LeftSidebar";
import CareTeamLink from "main-app/shared/CareTeamLink";
import { MAX_WIDTH_MOBILE_MEDIA } from "main-app/constants";

import "./styles.scss";

type Props = {};

export const SimulabCoachees = (props: Props) => {
    const { t } = useTranslation();
    const isMobile = useMediaQuery(MAX_WIDTH_MOBILE_MEDIA);
    const { user } = useAuthContext();

    if (!user?.practiceLab) {
        return <Navigate to="/404" replace />;
    }

    return (
        <ParticipantLayout
            className="practice-lab-layout"
            noPadding
            showLogoOnMobile={false}
            leftSidebar={<LeftSidebar />}
            title={!isMobile && <SimulabHeader />}
            header={isMobile && <SimulabHeader />}
        >
            <section className="practice-lab-coachee">
                <p className="practice-lab-coachee__text">
                    {t("Who would you like to practice Spot Coaching with today?")}
                </p>
                <main className="practice-lab-coachee__wrapper">
                    <SimulabCoacheeList />
                </main>
            </section>
            <div className="practice-lab-layout__help">
                <CareTeamLink
                    text={t("If you experience any issues with our new feature:")}
                    variant="brand"
                    className="fs-14 m-0"
                />
            </div>
        </ParticipantLayout>
    );
};
