import React from "react";
import { SimulabStage, useSimulabContext } from "main-app/entities/simulab";
import { StageName } from "./StageName";

import "./../styles.scss";

export const SimulabFirstMesageStage = () => {
    const { simulabMessages, conversation } = useSimulabContext();

    const firstStage: SimulabStage = conversation?.stages?.[0];

    return (
        simulabMessages?.messages?.length === 0 && (
            <div className="mb-30">
                <StageName
                    disabled
                    phase={firstStage?.phase}
                    name={firstStage?.title}
                    isCurrentStage={true}
                    isFirstStage={true}
                    completed={false}
                />
            </div>
        )
    );
};
