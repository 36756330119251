import { useEffect, useRef } from "react";
import { DateTime } from "luxon";
import {
    DEFAULT_SESSION_DURATION,
    SESSION_AVAILABLE_TIME,
    SESSION_CHECK_TIME,
    SESSION_REFETCH_DELAY_TIME
} from "main-app/constants";

type Options = {
    date: string | null;
    duration?: number;
    withStartWindow?: boolean;
};

function useLiveSessionCheck(
    { date, withStartWindow = true, duration = DEFAULT_SESSION_DURATION }: Options,
    cb: () => void
) {
    const fetchedSession = useRef(false);

    const checkDate = () => {
        const parsedDate = DateTime.fromISO(date);
        const currentDate = DateTime.now();
        const isAvailableTime = withStartWindow
            ? currentDate >= parsedDate.minus({ minutes: SESSION_AVAILABLE_TIME })
            : currentDate.toMillis() >=
              parsedDate.plus({ minutes: duration, seconds: SESSION_REFETCH_DELAY_TIME }).toMillis();
        if (isAvailableTime && !fetchedSession.current) {
            cb();
            fetchedSession.current = true;
        }
    };

    useEffect(() => {
        let intervalId;

        if (date) {
            checkDate();
            intervalId = setInterval(() => {
                if (fetchedSession.current) {
                    clearInterval(intervalId);
                } else {
                    checkDate();
                }
            }, SESSION_CHECK_TIME);
        }

        return () => {
            clearInterval(intervalId);
            fetchedSession.current = false;
        };
    }, [date]);
}

export default useLiveSessionCheck;
