import React from "react";
import classNames from "classnames";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

import Avatar from "main-app/shared/avatar/Avatar";
import { SCHEDULE_STATUS_TEXT } from "main-app/constants";
import Button from "main-app/shared/button/Button";
import { ThreeWayAvatar } from "main-app/entities/three-way";

type Props = {
    className?: string;
    session: any;
    onScheduleClick: (session) => void;
};

const ScheduleItem = ({ session, className = "", onScheduleClick }: Props) => {
    const { t } = useTranslation();
    const isExpired = DateTime.now().toMillis() > DateTime.fromSQL(session.scheduling_window_end).toMillis();

    const isThreeWay = session?.is_three_way_session;

    return (
        <div className={classNames("schedule-item", className)}>
            <div className="schedule-item__group-image">
                {isThreeWay ? (
                    <ThreeWayAvatar url={session.coach.photo} alt="coach photo" width={35} height={35} />
                ) : (
                    <Avatar url={session.coach.photo} width={35} height={35} alt="coach photo" />
                )}
            </div>
            <div className="schedule-item__group-info">
                <span className="d-block fs-14">{session.session_type.name}</span>
                <span className="color-gray fs-14">
                    {session?.session_type?.duration} {t("min")}
                    {isThreeWay && ", "}
                </span>
                <span className="color-gray fs-14 d-block d-md-inline">{isThreeWay ? `${t("three-way")}` : ""}</span>
            </div>
            <div className="schedule-item__group-time">
                <span className="color-gray">
                    {isExpired ? t("Expired") : SCHEDULE_STATUS_TEXT[session.event_status]}
                </span>
            </div>
            <div className="d-flex align-items-center">
                <div className="schedule-item__group-action">
                    <Button onClick={() => onScheduleClick(session)} className="schedule-item__group-action-btn">
                        {t("Schedule")}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ScheduleItem;
