import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import classNames from "classnames";
import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

import useMediaQuery from "common/hooks/use-media-query";
import { isNullOrUndefined } from "common/utils/gates";
import { UserEnrollModule } from "main-app/models/user";
import { useAuthContext } from "main-app/context/Auth";
import { MAX_WIDTH_MOBILE_MEDIA, ServerStateKeys } from "main-app/constants";
import { dashboardURL } from "main-app/components/onboarding/constants";
import { useStoreContext } from "main-app/context/GlobalStore";
import ModuleList from "./ModuleList";

import "./styles.scss";

type Props = {
    className?: string;
};

export const ModuleNavigation = ({ className = "" }: Props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const params = useParams();
    const { pathname } = useLocation();
    const queryClient = useQueryClient();
    const { user } = useAuthContext();
    const { toggleBurgerMenu, isOpenBurgerMenu } = useStoreContext();
    const isMobileView = useMediaQuery(MAX_WIDTH_MOBILE_MEDIA);
    const [collapseModules, setCollapseModules] = useState(false);

    useEffect(() => {
        if (isNullOrUndefined(params.moduleId)) {
            return;
        }

        const module = user?.enrolledModules.find(module => module.rank === +params.moduleId);

        if (isNullOrUndefined(module)) {
            setCollapseModules(true);
        }
    }, [user, params]);

    const onModuleClick = useCallback(
        (module: UserEnrollModule, moduleIndex: number) => {
            if (isOpenBurgerMenu && isMobileView) {
                toggleBurgerMenu();
            }

            if (!module) {
                navigate(dashboardURL + `/${moduleIndex}`);
                return;
            }
            queryClient.refetchQueries([ServerStateKeys.Phases, { id: module.id }]);
            navigate(dashboardURL + `/${module.rank}`, { replace: true });
        },
        [user, isOpenBurgerMenu, isMobileView]
    );

    const onCollapseUnassignModulesClick = useCallback(() => {
        if (isOpenBurgerMenu && isMobileView && collapseModules) {
            toggleBurgerMenu();
        }
        setCollapseModules(true);
    }, [isOpenBurgerMenu, isMobileView, collapseModules]);

    return (
        <section className={className}>
            <p
                className={classNames(`module-navigation_title`, {
                    "module-active": isMobileView,
                    "font-extrabold": pathname.includes("/dashboard")
                })}
            >
                {t("Modules")}
            </p>
            <ModuleList
                collapseModules={collapseModules}
                user={user}
                onModuleClick={onModuleClick}
                onCollapseUnassignModulesClick={onCollapseUnassignModulesClick}
            />
        </section>
    );
};
