import React from "react";

import Avatar from "main-app/shared/avatar/Avatar";
import { ParticipantData } from "main-app/models/participant-data-coach";

interface IProps {
    profile: ParticipantData;
    onClick: () => void;
}

const ProfileCard: React.FC<IProps> = ({ profile, onClick }) => {
    return (
        <div className="profile-card" onClick={onClick}>
            <Avatar url={profile?.photo} />

            <div className="profile-card__left">
                <p className="m-0 mb-1 font-bold">{profile?.firstName}</p>
                <span>view profile</span>
            </div>
        </div>
    );
};

export default ProfileCard;
