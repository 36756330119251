import React from "react";
import classNames from "classnames";

import "./styles.scss";

type Props = {
    provider: any;
    styles?: any;
    snapshot?: any;
    withStyles?: boolean;
    className?: string;
};

const DragElement = ({ provider, snapshot, className = "", withStyles = true }: Props) => {
    const getItemStyle = isDragging => ({
        backgroundColor: isDragging ? "#e3e3e3" : "#f3f3f3"
    });

    return (
        <div
            className={classNames("drag-element cursor-pointer", className, { "no-styles": !withStyles })}
            {...provider.dragHandleProps}
            style={getItemStyle(snapshot.isDragging)}
        >
            <svg width="15" height="20" viewBox="0 0 15 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="4.64082" cy="5.3083" r="1.071" fill="#6F6F6F" />
                <circle cx="4.64082" cy="10.0007" r="1.071" fill="#6F6F6F" />
                <circle cx="4.64082" cy="14.6921" r="1.071" fill="#6F6F6F" />
                <circle cx="9.63887" cy="5.3083" r="1.071" fill="#6F6F6F" />
                <circle cx="9.63887" cy="10.0007" r="1.071" fill="#6F6F6F" />
                <circle cx="9.63887" cy="14.6921" r="1.071" fill="#6F6F6F" />
            </svg>
        </div>
    );
};

export default DragElement;
