import * as React from "react";
import CurrentModuleCircleSvg from "./CurrentModuleCircleSvg";

function CompletedCurrentModuleSvg(props: React.SVGProps<SVGSVGElement> & { isCurrent?: boolean }) {
    const { width = 19, height = 19, isCurrent = false } = props;

    return (
        <span className="module-svg-wrapper">
            <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
                <circle cx={9.5} cy={9.5} r={9.5} fill="#FD4D00" />
                <path
                    d="M6.196 9.706l2.272 2.479 5.163-5.164"
                    stroke="#fff"
                    strokeWidth={1.781}
                    strokeLinecap="square"
                />
            </svg>
            {isCurrent && <CurrentModuleCircleSvg />}
        </span>
    );
}

export default React.memo(CompletedCurrentModuleSvg);
