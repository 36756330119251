import React from "react";
import { Link } from "react-router-dom";

import { Participant } from "main-app/models/participants";
import Avatar from "main-app/shared/avatar/Avatar";
import { formatDateSessions, getTimezoneAbbreviation } from "main-app/utils/date";
import { withTranslation } from "common/utils/lang";

interface IProps {
    participant: Participant;
}

const TableRow: React.FC<IProps> = ({ participant }) => {
    const nextSession = participant.upcomingSessions?.find(session => session.sessionTime);

    return (
        <tr data-testid="participant-table-row">
            <td className="text-left pl-0">
                <Link className="d-flex align-items-center color-inherit" to={`/session-participant/${participant.id}`}>
                    <Avatar url={participant.photo} className="mr-4" />
                    <span>
                        {participant.firstName} {participant.lastName}
                    </span>
                </Link>
            </td>
            <td className="d-none d-lg-table-cell">{participant.cohort?.name ?? ""}</td>
            <td className="d-none d-lg-table-cell">
                {!!nextSession ? (
                    <>
                        {formatDateSessions(nextSession?.sessionTime)}{" "}
                        <span className="d-block mt-2 color-gray">{getTimezoneAbbreviation()}</span>
                    </>
                ) : (
                    "Not yet scheduled"
                )}
            </td>
            <td>
                <span className="d-flex align-items-center justify-content-lg-center">
                    {withTranslation(participant.currentModule?.versionName)}
                </span>
            </td>
            {/* <td className="d-none d-lg-table-cell align-middle">
                <ModuleStatus status={participant.currentModule?.status} />
            </td> */}
        </tr>
    );
};

export default TableRow;
