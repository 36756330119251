import React from "react";
import { useCalendarsAttrs } from "main-app/entities/coach-calendar/api/use-calendars-attrs";
import { FullSpinner } from "main-app/shared/spinner";
import SimpleLayout from "main-app/layouts/SimpleLayout";
import DateWidget from "main-app/shared/DateWidget";
import CronofyWidget from "common/components/CronofyWidgets/CronofyWidget";
import { CRONOFY_WIDGET } from "common/constants";
import { WarningMessage } from "main-app/components/onboarding/components/WarningMessage";
import { getErrorMessages } from "common/utils/get-error-messages";

const CalendarAdd = () => {
    const { data, isLoading, isError, error } = useCalendarsAttrs();

    return (
        <SimpleLayout header={<DateWidget className="coach-date-title" />} headerVariant="gray" showProfilePhoto>
            <div className="coach-calendar-wrapper mx-auto">
                {isError && <WarningMessage message={getErrorMessages(error?.response?.data)?.join("")} />}
                {isLoading ? (
                    <FullSpinner />
                ) : (
                    <CronofyWidget
                        id="calendar-sync"
                        widget={CRONOFY_WIDGET.CalendarSync}
                        options={{
                            authorization: data?.authorization,
                            data_center: data?.data_center,
                            locale: data?.locale,
                            target_id: "cronofy-calendar-sync",
                            styles: {
                                prefix: "CalendarSync"
                            }
                        }}
                    />
                )}
            </div>
        </SimpleLayout>
    );
};

export default CalendarAdd;
