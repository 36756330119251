import { useQuery } from "@tanstack/react-query";
import { http } from "common/http";
import { ServerStateKeys } from "main-app/constants";
import Chapter, { IChapterApi } from "main-app/models/chapter";
import Urls from "./urls";

export const getChapter = async (id: number): Promise<IChapterApi> => {
    const { data } = await http.get(Urls.chapter(id));
    return data;
};

const transformChapterData = data => new Chapter(data);

export default function useChapters(id: number) {
    return useQuery([ServerStateKeys.ChapterLearn, id], () => getChapter(id), {
        select: transformChapterData,
        refetchOnMount: "always",
        cacheTime: 0,
        enabled: !!id
    });
}
