import { StepUrls, dashboardURL } from "main-app/components/onboarding/constants";
import { CoachUrls, ParticipantUrls, userRoles } from "main-app/constants";
import User from "main-app/models/user";

export const getIndexPage = (user: User, showDashboard) => {
    if (userRoles.coach.includes(user.role)) {
        return CoachUrls.SESSIONS_LIST;
    }

    if (!user.onboardingFinished) {
        return StepUrls.ONBOARDING_REDIRECT_PAGE;
    }

    if (showDashboard) {
        return dashboardURL;
    }

    return ParticipantUrls.SESSION_LIST;
};
