import * as Sentry from "@sentry/browser";
import User from "main-app/models/user";

export const setUserSentry = (user: User) => {
    const userData = {
        id: `${user.id}`,
        name: `${user.firstName} ${user.lastName}`,
        email: user.email,
        organization: user.organization,
        version: JSAPP_CONF?.static ?? ""
    };

    Sentry.setUser(userData);
};
