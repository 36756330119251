import React from "react";
import { useParams } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

import User from "main-app/models/user";

import { Status } from "main-app/constants";
import ModuleQueuedStatus from "./ModuleQueuedStatus";
import Notification from "main-app/shared/notification/Notification";
import CareLinkEmail from "main-app/shared/CareLinkEmail";
import useSpotCoaching from "main-app/api/use-spot-coaching";
import { isNullOrUndefined } from "common/utils/gates";
import ModuleUnassignedStatus from "./ModuleUnassignedStatus";
import ModuleArrowNavigation from "./ModuleArrowNavigation";
import { isUnassignedModule } from "main-app/utils/gates/module";
import { SimulabBanner } from "main-app/entities/simulab";

import "./styles.scss";

interface IProps {
    user: User;
}

const ParticipantSubHeader: React.FC<IProps> = ({ user }) => {
    const { t } = useTranslation();
    const { data: productType } = useSpotCoaching();
    const params = useParams();

    const module = user?.enrolledModules?.find(module => module.rank === +params.moduleId);
    const noEnrollments = user?.enrolledModules?.length === 0;
    const isUnassigned = isUnassignedModule(module) || isNullOrUndefined(module);

    return (
        <div className="main-subheader mb-40 mt-40 mt-10-xs" data-testid="participant-subheader">
            {user?.practiceLab && (
                <div className="mb-60">
                    <SimulabBanner />
                </div>
            )}

            {user?.offboardingPassed && (
                <div className="mb-5">
                    <Notification
                        heading={t("Congratulations, you've completed your {{title}} journey!", {
                            title: productType?.titleData
                        })}
                        text={t(
                            "You'll retain access to your account for the next couple months, in case you'd like to review any of the content."
                        )}
                    />
                </div>
            )}

            {!noEnrollments && <ModuleArrowNavigation module={module} user={user} />}

            {noEnrollments && (
                <div className="mt-30">
                    <h3 className="main-subheader-module-title">{t("Welcome to Wiser!")}</h3>
                    <p className="text-center">
                        {t(
                            "Great news— you’ve finished onboarding & nothing else is required before your first coaching session."
                        )}
                    </p>
                </div>
            )}

            {isUnassigned && noEnrollments && (
                <div className="mt-40">
                    <ModuleUnassignedStatus
                        withCareLink
                        variant="secondary"
                        headerText={<p className="m-0 font-extrabold">{t("")}</p>}
                        text={t(
                            "During your first coaching session, you will work with your coach to identify the right starting place for your Wiser journey. Take a moment to explore the available modules below in preparation for your first session."
                        )}
                    />
                </div>
            )}

            {isUnassigned && !noEnrollments && (
                <div className="mt-40">
                    <ModuleUnassignedStatus
                        withCareLink={false}
                        noMargin
                        headerText={
                            <p className="m-0 font-extrabold">
                                {t("Uh oh, looks like the window for this module has already passed.")}
                            </p>
                        }
                        text={
                            <Trans i18nKey="moduleUnassignedStatusText">
                                If you would like to retroactively choose a module to explore on your own time and at
                                your own pace, reach out to{" "}
                                <CareLinkEmail className="color-brand text-decoration-underline" />. There will not be a
                                coaching session associated with this module.
                            </Trans>
                        }
                    />
                </div>
            )}

            {module?.status === Status.Queued && (
                <div className="mt-30">
                    <ModuleQueuedStatus module={module} />
                </div>
            )}

            {module?.status === Status.Completed && (
                <div className="completed-module-status-text text-center">({t("Completed")})</div>
            )}
        </div>
    );
};

export default ParticipantSubHeader;
