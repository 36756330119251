import React from "react";
import { JS_CONF } from "main-app/constants";

type Props = {
    width?: number;
    height?: number;
};

const OwlErrorLogo = ({ width = 300, height = 300 }: Props) => {
    return (
        <div className="owl-error">
            <img src={JS_CONF?.static + "img/404owl.png"} />
        </div>
    );
};

export default OwlErrorLogo;
