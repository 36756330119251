import * as React from "react";

function ProgressModuleDot(props: React.SVGProps<SVGSVGElement> & { isActive?: boolean }) {
    const { width = 5, height = 5, isActive = false } = props;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 5 5"
            fill="none"
            data-testid="progress-module-dot-svg"
        >
            <circle cx="2.5" cy="2.8999" r="2" fill={isActive ? "#FD4D00" : "#6F6F6F"} />
        </svg>
    );
}

export default React.memo(ProgressModuleDot);
