export enum CoachCalendarStepUrls {
    LINK_CALENDAR_STEP = "/link-calendar",
    LINK_ACCOUNT_STEP = "/link-account",
    AVAILABILITY_STEP = "/availability-step",
    AVAILABILITY_CONFIRM_STEP = "/availability-confirm-step",
    CALENDAR_ADD = "/add-calendar"
}

export enum CoachSettingsUrls {
    CALENDARS = "/setup-calendar/calendars",
    AVAILABILITY = "/setup-calendar/availability",
    ZOOM_ACCOUNT = "/setup-calendar/zoom-account"
}

export type NavigationCoachCalendar = {
    url: (typeof CoachCalendarStepUrls)[keyof typeof CoachCalendarStepUrls];
    title: string;
};

export const coachCalendarNavigation = [
    { url: CoachCalendarStepUrls.LINK_CALENDAR_STEP, title: "Step 1" },
    { url: CoachCalendarStepUrls.LINK_ACCOUNT_STEP, title: "Step 2" },
    { url: CoachCalendarStepUrls.AVAILABILITY_STEP, title: "Step 3" },
    { url: CoachCalendarStepUrls.AVAILABILITY_CONFIRM_STEP, title: "Step 4" }
];
