import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { http } from "common/http";
import Urls from "main-app/api/urls";
import Spinner from "main-app/shared/spinner/Spinner";
import { useOnboardingStoreContext } from "./onboardingStore";
import useUpdateEffect from "common/hooks/use-update-effect";
import { useAuthContext } from "main-app/context/Auth";
import User from "main-app/models/user";
import { Role } from "main-app/constants";

import { OnboardingSteps, dashboardURL, onboardingUrls } from "./constants";
import useNavigationOnboarding from "main-app/utils/hooks/use-navigation-onboarding";

const OnBoardingContainer = ({ children }) => {
    const navigate = useNavigate();

    const { storeData, setStoreData } = useOnboardingStoreContext();
    const { setUser } = useAuthContext();
    const [loading, setLoading] = useState(true);
    const { lastStep, currentStep } = useNavigationOnboarding();

    useEffect(() => {
        if (!storeData.currenUser) {
            handleProfileData();
        }
    }, []);

    useEffect(() => {
        if (currentStep?.id === OnboardingSteps.FINISH || lastStep?.id === OnboardingSteps.FINISH) {
            setUser((user: User) => ({ ...user, onboardingFinished: true }));
            navigate(dashboardURL);
        } else if (
            currentStep?.id === OnboardingSteps.PERSONAL_INFO_STEP ||
            lastStep?.id === OnboardingSteps.PERSONAL_INFO_STEP
        ) {
            navigate(onboardingUrls[0]);
        }
    }, [navigate, currentStep, lastStep]);

    useUpdateEffect(() => {
        if (!lastStep) {
            return;
        }

        navigate(lastStep.url);
    }, [lastStep]);

    const handleProfileData = async () => {
        try {
            const { data } = await http.get(Urls.participantMe());
            const { data: profileSetupDataModal } = await http.get(Urls.onboardingAboutManager());

            setStoreData(store => ({ ...store, currenUser: data, profileSetupDataModal }));
            setUser(new User({ ...data, user_role: Role.Participant }));
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    return !storeData?.currenUser && loading ? (
        <div className="w-100 h-100 d-flex justify-content-center pt-5">
            <Spinner />
        </div>
    ) : (
        <>{children}</>
    );
};

export default OnBoardingContainer;
