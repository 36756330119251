import { Module } from "../models/module";

export const groupByCategory = (modules: Module[]) => {
    return modules.reduce((acc, module) => {
        if (!acc[module.category]) {
            acc[module.category] = {
                category: module.category,
                categoryDescription: module.categoryDescription,
                modules: []
            };
        }
        acc[module.category] = {
            ...acc[module.category],
            modules: [...acc[module.category].modules, module]
        };
        return acc;
    }, {});
};
