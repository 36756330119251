import { useQuery } from "@tanstack/react-query";
import { http } from "common/http";
import { ServerStateKeys } from "main-app/constants";
import { Resource } from "main-app/models/resource";
import Urls from "main-app/api/urls";
import { useCallback } from "react";

const getResources = async (id: number) => {
    const { data } = await http.get(Urls.resources(id));
    return data.results;
};

export default function useResources(id: number) {
    return useQuery<Resource[]>([ServerStateKeys.Resources, id], () => getResources(id), {
        select: useCallback(data => {
            return data.map(resource => new Resource(resource));
        }, []),
        enabled: !isNaN(id)
    });
}
