import React from "react";
import classNames from "classnames";

import { ComponentType } from "main-app/constants";
import ContentResponses from "./ContentRespones";
import MediaVideoReponses from "./MediaVideoRespones";
import QuestionFreeResponses from "./QuestionFreeResponses";
import QuestionLikertResponses from "./QuestionLikertResponses";
import TrackPracticeResponses from "./TrackPracticeResponses";

interface IProps {
    component: any;
}

const renderComponent = props => {
    return {
        [ComponentType["Question: Free Response"]]: <QuestionFreeResponses {...props} />,
        [ComponentType["Question: Likert Group"]]: <QuestionLikertResponses {...props} />,
        [ComponentType["Track Practice"]]: <TrackPracticeResponses {...props} />
    };
};

const MobileResponseItem: React.FC<IProps> = ({ component }) => {
    return (
        <div className={classNames("responses-body")}>
            {component && renderComponent({ component: component })[component?.type]}
        </div>
    );
};

export default MobileResponseItem;
