import React, { useCallback } from "react";
import classNames from "classnames";

import { AttendanceStatus } from "main-app/models/types";
import { MarkAttendeeType } from "main-app/constants";
import { CheckMarkAttendee } from "main-app/svgs/CheckMarkAttendee";
import { CrossMarkAttendee } from "main-app/svgs/CrossMarkAttendee";

import "./styles.scss";

type Props = {
    status: AttendanceStatus;
    marked: {
        checked: boolean;
        name: AttendanceStatus | "";
    };
    id: string | number;
    name?: string;
    className?: string;
    type: "cross" | "check";
    handleRadioButtonClick: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const AttendeeRadioButton = ({ status, id, marked, name, className, type, handleRadioButtonClick }: Props) => {
    const getCheckMarksType = useCallback(
        (id: AttendanceStatus): MarkAttendeeType => {
            const { checked, name } = marked;

            return !checked ? "default" : name === id ? "marked" : "unmarked";
        },
        [marked]
    );

    return (
        <label className={classNames("radio-check-attendee", className)}>
            <input
                type="radio"
                data-id={status}
                name={name}
                data-testid={`checkmark-present-${id}`}
                onChange={handleRadioButtonClick}
                checked={marked.checked && marked.name === status}
            />
            <span className="radio-check-attendee-button">
                {type === "check" ? (
                    <CheckMarkAttendee type={getCheckMarksType(status)} />
                ) : (
                    <CrossMarkAttendee type={getCheckMarksType(status)} />
                )}
            </span>
        </label>
    );
};
