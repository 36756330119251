import React from "react";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface IProps {
    control: any;
    rowIndex: number;
    colIndex: number;
}

const TrackPracticeMobileColumnFields: React.FC<IProps> = ({ control, rowIndex, colIndex }) => {
    const { t } = useTranslation();
    const value = useWatch({ control, name: `rows.${rowIndex}.columns.${colIndex}.answer` });

    return <p className="font-sm">{value ? value : t("Start typing...")}</p>;
};

export default TrackPracticeMobileColumnFields;
