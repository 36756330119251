import { COACHEE_CONVERSATION_COMPLETETION_STATUS } from "../constants";

export interface CoacheeApi {
    id: number;
    name: string;
    summary: string;
    description: string;
    photo: string | null;
    conversations: CoacheeConversationApi[] | null;
}

export type CoacheeConversationStatus =
    (typeof COACHEE_CONVERSATION_COMPLETETION_STATUS)[keyof typeof COACHEE_CONVERSATION_COMPLETETION_STATUS];

export interface CoacheeConversationApi {
    id: number;
    status: CoacheeConversationStatus;
    completed_stamp: string | null;
}

export class CoacheeConversation {
    id: number;
    status: CoacheeConversationStatus;
    completedStamp: string | null;

    constructor(data: CoacheeConversationApi) {
        this.id = data.id;
        this.status = data.status;
        this.completedStamp = data.completed_stamp;
    }
}

export class Coachee {
    id: number;
    name: string;
    summary: string;
    description: string;
    photo: string | null;
    conversations: CoacheeConversation[];

    constructor(data: CoacheeApi) {
        this.id = data.id;
        this.name = data.name || "";
        this.summary = data.summary || "";
        this.description = data.description || "";
        this.photo = data.photo || "";
        this.conversations = data?.conversations?.map(conversation => new CoacheeConversation(conversation)) || [];
    }
}
