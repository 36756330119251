import React from "react";
import { useTranslation } from "react-i18next";

interface IProps {
    onClick: () => void;
    className?: string;
    isLast: boolean;
    disabled: boolean;
}

const NextButton: React.FC<IProps> = ({ onClick, className, isLast = false, disabled }) => {
    const { t } = useTranslation();

    return (
        <button className={`btn btn-next btn--brand-outline ${className}`} onClick={onClick} disabled={disabled}>
            {isLast ? t("Complete") : t("Next")}
        </button>
    );
};

export default NextButton;
