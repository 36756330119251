import React, { ReactNode } from "react";
import classNames from "classnames";

import "./styles.scss";

interface IProps {
    className?: string;
    top?: string;
    left?: string;
    children: ReactNode;
}

const WarningToast: React.FC<IProps> = ({ className = "", children, top = "20px", left = "50%" }) => {
    return (
        <div className="warning-toast" style={{ top, left }}>
            <span className={classNames({ [className]: className })}>{children}</span>
        </div>
    );
};

export default WarningToast;
