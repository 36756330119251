import { useEffect } from "react";
import User from "main-app/models/user";
import { i18n } from "common/i18n";
import { FALLBACK_LANG } from "common/constants";

export function useLanguage(user: User) {
    useEffect(() => {
        if (user) {
            const availableLanguage =
                user?.cohortAvailableLanguages && user.lang in user.cohortAvailableLanguages
                    ? user.lang
                    : FALLBACK_LANG;
            i18n.changeLanguage(availableLanguage);
        }
    }, [user]);
}
