import React from "react";
import classnames from "classnames";
import { usePagination, DOTS, TPaginationRange, IPagination } from "common/hooks/use-pagination";

const PAGE_SIZE: number = 10;

import "./styles.scss";

const Pagination = ({ onPageChange, totalCount, siblingCount = 1, currentPage, pageSize = PAGE_SIZE, className }) => {
    const { getPaginationRange }: IPagination = usePagination();

    const paginationRange: TPaginationRange = getPaginationRange({
        currentPage,
        totalCount,
        siblingCount,
        pageSize
    });

    const lastPage = paginationRange?.[paginationRange?.length - 1];

    return totalCount > PAGE_SIZE ? (
        <ul className={classnames("pagination-container", { [className]: className })}>
            <li
                className={classnames("pagination-item pagination-item-arrow", {
                    disabled: currentPage === 1
                })}
                onClick={() => {
                    onPageChange(currentPage - 1);
                }}
            >
                <div className="arrow left" />
            </li>
            {paginationRange?.map(pageNumber => {
                if (pageNumber === DOTS) {
                    return (
                        <li key={pageNumber} className="pagination-item dots">
                            &#8230;
                        </li>
                    );
                }

                return (
                    <li
                        className={classnames("pagination-item", {
                            selected: pageNumber === currentPage
                        })}
                        key={pageNumber}
                        onClick={() => onPageChange(pageNumber)}
                    >
                        {pageNumber}
                    </li>
                );
            })}
            <li
                className={classnames("pagination-item pagination-item-arrow", {
                    disabled: currentPage === lastPage
                })}
                onClick={() => {
                    onPageChange(currentPage + 1);
                }}
            >
                <div className="arrow right" />
            </li>
        </ul>
    ) : (
        <></>
    );
};

export { PAGE_SIZE };

export default Pagination;
