import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { SimulabCoachee } from "main-app/entities/simulab";
import Button from "main-app/shared/button/Button";
import { PDF_FILE_URL } from "../constants";

type Props = {
    coachee: SimulabCoachee;
    showButton: boolean;
    onButtonClick: () => void;
};

export const SimulabCoachingIntroCard = ({ coachee, showButton, onButtonClick }: Props) => {
    const { t } = useTranslation();

    return (
        <div className="coaching-intro-card">
            <div className={`${showButton ? "mb-30" : ""}`}>
                <p className="mb-10 fs-14">
                    <span className="font-extrabold">Access Point:</span> Request 💬
                </p>
                <ul className="mb-0 fs-14">
                    <li>
                        {t("{{coacheeName}} would like to discuss a challenge they're facing", {
                            coacheeName: coachee.name
                        })}
                        .
                    </li>
                    <li>
                        <Trans
                            i18nKey="simulabCoachingIntroCardPdfLinkText"
                            values={{ coacheeName: coachee.name }}
                            components={{
                                1: (
                                    <a href={PDF_FILE_URL} target="_blank" className="simulab-underline font-extrabold">
                                        D.I.G. Model
                                    </a>
                                )
                            }}
                        />
                    </li>
                    <li>{t("Begin the conversation by asking a question")}.</li>
                </ul>
            </div>
            {showButton && (
                <Button className="coaching-intro-card__btn font-extrabold" onClick={onButtonClick}>
                    {t("Got It, Let's Start")}
                </Button>
            )}
        </div>
    );
};
