import React from "react";
import classNames from "classnames";

import "./styles.scss";

interface IProps {
    header: JSX.Element | React.ReactNode | null;
    body: JSX.Element | React.ReactNode | null;
    className?: string;
}

const QuestionCard: React.FC<IProps> = ({ header, body, className = "" }) => {
    return (
        <div className={classNames("question-card", { [className]: className })}>
            <div className="question-card__header">{header}</div>
            <div className="question-card__body">{body}</div>
        </div>
    );
};

export default QuestionCard;
