import React from "react";
import { useTranslation } from "react-i18next";
import Modal from "main-app/shared/modal";
import { withTranslation } from "common/utils/lang";
import { defaultAllowedStyles } from "main-app/constants";
import { sanitizeContent } from "main-app/utils/common";
import QuestionOption from "../question-result-options";

import "./styles.scss";

type Props = {
    show: boolean;
    participantName: string;
    preProgramDiagnostics: any[];
    onClose: () => void;
};

const DiagnosticResponsesModal = ({ show, onClose, participantName, preProgramDiagnostics }: Props) => {
    const { t } = useTranslation();
    const answers = preProgramDiagnostics?.flatMap(diagnostic => diagnostic.answer_data);
    const answersWithOptions = preProgramDiagnostics?.reduce((result, current) => {
        current.question_list.forEach(question => {
            answers?.forEach(answer => {
                if (answer?.question_uuid === question?.uuid) {
                    result.push({ ...answer, options: question.options });
                }
            });
        });
        return result;
    }, []);

    return (
        <Modal
            show={show}
            onClose={onClose}
            headerContent={
                <div className="p-3">
                    <h4 className="h4 font-extrabold m-0 text-center fs-24">
                        {participantName} {t("Pre-Program Diagnostic")}
                    </h4>
                </div>
            }
            modalBodyClass="diagnostic-response-modal"
            disableBodyScroll
        >
            <div className="diagnostic-response-modal__body">
                {answersWithOptions?.map((answer, index) => (
                    <div className={"result-collapse-container mb-30"} key={answer.uuid || answer.question_uuid}>
                        <div className="result-collapse-item no-heigth">
                            <p className="mb-40 px-3 d-flex">
                                <span className="font-extrabold mr-1">{index + 1}. </span>
                                <span
                                    className="resulst-"
                                    dangerouslySetInnerHTML={{
                                        __html: sanitizeContent(withTranslation(answer?.question), {
                                            allowedStyles: defaultAllowedStyles,
                                            transformTags: {
                                                p: "span"
                                            }
                                        })
                                    }}
                                />
                            </p>

                            <div className="level-choice d-flex justify-content-center">
                                {answer.options?.map(o => (
                                    <QuestionOption
                                        checked={o.uuid === answer.option_uuid}
                                        label={withTranslation(o.response)}
                                        id={o.uuid}
                                        key={o.uuid}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </Modal>
    );
};

export default DiagnosticResponsesModal;
