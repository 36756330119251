import React, { useEffect, useState } from "react";
import { matchPath, useLocation } from "react-router-dom";

import { isNullOrUndefined } from "common/utils/gates";
import { useAuthContext } from "main-app/context/Auth";
import { onboardingProfileSetupURL, StepUrls } from "main-app/components/onboarding/constants";
import { AuthUrls, ParticipantUrls, ServerStateKeys } from "main-app/constants";

import { useQueryClient } from "@tanstack/react-query";
import ReflectionJourneyModal from "./ReflectionJourneyModal";

import "./styles.scss";

type Props = {
    show?: boolean;
    moduleId?: number;
    sessionId?: number;
    showOnlyOnTrigger?: boolean;
    onClose?: () => void;
};

const PostReflectionModalWrapper = ({
    show = false,
    moduleId,
    sessionId,
    showOnlyOnTrigger = false,
    onClose
}: Props) => {
    const location = useLocation();
    const queryClient = useQueryClient();
    const { user, getUserData } = useAuthContext();
    const [showJourneyReflectionModal, setShowJourneyReflectionModal] = useState(show);

    const activatePostModuleReflection = () => {
        const showModal = !isNullOrUndefined(user?.reflectionPopupData);

        const dontShowModalPage = [
            onboardingProfileSetupURL,
            ...Object.values(StepUrls),
            ParticipantUrls.OFFBOARDING,
            ParticipantUrls.END_OF_JOURNEY_PAGE,
            ParticipantUrls.WAITING_LIST,
            AuthUrls.LOGIN
        ].some(url => matchPath(url, location.pathname));

        if (dontShowModalPage) {
            return;
        }

        setShowJourneyReflectionModal((showModal && !showOnlyOnTrigger) || show);
    };

    useEffect(() => {
        activatePostModuleReflection();
    }, [user, location.pathname, show]);

    const onCloseJourneyReflection = () => {
        setShowJourneyReflectionModal(false);
        queryClient.invalidateQueries([ServerStateKeys.CoachingSession]);
        getUserData();
        onClose?.();
    };

    return (
        <>
            {showJourneyReflectionModal && (
                <ReflectionJourneyModal
                    show={showJourneyReflectionModal}
                    moduleId={user?.reflectionPopupData?.moduleId || moduleId}
                    sessionId={user?.reflectionPopupData?.coachingSessionId || sessionId}
                    onClose={onCloseJourneyReflection}
                />
            )}
        </>
    );
};

export default PostReflectionModalWrapper;
