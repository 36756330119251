import React from "react";
import { useTranslation } from "react-i18next";
import CareTeamLink from "main-app/shared/CareTeamLink";
import classNames from "classnames";

type Props = {
    className?: string;
};

export const SimulabCareLink = ({ className }: Props) => {
    const { t } = useTranslation();

    return (
        <div className={classNames("simulab-care-link", className)}>
            <CareTeamLink
                text={t("If you experience any issues with our new feature:")}
                variant="brand"
                className="fs-14 mt-0 simulab-care-team-link"
            />
        </div>
    );
};
