import React, { useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import useBoolean from "common/hooks/use-boolean";
import { getErrorMessages } from "common/utils/get-error-messages";
import Heading from "common/components/Heading/Heading";
import { isNullOrUndefined } from "common/utils/gates";
import { withTranslation } from "common/utils/lang";

import Coach from "main-app/models/coach";
import { isLikertComponent, isLikertDefaultComponent } from "main-app/utils/gates/utilts";
import Button from "main-app/shared/button/Button";
import LabelDropdown from "main-app/shared/dropdown/LabelDropdown";
import { useAuthContext } from "main-app/context/Auth";
import useOffBoarding from "main-app/api/use-offboarding";
import useSubmitOffboarding from "main-app/api/mutations/use-submit-offboarding";
import AssessmentResponses from "main-app/elements/assessment-responses/AssessmentResponses";
import {
    CoachRatingAskedFrom,
    CoachRatingSource,
    JS_CONF,
    ConfigKeys,
    ParticipantUrls,
    ProductType
} from "main-app/constants";
import NpsQuestion from "main-app/elements/nps/NpsQuestion";
import useCoachingSessions from "main-app/api/use-coaching-sessions";
import CoachRating from "main-app/elements/coach-rating/CoachRating";
import { useStoreContext } from "main-app/context/GlobalStore";

import DiagnosticQuestionsList from "./DiagnostiQuestionsList";
import OffboardingContainer from "./OffboardingContainer";
import { WarningMessage } from "../onboarding/components/WarningMessage";
import { dashboardURL } from "../onboarding/constants";

import "./styles.scss";

type Props = {};

const Offboarding = (props: Props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { user } = useAuthContext();
    const { getConfig } = useStoreContext();

    const hasCoachSessions = user?.productType !== ProductType.SPOT_COACHING;

    const { data: offboardingJourney, isLoading } = useOffBoarding();
    const submitOffboarding = useSubmitOffboarding();
    const { data: sessions } = useCoachingSessions({ enabled: hasCoachSessions });

    const { value: showDropdown, toggle: onToggleDropdown } = useBoolean(false);
    const { value: isBusy, setValue: setIsBusy } = useBoolean(false);
    const [feedbackSubmitted, setFeedbackSubmitted] = useState({
        nps: false,
        postModuleRating: false,
        offboardingRating: false
    });
    const [error, setError] = useState(null);

    if (user?.offboardingPassed) {
        navigate(dashboardURL);
    }

    const module = useMemo(() => user?.enrolledModules?.[user?.enrolledModules?.length - 1], [user]);

    const selfAssessmentQuestions =
        offboardingJourney?.self_assessment?.question_list?.length > 0
            ? offboardingJourney?.self_assessment?.question_list
            : [];

    const questionsList = useMemo(
        () =>
            offboardingJourney
                ? [
                      ...offboardingJourney?.module_reflection,
                      ...offboardingJourney?.journey_final_reflection,
                      ...selfAssessmentQuestions
                  ]
                : [],
        [offboardingJourney, selfAssessmentQuestions]
    );

    const coachSession = useMemo(() => {
        const hasLiveSession = !isNullOrUndefined(sessions?.live_session);

        const hasEnrolledModules = getConfig(ConfigKeys.hasEnrolledModules);

        if (!hasEnrolledModules) {
            return hasLiveSession
                ? sessions?.live_session
                : sessions?.passed_sessions?.[sessions?.passed_sessions?.length - 1];
        }

        const passedSession =
            sessions?.passed_sessions?.find(session => session.enrollment_rank === module?.rank) ??
            sessions?.passed_sessions?.[sessions?.passed_sessions?.length - 1];

        const session = hasLiveSession
            ? sessions?.live_session?.enrollment_rank === module?.rank
                ? sessions?.live_session
                : passedSession
            : passedSession;

        return session;
    }, [sessions, module, getConfig]);

    const coach = useMemo(() => {
        return !isNullOrUndefined(coachSession?.coach) ? new Coach(coachSession.coach) : null;
    }, [coachSession]);

    const activeDiagnosticAnswers = useMemo(
        () => offboardingJourney?.self_assessment?.answer_data?.filter(answer => answer.is_active),
        [offboardingJourney]
    );

    const shapeWithDynamicValidation = useMemo(() => {
        const validationSchema = {};
        if (questionsList.length) {
            validationSchema["diagnostic"] = yup.object(
                questionsList.reduce((validationMap, question) => {
                    validationMap[question.uuid] = question.response_required
                        ? yup
                              .string()
                              .max(5046, t("Ensure this field has no more than 5046 characters"))
                              .required(t("Answer is required"))
                              .nullable(true)
                        : yup.string().notRequired().nullable(true);
                    return validationMap;
                }, {})
            );
        }
        return validationSchema;
    }, [questionsList]);

    const methods = useForm({
        resolver: yupResolver(yup.object(shapeWithDynamicValidation))
    });

    const {
        handleSubmit,
        setError: setFormError,
        clearErrors,
        formState: { errors }
    } = methods;

    const onSubmit = values => {
        const answersList = getAnswersList(values);
        if (!feedbackSubmitted.nps) {
            setFormError("nps", { type: "custom", message: t("Answer is required") });
            return;
        }

        submitData({
            question_list: questionsList,
            answer_list: answersList
        });
    };

    const getAnswersList = values => {
        return questionsList.map(question => {
            const answer = values.diagnostic[question.uuid];
            let answerObj: any = {
                question_uuid: question.uuid,
                question: question.question,
                answer: answer ?? ""
            };

            if (isLikertComponent(question.component_type) || isLikertDefaultComponent(question)) {
                const option = question.options.find(option => option.uuid === answer);
                answerObj = {
                    ...question,
                    question_uuid: question.uuid,
                    question: question.question,
                    option_uuid: answer,
                    answer: option?.response?.[JS_CONF.lang] ?? answer ?? ""
                };
            }

            if (isNullOrUndefined(answerObj.option_uuid)) {
                delete answerObj["option_uuid"];
            }

            return answerObj;
        });
    };

    const submitData = async data => {
        setIsBusy(true);
        try {
            await submitOffboarding.mutateAsync(data);
            navigate(ParticipantUrls.DASHBOARD);
        } catch (e) {
            setError(getErrorMessages(e));
        } finally {
            setIsBusy(false);
        }
    };

    const onSubmitNps = () => {
        setFeedbackSubmitted({
            ...feedbackSubmitted,
            nps: true
        });
        clearErrors("nps");
    };

    const onSubmitCoachRating = (source: CoachRatingSource) => {
        if (source === CoachRatingAskedFrom.POST_MODULE_REFLECTION_POPUP) {
            setFeedbackSubmitted(feedback => ({ ...feedback, postModuleRating: true }));
        }

        if (source === CoachRatingAskedFrom.OFFBOARDING) {
            setFeedbackSubmitted(feedback => ({ ...feedback, offboardingRating: true }));
        }
    };

    const hasDiagnosticQuestion = offboardingJourney?.journey_final_reflection?.length > 0;

    return (
        <OffboardingContainer>
            <p className="color-gray fs-14 text-center">
                {t(
                    "We're a small business looking to grow & improve, so your honest feedback in this final reflection means a lot!"
                )}
            </p>
            <div className="questions-container">
                <FormProvider {...methods}>
                    <div className={classNames("reflection-container", { "d-none": !hasCoachSessions })}>
                        <Heading tag="h2" fontSize={24} className="mb-5">
                            {withTranslation(module?.name)} {t("Reflection")}
                        </Heading>
                        {hasCoachSessions && (
                            <>
                                <Heading tag="h3" fontSize={16} className="mb-4">
                                    {t("My Experience")}
                                </Heading>
                                <CoachRating
                                    askedFrom={CoachRatingAskedFrom.POST_MODULE_REFLECTION_POPUP}
                                    coachSessionId={coachSession?.session_id}
                                    coachData={coach}
                                    onSubmitCoachRating={onSubmitCoachRating}
                                />
                            </>
                        )}
                        {offboardingJourney?.module_reflection?.length > 0 &&
                        offboardingJourney?.self_assessment?.question_list?.length > 0 ? (
                            <Heading tag="h3" fontSize={16} className="mb-4">
                                {t("My Development")}
                            </Heading>
                        ) : null}
                        {activeDiagnosticAnswers?.length ? (
                            <LabelDropdown
                                text={`${showDropdown ? t("Hide") : t("Show")} ${t("My Previous Responses")}`}
                                active={showDropdown}
                                onToggle={onToggleDropdown}
                                className="offboarding mb-4 font-extrabold"
                            />
                        ) : null}

                        {showDropdown && (
                            <AssessmentResponses
                                diagnosticAnswers={activeDiagnosticAnswers}
                                title={t("Initial Assessment Responses")}
                                className="mb-40"
                                variant="offboarding"
                            />
                        )}

                        <DiagnosticQuestionsList
                            diagnosticQuestions={offboardingJourney?.module_reflection}
                            name="diagnostic"
                            errors={errors}
                            isLoading={isLoading}
                        />

                        <DiagnosticQuestionsList
                            diagnosticQuestions={offboardingJourney?.self_assessment?.question_list}
                            name="diagnostic"
                            errors={errors}
                            isLoading={isLoading}
                        />
                    </div>

                    <div className="reflection-container">
                        <Heading tag="h2" fontSize={24} className="mb-5">
                            {t("Journey Reflection")}
                        </Heading>
                        {hasCoachSessions && (
                            <>
                                <Heading tag="h3" fontSize={16} className="mb-4">
                                    {t("My Experience")}
                                </Heading>
                                <CoachRating
                                    askedFrom={CoachRatingAskedFrom.OFFBOARDING}
                                    coachData={coach}
                                    onSubmitCoachRating={onSubmitCoachRating}
                                    orderNumber={1}
                                />
                            </>
                        )}

                        <NpsQuestion
                            onSubmitNps={onSubmitNps}
                            errorMsg={errors?.nps?.message}
                            showDividerLine={hasDiagnosticQuestion}
                            order={hasCoachSessions ? 2 : null}
                        />

                        {hasDiagnosticQuestion && (
                            <Heading tag="h3" fontSize={16} className="mb-4">
                                {t("My Development")}
                            </Heading>
                        )}

                        <DiagnosticQuestionsList
                            diagnosticQuestions={offboardingJourney?.journey_final_reflection}
                            errors={errors}
                            name="diagnostic"
                            isLoading={isLoading}
                        />
                    </div>

                    <WarningMessage message={error} />
                    <div className="mt-47 text-center">
                        <Button onClick={handleSubmit(onSubmit)} type="submit" isBusy={isBusy}>
                            {t("Submit")}
                        </Button>
                    </div>
                </FormProvider>
            </div>
        </OffboardingContainer>
    );
};

export default Offboarding;
