import { ParticipantBase } from "main-app/models/participants";
import { useEffect, useState } from "react";
import { PARTICIPANTS_IN_ROW_MAX_COUNT } from "main-app/constants";

type TParams = { participantList: ParticipantBase[] | []; viewLimit?: number };

type TReturn = ReturnType<
    ({ participantList }: TParams) => {
        sortedParticipantList: ParticipantBase[];
        isParticipantsAmountExceedingViewLimit: boolean;
        exceedingParticipantsCount: number;
    }
>;

function useCroppedAndSortedParticipantList({
    participantList,
    viewLimit = PARTICIPANTS_IN_ROW_MAX_COUNT
}: TParams): TReturn {
    const [sortedParticipantList, setSortedParticipantList] = useState<ParticipantBase[] | []>(participantList ?? []);

    const isParticipantsAmountExceedingViewLimit: boolean = participantList.length > viewLimit;

    const exceedingParticipantsCount: number = participantList.length - sortedParticipantList.length;

    useEffect((): void => {
        const sortedArray: ParticipantBase[] = sortedParticipantList.sort(sortParticipantByPhotoAndName);

        isParticipantsAmountExceedingViewLimit && setSortedParticipantList(sortedArray?.slice(0, viewLimit));
    }, []);

    return { sortedParticipantList, isParticipantsAmountExceedingViewLimit, exceedingParticipantsCount };
}

export { useCroppedAndSortedParticipantList };

function sortParticipantByPhotoAndName(pFirst?: ParticipantBase, pNext?: ParticipantBase): number {
    return pFirst?.photo && !pNext?.photo
        ? -1
        : !pFirst?.photo && pNext?.photo
          ? 1
          : pFirst?.firstName?.localeCompare(pNext.firstName);
}
