import { GoalsSortType } from "main-app/shared/types/sort";

import {
    useArchiveOldBeliefData,
    useArhiveOldBelief,
    useCreateOldBelief,
    useEditOldBelief,
    useOldBeliefData,
    useSortOldBelief,
    useUnarciveOldBelief
} from "../api/old-belief.api";

type Options = {
    userId: number;
    sort: GoalsSortType;
};

export function useOldBeliefApi({ userId, sort }: Options) {
    const { data: oldBeliefs, isLoading: isLoadingOldBeliefs, error: oldBeliefError } = useOldBeliefData(userId, sort);
    const { data: archivedOldBeliefs, error: archiveError } = useArchiveOldBeliefData(userId);
    const createOldBelief = useCreateOldBelief(userId);
    const editOldBelief = useEditOldBelief(userId);
    const archiveOldBelief = useArhiveOldBelief(userId);
    const unarchiveOldBelief = useUnarciveOldBelief(userId);
    const sortOldBeliefs = useSortOldBelief(userId);

    return {
        oldBeliefs,
        isLoadingOldBeliefs,
        oldBeliefError,
        archivedOldBeliefs,
        archiveError,
        createOldBelief,
        editOldBelief,
        archiveOldBelief,
        unarchiveOldBelief,
        sortOldBeliefs
    };
}
