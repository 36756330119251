import React, { useMemo } from "react";

import Coach from "main-app/models/coach";
import OwlLoader from "main-app/shared/spinner/OwlLoader";
import { ThreeWay, ThreeWayManager } from "main-app/entities/three-way";
import { isNullOrUndefined } from "common/utils/gates";
import CronofyWidget from "common/components/CronofyWidgets/CronofyWidget";
import { CRONOFY_WIDGET } from "common/constants";

import CoachTimeError from "./CoachTimeError";
import { BookedTime } from "./types";
import CoachTimeBooked from "./CoachTimeBooked";

type Props = {
    isLoading: boolean;
    data: any;
    error: string | null;
    bookedTime: BookedTime | null;
    coach: Coach;
    showBackBtn: boolean;
    showManagerInfoBlock?: boolean;
    session?: any;
    sessionId: number;
    onTimePick: (notification) => void;
    onClickBack: () => void;
    onCloseModal: () => void;
};

const CoachTimeScheduleContainer = ({
    isLoading,
    data,
    error,
    bookedTime,
    coach,
    showBackBtn,
    showManagerInfoBlock = false,
    session,
    sessionId,
    onTimePick,
    onCloseModal,
    onClickBack
}: Props) => {
    if (isLoading) {
        return (
            <div className="p-5 coach-time-loader  d-flex align-items-center justify-content-center">
                <OwlLoader />
            </div>
        );
    }

    if (error) {
        return <CoachTimeError error={error} onClickBack={onClickBack} showBackBtn={showBackBtn} />;
    }

    if (bookedTime) {
        return <CoachTimeBooked bookedTime={bookedTime} coach={coach} onClose={onCloseModal} />;
    }

    const threeWayManager = useMemo(() => {
        if (!isNullOrUndefined(session?.three_way_session_manager)) {
            return new ThreeWayManager(session?.three_way_session_manager);
        }

        if (session?.extra_parties_info?.length) {
            return new ThreeWayManager(session?.extra_parties_info[0]);
        }

        return null;
    }, [session]);

    return (
        <div className="book-session-box">
            {showManagerInfoBlock && (
                <ThreeWay threeWayManager={threeWayManager} sessionId={sessionId} className="mb-40" />
            )}
            {data ? (
                <CronofyWidget
                    widget={CRONOFY_WIDGET.DateTimePicker}
                    id="time-picker-widget-cronofy"
                    options={{
                        ...data,
                        callback: notification => onTimePick(notification),
                        styles: {
                            prefix: "TPC"
                        }
                    }}
                />
            ) : null}
        </div>
    );
};

export default CoachTimeScheduleContainer;
