import React, { SVGProps } from "react";
import classNames from "classnames";
import { MarkAttendeeType } from "main-app/constants";

interface Props extends SVGProps<SVGSVGElement> {
    type: MarkAttendeeType;
    enableBorder?: boolean;
}

const CrossMarkAttendee = (props: Props) => {
    const { width = 36, height = 36, className = "", enableBorder = false, type = "default" } = props;

    const classes = classNames("cross-mark-attendee", {
        [className]: className,
        "with-border": enableBorder,
        default: type === "default",
        marked: type === "marked",
        unmarked: type === "unmarked"
    });

    return (
        <svg
            width={width}
            height={height}
            className={classes}
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect x="0.5" y="0.5" width="35" height="35" rx="17.5" />
            <path d="M21.8098 23.225L22.5169 23.9321L23.9311 22.5179L23.224 21.8108L19.4143 18.001L23.2256 14.1897L23.9328 13.4825L22.5185 12.0683L21.8114 12.7754L18.0001 16.5868L14.1887 12.7754L13.4816 12.0683L12.0674 13.4825L12.7745 14.1897L16.5858 18.001L12.7761 21.8108L12.069 22.5179L13.4832 23.9321L14.1903 23.225L18.0001 19.4152L21.8098 23.225Z" />
        </svg>
    );
};

export { CrossMarkAttendee };
