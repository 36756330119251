import React from "react";
import Button from "main-app/shared/button/Button";
import { ErrorPage } from "./ErrorPage";

type Props = {};

const TestErrorsPage = (props: Props) => {
    const triggerError = () => {
        throw new Error("This is a Sentry test error!");
    };

    return (
        <ErrorPage
            errorContent={
                <div className="d-flex align-items-center justify-content-center">
                    <Button onClick={triggerError}>Test Error</Button>
                </div>
            }
        />
    );
};

export default TestErrorsPage;
