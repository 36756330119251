import React, { Suspense, useDeferredValue, useMemo } from "react";
import classNames from "classnames";
import { Session } from "main-app/models/session";
import { WarningMessage } from "main-app/components/onboarding/components/WarningMessage";

import Pagination from "../Pagination";
import TableHeader from "./TableHeader";
import TableRow from "./TableRow";
import { TableLoader } from "../TableLoader";

interface IProps {
    sessions: Session[];
    loading?: boolean;
    searchValue?: string;
    selectedCohort?: string;
    error: string | null;
    onSort?: (order: boolean) => void;
    totalSessionsCount?: number;
    handlePageChange(page: number): void;
    currentPaginationPage: number;
}

const SessionsTable: React.FC<IProps> = ({
    sessions,
    onSort,
    loading,
    searchValue,
    selectedCohort,
    error,
    totalSessionsCount,
    handlePageChange,
    currentPaginationPage
}) => {
    const noSearchResults = sessions?.length === 0 && searchValue?.length > 0;
    const noSessionsInCohort = sessions?.length === 0 && !loading && !!selectedCohort;
    const noSessions = sessions?.length === 0 && !loading && !noSearchResults && !noSessionsInCohort;

    const tableStatusText = useMemo(() => {
        if (noSearchResults) {
            return "We couldn’t find any results";
        }

        if (noSessionsInCohort) {
            return "You have no upcoming or past coaching sessions scheduled with participants in this cohort";
        }

        if (noSessions) {
            return "You have no upcoming or past coaching sessions scheduled";
        }

        return "";
    }, [noSearchResults, noSessionsInCohort, noSessions]);

    return (
        <Suspense fallback={<TableLoader loading={loading} />}>
            <div className="table-wrapper" data-testid="sessions-table">
                <div
                    className={classNames("table-results-text", {
                        active: (noSearchResults || noSessions || noSessionsInCohort || error) && !loading
                    })}
                >
                    {error ? <WarningMessage message={error} /> : tableStatusText}
                </div>
                <table className="table table-no-head-mobile valign-middle text-left text-lg-center">
                    <TableHeader onSort={onSort} />
                    <tbody>
                        {useDeferredValue(sessions)?.map(session => (
                            <TableRow key={session.sessionTime} session={session} />
                        ))}
                    </tbody>
                </table>
            </div>
            <Pagination
                className="pagination-bar mt-50"
                currentPage={currentPaginationPage}
                totalCount={totalSessionsCount}
                onPageChange={handlePageChange}
            />
        </Suspense>
    );
};

export default SessionsTable;
