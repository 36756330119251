import { useEffect } from "react";
import { initDatadog } from "common/utils/init-datadog";

function useInitDataDog() {
    useEffect(() => {
        initDatadog();
    }, []);
}

export default useInitDataDog;
