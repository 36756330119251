import React, { useEffect, useMemo, useRef, useState } from "react";
import { FormProvider, useFieldArray, useForm, useFormContext } from "react-hook-form";
import { useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import classNames from "classnames";

import useHover from "common/hooks/use-hover";
import { WarningMessage } from "main-app/components/onboarding/components/WarningMessage";
import Button from "main-app/shared/button/Button";
import { BehavioralChange } from "../../model/behavioral-goal";
import { useAuthContext } from "main-app/context/Auth";
import { getErrorMessages } from "common/utils/get-error-messages";
import { isEmptyString, isNullOrUndefined } from "common/utils/gates";
import { useTranslation } from "react-i18next";

import { usePracticeApi } from "../../hooks/use-practice-api";
import { GoalsSortType } from "main-app/shared/types/sort";
import BehavioralPracticeList from "./BehavioralPracticeList";
import { BehavioralPracticeStatus, QueryKey } from "../../consts";

import "./styles.scss";

type Props = {
    behaviorIndex: number;
    sort: GoalsSortType;
    isArchivedBehavioralChange: boolean;
    allowAdd: boolean;
    setHasArchivedPractices: () => void;
};

const BehavioralPractice = ({
    behaviorIndex,
    isArchivedBehavioralChange,
    sort,
    allowAdd,
    setHasArchivedPractices
}: Props) => {
    const { t } = useTranslation();

    const queryClient = useQueryClient();
    const { user } = useAuthContext();
    const params = useParams();
    const userId = !isNullOrUndefined(params?.id) ? +params?.id : user?.id;
    const methods = useForm();
    const { getValues, control } = methods;

    const { getValues: getValuesContext } = useFormContext<{ goals: BehavioralChange[] }>();
    const { id } = getValuesContext(`goals.${behaviorIndex}`);
    const divRef = useRef();
    const hover = useHover(divRef);

    const {
        behavioralPractices,
        isLoadingPractices,
        archivedBehavioralPractices,
        createPractice,
        editPractice,
        archivePractice,
        unarchivePractice,
        sortPractice
    } = usePracticeApi({ userId, sort, behavioralId: id });

    const [errorMsg, setErrorMsg] = useState(null);

    const { append, move, remove, fields, replace } = useFieldArray({
        control,
        name: `practices`,
        keyName: "uuid"
    });

    useEffect(() => {
        if (!isLoadingPractices && behavioralPractices) {
            replace(behavioralPractices);
        }
    }, [behavioralPractices, isLoadingPractices]);

    const hasArchived = useMemo(() => {
        const archived = archivedBehavioralPractices?.length > 0;

        if (archived) {
            setHasArchivedPractices();
        }

        return archived;
    }, [archivedBehavioralPractices]);

    const onAddPractice = () => {
        if (sort === "all" && isArchivedBehavioralChange) {
            return;
        }

        if (sort === "archived" && !allowAdd) {
            return;
        }

        append(
            {
                status: BehavioralPracticeStatus.Queued,
                behavioralChangeId: id,
                id: Date.now(),
                description: "",
                order: fields.length + 1
            },
            { shouldFocus: true }
        );
    };

    const onBlurPractice = async (index: number) => {
        const fields = getValues(`practices`);

        const isEditPractice = fields.length === behavioralPractices.length;

        const { order, description, id, behavioralChangeId, status } = getValues(`practices.${index}`);

        if (isEmptyString(description) && !isEditPractice) {
            remove(index);
            return;
        }

        const practice = {
            order,
            description,
            behavioralChangeId,
            status
        };

        try {
            if (isEditPractice) {
                await editPractice.mutateAsync({ ...practice, id });
                invalidateQueries();
            } else {
                const { data } = await createPractice.mutateAsync(practice);
                makeSort({ oldId: id, newId: data.id });
            }
        } catch (error) {
            setErrorMsg(getErrorMessages(error));
        }
    };

    const onArchivePractice = async (index: number) => {
        const { id: practiceId } = getValues(`practices.${index}`);
        const isArchived = archivedBehavioralPractices.includes(practiceId);

        try {
            if (isArchived) {
                await unarchivePractice.mutateAsync({ behavioralChangeId: id, practiceId });
            } else {
                await archivePractice.mutateAsync({ behavioralChangeId: id, practiceId });
            }
            invalidateQueries();
        } catch (error) {
            setErrorMsg(getErrorMessages(error));
        }
    };

    const onDragPractice = async result => {
        const { source, destination, type } = result;
        if (!destination) return;
        if (type === "practice-goal-drag") {
            move(source.index, destination.index);

            const ids = methods.getValues("practices").map(goal => goal.id);

            try {
                await sortPractice.mutateAsync({ ids, behavioralChangeId: id });
                invalidateQueries();
            } catch (error) {
                setErrorMsg(getErrorMessages(error));
            }
        }
    };

    const makeSort = async ({ oldId, newId }: { oldId: number; newId: number }) => {
        const practices = getValues(`practices`);
        const ids = practices.map(goal => (goal.id === oldId ? newId : goal.id));

        try {
            await sortPractice.mutateAsync({ ids, behavioralChangeId: id });
            invalidateQueries();
        } catch (error) {
            setErrorMsg(getErrorMessages(error));
        }
    };

    const invalidateQueries = () => {
        queryClient.invalidateQueries([QueryKey.BehavioralPractice, { participantId: userId, behavioralChangeId: id }]);
    };

    return (
        <FormProvider {...methods}>
            <div
                className={classNames("behavioral-practice", {
                    "bg-light-accent":
                        (hasArchived || isArchivedBehavioralChange) && (sort === "archived" || sort === "all")
                })}
                ref={divRef}
            >
                <div className="d-flex flex-column flex-md-row align-items-start">
                    <Button
                        variant="default"
                        className={classNames("p-0 mb-1 font-extrabold practice-btn-add", {
                            "color-dark-gray": sort === "archived" || isArchivedBehavioralChange,
                            "color-brand": sort !== "archived"
                        })}
                        onClick={onAddPractice}
                    >
                        <div
                            className={classNames("behavioral-practice__plus-ico mr-1", {
                                red: !isArchivedBehavioralChange
                            })}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9" fill="none">
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M3.87503 5.12503V8.5H5.12503V5.12503H8.5V3.87503H5.12503V0.5L3.87503 0.5V3.87503H0.5V5.12503H3.87503Z"
                                    fill="white"
                                />
                            </svg>
                        </div>
                        {t("Add practice")}
                    </Button>
                </div>
                <WarningMessage message={errorMsg} />
                <BehavioralPracticeList
                    sort={sort}
                    showDrag={hover}
                    practices={fields}
                    archivedPractices={archivedBehavioralPractices}
                    remove={remove}
                    onBlurPractice={onBlurPractice}
                    onArchiveClick={onArchivePractice}
                    onDragEnd={onDragPractice}
                />
            </div>
        </FormProvider>
    );
};

export default BehavioralPractice;
