import React, { useState } from "react";

import LikertRadio from "main-app/shared/likert/LikertRadio";
import { WarningMessage } from "main-app/components/onboarding/components/WarningMessage";
import { useWatch } from "react-hook-form";
import getColor from "main-app/utils/get-color";
import QuestionRow from "./QuestionRow";
import DiagnosticPreviousAnswer from "./DiagnosticPreviousAnswer";
import { withTranslation } from "common/utils/lang";

import "./styles.scss";

interface IProps {
    questionOrder: number;
    item: any; // TODO add typings
    error: any;
    register: any;
    control: any;
    name: string;
    previosAnswer?: any;
    colors?: any[];
    type?: "blank" | "gray" | "brand" | "colored";
}

const DiagnosticQuestionOffboarding: React.FC<IProps> = ({
    questionOrder,
    item,
    register,
    name,
    error,
    control,
    previosAnswer,
    type = "blank"
}) => {
    const checked = useWatch({ name });
    const [hoveredId, setHoveredId] = useState(null);

    const likertColor = (index: number) => {
        if (type !== "colored") return "";
        return getColor(index, item.color_range);
    };

    return (
        <div className="diagnostic-question-offboarding mb-40">
            <QuestionRow
                question={withTranslation(item?.question)}
                order={questionOrder}
                isRequired={item.response_required}
                className="pl-3"
            />
            <DiagnosticPreviousAnswer previosAnswer={previosAnswer} />
            <WarningMessage message={error} />
            <div className={`mb-4 level-choice level-choice--${type} d-flex justify-content-center`}>
                {item.options?.map((checkbox, index, options) => (
                    <LikertRadio
                        name={name}
                        value={checkbox.uuid}
                        hoveredId={hoveredId}
                        setHovered={setHoveredId}
                        showHover
                        id={checkbox.uuid}
                        control={control}
                        label={withTranslation(checkbox?.response)}
                        isFirstOption={index === 0}
                        isLastOption={index === options.length - 1}
                        register={register}
                        key={checkbox.uuid}
                        color={likertColor(index + 1)}
                        checked={checked}
                    />
                ))}
            </div>
        </div>
    );
};

export default DiagnosticQuestionOffboarding;
