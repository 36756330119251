import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";

import { JS_CONF } from "main-app/constants";

const botPatterns = "[pP]ingdom";

const botRegex = new RegExp(botPatterns, "i");

const isPingdomBot = botRegex.test(navigator.userAgent);

export const initDatadog = () => {
    if (!isPingdomBot) {
        datadogRum.init({
            applicationId: JS_CONF.datadog_application_id,
            clientToken: JS_CONF.datadog_client_token,
            site: JS_CONF.datadog_site,
            service: JS_CONF.datadog_service,
            env: JS_CONF.datadog_env,
            sessionReplaySampleRate: 100,
            trackResources: true,
            trackLongTasks: true,
            defaultPrivacyLevel: "mask-user-input",
            version: JSAPP_CONF?.static ?? ""
        });

        datadogLogs.init({
            clientToken: JS_CONF.datadog_client_token,
            site: JS_CONF.datadog_site,
            forwardErrorsToLogs: true,
            sessionSampleRate: 100,
            forwardConsoleLogs: "all",
            forwardReports: "all"
        });

        datadogRum.startSessionReplayRecording();
        console.log("session started");
    }
};
