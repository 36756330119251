import React, { memo, useEffect, useMemo, useRef } from "react";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { useAuthContext } from "main-app/context/Auth";
import { useStoreContext } from "main-app/context/GlobalStore";
import Sidebar from "main-app/shared/sidebar/Sidebar";
import {
    CARE_TEAM_EMAIL,
    ConfigKeys,
    MAX_WIDTH_MOBILE_MEDIA,
    ParticipantUrls,
    SessionScheduleStatus
} from "main-app/constants";
import useMediaQuery from "common/hooks/use-media-query";
import useCoachingSessions from "main-app/api/use-coaching-sessions";
import LiveNowSvg from "main-app/svgs/LiveNowSvg";
import useSpotCoaching from "main-app/api/use-spot-coaching";
import ProductTypeLogo from "main-app/shared/ProductTypeLogo";
import { OnboardingSteps } from "../onboarding/constants";
import useOnboardingSteps from "main-app/api/use-onboarding-steps";
import { CohortModalities } from "main-app/models/types";
import { isIndidualCoachSession } from "main-app/utils/gates/session";
import { ModuleNavigation } from "main-app/entities/module-navigation";
import Avatar from "main-app/shared/avatar/Avatar";
import { BetaStatusTag } from "main-app/shared/tags/BetaStatusTag";

interface IProps {
    closedMenu?: boolean;
    integrated?: boolean;
}

const LeftSidebar: React.FC<IProps> = ({ closedMenu = false, integrated = false }) => {
    const { t } = useTranslation();
    const { logout, user } = useAuthContext();
    const navigate = useNavigate();
    const { toggleBurgerMenu, isOpenBurgerMenu, getConfig } = useStoreContext();
    const isMobileView = useMediaQuery(MAX_WIDTH_MOBILE_MEDIA);
    const { pathname } = useLocation();
    const { data: sessions } = useCoachingSessions();
    const { data: productType } = useSpotCoaching();
    const { data: steps } = useOnboardingSteps();

    const handleLogout = async e => {
        e.preventDefault();

        logout();
    };

    const sidebarTogglerButtonRef = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        integrated && closedMenu && setTimeout(() => sidebarTogglerButtonRef?.current?.click(), 0);
    }, [integrated, closedMenu]);

    const notScheduledSessions = useMemo(() => {
        return (
            sessions?.upcoming_sessions.filter(
                session =>
                    !session.session_time &&
                    session.event_status === SessionScheduleStatus.NOT_SCHEDULED &&
                    (session.cohort_modality === CohortModalities.Individual || isIndidualCoachSession(session))
            ) ?? []
        );
    }, [sessions]);

    const handleNavigation = (e, path: string) => {
        e.preventDefault();

        if (isOpenBurgerMenu && isMobileView) {
            toggleBurgerMenu();
        }

        navigate(path);
    };

    const hasDiagnosticeStep = steps?.previousSteps?.some(
        step => step === OnboardingSteps.DIAGNOSTIC_STEP || step === OnboardingSteps.DIAGNOSTIC_RESPONSES_STEP
    );

    const showDashboard = getConfig(ConfigKeys.showDashboard);

    return (
        <Sidebar
            sidebarLogo={<ProductTypeLogo />}
            closedMenu={closedMenu}
            sidebarTogglerButtonRef={sidebarTogglerButtonRef}
        >
            {isMobileView && (
                <Avatar url={user?.photo} circleFillColor="#FFFFFF" width={45} height={45} className="mb-4 mx-auto" />
            )}
            {showDashboard && <ModuleNavigation className="mt-30" />}
            <ul className="menu-capitalized no-margin">
                <li className={classNames({ active: pathname.includes(ParticipantUrls.SESSION_LIST) })}>
                    <a href="#" onClick={e => handleNavigation(e, ParticipantUrls.SESSION_LIST)}>
                        <span className={classNames({ "mr-2": sessions?.live_session || notScheduledSessions.length })}>
                            {productType?.sessionPageName}
                        </span>
                        {notScheduledSessions.length > 0 && !sessions?.live_session && <span className="red-dot" />}
                        {sessions?.live_session && <LiveNowSvg width={22} height={18} />}
                    </a>
                </li>

                {hasDiagnosticeStep && (
                    <li className={classNames({ active: pathname.includes(ParticipantUrls.DIAGNOSTIC) })}>
                        <a href="#" onClick={e => handleNavigation(e, ParticipantUrls.DIAGNOSTIC)}>
                            <span>{t("Diagnostic & Goals")}</span>
                        </a>
                    </li>
                )}

                {isMobileView && (
                    <li className={classNames({ active: pathname.includes(ParticipantUrls.PROFILE) })}>
                        <a href="#" onClick={e => handleNavigation(e, ParticipantUrls.PROFILE)}>
                            <span>{t("Profile") + " & " + t("Settings")}</span>
                        </a>
                    </li>
                )}

                {user?.availableCoachingPlan && (
                    <li className={classNames({ active: pathname.includes(ParticipantUrls.COACHING_PLAN) })}>
                        <a href="#" onClick={e => handleNavigation(e, ParticipantUrls.COACHING_PLAN)}>
                            <span>{t("Coaching Plan")}</span>
                        </a>
                    </li>
                )}

                {user?.practiceLab && (
                    <li
                        className={classNames({
                            active:
                                matchPath(ParticipantUrls.COACHEES, pathname) ||
                                matchPath(ParticipantUrls.SIMULAB_CHAT, pathname)
                        })}
                    >
                        <a href={`#`} onClick={e => handleNavigation(e, ParticipantUrls.COACHEES)}>
                            <span className="d-flex align-items-center justify-content-center justify-content-md-start">
                                {t("Wiser SimuLab")} <BetaStatusTag className="ml-2" size="s" />
                            </span>
                        </a>
                    </li>
                )}

                <li>
                    <a href={`mailto:${productType?.supportEmail}` || CARE_TEAM_EMAIL}>
                        <span>
                            {t("Contact")} {productType?.supportTeamName}
                        </span>
                    </a>
                </li>
                <li className="d-md-none">
                    <a href="#" onClick={handleLogout}>
                        <span>{t("Sign out")}</span>
                    </a>
                </li>
            </ul>
        </Sidebar>
    );
};

export default memo(LeftSidebar);
