import React, { useState } from "react";

interface IProps {
    onSortByName: (order: boolean) => void;
    onSortByModule: (order: boolean) => void;
}

const TableHeader: React.FC<IProps> = ({ onSortByName, onSortByModule }) => {
    const [sortName, setSortName] = useState(false);
    const [sortModule, setSortModule] = useState(false);

    const handleSort = (sortKey: "module" | "name") => {
        if (sortKey === "name") {
            setSortName(active => !active);
            onSortByName(sortName);
        } else {
            setSortModule(active => !active);
            onSortByModule(sortModule);
        }
    };

    return (
        <thead>
            <tr>
                <th className="sortable sort-desc text-left pl-0">
                    <span className="cursor-pointer" onClick={() => handleSort("name")} data-testid="sort-name">
                        <span>Participant Name</span>
                        <svg className="svg-16 ml-2">
                            {sortName ? (
                                <path d="m 1,9.2071068 7,-7 7,7" fill="none" stroke="currentColor" strokeWidth="2" />
                            ) : (
                                <path d="M1 1.5L8 8.5L15 1.5" fill="none" stroke="currentColor" strokeWidth="2" />
                            )}
                        </svg>
                    </span>
                </th>
                <th className="d-none d-lg-table-cell">
                    <span>Cohort</span>
                </th>
                <th className="">
                    <span>Next Meeting</span>
                </th>
                <th className="d-none d-lg-table-cell sortable sort-inc">
                    <span className="cursor-pointer" onClick={() => handleSort("module")} data-testid="sort-module">
                        <span>Current Module</span>
                        <svg className="svg-16 ml-2">
                            {sortModule ? (
                                <path d="m 1,9.2071068 7,-7 7,7" fill="none" stroke="currentColor" strokeWidth="2" />
                            ) : (
                                <path d="M1 1.5L8 8.5L15 1.5" fill="none" stroke="currentColor" strokeWidth="2" />
                            )}
                        </svg>
                    </span>
                </th>
                {/* <th className="">
                    <span>Status</span>
                </th> */}
            </tr>
        </thead>
    );
};

export default TableHeader;
