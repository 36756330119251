import React from "react";
import { useTranslation } from "react-i18next";

import Heading from "common/components/Heading/Heading";
import Modal from "main-app/shared/modal";

import Button from "main-app/shared/button/Button";

import "./styles.scss";

type Props = {
    isOpen: boolean;
    initialText: string;
    onClose: () => void;
    onSubmit: () => void;
};

export const BehavioralDeleteModal = ({ isOpen, initialText, onClose, onSubmit }: Props) => {
    const { t } = useTranslation();

    return (
        <Modal
            onClose={onClose}
            show={isOpen}
            disableBodyScroll
            enableOutSideClick={false}
            closeOnEscapePress={false}
            headerContent={
                <Heading className="text-center mb-0 p-3" fontSize={24}>
                    {t("Delete Entry?")}
                </Heading>
            }
            modalBodyClass="behavioral-delete-modal"
        >
            <div className="behavioral-delete-modal__body text-center">
                <p className="mb-1 font-extrabold">{t("Warning")}:</p>
                <p className="text-center mb-2">{t("Are you sure you want to delete your previous entry?")}</p>
                <p className="color-gray font-italic">"{initialText}"</p>

                <div className="text-center mb-2">
                    <Button onClick={onSubmit}>{t("Delete")}</Button>
                </div>

                <div className="text-center">
                    <Button variant="default" className="color-brand font-extrabold fs-14" onClick={onClose}>
                        {t("No, keep my entry.")}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};
