import classNames from "classnames";
import Button from "main-app/shared/button/Button";
import React, { ReactNode } from "react";

type Props = {
    className?: string;
    disabled?: boolean;
    children: ReactNode;
    onClick: () => void;
};
export const BehavioralButton = ({ className, disabled = false, children, onClick }: Props) => {
    return (
        <Button variant="default" className={className} onClick={onClick} disabled={disabled}>
            <div className={classNames("behavioral-btn__details d-flex align-items-center", { hover: !disabled })}>
                <div className={classNames("ico ico_plus fs-14", { active: !disabled })}>+</div>
                <span className="d-inline-block ml-1 fs-14">{children}</span>
            </div>
        </Button>
    );
};
