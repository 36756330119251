import React from "react";
import classNames from "classnames";
import { DateTime } from "luxon";

import { withTranslation } from "common/utils/lang";
import "./styles.scss";

interface IProps {
    columns: any[];
    rows: any[];
}

const TrackPracticeColumns: React.FC<IProps> = ({ columns, rows }) => {
    const date = DateTime.now().toFormat("LLL. dd, yyyy");

    return (
        <div className="track-practice-response">
            <div
                className={classNames("track-practice-response-header", {
                    two: columns.length === 2,
                    three: columns.length === 3
                })}
            >
                {columns?.map(col => (
                    <div key={col.uuid}>
                        <h4 className="h4 font-extrabold text-center">{withTranslation(col.title)}</h4>
                        <p className="h4 text-center p_none">{withTranslation(col?.subtitle)}</p>
                    </div>
                ))}
            </div>
            <div className="track-practice-response-rows">
                {rows?.map((row, index) => (
                    <div
                        key={index}
                        className={classNames("track-practice-response-row", {
                            one: columns.length === 1,
                            two: columns.length === 2,
                            three: columns.length === 3
                        })}
                    >
                        {row?.columns?.map(col => (
                            <div className="track-practice-response-box" key={col.uuid}>
                                <div className="track-practice-response-box-item">
                                    <div className="track-practice-response-box-header">{date}</div>
                                    <div className="track-practice-response-box-input-container">
                                        <textarea
                                            rows={col?.answer.length > 220 ? 7 : 4}
                                            disabled={true}
                                            value={col?.answer}
                                        />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default TrackPracticeColumns;
