import React, { useEffect } from "react";
import ContentLoader from "react-content-loader";
import useDiagnosticResults from "../api/use-diagnostic-results";
import SummaryResult from "main-app/elements/summary-result/SummaryResult";
import Button from "main-app/shared/button/Button";
import { useTranslation } from "react-i18next";

type Props = {
    showTooltip?: boolean;
    onTooltipClick?: (category) => void;
    onOpenFullDiagnostic?: () => void;
};

const DiagnosticResults = ({ showTooltip = false, onTooltipClick, onOpenFullDiagnostic }: Props) => {
    const { t } = useTranslation();
    const { data: diagnosticSummary, isLoading, refetch } = useDiagnosticResults();

    useEffect(() => {
        refetch();
    }, []);

    if (isLoading) {
        return (
            <>
                {Array(3)
                    .fill("")
                    .map((_, index) => (
                        <div className="w-100 mb-20" style={{ height: 350 }} key={index}>
                            <ContentLoader
                                speed={3}
                                height={350}
                                width={"100%"}
                                backgroundColor="#f3f3f3"
                                foregroundColor="#ecebeb"
                            >
                                <rect x="7" y="14" rx="0" ry="0" width="100%" height="100%" />
                                <rect x="122" y="71" rx="0" ry="0" width="0" height="1" />
                            </ContentLoader>
                        </div>
                    ))}
            </>
        );
    }

    const hasDiagnosticSummary = diagnosticSummary?.summary?.length > 0;

    return (
        <>
            {diagnosticSummary?.summary?.map((summaryItem, index, array) => (
                <SummaryResult
                    key={summaryItem.category.id}
                    summary={summaryItem}
                    className={`${array?.length - 1 === index ? "mb-1" : "mb-3"}`}
                    showQuestionTooltip={showTooltip}
                    onTooltipClick={onTooltipClick}
                />
            ))}
            {hasDiagnosticSummary && (
                <Button variant="default" className="p-1 font-weight-light fs-14" onClick={onOpenFullDiagnostic}>
                    <span className="color-brand">{t("View full diagnostic")}</span>
                </Button>
            )}
        </>
    );
};

export default DiagnosticResults;
