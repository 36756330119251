import React, { type FC, type ReactElement } from "react";

import { WEEK_RANGE_SEPARATOR } from "../utils";
import { GuestParticipantBadge as Badge } from ".";

type TGuestParticipantAccordionHeadingProps = {
    title: string;
    isPreferredWeek: boolean;
};

const GuestParticipantAccordionHeading: FC<TGuestParticipantAccordionHeadingProps> = ({
    title,
    isPreferredWeek
}: TGuestParticipantAccordionHeadingProps): ReactElement => (
    <div className="swap-session__accordion__heading">
        <p className="m-0 font-extrabold">
            <span className="swap-session__accordion__title">{title.split(WEEK_RANGE_SEPARATOR)?.[0]}</span>
            {WEEK_RANGE_SEPARATOR}
            <span className="swap-session__accordion__title">{title.split(WEEK_RANGE_SEPARATOR)?.[1]}</span>
        </p>
        <Badge isVisible={isPreferredWeek} />
    </div>
);

export { GuestParticipantAccordionHeading, type TGuestParticipantAccordionHeadingProps };
