import React, { forwardRef, useEffect, useState } from "react";
import * as CronofyElements from "cronofy-elements";
import { CRONOFY_WIDGET } from "common/constants";

type Props = {
    options: any;
    id: string;
    widget: (typeof CRONOFY_WIDGET)[keyof typeof CRONOFY_WIDGET];
};

const CronofyWidget = forwardRef(({ options, id, widget }: Props, ref) => {
    const [element, setElement] = useState(null);

    useEffect(() => {
        if (!element) {
            const widgetElement = CronofyElements[widget]({ ...options, target_id: id });
            setElement(widgetElement);
        }
    }, []);

    useEffect(() => {
        if (element) {
            element.update({ ...options, target_id: id });
        }
    }, [options, id]);

    return <div id={id} ref={ref as React.RefObject<HTMLDivElement>} />;
});

export default CronofyWidget;
