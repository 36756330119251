import React from "react";
import classNames from "classnames";
import { AttentionSvg } from "main-app/svgs";

import "./styles.scss";

interface IProps {
    register: any;
    onFocus?: () => void;
    placeholder?: string;
    name: string;
    onBlur?: () => void;
    className?: string;
    hasFocus?: boolean;
    rows?: number;
    variant?: "rect";
    errors?: any;
}

const TextArea: React.FC<IProps> = ({
    register,
    name,
    placeholder,
    hasFocus = false,
    rows = 4,
    variant = "",
    className = "",
    errors,
    onFocus,
    onBlur
}) => {
    const errorMessages = errors?.[name];
    const hasError = !!(errors && errorMessages);

    const inputVariants = {
        rect: "textarea-rect"
    };

    return (
        <>
            <textarea
                {...(register && register(name))}
                name={name}
                className={classNames(className, inputVariants[variant])}
                onFocus={onFocus}
                rows={rows}
                onBlur={onBlur}
                autoFocus={hasFocus}
                placeholder={placeholder || ""}
            />
            {hasError && (
                <div className="input-error">
                    <AttentionSvg />
                    <span>{errors[name]?.message}</span>
                </div>
            )}
        </>
    );
};

export default TextArea;
