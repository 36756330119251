import React from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { v4 as uuid } from "uuid";

import { MAX_WIDTH_MOBILE_MEDIA } from "main-app/constants";
import useMediaQuery from "common/hooks/use-media-query";

import TrackPracticeMobile from "./TrackPracticeMobile";
import TrackPracticeModal from "./TrackPracticeModal";

interface IProps {
    defaultFormValues: any;
    component: any;
    navBarTitle: string;
    onClose: () => void;
    show: boolean;
    phaseName: string;
    onSubmit: (values: any) => void;
}

const TrackPracticeForm: React.FC<IProps> = ({
    defaultFormValues,
    component,
    onClose,
    show,
    onSubmit,
    phaseName,
    navBarTitle
}) => {
    const isMobile = useMediaQuery(MAX_WIDTH_MOBILE_MEDIA);

    const methods = useForm({ defaultValues: defaultFormValues });
    const { control, handleSubmit } = methods;

    const { fields, append, remove } = useFieldArray({
        control,
        name: "rows",
        keyName: "uuid"
    });

    const addRows = () => {
        append(
            {
                uuid: uuid(),
                columns: component?.columns?.map(col => ({
                    answer: "",
                    column_uuid: col.uuid,
                    title: col.title.en,
                    subtitle: col.subtitle.en,
                    typing_prompt: col.typing_prompt.en
                }))
            },
            { shouldFocus: false }
        );
    };

    const onDeleteRow = index => {
        remove(index);
    };

    return (
        <FormProvider {...methods}>
            {isMobile ? (
                <TrackPracticeMobile
                    show={show}
                    onDeleteRow={onDeleteRow}
                    component={component}
                    handleSubmit={handleSubmit}
                    phaseName={phaseName}
                    addRows={addRows}
                    navBarTitle={navBarTitle}
                    fields={fields}
                    onSubmit={onSubmit}
                />
            ) : (
                <TrackPracticeModal
                    show={show}
                    onDeleteRow={onDeleteRow}
                    onClose={onClose}
                    component={component}
                    handleSubmit={handleSubmit}
                    addRows={addRows}
                    fields={fields}
                    phaseName={phaseName}
                    onSubmit={onSubmit}
                />
            )}
        </FormProvider>
    );
};

export default TrackPracticeForm;
