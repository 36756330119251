import React from "react";
import "./styles.scss";

interface IProps {
    firstName: string;
    lastName: string;
    width?: number;
    height?: number;
}

const ProfileImageLetter: React.FC<IProps> = ({ firstName, lastName, width = 50, height = 50 }) => {
    return (
        <div className="profile-image-letters" style={{ width, height }} title={`${firstName} ${lastName}`}>
            <span className="profile-image-letters-text">
                {firstName?.charAt(0)?.toLocaleUpperCase()}
                {lastName?.charAt(0).toLocaleUpperCase()}
            </span>
        </div>
    );
};

export default ProfileImageLetter;
