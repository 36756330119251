import React from "react";
import classNames from "classnames";

import AttentionSvg from "main-app/svgs/AttentionSvg";

export type Props = {
    message: string;
    className?: string;
    align?: "center" | "start" | "end";
    containerClassname?: string;
};
export const WarningMessage = ({ message, className = "", containerClassname = "", align = "center" }: Props) => {
    const alignmentClasses = {
        center: "justify-content-center",
        left: "justify-content-start",
        right: "justify-content-end"
    };

    return message ? (
        <div className={classNames("form-has-error", containerClassname)}>
            <div className={classNames("input-error", className, alignmentClasses[align])}>
                <AttentionSvg />
                <span className="d-inline-block">{message}</span>
            </div>
        </div>
    ) : null;
};
