import React, { forwardRef } from "react";
import classNames from "classnames";
import { Option } from "common/models/option";

import "./styles.scss";

type Props = {
    items: Option[];
    className?: string;
    styles?: React.CSSProperties;
    onClick: (item: Option) => void;
};

const ContextMenu = forwardRef<HTMLUListElement, Props>((props, ref) => {
    const { items, className, styles, onClick } = props;

    return (
        <ul className={classNames("context-menu", className)} ref={ref} style={styles}>
            {items.map(item => (
                <li onClick={() => onClick(item)} key={item?.id ?? item.label}>
                    {item.label}
                </li>
            ))}
        </ul>
    );
});

export default ContextMenu;
