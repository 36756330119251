import React from "react";
import { useTranslation } from "react-i18next";

interface IProps {
    height?: number;
    width?: number;
    circleFillColor?: string;
    isActive?: boolean;
}

const UserDefaultAvatar: React.FC<IProps> = ({
    height = 37,
    width = 37,
    circleFillColor = "#F3F3F3",
    isActive = false
}) => {
    const { t } = useTranslation();
    return (
        <svg width={width} height={height} viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
            <title>{t("User default avatar")}</title>
            <circle cx="18.5" cy="18.5001" r="18.5" fill={isActive ? "#FFFFFF" : circleFillColor} />
            <mask
                id="mask0_1389_1939"
                style={{ maskType: "alpha" }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="37"
                height="38"
            >
                <circle cx="18.5" cy="18.5001" r="18.5" fill="#F3F3F3" />
            </mask>
            <g mask="url(#mask0_1389_1939)">
                <circle cx="19" cy="15.0001" r="6" fill={isActive ? "#000000" : "#6F6F6F"} />
                <circle cx="19" cy="34.0001" r="12" fill={isActive ? "#000000" : "#6F6F6F"} />
            </g>
        </svg>
    );
};

export default UserDefaultAvatar;
