import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";

import { isEmptyString, isNullOrUndefined } from "common/utils/gates";
import AttentionSvg from "main-app/svgs/AttentionSvg";
import { LINE_BREAK_REGEX } from "common/constants";

import "./styles.scss";

type IProps = {
    className?: string;
    groupClassName?: string;
    type?: string;
    name: string;
    errors?: any;
    rules?: any;
    register: any;
    size?: number;
    variant?: "default" | "rect" | "rect-no-border";
    withAutoSize?: boolean;
    prefilled?: boolean;
    Icon?: React.ReactNode;
    isError?: boolean;
    genericError?: any;
    textArea?: boolean;
    dynamicHeight?: boolean;
    enableLineBreak?: boolean;
    errorClass?: string;
    maxHeight?: number;
    customPlaceholder?: React.ReactNode;
    preventEnter?: boolean;
    autoFocus?: boolean;
    currentValue?: string;
    id?: string | number;
} & React.HTMLProps<HTMLInputElement | HTMLTextAreaElement>;

const TextInput: React.FC<IProps> = ({
    name,
    type = "text",
    errors,
    className,
    groupClassName,
    register,
    rules,
    variant = "default",
    children = null,
    withAutoSize = false,
    prefilled,
    isError = false,
    textArea = false,
    preventEnter = false,
    rows = 1,
    genericError,
    currentValue,
    Icon,
    dynamicHeight = false,
    enableLineBreak = false,
    maxHeight,
    errorClass = "",
    customPlaceholder = null,
    autoFocus,
    placeholder,
    id = name,
    onBlur,
    onKeyDown,
    ...rest
}) => {
    const hasCustomPlaceholder = !isNullOrUndefined(customPlaceholder);
    const [showCustomPlaceHolder, setShowCustomPlaceHolder] = useState(hasCustomPlaceholder);
    const inputRef = useRef<HTMLTextAreaElement | HTMLInputElement>();
    const errorMessages = genericError || errors?.[name];
    const hasError = genericError || !!(errors && errorMessages);

    const { ref, ...restRegister } = register && register(name, rules);

    const variants = {
        default: "text-input",
        rect: "input-rect",
        ["rect-no-border"]: "input-rect no-border"
    };

    useEffect(() => {
        if (inputRef.current && textArea && dynamicHeight) {
            handleDynamicHeight();
        }

        if (hasCustomPlaceholder) {
            showPlaceholderHandler(inputRef.current.value);
        }

        if (autoFocus) {
            inputRef?.current?.focus();
        }
    }, [inputRef, textArea, dynamicHeight, hasCustomPlaceholder, autoFocus, maxHeight, currentValue]);

    const onInput = e => {
        if (hasCustomPlaceholder) {
            showPlaceholderHandler(e.target.value);
        }
    };

    const onKeyDownHandler = e => {
        const value = e.nativeEvent.target.value;
        const hasEmptySpaces = LINE_BREAK_REGEX.test(value);

        if (preventEnter && e.key === "Enter") {
            e.preventDefault();
            onKeyDown?.(e);
            return;
        }

        if (e.key === "Enter" && (hasEmptySpaces || isEmptyString(value)) && !e.shiftKey) {
            e.preventDefault();
            e.target.value = "";
            onKeyDown?.(e);
            return;
        }

        if (dynamicHeight && textArea) {
            handleDynamicHeight();
        }

        onKeyDown?.(e);
    };

    const handleDynamicHeight = () => {
        const initialScroll = inputRef.current.scrollHeight;
        if (!isNullOrUndefined(maxHeight) && initialScroll > maxHeight) {
            return;
        }

        inputRef.current.style.height = "0px";
        const scrollHeight = inputRef.current.scrollHeight;
        inputRef.current.style.height = scrollHeight + "px";
    };

    const showPlaceholderHandler = (value: string) => {
        setShowCustomPlaceHolder(isEmptyString(value));
    };

    return (
        <div
            className={classNames("form-group position-relative", { "has-error": hasError || isError }, groupClassName)}
        >
            <div className="position-relative">
                <label htmlFor={id || name} className="w-100 d-none">
                    {name}
                </label>
                {textArea ? (
                    <textarea
                        {...restRegister}
                        name={name}
                        ref={e => {
                            ref(e);
                            inputRef.current = e;
                        }}
                        rows={rows}
                        id={id}
                        aria-label={!customPlaceholder ? placeholder : name}
                        onBlur={onBlur}
                        className={classNames(className, variants[variant], { prefilled: prefilled })}
                        onKeyDown={onKeyDownHandler}
                        onInput={onInput}
                        autoFocus={autoFocus}
                        placeholder={!customPlaceholder ? placeholder : ""}
                        {...rest}
                    />
                ) : (
                    <input
                        type={type}
                        {...restRegister}
                        ref={e => {
                            ref(e);
                            inputRef.current = e;
                        }}
                        name={name}
                        id={id}
                        onBlur={onBlur}
                        aria-label={name}
                        onKeyDown={onKeyDownHandler}
                        onInput={onInput}
                        placeholder={!customPlaceholder ? placeholder : ""}
                        className={classNames(className, variants[variant], { prefilled: prefilled })}
                        autoFocus={autoFocus}
                        {...rest}
                    />
                )}
                {customPlaceholder && showCustomPlaceHolder && (
                    <div className="input-custom-placeholder">{customPlaceholder}</div>
                )}
            </div>
            <div className="input-custom-icon">{Icon}</div>
            {children}
            {hasError && (
                <div className={classNames("input-error", errorClass)} role="alert">
                    <AttentionSvg />
                    <span>{genericError || errors[name]?.message}</span>
                </div>
            )}
        </div>
    );
};

export default TextInput;
