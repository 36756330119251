import React from "react";

type Props = {
    className?: string;
    width?: number;
    height?: number;
};

const PhaseCompleted = (props: Props) => {
    const { className = "", width = 24, height = 24 } = props;

    return (
        <svg
            className={className}
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="24" height="24" rx="12" fill="black" />
            <path
                d="M7.36963 12.1284L10.1993 15.2153L16.6305 8.78418"
                stroke="white"
                strokeWidth="2"
                stroke-linecap="square"
            />
        </svg>
    );
};

export default PhaseCompleted;
