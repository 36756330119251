import { TranslationObj } from "common/utils/lang";

export type GroupedModule = {
    category: string;
    categoryDescription: TranslationObj;
    modules: Module[];
};

interface ModuleApi {
    id: number;
    category: string;
    category_description: TranslationObj;
    module_description: TranslationObj;
    version: TranslationObj;
    is_completed: boolean;
    is_assigned: boolean;
    title_pref_lang: string;
    category_description_pref_lang: string;
    module_description_pref_lang: string;
    version_pref_lang: string;
}

export class Module {
    id: number;
    category: string;
    categoryDescription: TranslationObj;
    moduleDescription: TranslationObj;
    version: TranslationObj;
    isCompleted: boolean;
    isAssigned: boolean;
    titlePrefLang: string;
    categoryDescriptionPrefLang: string;
    moduleDescriptionPrefLang: string;
    versionPrefLang: string;

    constructor(data: ModuleApi) {
        this.id = data.id;
        this.category = data.category || "";
        this.categoryDescription = data.category_description || {};
        this.moduleDescription = data.module_description || {};
        this.version = data.version || {};
        this.isCompleted = data.is_completed || false;
        this.isAssigned = data.is_assigned || false;
        this.titlePrefLang = data.title_pref_lang || "";
        this.categoryDescriptionPrefLang = data.category_description_pref_lang || "";
        this.moduleDescriptionPrefLang = data.module_description_pref_lang || "";
        this.versionPrefLang = data.version_pref_lang || "";
    }
}
