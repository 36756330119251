import { CohortModule, CohortModuleApi } from "./module";
import { Modality } from "./types";

interface CohortApi {
    id: number;
    name: string;
    slug?: string;
}

interface GroupCohortApi extends CohortApi {
    modality: Modality;
    modules: CohortModuleApi[];
    journey_length: number;
}

class Cohort {
    id: number;
    name: string;
    slug?: string;

    constructor(data: CohortApi) {
        this.id = data.id;
        this.name = data?.name || "";
        this.slug = data?.slug || "";
    }
}

class GroupCohort extends Cohort {
    modality: Modality;
    modules: CohortModule[];
    journeyLength: number;

    constructor(data: GroupCohortApi) {
        super(data);
        this.modality = data?.modality;
        this.modules = data?.modules?.map(module => new CohortModule(module)) ?? [];
        this.journeyLength = data?.journey_length;
    }
}

export { Cohort, GroupCohort, GroupCohortApi, CohortApi };
