import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import ParticipantLayout from "main-app/layouts/ParticipantLayout";
import LeftSidebar from "main-app/components/dashboard/LeftSidebar";
import useMediaQuery from "common/hooks/use-media-query";
import Heading from "common/components/Heading/Heading";
import { GoalsWrapper } from "./ui/GoalsWrapper";
import { OldBeliefGoal } from "main-app/entities/old-belief-goal";
import CoachingGoal from "main-app/widgets/coaching-goal/ui/CoachingGoal";
import { useAuthContext } from "main-app/context/Auth";
import { MAX_WIDTH_MOBILE_MEDIA } from "main-app/constants";
import { BehavioralGoals } from "main-app/widgets/behavioral-goal/ui/BehavioralGoal";

import "./ui/styles.scss";

type Props = {};

export const CoachingPlanPage = (props: Props) => {
    const { t } = useTranslation();

    const { user } = useAuthContext();
    const isMobile = useMediaQuery(MAX_WIDTH_MOBILE_MEDIA);
    const [isToggledOldBelief, setIsToggledOldBelief] = useState(isMobile);

    if (!user?.availableCoachingPlan) {
        return <Navigate to="/" replace />;
    }

    return (
        <ParticipantLayout
            leftSidebar={<LeftSidebar />}
            className="goals-layout"
            title={
                <Heading textAlign="center" fontSize={36} className="mb-0">
                    {t("Coaching Plan")}
                </Heading>
            }
        >
            <GoalsWrapper>
                <div className={classNames("goals-grid", { toggled: isToggledOldBelief })}>
                    <section className={classNames("goals-main", { expanded: isToggledOldBelief })}>
                        <CoachingGoal />
                        <div className="mb-50" />
                        <BehavioralGoals />
                    </section>

                    <aside className={classNames("goal-aside", { toggled: isToggledOldBelief })}>
                        <OldBeliefGoal
                            toggle={() => setIsToggledOldBelief(!isToggledOldBelief)}
                            isToggled={isToggledOldBelief}
                        />
                    </aside>
                </div>
            </GoalsWrapper>
        </ParticipantLayout>
    );
};
