import { useCallback } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { http } from "common/http";
import { CoachingGoalStatus } from "main-app/constants";
import Urls from "main-app/api/urls";

import { CoachingGoalApi, CoachingGoalModel } from "../model/types/coaching-goal";

export enum QueryKey {
    CoachingGoal = "coachingGoal"
}

export const getCoachingGoal = async ({ queryKey }): Promise<CoachingGoalApi[]> => {
    const [_key, { id, query }] = queryKey;
    const { data } = await http.get(Urls.coachingGoal(id, query));
    return data;
};

export function useCoachingGoalData(participantId: number, query: CoachingGoalStatus) {
    return useQuery<CoachingGoalApi[], AxiosError, CoachingGoalModel[]>(
        [QueryKey.CoachingGoal, { query, id: participantId }],
        getCoachingGoal,
        {
            select: useCallback(data => data.map(goal => new CoachingGoalModel(goal)), [])
        }
    );
}

export function useArchivedCoachingGoalData(participantId: number) {
    return useQuery<CoachingGoalApi[], AxiosError, number[]>(
        [QueryKey.CoachingGoal, { query: "archived", id: participantId }],
        getCoachingGoal,
        {
            select: useCallback(data => data.map(goal => goal.id), [])
        }
    );
}

export function useCreateCoachingGoal(participantId: number) {
    return useMutation((coachingGoal: Pick<CoachingGoalApi, "description" | "order">) =>
        http.post<{ id: number }>(Urls.createCoachingGoal(participantId), coachingGoal)
    );
}

export function useEditCoachingGoal(participantId: number) {
    return useMutation((coachingGoal: Pick<CoachingGoalApi, "description" | "order" | "id">) =>
        http.put(Urls.editCoachingGoal(participantId, coachingGoal.id), {
            description: coachingGoal.description,
            order: coachingGoal.order
        })
    );
}

export function useArchiveCoachingGoal(participantId: number) {
    return useMutation((goalId: number) => http.post(Urls.archiveCoachingGoal(participantId, goalId)));
}

export function useUnarchiveCoachingGoal(participantId: number) {
    return useMutation((goalId: number) => http.post(Urls.unarchiveCoachingGoal(participantId, goalId)));
}

export function useSortCoachingGoals(participantId: number) {
    return useMutation((sort: { ids: number[] }) => http.post(Urls.sortCoachingGoals(participantId), sort));
}
