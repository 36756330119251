import React from "react";
import classNames from "classnames";

import "./styles.scss";

type Props = {
    isToggled: boolean;
    name?: string;
    label?: string;
    onToggle: () => void;
};

const Switcher = ({ isToggled, name, label, onToggle }: Props) => {
    return (
        <div className="toggle-box d-flex align-items-center">
            {label?.length > 0 ? (
                <span className={classNames("mr-2", { "color-gray": !isToggled, "color-brand": isToggled })}>
                    {label}
                </span>
            ) : null}
            <label className="toggle-switch">
                <input type="checkbox" name={name} checked={isToggled} onChange={onToggle} />
                <span className="switch" />
            </label>
        </div>
    );
};

export default Switcher;
