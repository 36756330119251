import React, { memo } from "react";
import classNames from "classnames";

import { UserDefaultAvatar } from "main-app/svgs";
import "./styles.scss";

interface IProps {
    url: string | null;
    className?: string;
    width?: number;
    alt?: string;
    height?: number;
    badgeIcon?: React.ReactNode[] | React.ReactNode | null;
    circleFillColor?: string;
}

const Avatar: React.FC<IProps> = ({
    url = null,
    className = "",
    width = 50,
    height = 50,
    badgeIcon = null,
    alt = "",
    circleFillColor
}) => {
    const renderBadgeIcons = () => {
        if (Array.isArray(badgeIcon) && badgeIcon.filter(Boolean).length > 1) {
            return badgeIcon.map((Icon, index) => (
                <span
                    key={index}
                    className={`badge-icon position-${index + 1}`}
                    style={{ zIndex: badgeIcon.length - index - 1 }}
                >
                    {Icon}
                </span>
            ));
        }

        return <span className="badge-icon">{badgeIcon}</span>;
    };

    return (
        <div
            className={classNames("avatar-image", { [className]: className })}
            style={{ width, height }}
            data-testid="avatar-image"
        >
            {url ? (
                <img src={url} alt={alt} width={width} height={height} />
            ) : (
                <UserDefaultAvatar width={width} height={height} circleFillColor={circleFillColor} />
            )}
            {badgeIcon && renderBadgeIcons()}
        </div>
    );
};

export default memo(Avatar);
