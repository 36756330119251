import React from "react";
import sanitizeHtml from "sanitize-html";
import { sanitizeContent } from "main-app/utils/common";

type Props = {
    content: string;
    className?: string;
    attributes?: sanitizeHtml.IOptions;
    renderTag?: React.ElementType;
};

const HtmlContent = ({ content, className = "", attributes = {}, renderTag: Tag = "div" }: Props) => {
    return <Tag className={className} dangerouslySetInnerHTML={{ __html: sanitizeContent(content, attributes) }} />;
};

export default HtmlContent;
