import React, { memo } from "react";
import CurrentModuleCircleSvg from "./CurrentModuleCircleSvg";

interface IProps {
    height?: number;
    width?: number;
    className?: string;
    isCurrent?: boolean;
}

const NoModuleAssignedSvg: React.FC<IProps> = ({ height = 19, width = 19, className = "", isCurrent = false }) => {
    return (
        <span className="module-svg-wrapper">
            <svg
                width={width}
                height={height}
                className={className}
                viewBox="0 0 19 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <circle cx="9.5" cy="9.5" r="9.5" fill="#E1E1E1" />
                <circle cx="9.5" cy="9.5" r="4.5" fill="white" stroke="white" strokeWidth="1.75" />
            </svg>
            {isCurrent && <CurrentModuleCircleSvg />}
        </span>
    );
};

export default memo(NoModuleAssignedSvg);
