import React, { useMemo, useState } from "react";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import useOnRouteChange from "common/hooks/use-route-change";
import { CoachDashboardTab, CoachUrls } from "main-app/constants";
import Heading from "common/components/Heading/Heading";
import { Option } from "common/models/option";
import AttentionNotificationBox from "main-app/shared/notification/AttentionsNotificationBox";
import Button from "main-app/shared/button/Button";
import { useAuthContext } from "main-app/context/Auth";
import useRoles from "main-app/utils/hooks/use-roles";
import Select from "main-app/shared/select-default/Select";

interface IProps {
    selectedTab: CoachDashboardTab;
    selectedPeriod: string;
    onSearch: (value: string) => void;
    onClickSetupCalendar: (value: boolean) => void;
    onSelectSessionPeriod: (value: string) => void;
}

const sessionsPeriods = [
    new Option({ label: "Upcoming Sessions", value: "upcoming_sessions" }),
    new Option({ label: "Past Sessions", value: "past_sessions" })
];

const Header: React.FC<IProps> = ({
    selectedTab,
    selectedPeriod,
    onSearch,
    onSelectSessionPeriod,
    onClickSetupCalendar
}) => {
    const { t } = useTranslation();
    const { user } = useAuthContext();
    const { isCoach } = useRoles();
    const [searchValue, setSearchValue] = useState("");

    const isSessionsPage = selectedTab === CoachDashboardTab.GROUP || selectedTab === CoachDashboardTab.SESSIONS;

    const sessionPeriodIndex = useMemo(() => {
        return sessionsPeriods?.findIndex(option => option.value === selectedPeriod);
    }, [selectedPeriod]);

    useOnRouteChange(() => {
        setSearchValue("");
        onSearch("");
    });

    const handleSearch = (value: string) => {
        setSearchValue(value);
        if (!value) {
            onSearch(value);
        }
        if (value.length >= 3) {
            onSearch(value);
        }
    };

    const onSelectPeriod = (period: Option) => {
        onSelectSessionPeriod(period.value as string);
    };

    return (
        <div className="mb-80" data-testid="coach-table-header">
            <div className={classNames("row", { "mb-70": user?.calendar_setup_confirmed || !isCoach })}>
                <div className="col-12 col-lg-5">
                    <Heading>{t("coachSide.dashboard.heading")}</Heading>
                </div>
                <div className="col-12 col-lg-7">
                    <div className="form-group mb-0">
                        <input
                            type="text"
                            className="form-control"
                            placeholder={t("Search")}
                            data-testid="search-input"
                            value={searchValue}
                            onChange={e => handleSearch(e.target.value)}
                        />
                    </div>
                </div>
            </div>
            {!user?.calendar_setup_confirmed && isCoach && (
                <AttentionNotificationBox className="d-flex align-items-center justify-content-center mb-40 mt-60">
                    <div>
                        <span className="color-gray font-extrabold">Setup Incomplete </span>
                        Please set up your schedule before continuing.{" "}
                        <Button
                            className="p-0 m-0 color-brand font-extrabold"
                            variant="default"
                            onClick={() => onClickSetupCalendar(true)}
                        >
                            Set Up Now
                        </Button>
                    </div>
                </AttentionNotificationBox>
            )}
            <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center h-100">
                    <NavLink
                        to={CoachUrls.SESSIONS_LIST}
                        className={({ isActive }) => classNames("tab-link", { active: isActive })}
                    >
                        Sessions View
                    </NavLink>
                    <NavLink
                        to={CoachUrls.PARTICIPANTS_LIST}
                        className={({ isActive }) => classNames("tab-link", { active: isActive })}
                    >
                        Participants View
                    </NavLink>
                    <NavLink
                        to={CoachUrls.GROUP_LIST}
                        className={({ isActive }) => classNames("tab-link", { active: isActive })}
                    >
                        Groups View
                    </NavLink>
                </div>
                {isSessionsPage && (
                    <div className="form-group d-flex mb-0 position-relative col-4">
                        <label htmlFor="cohort" className="font-extrabold align-self-center mr-3 mb-0">
                            Viewing
                        </label>
                        <Select
                            optionsList={sessionsPeriods}
                            onSelect={onSelectPeriod}
                            defaultOptionIndex={sessionPeriodIndex}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default Header;
