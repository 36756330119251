import * as React from "react";
import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {}

const SideMenuClose = ({ width = 32, height = 21, className = "", ...rest }: Props) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 32 21"
        className={className}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <g>
            <g>
                <path d="M9 3.40039L2 10.4004L9 17.4004" stroke="#FD4D00" strokeWidth={2.5} />
            </g>
        </g>
        <g>
            <path d="M16 4.92188H31" stroke="#FD4D00" strokeWidth={2} strokeLinecap="square" />
            <path d="M16 10.4004H31" stroke="#FD4D00" strokeWidth={2} strokeLinecap="square" />
            <path d="M16 15.8779H31" stroke="#FD4D00" strokeWidth={2} strokeLinecap="square" />
        </g>
    </svg>
);

export { SideMenuClose };
