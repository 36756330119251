import * as React from "react";
import * as Sentry from "@sentry/react";

import { initSentry } from "common/utils/init-centry";
import { Link } from "react-router-dom";
import { ErrorPage } from "../error-page/ErrorPage";

type State = {
    hasError: boolean;
};

export class ErrorBoundary extends React.Component<{}, State> {
    state: State = { hasError: false };

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidMount() {
        initSentry();
    }
    componentDidCatch(error, errorInfo) {
        Sentry.withScope(scope => {
            scope.setExtras({
                errorInfo
            });
            scope.setTag("build_config", process.env.NODE_ENV);
            Sentry.captureException(error);
        });
    }

    render() {
        return this.state.hasError ? (
            <ErrorPage
                errorContent={
                    <div className="text-center">
                        Oops! Looks like something went wrong. The Wiser Care Team has been notified.
                        <br />
                        <Link to={"/"} className="color-brand font-bold d-block d-md-inline text-center">
                            Back to dashboard
                        </Link>
                    </div>
                }
            />
        ) : (
            this.props.children
        );
    }
}
