import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { AxiosResponse } from "axios";
import { DateTime } from "luxon";

import useCoachTimeWidget from "main-app/components/onboarding/api/use-coach-time-widget";
import { HTTPStatus } from "common/constants";
import Modal from "main-app/shared/modal";
import Coach from "main-app/models/coach";
import Avatar from "main-app/shared/avatar/Avatar";
import useBoolean from "common/hooks/use-boolean";
import { isNullOrUndefined } from "common/utils/gates";
import { getErrorMessages } from "common/utils/get-error-messages";
import { CronofyNotificationType, DATE_FORMAT, ERROR_TEXT, SessionScheduleStatus } from "main-app/constants";
import { formatDateSessions } from "main-app/utils/date";

import { ThreeWayManagerApi, ThreeWayManagerFormValues } from "main-app/entities/three-way";
import CoachTimeScheduleContainer from "./CoachTimeScheduleContainer";
import { BookedTime } from "./types";

import "./styles.scss";

type Props = {
    show: boolean;
    coach: Coach | null;
    title?: string;
    sessionTime?: string;
    session: {
        duration: number;
        session_type?: string;
        name?: string;
        id?: number;
    };
    showManagerInfoBlock?: boolean;
    scheduledSession?: any;
    sessionId: number;
    onClose: (bookedStatus: SessionScheduleStatus) => void;
    timePickRequest: (
        notification,
        threeWayManager?: ThreeWayManagerFormValues | ThreeWayManagerApi
    ) => Promise<AxiosResponse<any, any>>;
};

const CoachTimeScheduleModal = ({
    show,
    coach,
    title = "",
    sessionTime,
    session,
    showManagerInfoBlock = false,
    scheduledSession,
    sessionId,
    timePickRequest,
    onClose
}: Props) => {
    const { t } = useTranslation();
    const [errorsMsg, setErrorsMsg] = useState(null);
    const { value: showBackBtn, setValue: setShowBackBtn } = useBoolean(false);
    const { value: isBusyWidget, setValue: setIsBusyWidget } = useBoolean(false);
    const {
        data: cronofyOptions,
        isLoading,
        isError: isWidgetError,
        error: widgetError
    } = useCoachTimeWidget(coach?.coachId || coach?.id, scheduledSession?.session_id || "");
    const [bookedTime, setBookedTime] = useState<BookedTime>(null);

    useEffect(() => {
        if (isWidgetError && show) {
            setErrorsMsg(getErrorMessages(widgetError));
            setShowBackBtn(false);
        }
    }, [isWidgetError, show]);

    const onTimePick = async ({ notification }) => {
        if (notification.type === CronofyNotificationType.SLOT_SELECTED) {
            setIsBusyWidget(true);
            try {
                await timePickRequest(notification);
                setBookedTime({
                    time: notification.slot.start,
                    tz: notification.tzid
                });
            } catch (error) {
                if (error?.response?.status === HTTPStatus.INTERNAL_SERVER_ERROR) {
                    setErrorsMsg(ERROR_TEXT);
                    setShowBackBtn(false);
                } else {
                    setErrorsMsg(getErrorMessages(error));
                    setShowBackBtn(true);
                }
            } finally {
                setIsBusyWidget(false);
            }
        }
    };

    const onClickBackBtn = () => {
        setErrorsMsg(null);
        setShowBackBtn(false);
    };

    const onCloseCoachSelectModal = () => {
        if (bookedTime) {
            onClose(SessionScheduleStatus.SCHEDULED);
            setBookedTime(null);
        } else {
            onClose(SessionScheduleStatus.NOT_SCHEDULED);
        }

        if (errorsMsg) {
            setErrorsMsg(null);
        }
    };

    const options = useMemo(() => {
        const hasSession = !isNullOrUndefined(session);
        const hasCronofyOptions = !isNullOrUndefined(cronofyOptions);

        if (hasSession && hasCronofyOptions) {
            return {
                ...cronofyOptions,
                availability_query: {
                    ...cronofyOptions?.availability_query,
                    required_duration: {
                        minutes: session?.duration
                    }
                },
                tzid: Intl.DateTimeFormat().resolvedOptions().timeZone
            };
        }

        return null;
    }, [cronofyOptions, session]);

    const scheduledTime = sessionTime ?? bookedTime?.time ?? null;

    return (
        <Modal
            onClose={onCloseCoachSelectModal}
            show={show}
            modalBodyClass="coach-time-select-modal"
            headerContent={
                <div className="d-flex align-items-center mx-5">
                    <Avatar url={coach?.photo} className="mr-20" width={40} height={40} />
                    <div>
                        <p className="m-0 font-weight-800">{title || session?.name || session?.session_type}</p>
                        <span className="color-gray fs-14">
                            {session?.duration} {session?.duration ? t("min") : null}
                        </span>
                    </div>
                    {scheduledTime ? (
                        <div className="ml-md-20 ml-4">
                            <p className="m-0">{formatDateSessions(scheduledTime, false)}</p>
                            <span className="color-gray">
                                {DateTime.fromISO(scheduledTime).toFormat(DATE_FORMAT.TIMEZONE_ABBREVIATED)}
                            </span>
                        </div>
                    ) : null}
                </div>
            }
            disableBodyScroll
            closeOnEscapePress={false}
            enableOutSideClick={false}
        >
            <CoachTimeScheduleContainer
                data={options}
                error={Array.isArray(errorsMsg) ? errorsMsg?.join(" ") : errorsMsg}
                onTimePick={onTimePick}
                bookedTime={bookedTime}
                showBackBtn={showBackBtn}
                isLoading={isLoading || isBusyWidget}
                coach={coach}
                showManagerInfoBlock={showManagerInfoBlock}
                session={session}
                sessionId={sessionId}
                onCloseModal={onCloseCoachSelectModal}
                onClickBack={onClickBackBtn}
            />
        </Modal>
    );
};

export default CoachTimeScheduleModal;
