import React from "react";
import { useLocation } from "react-router-dom";
import classNames from "classnames";
import useIsMobileLandscape from "common/hooks/use-is-mobile-landscape";
import "main-app/assets/footer.scss";

type Props = {};

const Footer = (props: Props) => {
    const { pathname } = useLocation();
    const isLandscape = useIsMobileLandscape();

    return (
        <footer
            className={classNames("footer", { "is-landscape": isLandscape && pathname.includes("/register") })}
        ></footer>
    );
};

export default Footer;
