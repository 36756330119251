import React, { useCallback } from "react";
import { Draggable } from "react-beautiful-dnd";
import DragDropWrapper from "main-app/shared/drag-n-drop/DragDropWrapper";

import { OldBelief } from "../model/types/old-belief";
import { OldBeliefGoalCard } from "./OldBeliefGoalCard";

type Props = {
    goals: OldBelief[];
    archivedBeliefs: number[];
    onClickOldeBelief: (index: number) => void;
    onArchiveCick: (index: number) => void;
    onDragEnd: (result: any) => void;
};

export const OldBeliefGoalList = ({ goals, archivedBeliefs, onClickOldeBelief, onDragEnd, onArchiveCick }: Props) => {
    const isArchived = useCallback(
        id => {
            return archivedBeliefs?.includes(id);
        },
        [archivedBeliefs]
    );

    return (
        <DragDropWrapper onDragEnd={onDragEnd} droppableId="droppable" type="old-belief-goal-drag">
            {goals.map((goal, index) => (
                <Draggable key={`item-${goal.id}`} draggableId={`item-${goal.id}`} index={index}>
                    {(provided, snapshot) => {
                        const isArchivedGoal = isArchived(goal.id);

                        return (
                            <div ref={provided.innerRef} {...provided.draggableProps} className="mb-1">
                                <OldBeliefGoalCard
                                    key={goal.id}
                                    goal={goal}
                                    isArchived={isArchivedGoal}
                                    index={index}
                                    showDrag={goals.length > 1 && !isArchivedGoal}
                                    onArchiveClick={onArchiveCick}
                                    provider={provided}
                                    snapshot={snapshot}
                                    oldBeliefIndex={index}
                                    onClickOldeBelief={onClickOldeBelief}
                                />
                            </div>
                        );
                    }}
                </Draggable>
            ))}
        </DragDropWrapper>
    );
};
