import React from "react";
import ThreeDotsSvg from "main-app/svgs/ThreeDotsSvg";
import Button from "../button/Button";

type Props = {
    onClick?: () => void;
};

const ThreeDotsMenu = ({ onClick }: Props) => {
    return (
        <Button variant="default" className="p-2" onClick={onClick}>
            <ThreeDotsSvg />
        </Button>
    );
};
export default ThreeDotsMenu;
