import React from "react";
import classNames from "classnames";

import "./../styles.scss";

type Props = {
    variant: "active" | "system" | "other";
    message: string;
    onClickMessage: () => void;
};

export const SimulabChatMessage = ({ variant, message, onClickMessage }: Props) => {
    return (
        <div
            className={classNames("simulab-chat-message", variant)}
            onClick={onClickMessage}
            data-testid="simulab-chat-message"
        >
            <span>{message}</span>
        </div>
    );
};
