import { AxiosError } from "axios";
import { useCallback } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";

import { http } from "common/http";
import Urls from "main-app/api/urls";
import { CoachingGoalStatus } from "main-app/constants";
import { BehavioralPractice, BehavioralPracticeApi } from "../model/behavioral-goal";
import { QueryKey } from "../consts";

const getBehavioralPractice = async ({ queryKey }): Promise<BehavioralPracticeApi[]> => {
    const [_key, { behavioralChangeId, participantId, query }] = queryKey;
    const { data } = await http.get(Urls.behavioralPractice(participantId, behavioralChangeId, query));
    return data;
};

type Params = {
    participantId: number;
    behavioralChangeId: number;
    query: CoachingGoalStatus;
};

export function useBehavioralPracticeData(
    participantId: number,
    behavioralChangeId: number,
    query: CoachingGoalStatus
) {
    return useQuery<BehavioralPracticeApi[], AxiosError, BehavioralPractice[]>(
        [QueryKey.BehavioralPractice, { participantId, behavioralChangeId, query }],
        getBehavioralPractice,
        {
            select: useCallback(data => data.map(item => new BehavioralPractice(item)), [])
        }
    );
}

export function useArchivedBehavioralPracticeData(participantId: number, behavioralChangeId: number) {
    return useQuery<BehavioralPracticeApi[], AxiosError, number[]>(
        [QueryKey.BehavioralPractice, { participantId, behavioralChangeId, query: "archived" }],
        getBehavioralPractice,
        {
            select: useCallback(data => data.map(item => item.id), [])
        }
    );
}

export function useCreateBehavioralPractice(participantId: number) {
    return useMutation(
        (behavioralPractice: Pick<BehavioralPractice, "description" | "order" | "status" | "behavioralChangeId">) => {
            const { behavioralChangeId, ...data } = behavioralPractice;
            return http.post<{ id: number }>(Urls.createBehavioralPractice(participantId, behavioralChangeId), data);
        }
    );
}

export function useEditBehavioralPractice(participantId: number) {
    return useMutation(
        (
            behavioralPractice: Pick<
                BehavioralPractice,
                "description" | "order" | "status" | "behavioralChangeId" | "id"
            >
        ) => {
            const { behavioralChangeId, id, ...data } = behavioralPractice;
            return http.put(Urls.editBehavioralPractice(participantId, behavioralChangeId, id), data);
        }
    );
}

export function useArchiveBehavioralPractice(participantId: number) {
    return useMutation(({ behavioralChangeId, practiceId }: { behavioralChangeId: number; practiceId: number }) =>
        http.post(Urls.archiveBehavioralPractice(participantId, behavioralChangeId, practiceId))
    );
}

export function useUnarchiveBehavioralPractice(participantId: number) {
    return useMutation(({ behavioralChangeId, practiceId }: { behavioralChangeId: number; practiceId: number }) =>
        http.post(Urls.unarchiveBehavioralPractice(participantId, behavioralChangeId, practiceId))
    );
}

export function useSortBehavioralPractice(participantId: number) {
    return useMutation((data: { ids: number[]; behavioralChangeId: number }) => {
        const { behavioralChangeId, ids } = data;
        return http.post(Urls.sortBehavioralPractice(participantId, behavioralChangeId), { ids });
    });
}
