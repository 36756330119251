import React, { useState, useMemo } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import CoachLayout from "main-app/layouts/CoachLayout";
import LiveNowLine from "main-app/shared/livenow-line/LiveNowLine";
import { useQueryClient } from "@tanstack/react-query";
import AttendanceModal from "main-app/elements/attendance-modal/AttendanceModal";
import { ServerStateKeys, SessionType } from "main-app/constants";
import RsvpModal from "main-app/elements/rsvp-modal/RsvpModal";
import { GroupSessionParticipant } from "main-app/models/participants";
import useLiveSessionCheck from "main-app/utils/hooks/use-live-session-check";
import CareTeamLink from "main-app/shared/CareTeamLink";
import useCoachingSessions from "main-app/api/use-coaching-sessions";

import SessionAccordion from "../group-info/SessionAccordion";
import PaginatedSessions from "../group-info/PaginatedSessions";
import useParticipantDataByCoach from "main-app/api/use-participant-data";
import { CohortModalities } from "main-app/models/types";
import { HTTPStatus } from "common/constants";
import Heading from "common/components/Heading/Heading";

interface IProps {}

const ParticipantSessions: React.FC<IProps> = () => {
    const { t } = useTranslation();
    const params = useParams();
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const { data, isLoading, refetch } = useCoachingSessions({ id: +params.id });
    const { data: participant, isError, error } = useParticipantDataByCoach(params.id);
    const [showAttendanceModal, setShowAttendanceModal] = useState(false);
    const [showRsvpModal, setShowRsvpModal] = useState(false);
    const [sessionId, setSessionId] = useState(null);
    const [selectedRsvp, setSelectedRsvp] = useState<GroupSessionParticipant | null>(null);

    const onCloseAttendanceModal = () => {
        setShowAttendanceModal(false);
        queryClient.invalidateQueries([ServerStateKeys.CoachingSession, { id: +params.id }]);
    };

    const onOpenAttendanceModal = (id: number) => {
        setSessionId(id);
        setShowAttendanceModal(true);
    };

    const onClickParticipant = (id: number) => {
        navigate(`/participant-works/${id}`);
    };

    const onCloseRsvpModal = () => {
        setShowRsvpModal(false);
        setSelectedRsvp(null);
    };

    const onClickOpenRsvp = (participant: GroupSessionParticipant) => {
        setShowRsvpModal(true);
        setSelectedRsvp(participant);
    };

    const session = data?.upcoming_sessions?.find(session => session.session_time);

    useLiveSessionCheck({ date: session?.session_time }, () => {
        refetch();
    });

    const isGroupSession = useMemo(() => {
        return data ? "group_info" in data : false;
    }, [data]);

    const pageTitle = useMemo(() => {
        if (data && participant) {
            return isGroupSession ? data.group_info?.title : `${participant.firstName} ${participant.lastName}`;
        }

        return "";
    }, [data, isGroupSession, participant]);

    if (isError && error?.response?.status === HTTPStatus.FORBIDDEN) {
        return <Navigate to="/forbidden" replace />;
    }

    return (
        <CoachLayout isLoadingPage={isLoading}>
            {data ? (
                <div className="group-info">
                    <Heading tag="h1" textAlign="center" className="mb-50">
                        {pageTitle}
                    </Heading>
                    {data.live_session ? (
                        <div className="mb-50">
                            <div className="mb-3">
                                <LiveNowLine joinLink={data.live_session.join_url} />
                            </div>

                            <SessionAccordion
                                isLive={true}
                                session={data.live_session}
                                onOpenAttendanceModal={onOpenAttendanceModal}
                                onClickRsvpNote={onClickOpenRsvp}
                                onClickParticipant={onClickParticipant}
                            />
                        </div>
                    ) : null}
                    <Heading tag="h2" fontSize={24} textAlign="center" className="mb-3">
                        {t("Upcoming Sessions")}
                    </Heading>
                    <PaginatedSessions
                        sessions={data.upcoming_sessions}
                        type={SessionType.UPCOMING}
                        cohort={isGroupSession ? CohortModalities.Group : CohortModalities.Individual}
                        onClickParticipant={onClickParticipant}
                        onClickRsvpNote={onClickOpenRsvp}
                    />

                    <Heading tag="h2" fontSize={24} textAlign="center" className="mt-50 mb-3">
                        {t("Past Sessions")}
                    </Heading>
                    <PaginatedSessions
                        sessions={data.passed_sessions}
                        type={SessionType.PAST}
                        cohort={isGroupSession ? CohortModalities.Group : CohortModalities.Individual}
                        onOpenAttendanceModal={onOpenAttendanceModal}
                        onClickRsvpNote={onClickOpenRsvp}
                        onClickParticipant={onClickParticipant}
                    />

                    <CareTeamLink variant="brand" text={t("Need help?")} className="font-normal text-center" />
                </div>
            ) : null}

            {showAttendanceModal && (
                <AttendanceModal show={showAttendanceModal} sessionId={sessionId} onClose={onCloseAttendanceModal} />
            )}
            {showRsvpModal && <RsvpModal show={showRsvpModal} onClose={onCloseRsvpModal} participant={selectedRsvp} />}
        </CoachLayout>
    );
};

export default ParticipantSessions;
