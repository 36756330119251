import { useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import { http } from "common/http";

import { ServerStateKeys } from "main-app/constants";
import Urls from "main-app/api/urls";
import { PreprogramDiagnostic } from "../models/PreprogramDiagnostic";
import { ServerStateOnbordingKeys } from "../constants";

const getPreprogramDiagnosticQuestions = async () => {
    const { data } = await http.get(Urls.onboardingStep3());
    return data;
};

export default function usePreprogramQuestions() {
    return useQuery<PreprogramDiagnostic[]>([ServerStateOnbordingKeys.Preprogram], getPreprogramDiagnosticQuestions, {
        select: useCallback(data => {
            return data.map(item => new PreprogramDiagnostic(item));
        }, [])
    });
}
