import React from "react";
import { useTranslation } from "react-i18next";

import { isNullOrUndefined } from "common/utils/gates";
import { WarningMessage } from "main-app/components/onboarding/components/WarningMessage";
import { ThreeWayManager } from "../model/three-way";
import Button from "main-app/shared/button/Button";
import TextInput from "main-app/shared/input/TextInput";

type Props = {
    threeWayManager: ThreeWayManager | null;
    error: string;
    isUpdating: boolean;
    register: any;
    onUpdateFields: (value) => void;
    isActiveBtn: boolean;
};

export const ThreeWayForm = ({ threeWayManager, error, isUpdating, register, onUpdateFields, isActiveBtn }: Props) => {
    const { t } = useTranslation();
    const noThreeWayManager = isNullOrUndefined(threeWayManager);

    return (
        <form onSubmit={onUpdateFields}>
            <div className={"mb-15"}>
                <div className="tree-way__form-wrapper">
                    <span className="font-sm">{t("Email:")}</span>
                    <TextInput
                        name="email"
                        groupClassName="m-0 gray-placeholder"
                        className="font-light"
                        variant="rect"
                        placeholder={t("j.doe@yourorganization.com")}
                        autoFocus={noThreeWayManager}
                        register={register}
                    />
                </div>
                <div className="tree-way__form-wrapper">
                    <span className="font-sm">{t("Name:")}</span>
                    <div className="flex-container">
                        <TextInput
                            groupClassName="m-0 gray-placeholder"
                            className="font-light"
                            variant="rect"
                            placeholder={t("Jane")}
                            name="first_name"
                            register={register}
                        />
                        <TextInput
                            groupClassName="m-0 gray-placeholder"
                            className="font-light"
                            variant="rect"
                            placeholder={t("Doe")}
                            name="last_name"
                            register={register}
                        />
                    </div>
                </div>
            </div>
            <WarningMessage message={error} />
            <Button
                type="submit"
                isBusy={isUpdating}
                variant={isActiveBtn ? "brand" : "disabled-grey"}
                className="tree-way__btn"
                disabled={isActiveBtn ? false : true}
            >
                {t("threeWaySessionModal.form.submitButton.title")}
            </Button>
        </form>
    );
};
