import { useMutation } from "@tanstack/react-query";
import { http } from "common/http";
import Urls from "../urls";

interface IndividualSessionApiData {
    session_datetime: string;
    coach: number;
    tz: string;
}

export default function useIndividualCoachingReschedule(sessionId: number) {
    return useMutation((session: IndividualSessionApiData) =>
        http.post(Urls.individualSessionReschedule(sessionId), session)
    );
}
