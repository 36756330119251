import React from "react";
import { ReflectionComponentType } from "main-app/constants";
import LikertComponent from "main-app/shared/likert/LikertComponent";
import { useFormContext } from "react-hook-form";

interface IProps {
    diagnosticQuestions: any[];
    errors: any;
}

const DiganosticResponses: React.FC<IProps> = ({ diagnosticQuestions, errors }) => {
    const { register, control } = useFormContext();

    return (
        <>
            {diagnosticQuestions?.map((item, index) => (
                <LikertComponent
                    key={item.uuid}
                    item={item}
                    questionNumber={null}
                    color="blank"
                    register={register}
                    name={`diagnostic.${item.uuid}`}
                    control={control}
                    errors={errors}
                    error={errors?.diagnostic?.[item.uuid]?.message?.toString()}
                    questionStyle="one-line"
                    className="mb-40"
                    likertType={ReflectionComponentType.LIKERT_GROUP}
                />
            ))}
        </>
    );
};

export default DiganosticResponses;
