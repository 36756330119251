import { MutationCache, QueryCache, QueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import * as Sentry from "@sentry/react";
import { HTTPStatus, ONE_MINUTE_STALE_TIME } from "common/constants";
import { triggerErrorBoundary } from "./utils/throw-error";
import { Role } from "./constants";

const captureErrors = (error, scope: Sentry.Scope) => {
    scope.setExtras({
        method: error?.config?.method,
        request_payload: error?.config?.data || null,
        status_code: error?.response?.status,
        url: error?.config?.url
    });
    scope.setTag("build_config", process.env.NODE_ENV);
    scope.setTag("request_url", error?.config?.url);
    scope.setTag("method", error?.config?.method);
};

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            staleTime: ONE_MINUTE_STALE_TIME,
            retry: false,
            useErrorBoundary: (error: AxiosError) => {
                return error?.response?.status >= HTTPStatus.INTERNAL_SERVER_ERROR;
            }
        }
    },
    mutationCache: new MutationCache({
        onError: (err: AxiosError, _variables, _context, mutation) => {
            Sentry.withScope(scope => {
                scope.setContext("mutation", {
                    mutationId: mutation.mutationId,
                    variables: mutation.state.variables,
                    data: err?.response?.data || null
                });
                if (mutation.options.mutationKey) {
                    scope.setFingerprint(Array.from(mutation.options.mutationKey) as string[]);
                }
                captureErrors(err, scope);
                Sentry.captureException(err);
            });

            if (err?.response?.status >= HTTPStatus.INTERNAL_SERVER_ERROR) {
                triggerErrorBoundary(err);
            }

            if (err?.response?.status === HTTPStatus.UNAUTHORIZED) {
                console.log("Unauthorized error", { err });
                setTimeout(() => {
                    window.location.reload();
                });
            }
        }
    }),
    queryCache: new QueryCache({
        onError: (err: AxiosError, query: any) => {
            Sentry.withScope(scope => {
                scope.setContext("query", { queryHash: query.queryHash, data: err?.response?.data || null });
                scope.setFingerprint([query.queryHash.replaceAll(/[0-9]/g, "0")]);
                captureErrors(err, scope);
                Sentry.captureException(err);
            });

            const user = query.cache.queries.find(query => query.queryKey.includes("user"))?.state?.data;

            console.log({ err, query, user });

            if (err?.response?.status === HTTPStatus.UNAUTHORIZED) {
                console.log("Unauthorized error", { err, query, user });
                setTimeout(() => {
                    window.location.reload();
                });
            }
        }
    })
});

export { queryClient };
