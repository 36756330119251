import classNames from "classnames";
import { isNullOrUndefined } from "common/utils/gates";
import React from "react";
import ContentLoader from "react-content-loader";

type Props = {
    rows: number;
    height?: number | string;
    width?: number | string;
    className?: string;
};

const Skeleton = ({ rows = 3, height = 50, className = "", width }: Props) => {
    return (
        <>
            {Array.from({ length: rows }, (index: number) => index + 1).map((_, index) => (
                <div
                    className={classNames(className)}
                    style={{ height, width: isNullOrUndefined(width) ? "100%" : width }}
                    key={index}
                    data-testid="content-loader"
                >
                    <ContentLoader
                        speed={3}
                        height={height}
                        width={"100%"}
                        backgroundColor="#f3f3f3"
                        foregroundColor="#ecebeb"
                    >
                        <rect x="7" y="14" rx="0" ry="0" width="100%" height="100%" />
                        <rect x="122" y="71" rx="0" ry="0" width="0" height="1" />
                    </ContentLoader>
                </div>
            ))}
        </>
    );
};

export default Skeleton;
