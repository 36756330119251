import React, { useState } from "react";
import { ColorRange, LikertColorType } from "main-app/models/types";
import { ReflectionComponentType } from "main-app/constants";
import getColor from "main-app/utils/get-color";
import { withTranslation } from "common/utils/lang";
import LikertRadio from "main-app/shared/likert/LikertRadio";
import { PairingQuestionAnswerOption } from "../models";

type Props = {
    options: PairingQuestionAnswerOption[];
    colorRange?: ColorRange[];
    likertColorType: LikertColorType;
    likertType: ReflectionComponentType.LIKERT_GROUP | ReflectionComponentType.COLORED_LIKERT_GROUP;
    register: any;
    control: any;
    name: any;
    className?: string;
    showOddLabels?: boolean;
};

export const PairingQuestionLikertRow = ({
    options,
    likertColorType,
    likertType,
    colorRange = [],
    register,
    control,
    name,
    className = "",
    showOddLabels = false
}: Props) => {
    const isDefaultLikert = likertType === ReflectionComponentType.LIKERT_GROUP;
    const [hoveredId, setHoveredId] = useState<string | null>(null);
    const likertColor = (index: number) => {
        if (isDefaultLikert) return "";
        return getColor(index, colorRange);
    };

    return (
        <fieldset
            className={`mb-4 level-choice level-choice--${likertColorType} ${className} d-flex justify-content-center`}
        >
            {options?.map((checkbox, index, options) => (
                <LikertRadio
                    name={name}
                    register={register}
                    hoveredId={hoveredId}
                    setHovered={setHoveredId}
                    showHover
                    control={control}
                    label={withTranslation(checkbox.optionText)}
                    id={`${checkbox.uuid}`}
                    value={`${checkbox.uuid}`}
                    isFirstOption={index === 0}
                    key={`${checkbox.uuid}`}
                    color={likertColorType === "colored" ? checkbox.color : undefined}
                    showLabel={showOddLabels && options?.length % 2 !== 0 ? (index + 1) % 2 !== 0 : true}
                    isLastOption={index === options.length - 1}
                />
            ))}
        </fieldset>
    );
};
