import { TranslationObj } from "common/utils/lang";

export class Category {
    id: number;
    name: TranslationObj;
    description: TranslationObj;

    constructor(data) {
        this.id = data.id;
        this.name = data.name;
        this.description = data.description;
    }
}
