import React from "react";
import ContentLoader from "react-content-loader";
import classNames from "classnames";

import { Status } from "main-app/constants";

import "./styles.scss";

interface IProps {
    phases: any[];
    selectedPhase: any;
    isLoading: boolean;
    selectedChapter: any;
    onSelectPhase: (phase) => void;
    onSelectChapter: (chapter) => void;
}

const PhasesLinks: React.FC<IProps> = ({ phases, isLoading, onSelectPhase, selectedPhase }) => {
    if (isLoading) {
        return (
            <div>
                {Array.from({ length: 5 }).map((_, index) => (
                    <div className="w-100" style={{ height: 10 }} key={index}>
                        <ContentLoader
                            speed={3}
                            height={"100%"}
                            width={"100%"}
                            backgroundColor="#f3f3f3"
                            foregroundColor="#ecebeb"
                        >
                            <rect x="7" y="14" rx="0" ry="0" width="100%" height="100%" />
                            <rect x="122" y="71" rx="0" ry="0" width="0" height="1" />
                        </ContentLoader>
                    </div>
                ))}
            </div>
        );
    }

    const handlePhaseSelect = phase => {
        if (phase.status === Status.Completed) {
            onSelectPhase(phase);
        }
    };

    return (
        <>
            {phases?.length > 0 &&
                phases.map(phase => (
                    <div
                        className={classNames("phase-item-title", { active: selectedPhase?.id === phase.id })}
                        key={phase.id}
                        onClick={() => handlePhaseSelect(phase)}
                    >
                        <span className="phase-item-name font-extrabold">Phase {phase.order}</span>
                        <span className="phase-item-status text-capitalize">{phase.status}</span>
                    </div>
                ))}
        </>
    );
};

export default PhasesLinks;
