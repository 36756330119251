import * as Yup from "yup";

const validationSchema = Yup.object({
    email: Yup.string()
        .test("email", "This field is required", function (value) {
            const { first_name, last_name } = this.parent;
            return !(first_name || last_name) || !!value;
        })
        .email("Must be a valid email"),
    first_name: Yup.string().test("first_name", "This field is required", function (value) {
        const { email, last_name } = this.parent;
        return !(email || last_name) || !!value;
    }),
    last_name: Yup.string().test("last_name", "This field is required", function (value) {
        const { email, first_name } = this.parent;
        return !(email || first_name) || !!value;
    })
});

export { validationSchema };
