import { TranslationObj } from "common/utils/lang";
import { Status } from "main-app/constants";
import { ITrackPractice } from "Manager/chapter/models/types";

export interface PhaseApi {
    id: number;
    completed_data: string | null;
    name: string;
    open_date: string;
    order: number;
    status: string;
    due_date: string;
    short_description: {
        en: string;
    };
    long_description: {
        en: string;
    };
    chapters: any[];
}

export class ChapterPhase {
    icon: null | string;
    id: number;
    name: {
        en: string;
    };
    status: Status;
    trackPracticeComponents: ITrackPractice[];
    components?: any[];
    show_summary_after_finish?: boolean;
    chapter_summary?: {
        short_summary?: {
            en: string;
        };
        summary_subtitle?: {
            en: string;
        };
        summary?: {
            en: string;
        };
    };

    constructor(data) {
        this.icon = data.icon;
        this.id = data.id;
        this.name = data.name;
        this.status = data.status;
        this.trackPracticeComponents = data.track_practice_components;
        this.components = data.components ?? [];
        this.show_summary_after_finish = data.show_summary_after_finish ?? false;
        this.chapter_summary = data.chapter_summary;
    }
}

export class Phase {
    id: number;
    completedDate: string | null;
    name: TranslationObj;
    openDate: string;
    order: number;
    status: string;
    dueDate: string;
    shortDescription: {
        en: string;
    };
    longDescription: {
        en: string;
    };
    chapters: ChapterPhase[];

    constructor(data) {
        this.id = data.id;
        this.completedDate = data.completed_date;
        this.order = data.order;
        this.name = data.name;
        this.status = data.status;
        this.openDate = data.open_date;
        this.dueDate = data.due_date;
        this.shortDescription = data.short_description;
        this.longDescription = data.long_description;
        this.chapters = data.chapters.map(chapter => new ChapterPhase(chapter));
    }
}
