import classNames from "classnames";
import { DateTime } from "luxon";
import React from "react";

interface IProps {
    className?: string;
}

const DateWidget: React.FC<IProps> = ({ className }) => {
    return (
        <p className={classNames({ [className]: className, "fs-14-regular": !className })}>
            {DateTime.now().toFormat("EEEE, MMMM dd")}
        </p>
    );
};

export default DateWidget;
