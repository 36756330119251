import React, { memo, SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {}

const ReloadVectorSvg = memo(({ height = 15, width = 16, className = "", ...props }: Props) => (
    <svg
        width={16}
        height={15}
        viewBox="0 0 16 15"
        className={className}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M14.801 7.06569C14.4988 7.06569 14.2343 7.33017 14.2343 7.63245C14.2343 9.29494 13.592 10.8442 12.4207 12.0533C11.2494 13.2246 9.66233 13.8669 7.99984 13.8669C4.5616 13.8669 1.76539 11.0709 1.76539 7.63245C1.76539 4.19401 4.5614 1.398 7.99984 1.398C9.54897 1.398 11.0225 1.96476 12.1561 3.02271H10.5314C10.2291 3.02271 9.9646 3.2872 9.9646 3.58947C9.9646 3.89174 10.2291 4.15623 10.5314 4.15623H13.5541C13.8563 4.15623 14.1208 3.89174 14.1208 3.58947V0.566758C14.1208 0.264487 13.8563 0 13.5541 0C13.2518 0 12.9873 0.264487 12.9873 0.566758V2.22925C11.6271 0.982381 9.85135 0.264487 7.99984 0.264487C3.91918 0.264487 0.63208 3.55158 0.63208 7.63224C0.63208 11.7129 3.91918 15 7.99984 15C9.9646 15 11.8161 14.2443 13.214 12.8462C14.6119 11.4481 15.3678 9.5968 15.3678 7.63204C15.3678 7.29199 15.1033 7.06528 14.801 7.06528V7.06569Z"
            fill="#6F6F6F"
        />
    </svg>
));

export { ReloadVectorSvg };
