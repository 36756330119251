import React from "react";
import { useTranslation } from "react-i18next";

import CareTeamLink from "main-app/shared/CareTeamLink";
import { containsHTML, sanitizeContent } from "main-app/utils/common";
import Button from "main-app/shared/button/Button";

type Props = {
    error: string | null;
    showBackBtn?: boolean;
    onClickBack: () => void;
};

const CoachTimeError = ({ error, onClickBack, showBackBtn = true }: Props) => {
    const { t } = useTranslation();

    return (
        <section className="coach-time-error">
            {showBackBtn && (
                <Button variant="default" className="p-0 d-flex align-items-center mb-5" onClick={onClickBack}>
                    <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.5 3l-7 7 7 7" stroke="#FD4D00" strokeWidth={2.5} />
                    </svg>
                    <span className="color-gray">{t("Back to Scheduling")}</span>
                </Button>
            )}

            {containsHTML(error) ? (
                <div className="coach-time-error__text" dangerouslySetInnerHTML={{ __html: sanitizeContent(error) }} />
            ) : (
                <h2 className="text-center mb-5 fs-16-regular font-extrabold">{error}</h2>
            )}
            <CareTeamLink text={t("Having trouble?")} className="care-team-link-text" />
        </section>
    );
};

export default CoachTimeError;
