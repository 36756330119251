import { useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import { http } from "common/http";
import { QueryKey } from "../constants";
import { Urls } from "./urls";
import { Module } from "../models/module";

const getModulesByParticipant = async ({ queryKey }) => {
    const [_key, { id, sortLanguage }] = queryKey;
    const { data } = await http.get(Urls.modulesByParticipantTranslated({ participantId: id, lang: sortLanguage }));
    return data.results;
};

interface Params {
    participantId: number;
    sortLanguage?: string; // TODO: provide type for available locales in a following related story
}

function useModulesByParticipant({ participantId, sortLanguage = "en" }: Params) {
    return useQuery([QueryKey.ModulesByParticipant, { id: participantId, sortLanguage }], getModulesByParticipant, {
        select: useCallback(data => data.map(module => new Module(module)), [])
    });
}

export { useModulesByParticipant };
