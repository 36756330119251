import { useQuery } from "@tanstack/react-query";
import { http } from "common/http";
import { ServerStateKeys } from "main-app/constants";
import { Participant, ParticipantApi } from "main-app/models/participants";
import Urls from "main-app/api/urls";
import { useCallback } from "react";

interface Query {
    search: string;
    cohort: string;
    enabled: boolean;
    page: number;
}

const getParticipants = async ({ search, cohort, page }: Query) => {
    const { data } = await http.get(Urls.participants({ search, cohort, page }));
    return data;
};

export default function useParticipants(query: Query) {
    return useQuery([ServerStateKeys.Participants, query], () => getParticipants(query), {
        select: useCallback(
            data => ({
                participants: data?.results?.map((participant: ParticipantApi) => new Participant(participant)),
                totalParticipantsCount: data?.count
            }),
            []
        ),
        enabled: query.enabled
    });
}
