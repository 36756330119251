import { useMutation } from "@tanstack/react-query";
import { http } from "common/http";
import Urls from "main-app/api/urls";

type PostData = {
    sessionId: number;
    email: string;
    first_name: string;
    last_name: string;
};

function useSetManager() {
    return useMutation(({ sessionId, email, first_name, last_name }: PostData) =>
        http.post(Urls.setManagerInfo(sessionId), { email, first_name, last_name })
    );
}

export { useSetManager };
