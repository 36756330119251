import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import useMediaQuery from "common/hooks/use-media-query";
import { PREVIOUS_PAGE } from "common/constants";
import { scrollToTop } from "common/utils/functions";
import CoachLayout from "main-app/layouts/CoachLayout";
import { UserEnrollModule } from "main-app/models/user";
import useParticipantDataByCoach from "main-app/api/use-participant-data";
import useParticipantProgress from "main-app/api/use-participant-progress";
import { MAX_WIDTH_MOBILE_MEDIA } from "main-app/constants";
import { CoachingPlanPageCoach } from "main-app/pages/coaching-plan";

import LeftSidebar from "./LeftSidebar";
import ParticipantResponses from "./ParticipantResponses";
import OnboardingResults from "./OnboardingResults";
import ProfileModal from "./components/ProfileModal";
import { useAuthContext } from "main-app/context/Auth";
import { isNullOrUndefined } from "common/utils/gates";

import "./styles.scss";

interface IProps {}

const ParticipantWorks: React.FC<IProps> = () => {
    const navigate = useNavigate();
    const params = useParams();
    const matches = useMediaQuery(MAX_WIDTH_MOBILE_MEDIA);
    const { user } = useAuthContext();
    const [openParticipantModal, setOpenParticipantModal] = useState(false);
    const [selectedModule, setSelectedModule] = useState<UserEnrollModule>(null);
    const [onboardingSelected, setOnboardingSelected] = useState(true);
    const [selectedModuleIndex, setSelectedModuleIndex] = useState(0);
    const [selectedPhase, setSelectedPhase] = useState(null);
    const [selectedChapter, setSelectedChapter] = useState(null);
    const [showCoachingPlan, setShowCoachingPlan] = useState(false);
    const { data: participant, isLoading, isError, error } = useParticipantDataByCoach(params.id);
    const {
        data: phases,
        isLoading: isLoadingPhases,
        isError: isErrorProgress,
        error: progressError
    } = useParticipantProgress({
        moduleId: selectedModule?.id,
        participantId: +params.id
    });

    useEffect(() => {
        if (selectedModule && phases?.length) {
            const firstPhase = phases?.find(phase => phase);
            if (firstPhase) {
                setSelectedPhase(firstPhase);
                if (firstPhase?.chapters?.length) {
                    setSelectedChapter(firstPhase?.chapters?.[0]);
                }
            }
        }
    }, [selectedModule, phases]);

    useEffect(() => {
        if (user?.availableCoachingPlan && participant?.availableCoachingPlan) {
            setShowCoachingPlan(true);
            setOnboardingSelected(false);
        }
    }, [user, participant]);

    const onOpenProfileModal = useCallback(() => {
        setOpenParticipantModal(true);
    }, []);

    const onCloseProfileModal = useCallback(() => {
        setOpenParticipantModal(false);
    }, []);

    const onSelectPreJourney = () => {
        setOnboardingSelected(true);
        setSelectedModule(null);
        setSelectedModuleIndex(0);
        setSelectedPhase(null);
        setShowCoachingPlan(false);
    };

    const onSelectCoachingPlan = () => {
        setShowCoachingPlan(true);
        setSelectedModule(null);
        setSelectedModuleIndex(0);
        setSelectedPhase(null);
        setOnboardingSelected(false);
    };

    const onSelectModule = useCallback(
        module => {
            if (module?.name?.en === "Onboarding") {
                setOnboardingSelected(true);
                setSelectedModule(null);
                setSelectedModuleIndex(0);
                setSelectedPhase(null);
                setShowCoachingPlan(false);
                return;
            }

            const selectedModule = participant?.enrolledModules?.find(mod => mod.id === module?.id);
            setSelectedModule(selectedModule);

            if (isNullOrUndefined(selectedModule)) {
                setSelectedChapter(null);
            }

            setOnboardingSelected(false);
            if (matches) {
                setSelectedPhase(null);
            }
            setShowCoachingPlan(false);
        },
        [participant]
    );

    const onSelectPhase = useCallback(phase => {
        setSelectedPhase(phase);
        scrollToTop();
    }, []);

    const onSelectChapter = useCallback(chapter => {
        setSelectedChapter(chapter);
    }, []);

    const onGoBack = () => {
        navigate(PREVIOUS_PAGE);
    };

    const content = () => {
        if (showCoachingPlan) {
            return <CoachingPlanPageCoach />;
        }

        if (onboardingSelected) {
            return <OnboardingResults participantName={participant?.firstName} />;
        }

        return (
            <ParticipantResponses
                chapter={selectedChapter}
                enrolledModules={participant?.enrolledModules}
                participantName={participant?.firstName}
                phasesList={phases}
                error={isError ? error : isErrorProgress ? progressError : null}
                selectedPhase={selectedPhase}
                onGoBack={onGoBack}
                selectedModuleIndex={selectedModuleIndex}
                onSelectPhase={onSelectPhase}
                onSelectModule={onSelectModule}
            />
        );
    };

    return (
        <CoachLayout
            leftSidebar={
                <LeftSidebar
                    showCoachingPlan={showCoachingPlan}
                    loadingEnrollments={isLoading}
                    loadingPhases={isLoadingPhases}
                    phasesList={phases}
                    selectedPhase={selectedPhase}
                    selectedChapter={selectedChapter}
                    onboardingSelected={onboardingSelected}
                    onSelectCoachingPlan={onSelectCoachingPlan}
                    onSelectModuleIndex={setSelectedModuleIndex}
                    onSelectModule={onSelectModule}
                    onSelectPhase={onSelectPhase}
                    onSelectChapter={onSelectChapter}
                    onSelectPrejourney={onSelectPreJourney}
                    onOpenProfileModal={onOpenProfileModal}
                />
            }
            headerTitle={<p className="color-gray m-0">Participant Work</p>}
            fullWidth={showCoachingPlan}
            align={showCoachingPlan ? "left" : "center"}
            size={showCoachingPlan ? "default" : "max-md"}
        >
            {content()}
            <ProfileModal participant={participant} show={openParticipantModal} onClose={onCloseProfileModal} />
        </CoachLayout>
    );
};

export default ParticipantWorks;
