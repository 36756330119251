export const QueryKey = {
    Calendars: "calendars",
    CalendarAttrs: "calendarAttrs",
    AvConfirmRules: "avConfirmRules",
    AvRulesAttrs: "avRulesAttrs",
    accountZoomLink: "accountZoomLink"
} as const;

export const CalendarStatus = {
    Active: "active",
    Pending: "pending",
    Disabled: "disabled"
} as const;
