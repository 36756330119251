import React from "react";

interface IProps {
    heigth?: number;
    width?: number;
    className?: string;
}

const ArrowDown: React.FC<IProps> = ({ heigth = 20, width = 20, className = "" }) => {
    return (
        <svg
            width={width}
            height={heigth}
            className={className}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M3 6.5L10 13.5L17 6.5" stroke="#FD4D00" strokeWidth="2.5" />
        </svg>
    );
};

export default ArrowDown;
