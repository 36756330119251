import React from "react";
import useSpotCoaching from "main-app/api/use-spot-coaching";
import { CARE_TEAM_EMAIL } from "main-app/constants";
import { isNullOrUndefined } from "common/utils/gates";

interface IProps {
    className?: string;
    text?: string;
}

const CareLinkEmail: React.FC<IProps> = ({ className }) => {
    const { data: productType } = useSpotCoaching();

    const productTypeData = isNullOrUndefined(productType)
        ? {
              supportEmail: CARE_TEAM_EMAIL.split(":")[1],
              supportTeamName: "Wiser Care"
          }
        : productType;

    return (
        <a href={`mailto:${productTypeData?.supportEmail}`} className={className}>
            {productTypeData?.supportEmail}
        </a>
    );
};

export default CareLinkEmail;
