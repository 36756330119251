import { GoalsSortType } from "main-app/shared/types/sort";
import {
    useArchiveBehavioralChange,
    useArchivedBehavioralChangeData,
    useBehavioralChangeData,
    useCreateBehavioralChange,
    useEditBehavioralChange,
    useSortBehavioralChange,
    useUnarchiveBehavioralChange
} from "../api/behavioral-goal.api";

type Options = {
    userId: number;
    sort: GoalsSortType;
};

export function useBehavioralApi({ userId, sort }: Options) {
    const {
        data: behavioralChanges,
        isLoading: isLoadingBehavioralChanges,
        error: behavioralError
    } = useBehavioralChangeData(userId, sort);
    const { data: archivedBehavioralChanges, error: archiveError } = useArchivedBehavioralChangeData(userId);
    const createBehavioralChange = useCreateBehavioralChange(userId);
    const editBehavioralChange = useEditBehavioralChange(userId);
    const sortBehavioralChange = useSortBehavioralChange(userId);
    const archiveBehavioralChange = useArchiveBehavioralChange(userId);
    const unarchiveBehavioralChange = useUnarchiveBehavioralChange(userId);

    return {
        behavioralChanges,
        isLoadingBehavioralChanges,
        behavioralError,
        archivedBehavioralChanges,
        archiveBehavioralChange,
        archiveError,
        createBehavioralChange,
        editBehavioralChange,
        sortBehavioralChange,
        unarchiveBehavioralChange
    };
}
