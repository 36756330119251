import React, { useCallback, useMemo, useRef } from "react";
import classNames from "classnames";
import { isNullOrUndefined } from "common/utils/gates";
import { isUnassignedModule } from "main-app/utils/gates/module";
import { useTranslation } from "react-i18next";

import { withTranslation } from "common/utils/lang";
import { UserEnrollModule } from "main-app/models/user";
import { Status } from "main-app/constants";

import { ModuleStatus } from "./ModuleStatus";
import useHover from "common/hooks/use-hover";
import useUpdateEffect from "common/hooks/use-update-effect";

type Props = {
    isActive: boolean;
    module: UserEnrollModule | null;
    order?: number;
    isCollapsed: boolean;
    unAssignCount: number;
    itemIndex: number;
    removedHoverIndex?: number;
    isLastItem: boolean;
    className?: string;
    moduleName?: string | React.ReactNode;
    canClick?: boolean;
    onHover?: (index: number) => void;
    onClick: (module: UserEnrollModule, moduleIndex: number) => void;
    onUnassignedClick?: () => void;
};

export const ModuleItem = ({
    isActive,
    module,
    order,
    isCollapsed,
    unAssignCount,
    itemIndex,
    isLastItem,
    removedHoverIndex,
    className = "",
    moduleName,
    canClick = true,
    onClick,
    onUnassignedClick,
    onHover
}: Props) => {
    const hoverRef = useRef();
    const { t } = useTranslation();
    const hasModule = !isNullOrUndefined(module) && !isUnassignedModule(module);
    const hasUnassignedModules = !isCollapsed && unAssignCount > 0;
    const isHovered = useHover(hoverRef);

    const onModuleItemClick = useCallback(() => {
        if (!canClick) {
            return;
        }

        if (hasUnassignedModules) {
            onUnassignedClick?.();
        } else {
            onClick(module, order);
        }
    }, [hasUnassignedModules, module, order, onUnassignedClick, onClick]);

    const title = useMemo(() => {
        if (hasUnassignedModules) {
            return t(`+ {{unAssignCount}} More`, { unAssignCount });
        }

        if (hasModule) {
            return moduleName ?? withTranslation(module.name);
        }

        return moduleName ?? t("Unassigned");
    }, [hasUnassignedModules, unAssignCount, hasModule, module]);

    useUpdateEffect(() => {
        if (isHovered && itemIndex > 0) {
            onHover?.(itemIndex - 1);
        } else {
            onHover?.(null);
        }
    }, [isHovered]);

    const showOrder = !hasUnassignedModules && !isNullOrUndefined(order);
    const isUnassigned = showOrder && !hasModule;

    return (
        <li
            className={classNames("module-navigation__item", className, { active: isActive, "not-allowed": !canClick })}
            onClick={onModuleItemClick}
            data-testid="module-navigation-item"
            ref={hoverRef}
        >
            <ModuleStatus isActive={isActive} status={hasModule ? module?.status : Status.Queued} />
            <p
                className={classNames("module-navigation__item_title", {
                    active: isActive,
                    "no-border": isHovered || removedHoverIndex === itemIndex,
                    "is-last": isLastItem
                })}
            >
                {showOrder && <span className="font-extrabold mr-1">{order}: </span>}
                <span className={classNames({ "font-italic": isUnassigned })}>{title}</span>
            </p>
        </li>
    );
};
