import Card from "main-app/shared/card/Card";
import React, { useMemo, useState } from "react";

import ChevronToggle from "main-app/shared/chevron-toggle/ChevronToggle";
import Accordion from "main-app/shared/accordion/Accordion";
import { CalendarAccount } from "main-app/entities/coach-calendar";

import { CalendarPrimaryItem } from "./CalenadarPrimaryItem";

import "./styles.scss";

type Props = {
    data: CalendarAccount[];
};

export const CalendarPrimary = ({ data }: Props) => {
    const [active, setActive] = useState(false);

    const primaryProfile = useMemo(() => data?.find(calendar => calendar.coachHasPrimaryCalendarProfile), [data]);

    const primaryCalendar = useMemo(
        () => primaryProfile?.profileCalendars?.find(calendar => calendar.isCalendarPrimary),
        [primaryProfile]
    );

    return (
        <Card className="calendar-primary text-left">
            <div className="calendar-primary__header cursor-pointer" onClick={() => setActive(!active)}>
                <div className="flex-1">
                    <span className="color-brand font-extrabold">
                        {primaryProfile ? `${primaryProfile?.profileName}: ` : "Select a Primary Calendar"}
                    </span>
                    <span>{primaryCalendar?.calendarName}</span>
                </div>
                <div>
                    <ChevronToggle active={active} />
                </div>
            </div>
            <Accordion active={active}>{data?.map(calendar => <CalendarPrimaryItem calendar={calendar} />)}</Accordion>
        </Card>
    );
};
