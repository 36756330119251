import React, { memo } from "react";
import classNames from "classnames";
import User from "main-app/models/user";
import Avatar from "main-app/shared/avatar/Avatar";
import { SimulabBadMessage } from "../messages/SimulabBadMessage";

import "./../styles.scss";

type Props = {
    user: User;
    text: string;
    className?: string;
};

export const ParticipantFeedbackBadMessage = memo(({ user, text, className = "" }: Props) => {
    return (
        <div className={classNames("participant-report-item", className)} data-testid="participant-report-item">
            <div className="participant-message-row">
                <div className="participant-message-row__wrapper">
                    <div className="participant-message-row__bubble">
                        <p className="m-0">{text}</p>
                    </div>
                    <Avatar url={user?.photo} width={30} height={30} className="participant-message-row__avatar" />
                </div>
            </div>

            <SimulabBadMessage viewOnly={true} className="p-0" />
        </div>
    );
});
