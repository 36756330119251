import { FeedbackTypes, SimulabReport } from "main-app/entities/simulab";
import User from "main-app/models/user";
import React, { memo } from "react";
import { OwlFeedbackStageItem } from "./OwlFeedbackStageItem";
import { ParticipantFeedbackBadMessage } from "./ParticipanFeedbackBadMessage";
import { SimulabChatMessageRow } from "../messages/SimulabChatMessageRow";

type Props = {
    report: SimulabReport;
    user: User;
};

export const FeedbackView = memo(({ report, user }: Props) => {
    if (report?.type === FeedbackTypes.PRACTICE_LAB_OWL_FEEDBACK) {
        return <OwlFeedbackStageItem className="simulab-report-modal__feedback" reportData={report} />;
    }

    if (report?.type === FeedbackTypes.PRACTICE_LAB_COACHEE_MESSAGE) {
        return (
            <div className="simulab-report-modal__coachee" data-testid="simulab-message-row-item">
                <SimulabChatMessageRow
                    message={report.message}
                    isActiveMessage={false}
                    isMyMessage={false}
                    coachee={report.coachee}
                    user={user}
                    onClickMessageRow={() => {}}
                />
            </div>
        );
    }

    if (report?.type === FeedbackTypes.PRACTICE_LAB_REJECTED_QUESTION) {
        return (
            <ParticipantFeedbackBadMessage
                user={user}
                text={report.message}
                className="simulab-report-modal__participant"
            />
        );
    }

    return null;
});
