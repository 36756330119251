import { useCallback } from "react";
import { type QueryFunction, type QueryObserverOptions, type UseQueryResult, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { http } from "common/http";
import { ServerStateKeys } from "main-app/constants";
import Urls from "main-app/api/urls";

interface IAvailableGuestSessionModel {
    id: number;
    startTime: string;
    endTime: string;
}

interface IAvailableGuestSessionDto {
    id: number;
    start: string;
    end: string;
}

type TGetAvailableGuestSessionQueryParams = QueryObserverOptions & {
    id?: number;
};

type TUseGetAvailableGuestSessions = UseQueryResult<IAvailableGuestSessionModel[], AxiosError>;

function availableGuestSessionsAdapter(dtos: IAvailableGuestSessionDto[]): IAvailableGuestSessionModel[] {
    return dtos.map(
        ({ end, start, ...rest }: IAvailableGuestSessionDto): IAvailableGuestSessionModel => ({
            ...rest,
            startTime: start,
            endTime: end
        })
    );
}

const getAvailableGuestSessions: QueryFunction<IAvailableGuestSessionDto[], [string, { id: number }]> = async ({
    queryKey
}): Promise<IAvailableGuestSessionDto[]> => {
    const [_key, { id: originalSessionId }] = queryKey;
    const { data } = await http.get(Urls.getAvailableGuestSessions({ originalSessionId }));

    return data;
};

function useGetAvailableGuestSessions({
    id,
    ...params
}: TGetAvailableGuestSessionQueryParams): TUseGetAvailableGuestSessions {
    return useQuery({
        queryKey: [ServerStateKeys.AvailableGuestSessions, { id }],
        queryFn: useCallback(getAvailableGuestSessions, []),
        select: useCallback(availableGuestSessionsAdapter, []),
        enabled: params.enabled
    });
}

export {
    useGetAvailableGuestSessions,
    type IAvailableGuestSessionModel,
    type TUseGetAvailableGuestSessions,
    type TGetAvailableGuestSessionQueryParams
};
