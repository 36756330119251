import React from "react";
import Avatar from "main-app/shared/avatar/Avatar";
import GuestBadge from "main-app/svgs/GuestBadge";

import "../features/guest-participant/ui/styles.scss";

type Props = {
    url: string;
    alt?: string;
    badgeIcon?: React.ReactNode;
};

export const GuestParticipantAvatar = ({ url, alt = "guest participant photo", badgeIcon = null }: Props) => {
    return (
        <div className="guest-participant-avatar">
            <Avatar
                url={url}
                alt={alt}
                badgeIcon={[
                    badgeIcon,
                    <GuestBadge width={20} height={20} className="guest-participant-avatar-badge" />
                ]}
            />
        </div>
    );
};
