import React from "react";

import UserHeaderDropdown from "main-app/elements/participant-layout-header/UserHeaderDropdown";
import { useAuthContext } from "main-app/context/Auth";
import { useOnboardingStoreContext } from "../onboardingStore";
import OnBoardingContainer from "../OnBoardingContainer";
import ProductTypeLogo from "main-app/shared/ProductTypeLogo";

import "main-app/assets/onboarding.scss";

interface IProps {
    header?: React.ReactNode;
    pageTitle?: React.ReactNode;
    children?: React.ReactNode;
}

const OnboardingLayout: React.FC<IProps> = ({ children, header = null, pageTitle = null }) => {
    const { user } = useAuthContext();
    const { logout } = useAuthContext();
    const { setStoreData } = useOnboardingStoreContext();

    const handleLogout = async e => {
        e.preventDefault();

        logout();
        setStoreData({});
    };

    return (
        <div className={"wrapper-onboarding flex-column"}>
            <header className="header-sticky">
                <div className="container">
                    <nav className="navbar navbar-expand-lg">
                        <ProductTypeLogo width={26} heigth={34} />
                        <span className="header-center mx-auto order-3 order-md-2 mt-4 mt-md-0">{header}</span>
                        <div className="order-2 order-md-3">
                            <UserHeaderDropdown handleLogout={handleLogout} user={user} showOnMobile />
                        </div>
                    </nav>
                </div>
            </header>
            <main className="main w-100">
                <div className="container">
                    {pageTitle && (
                        <div className="page-title">
                            <h1 className="h1 font-extrabold text-center mb-6">{pageTitle}</h1>
                        </div>
                    )}
                    <OnBoardingContainer>{children}</OnBoardingContainer>
                </div>

                <footer className="footer-onboarding"></footer>
            </main>
        </div>
    );
};

export default OnboardingLayout;
