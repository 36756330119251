import React, { type FC, type ReactElement } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

type TGuestParticipantBadgeProps = {
    isVisible: boolean;
};

const GuestParticipantBadge: FC<TGuestParticipantBadgeProps> = ({
    isVisible
}: TGuestParticipantBadgeProps): ReactElement => {
    const { t } = useTranslation();

    return (
        <div className={classNames("swap-session__accordion__badge", { hidden: !isVisible })}>
            <span className="font-sm">
                {t("participantSide.swapSessionsScreen.sessionAccordion.preferredWeekBadge.label")}
            </span>
        </div>
    );
};

export { GuestParticipantBadge };
