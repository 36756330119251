import React from "react";
import { useTranslation } from "react-i18next";

import Modal from "main-app/shared/modal";
import CrossSvg from "main-app/svgs/CrossSvg";
import { capitalized } from "main-app/utils/common";
import Avatar from "main-app/shared/avatar/Avatar";

import "./styles.scss";

interface IProps {
    show: boolean;
    onClose: () => void;
    participant: any; // todo add model
}

const RsvpModal: React.FC<IProps> = ({ show, onClose, participant }) => {
    const { t } = useTranslation();

    return (
        <Modal
            show={show}
            onClose={onClose}
            modalBodyClass="rsvp-modal"
            headerContent={
                <div className="p-3">
                    <h4 className="h4 font-extrabold m-0">{t("RSVP Note")}</h4>
                </div>
            }
        >
            <div className="rsvp-modal__body">
                <div className="d-flex align-items-center">
                    <div className="mr-5">
                        <Avatar url={participant.photo} width={80} height={80} />
                    </div>
                    <div>
                        <p className="font-extrabold">
                            {participant.first_name}'s {t("RSVP Says")}:{" "}
                            <span className="color-brand">{capitalized(participant.invitation.status)}</span>
                        </p>
                        <p className="color-gray font-italic">{participant.invitation.comment}</p>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default RsvpModal;
