import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { Option } from "common/models/option";

import { GoalsSortType } from "main-app/shared/types/sort";
import WithMenuCard from "main-app/shared/card/WithMenuCard";
import TextInput from "main-app/shared/input/TextInput";
import Button from "main-app/shared/button/Button";
import { isEmptyString } from "common/utils/gates";
import { COACHING_PLAN_ARCHIVE_ITEMS, COACHING_PLAN_MENU_ITEMS } from "main-app/constants";

import "./styles.scss";

type Props = {
    goalIndex: number;
    initialValue: string;
    isArchived: boolean;
    sort: GoalsSortType;
    onArchiveClick: (index: number, id: number) => void;
    onBlur: (index: number) => void;
};

export const CoachingGoalCard = ({ goalIndex, isArchived, initialValue, onArchiveClick, onBlur }: Props) => {
    const { t } = useTranslation();

    const { formState, register, getValues, setValue, setError, clearErrors } = useFormContext();
    const goal = getValues(`goals.${goalIndex}`);
    const showFocus = goalIndex === 0 && !goal.description;
    const [isEdit, setIsEdit] = useState(showFocus);

    const onBlurInput = () => {
        if (!isEdit) {
            return;
        }

        const value = getValues(`goals.${goalIndex}.description`);

        const hadInitialValue = isEmptyString(value) && initialValue;
        const hasInitialValue = initialValue.length > 0 && value === initialValue;

        if (hadInitialValue) {
            setError(`goals.${goalIndex}.description`, { message: t("Field is required") });
            return;
        }

        setIsEdit(false);

        if (hasInitialValue) {
            return;
        }

        clearErrors();
        onBlur(goalIndex);
    };

    const onKeyClick = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Escape") {
            const value = getValues(`goals.${goalIndex}.description`);
            const hadInitialValue = !isEmptyString(initialValue) && value !== initialValue;
            setIsEdit(false);

            if (hadInitialValue) {
                setValue(`goals.${goalIndex}.description`, initialValue);
                return;
            }

            if (value && isEmptyString(initialValue)) {
                setValue(`goals.${goalIndex}.description`, "");
                clearErrors();
            }

            onBlur(goalIndex);
            clearErrors();
            return;
        }

        if (e.key === "Enter" && !e.shiftKey) {
            onBlurInput();
        }
    };

    const onClickCard = () => {
        if (isArchived) {
            return;
        }

        setIsEdit(true);
    };

    const onMenuItemClick = (item: Option) => {
        if (item.value === "archive" || item.value === "unarchive") {
            onArchiveClick(goalIndex, goal.id);
            return;
        }

        if (isArchived) {
            return;
        }

        setIsEdit(true);
    };

    return (
        <WithMenuCard
            cardClassName={classNames("pr-4", { "bg-light-accent": isArchived })}
            menuItems={isArchived ? COACHING_PLAN_ARCHIVE_ITEMS : COACHING_PLAN_MENU_ITEMS}
            onMenuItemClick={onMenuItemClick}
            menuOffsets={{ right: 5 }}
            onClickOutside={onBlurInput}
            showContextMenu={!isEdit}
        >
            <Button variant="default" className="p-0 m-0 d-block text-left w-100" onClick={onClickCard}>
                {!isEdit ? (
                    <>
                        <p className="m-0 color-gray">{goal.description}</p>
                    </>
                ) : (
                    <>
                        <p className="mb-10 font-italic">
                            {t("What are you hoping to accomplish in this coaching engagement?")}
                        </p>
                        <TextInput
                            groupClassName="m-0 w-100 gray-placeholder"
                            className="font-light"
                            variant="rect"
                            textArea
                            autoFocus={isEdit}
                            placeholder={t("Start typing...")}
                            errorClass="input-error-small"
                            name={`goals.${goalIndex}.description`}
                            dynamicHeight
                            register={register}
                            maxLength={1024}
                            onKeyDown={onKeyClick}
                            genericError={formState?.errors?.goals?.[goalIndex]?.description?.message}
                        />
                    </>
                )}
            </Button>
        </WithMenuCard>
    );
};
