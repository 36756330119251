import { useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { http } from "common/http";
import { QueryKey } from "../constants";
import { SimulabConversation } from "../models";
import { SimulabConversationApi } from "../models/conversation";
import { SimulabMessageData, SimulabMessagesDataApi } from "../models/simulab-message";
import { Urls } from "./urls";

export const getConversation = async ({ queryKey }) => {
    const [_key, { id }] = queryKey;
    const { data } = await http.get(Urls.getConversation(id));
    return data;
};

export const getNewMessages = async ({ queryKey }): Promise<SimulabMessagesDataApi> => {
    const [_key, { id }] = queryKey;
    const { data } = await http.get(Urls.getNewMessages(id));
    return data;
};

const getMessages = async ({ queryKey }) => {
    const [_key, { id }] = queryKey;
    const { data } = await http.get(Urls.getMessages(id));
    return data;
};

type Options = {
    conversationId: string;
};

export function useSimulabConversation({ conversationId }: Options) {
    return useQuery<SimulabConversationApi, AxiosError, SimulabConversation>(
        [QueryKey.SimulabConversation, { id: conversationId }],
        getConversation,
        {
            select: useCallback(data => new SimulabConversation(data), [])
        }
    );
}

export function useSimulabNewMessages({ conversationId }: Options) {
    return useQuery<SimulabMessagesDataApi, AxiosError, SimulabMessageData>(
        [QueryKey.SimulabNewMessages, { id: conversationId }],
        getNewMessages,
        {
            select: useCallback((data: SimulabMessagesDataApi) => new SimulabMessageData(data), [])
        }
    );
}

export function useSimulabMessages({ conversationId }: Options) {
    return useQuery<SimulabMessagesDataApi, AxiosError, SimulabMessageData>(
        [QueryKey.SimulabMessages, { id: conversationId }],
        getMessages,
        {
            select: useCallback((data: SimulabMessagesDataApi) => new SimulabMessageData(data), [])
        }
    );
}
