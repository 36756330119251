import React, { useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { datadogLogs } from "@datadog/browser-logs";

import { useAuthContext } from "main-app/context/Auth";
import Modal from "main-app/shared/modal";
import useReflectionSubmit from "main-app/api/mutations/use-reflection-submit";
import useReflectionQuestions from "main-app/api/use-reflection-questions";
import DiganosticResponses from "./DiagnosticQuestions";
import ReflectionQuestions from "./ReflectionQuestions";
import useSkipReflection from "main-app/api/mutations/use-skip-reflection";
import AssessmentResponses from "../assessment-responses/AssessmentResponses";
import LabelDropdown from "main-app/shared/dropdown/LabelDropdown";
import CoachRating from "../coach-rating/CoachRating";
import { CoachRatingAskedFrom } from "main-app/constants";
import useCoachingSessions from "main-app/api/use-coaching-sessions";
import Heading from "common/components/Heading/Heading";
import { withTranslation } from "common/utils/lang";
import { isEmptyString, isNullOrUndefined } from "common/utils/gates";
import Coach from "main-app/models/coach";
import { formatMonthDayYear } from "main-app/utils/date";
import Button from "main-app/shared/button/Button";
import { Spinner } from "main-app/shared/spinner";
import { http } from "common/http";
import Urls from "main-app/api/urls";
import { WarningMessage } from "main-app/components/onboarding/components/WarningMessage";

interface IProps {
    show: boolean;
    moduleId: number;
    sessionId: number;
    onClose: () => void;
}

const ReflectionJourneyModal: React.FC<IProps> = ({ show, moduleId, sessionId, onClose }) => {
    const { t } = useTranslation();
    const { user } = useAuthContext();
    const submitModuleReflection = useReflectionSubmit();
    const submitSkipReflection = useSkipReflection(sessionId);
    const { data: sessions } = useCoachingSessions();
    const { data, isLoading: isLoadingReflection } = useReflectionQuestions(moduleId);
    const [coachRating, setCoachRating] = useState(null);
    const [error, setError] = useState(null);
    const [activeDropdown, setActiveDropdown] = useState(false);

    useEffect(() => {
        if (activeDropdown) {
            sendDDReflectionOpenLogs();
        }
    }, [activeDropdown]);

    const coachSession = useMemo(
        () =>
            sessions?.live_session?.session_id === sessionId
                ? sessions?.live_session
                : sessions?.passed_sessions?.find(session => session.session_id === sessionId),
        [sessions]
    );

    const module = useMemo(() => user?.enrolledModules?.find(module => module.id === moduleId), [moduleId, user]);

    const coach = useMemo(() => {
        if (coachSession) {
            return !isNullOrUndefined(coachSession?.coach) ? new Coach(coachSession?.coach) : null;
        }
        return null;
    }, [coachSession]);

    const sendDDReflectionOpenLogs = () => {
        datadogLogs.logger.info("Post Session Reflection Popup", {
            name: "POST SESSION REFLECTION POPUP",
            userId: user.id,
            userName: `${user.firstName} ${user.lastName}`,
            opened: activeDropdown,
            org: user.organization,
            cohort: user.cohort,
            previous_answers: data?.activeDiagnosticAnswers
        });
    };

    const shapeWithDynamicValidation = useMemo(() => {
        const validationSchema = {};
        if (data?.diagnosticQuestions) {
            validationSchema["diagnostic"] = yup.object(
                data?.diagnosticQuestions?.reduce((validationMap, question) => {
                    validationMap[question.uuid] = yup.string().required(t("Answer is required")).nullable(true);
                    return validationMap;
                }, {})
            );
        }
        return validationSchema;
    }, [data]);

    const methods = useForm({
        resolver: yupResolver(yup.object(shapeWithDynamicValidation))
    });

    const {
        handleSubmit,
        formState: { errors }
    } = methods;

    const onSubmit = async values => {
        const moduleReflectionAnswers = values?.module_reflection
            ? Object.entries(values?.module_reflection).reduce((acc: any[], [question_uuid, answerText]: any) => {
                  const answer = {};
                  answer["question_uuid"] = question_uuid;
                  answer["answer"] = answerText;
                  acc.push(answer);
                  return acc;
              }, [])
            : [];

        const diagnosticResponsesAnswers = values?.diagnostic
            ? Object.entries(values?.diagnostic).reduce((acc: any[], [question_uuid, optionUUid]: any) => {
                  const answer = {};
                  answer["question_uuid"] = question_uuid;
                  answer["option_uuid"] = optionUUid;
                  answer["question"] = data?.diagnosticQuestions?.find(
                      question => question.uuid === question_uuid
                  )?.question;
                  answer["answer"] = withTranslation(
                      data?.diagnosticQuestions
                          ?.find(item => item.uuid === question_uuid)
                          ?.options.find(option => option.uuid === optionUUid)?.response
                  );
                  acc.push(answer);
                  return acc;
              }, [])
            : [];

        const moduleReflection = {
            type: "module_reflection",
            answer_list: moduleReflectionAnswers,
            question_list: data?.reflection?.module_reflection || []
        };

        const diagnosticResponses = {
            type: "diagnostic",
            answer_list: diagnosticResponsesAnswers,
            question_list: data?.diagnosticQuestions,
            summary_list: data?.reflection?.self_assessment?.summary_list || []
        };

        if (
            isNullOrUndefined(coachRating) &&
            moduleReflection?.answer_list?.length === 0 &&
            diagnosticResponses?.answer_list?.length === 0
        ) {
            setError("Rating is required");
            return;
        }

        try {
            if (coachRating) {
                await http.post(Urls.submitRating(), {
                    participant: coachRating.participant,
                    coach: coachRating.coach,
                    rating: coachRating.rating,
                    question: coachRating.question,
                    free_response: coachRating?.free_response ?? "",
                    coaching_session: coachRating?.coaching_session
                });
            }

            const data = [];

            if (moduleReflection?.answer_list?.length > 0) {
                data.push(moduleReflection);
            }

            if (diagnosticResponses?.answer_list?.length > 0) {
                data.push(diagnosticResponses);
            }

            if (data.length > 0) {
                submitModuleReflection.mutate(
                    { module_id: moduleId, responses: data },
                    {
                        onSuccess: () => {
                            onClose();
                        }
                    }
                );
                return;
            }

            onClose();
        } catch (e) {
            console.log(e);
        }
    };

    const onRatingClick = (data: any) => {
        setCoachRating(data);
        setError(null);
    };

    const onSaveFreeResponse = (_: number, response: string) => {
        setCoachRating(data => ({ ...data, free_response: response }));
    };

    const onClickSkip = () => {
        submitSkipReflection.mutate(null, {
            onSuccess: () => {
                onClose();
            }
        });
    };

    const onToggleActiveDropdown = () => {
        setActiveDropdown(prev => {
            return !prev;
        });
    };

    const sessionTitle = useMemo(() => {
        const sessionName = coachSession?.session_type_pref_lang?.name;
        const sessionTime = formatMonthDayYear(coachSession?.session_time);

        if (sessionName && sessionTime) {
            return `${sessionName}: ${sessionTime}`;
        }

        return "";
    }, [coachSession]);

    return (
        <>
            <Modal
                show={show}
                onClose={onClickSkip}
                enableOutSideClick={false}
                closeOnEscapePress={false}
                disableBodyScroll={true}
                showCloseIcon={true}
                modalBodyClass="journey-reflection-modal"
                headerContent={
                    <div className="p-3">
                        <Heading tag="h2" className="text-center m-0 reflection-header-title" fontSize={24}>
                            {t("How was your session?")}
                        </Heading>

                        {!isEmptyString(sessionTitle) ? (
                            <p className="text-center fs-14 color-gray m-0">{sessionTitle}</p>
                        ) : null}
                    </div>
                }
            >
                <div className="journey-reflection-modal-body">
                    <FormProvider {...methods}>
                        <div className="my-2">
                            <CoachRating
                                orderNumber={null}
                                askedFrom={CoachRatingAskedFrom.POST_MODULE_REFLECTION_POPUP}
                                coachSessionId={sessionId}
                                saveCoachRating={onSaveFreeResponse}
                                onStarSelect={onRatingClick}
                                textInputBgVariant="light"
                                sendFreeResponse={false}
                                coachData={coach}
                            />
                            <WarningMessage message={error} />
                        </div>
                        {!isNullOrUndefined(module) && (
                            <Heading tag="h2" fontSize={16} className="modal-module-title">
                                {t("{{moduleName}} Module Questions", { moduleName: withTranslation(module?.name) })}
                            </Heading>
                        )}
                        {data?.activeDiagnosticAnswers?.length ? (
                            <LabelDropdown
                                text={`${activeDropdown ? t("Hide") : t("Show")} ${t("My Previous Responses")}`}
                                active={activeDropdown}
                                onToggle={onToggleActiveDropdown}
                                className={activeDropdown ? "mb-3" : "mb-40"}
                            />
                        ) : null}

                        {activeDropdown && (
                            <AssessmentResponses
                                diagnosticAnswers={data?.activeDiagnosticAnswers}
                                title={t("Initial Assessment Responses")}
                                className="mb-40 text-left"
                                variant="offboarding"
                            />
                        )}

                        {isLoadingReflection && (
                            <div className="my-3 p-5 d-flex align-items-center justify-content-center">
                                <Spinner />
                            </div>
                        )}

                        {data?.diagnosticQuestions?.length > 0 && (
                            <DiganosticResponses diagnosticQuestions={data?.diagnosticQuestions} errors={errors} />
                        )}

                        <ReflectionQuestions moduleReflection={data?.reflection?.module_reflection} errors={errors} />

                        <div className="text-center mb-30">
                            <Button onClick={handleSubmit(onSubmit)} isBusy={isLoadingReflection}>
                                {t("Submit")}
                            </Button>
                        </div>

                        <p className="text-center m-0 color-gray fs-14">
                            {t(
                                "Your responses will be anonymized and aggregated with fellow participants before being shared back to your organization."
                            )}
                        </p>
                    </FormProvider>
                </div>
            </Modal>
        </>
    );
};

export default ReflectionJourneyModal;
