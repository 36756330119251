import React from "react";
import classNames from "classnames";

import "./styles.scss";

type Props = {
    checked: boolean;
    label?: string;
    placeholder?: string;
    name?: string;
    full?: boolean;
    checkMarkVariant?: "default" | "full-fill";
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const Checkbox = ({ checked, label, placeholder, name, full, checkMarkVariant = "default", onChange }: Props) => {
    const checkBoxVariants = {
        default: "default",
        "full-fill": "full-fill"
    };

    return (
        <div className="d-flex align-items-center justify-content-center">
            <label className="checkbox-custom">
                <input type="checkbox" checked={checked} onChange={onChange} name={name} placeholder={placeholder} />
                <span className={classNames("checkbox-custom__checkmark", checkBoxVariants[checkMarkVariant])} />
            </label>
            {label && <p className="checkbox-custom__label color-gray">{label}</p>}
        </div>
    );
};

export default Checkbox;
