import React from "react";
import { sanitizeContent } from "main-app/utils/common";
import { withTranslation } from "common/utils/lang";

interface IProps {
    shortSummary?: {
        en: string;
    };
    summarySubtitle?: {
        en: string;
    };
    summary?: {
        en: string;
    };
}

const SummaryBlock: React.FC<IProps> = ({ shortSummary, summary, summarySubtitle }) => {
    return (
        <div className="text-center mb-2">
            <h2 className="h5 color-brand text-uppercase font-extrabold">{withTranslation(shortSummary)}</h2>
            <div
                className="text-container text-container--sm content-text font-bold"
                dangerouslySetInnerHTML={{ __html: sanitizeContent(withTranslation(summary)) }}
            />
        </div>
    );
};

export default SummaryBlock;
