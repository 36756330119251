import React, { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";

import Button from "main-app/shared/button/Button";
import { useRespondentSurvey, useRespondentSurveySubmit } from "main-app/entities/survey";
import useQueryParam from "common/hooks/use-query-param";
import { getErrorMessages } from "common/utils/get-error-messages";
import { WarningMessage } from "main-app/components/onboarding/components/WarningMessage";
import { FullSpinner } from "main-app/shared/spinner";
import { FeedbackSurvey } from "./FeedbackSurvey";
import { Survey } from "./Survey";
import ErrorTooltip from "main-app/shared/error-page/ErrorTooltip";
import OwlErrorLogo from "main-app/svgs/OwlErrorLogo";
import CareTeamLink from "main-app/shared/CareTeamLink";
import { createValidationSchema } from "../validation";
import { SurveySubmitData } from "main-app/entities/survey/models";

import "./styles.scss";

export const StakeHolderSurvey = () => {
    const { t } = useTranslation();
    const query = useQueryParam();
    const accessCode = query.get("access_code");
    const [error, setError] = useState(null);
    const {
        data,
        isLoading,
        isSuccess,
        isError,
        isFetching,
        error: surveyError,
        refetch
    } = useRespondentSurvey(accessCode);
    const submitSurvey = useRespondentSurveySubmit(accessCode);
    const [surveySubmitLoading, setSurveySubmitLoading] = useState(false);

    const methods = useForm({
        shouldFocusError: true,
        resolver: data?.questions && isSuccess ? yupResolver(createValidationSchema(data.questions)) : undefined
    });

    useEffect(() => {
        if (data && isSuccess && !data.message) {
            methods.reset(
                data.questions.reduce((acc, question) => {
                    return {
                        [question.id]: {
                            free_response: null,
                            answer: null
                        }
                    };
                }, {})
            );
        }
    }, [data, isSuccess]);

    const { handleSubmit } = methods;

    const submitFeedback = useCallback(
        values => {
            const submitData: SurveySubmitData[] = Object.entries(values).map(
                ([key, value]: [key: string, value: { answer: string | number; free_response: string | null }]) => ({
                    question: +key,
                    answer: value?.answer ? +value.answer : null,
                    free_response: value?.free_response || null
                })
            );
            setSurveySubmitLoading(true);
            submitSurvey.mutate(
                { response: submitData },
                {
                    onSuccess: () => {
                        refetch().finally(() => {
                            setSurveySubmitLoading(false);
                        });
                    },
                    onError: error => setError(getErrorMessages(error))
                }
            );
        },
        [submitSurvey, refetch]
    );

    if ((isLoading || isFetching || surveySubmitLoading) && !isSuccess) {
        return <FullSpinner />;
    }

    if (isError && surveyError) {
        return (
            <div className="d-flex flex-column align-items-center">
                <ErrorTooltip>
                    <p className="text-center m-0">{getErrorMessages(surveyError).join("")}</p>
                </ErrorTooltip>
                <div className="mt-5 d-flex justify-content-center">
                    <OwlErrorLogo />
                </div>
                <CareTeamLink variant="brand" text="Need help?" className="care-link" />
            </div>
        );
    }

    return (
        <main className="stakeholder-survey">
            <FormProvider {...methods}>
                {data.message ? (
                    <FeedbackSurvey status={data.message} isSelfAssessment={data?.isSelfAssessment} />
                ) : (
                    <Survey survey={data} />
                )}
            </FormProvider>
            {!data.message && (
                <>
                    <WarningMessage message={error} />
                    <div className="text-center">
                        <Button onClick={handleSubmit(submitFeedback)}>{t("Submit")}</Button>
                    </div>
                </>
            )}
        </main>
    );
};
