import Modal from "main-app/shared/modal";
import SimpleTextField from "common/components/SimpleTextField/SimpleTextField";
import { ParticipantData } from "main-app/models/participant-data-coach";
import { getTimeInRoleDuration } from "main-app/utils/common";
import React from "react";

interface IProps {
    participant: ParticipantData;
    show: boolean;
    onClose: () => void;
}

const ProfileModal: React.FC<IProps> = ({ participant, show, onClose }) => {
    return (
        <Modal show={show} onClose={onClose} modalBodyClass="profile-modal" disableBodyScroll={true}>
            <h2 className="h2 font-extrabold">Participant Profile</h2>
            <div className="row mb-2">
                <div className="col-6">
                    <SimpleTextField text={participant?.firstName} />
                </div>
                <div className="col-6">
                    <SimpleTextField text={participant?.lastName} />
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-12">
                    <SimpleTextField text={participant?.jobTitle} />
                </div>
            </div>
            <div className="row mb-4">
                <div className="col-6">
                    <SimpleTextField text={participant?.department} />
                </div>
                <div className="col-6">
                    <SimpleTextField text={participant?.officeLocation} />
                </div>
            </div>
            <div className="row mb-4">
                <div className="col-12">
                    <div className="row">
                        <div className="col-3 pr-0">
                            <span className="font-extrabold">Time in Current Role:</span>
                        </div>
                        <div className="col pl-5">
                            <span className="">
                                {" "}
                                {participant ? getTimeInRoleDuration({ participant, capitalized: true }) : null}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mb-80">
                <div className="col-12">
                    <div className="row">
                        <div className="col-3 pr-0">
                            <span className="font-extrabold"># of Direct Reports:</span>
                        </div>
                        <div className="col pl-5">
                            <span className="">{participant?.directReportsNumber} Direct Reports</span>
                        </div>
                    </div>
                </div>
            </div>
            <h2 className="h2 font-extrabold">Participant’s Manager Information</h2>
            <div className="row mb-2">
                <div className="col-6">
                    <SimpleTextField text={participant?.managerFirstName} />
                </div>
                <div className="col-6">
                    <SimpleTextField text={participant?.managerLastName} />
                </div>
            </div>
            <div className="row mb-50">
                <div className="col-12">
                    <SimpleTextField text={participant?.managerEmail} />
                </div>
            </div>
            <div className="text-center">
                <button className="btn btn--brand" onClick={onClose}>
                    Close
                </button>
            </div>
        </Modal>
    );
};
export default ProfileModal;
