import React, { useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import useBoolean from "common/hooks/use-boolean";
import ParticipantLayout from "main-app/layouts/ParticipantLayout";
import useCoachingSessions from "main-app/api/use-coaching-sessions";
import LiveNowLine from "main-app/shared/livenow-line/LiveNowLine";
import FullSpinner from "main-app/shared/spinner/FullSpinner";
import useLiveSessionCheck from "main-app/utils/hooks/use-live-session-check";
import CareTeamLink from "main-app/shared/CareTeamLink";
import { SessionScheduleStatus, ConfigKeys, ServerStateKeys } from "main-app/constants";
import useSpotCoaching from "main-app/api/use-spot-coaching";
import CoachTimeScheduleModal from "main-app/elements/coach-time-schedule-modal/CoachTimeScheduleModal";
import useIndividualSession from "main-app/api/mutations/use-individual-session-assign";
import useIndividualCoachingReschedule from "main-app/api/mutations/use-individual-coaching-reschedule";
import CoachInfo from "main-app/elements/coach-info/CoachInfo";
import PostReflectionModalWrapper from "main-app/elements/reflection-modals/PostReflectionModalWrapper";
import { useAuthContext } from "main-app/context/Auth";
import Notification from "main-app/shared/notification/Notification";
import { useStoreContext } from "main-app/context/GlobalStore";
import Heading from "common/components/Heading/Heading";
import { ReflectionPopupData } from "main-app/elements/reflection-modals/types";
import MultiSessionScheduleModal from "main-app/shared/modals/scheduling/ui/MultiSessionScheduleModal";
import { findSessionToSchedule, findThreeWaySession, getNotScheduledSessions } from "main-app/utils/session";
import Coach from "main-app/models/coach";
import { useQueryClient } from "@tanstack/react-query";

import SessionAccordion from "../group-info/SessionAccordion";
import LeftSidebar from "../dashboard/LeftSidebar";
import ScheduleBox from "./ScheduleBox";

import "./styles.scss";

interface IProps {}

const CoachinSessions: React.FC<IProps> = () => {
    const { t } = useTranslation();
    const { user } = useAuthContext();
    const queryClient = useQueryClient();
    const { data, isLoading, refetch } = useCoachingSessions();
    const { getConfig } = useStoreContext();
    const { data: productType } = useSpotCoaching();
    const [scheduledSession, setScheduledSession] = useState(null);
    const [reflectionModalData, setReflectionModalData] = useState<ReflectionPopupData>({
        show: false,
        moduleId: null,
        sessionId: null
    });
    const makeScheduleSession = useIndividualSession(scheduledSession?.session_id);
    const makeRescheduleSession = useIndividualCoachingReschedule(scheduledSession?.session_id);
    const { value: showCoachTimeSelectModal, setValue: setShowCoachTimeSelectModal } = useBoolean(false);
    const { value: showMultipleSessionModal, setValue: setShowMultipleSessionModal } = useBoolean(false);
    const [threeWaySessionSchedule, setThreeWaySessionSchedule] = useState(null);

    const session = data?.upcoming_sessions.find(session => session.session_time);

    useLiveSessionCheck({ date: session?.session_time }, () => {
        refetch();
    });

    useEffect(() => {
        refetch();
    }, []);

    const notScheduledSessions = useMemo(() => getNotScheduledSessions(data), [data, getNotScheduledSessions]);

    const threeWaySessionsCount = useMemo(
        () => notScheduledSessions?.filter(session => session.is_three_way_session)?.length,
        [notScheduledSessions]
    );

    const onTimePick = notification => {
        const data = {
            session_datetime: notification.slot.start,
            coach: scheduledSession?.coach?.id,
            tz: notification.tzid
        };

        return scheduledSession?.session_time
            ? makeRescheduleSession.mutateAsync(data)
            : makeScheduleSession.mutateAsync(data, {
                  onSuccess: async () => setThreeWaySessionSchedule(null) // WISER-2956
              });
    };

    const onScheduleClick = session => {
        setScheduledSession(session);
        setShowCoachTimeSelectModal(true);
        setShowMultipleSessionModal(false);
    };

    const onCloseScheduleModal = async bookedStatus => {
        setShowCoachTimeSelectModal(false);
        const { data } = await refetch();

        const notScheduledSessions = getNotScheduledSessions(data);

        const isInvalidStatusOrEmpty =
            bookedStatus !== SessionScheduleStatus.SCHEDULED ||
            !notScheduledSessions ||
            notScheduledSessions.length === 0;

        if (isInvalidStatusOrEmpty) {
            setScheduledSession(null);
            return;
        }

        const threeWaySession = findThreeWaySession(notScheduledSessions);
        if (threeWaySession) {
            setThreeWaySessionSchedule(threeWaySession);
        }

        const sessionToSchedule = findSessionToSchedule(notScheduledSessions, threeWaySession);
        setScheduledSession(sessionToSchedule);

        if (sessionToSchedule || threeWaySession) {
            setShowMultipleSessionModal(true);
        }
    };

    const onNoFeedbackClick = (sessionId: number, moduleId: number) => {
        setReflectionModalData({ show: true, sessionId, moduleId });
    };

    const onCloseReflectionModal = () => {
        setReflectionModalData({ show: false, sessionId: null, moduleId: null });
        queryClient.invalidateQueries([ServerStateKeys.CoachingSession]);
    };

    const onOpenMultiScheduleModal = session => {
        setScheduledSession(session);
        setShowMultipleSessionModal(true);
    };

    const onCloseMultiScheduleModal = () => {
        setShowMultipleSessionModal(false);
    };

    const onThreeWaySessionClick = session => {
        setThreeWaySessionSchedule(session);
    };

    if (isLoading) {
        return (
            <ParticipantLayout leftSidebar={<LeftSidebar />}>
                <FullSpinner />
            </ParticipantLayout>
        );
    }

    const hasEnrollments = getConfig(ConfigKeys.hasEnrolledModules);

    const isReflectionModalOpenableBasedOnProductTypeConfig: boolean = productType?.configs?.find(
        ({ title }) => title === "show-post-session-popup"
    )?.is_active;

    return (
        <>
            <ParticipantLayout
                leftSidebar={<LeftSidebar />}
                title={
                    <Heading textAlign="center" className="m-0">
                        {productType?.sessionPageName}
                    </Heading>
                }
            >
                <div className="sessions-wrapper">
                    {user?.offboardingPassed && !hasEnrollments && (
                        <div className="mb-5">
                            <Notification
                                heading={t("Congratulations, you've completed your {{title}} journey!", {
                                    title: productType?.titleData
                                })}
                                text={t(
                                    "You'll retain access to your account for the next couple months, in case you'd like to review any of the content."
                                )}
                            />
                        </div>
                    )}
                    {data?.live_session ? (
                        <>
                            <div className="mb-3">
                                <LiveNowLine />
                            </div>

                            <SessionAccordion isLive={true} session={data?.live_session} />
                        </>
                    ) : null}

                    <Heading
                        tag="h2"
                        fontSize={24}
                        textAlign="center"
                        className={classNames("mb-3", { "mt-50": data?.live_session })}
                    >
                        {t("Upcoming sessions")}
                    </Heading>

                    {notScheduledSessions.length > 0 && (
                        <div className="mb-3" data-testid="notscheduled-sessions">
                            <ScheduleBox
                                sessions={notScheduledSessions}
                                threeWayScheduled={threeWaySessionSchedule}
                                onScheduleClick={onScheduleClick}
                                onMultipleSessionClick={onOpenMultiScheduleModal}
                                setThreeWaySession={onThreeWaySessionClick}
                            />
                        </div>
                    )}

                    {data?.upcoming_sessions?.length > 0 ? (
                        data?.upcoming_sessions?.map(session =>
                            session.session_time ? (
                                <div className="mb-3" key={session.session_time} data-testid="upcoming-sessions">
                                    <SessionAccordion
                                        isUpcoming={true}
                                        session={session}
                                        onClickReschedule={onScheduleClick}
                                    />
                                </div>
                            ) : null
                        )
                    ) : (
                        <p className="color-gray font-italic">{t("No upcoming sessions")}</p>
                    )}

                    <Heading tag="h2" fontSize={24} textAlign="center" className="mb-3 mt-50">
                        {t("Past sessions")}
                    </Heading>

                    {data?.passed_sessions?.length > 0 ? (
                        data?.passed_sessions?.map(session => (
                            <div className="mb-3" key={session.session_time} data-testid="passed-sessions">
                                <SessionAccordion
                                    isPast={true}
                                    session={session}
                                    onNoFeedbackClick={onNoFeedbackClick}
                                    isReflectionModalOpenableBasedOnProductTypeConfig={
                                        isReflectionModalOpenableBasedOnProductTypeConfig
                                    }
                                />
                            </div>
                        ))
                    ) : (
                        <p className="color-gray font-italic">{t("No past sessions")}</p>
                    )}

                    <CoachInfo />

                    <CareTeamLink className="mb-50" />
                </div>

                <CoachTimeScheduleModal
                    show={showCoachTimeSelectModal}
                    onClose={onCloseScheduleModal}
                    key={scheduledSession?.session_id}
                    coach={new Coach(scheduledSession?.coach ?? {})}
                    title={scheduledSession?.session_type_pref_lang?.name}
                    showManagerInfoBlock={scheduledSession?.is_three_way_session}
                    timePickRequest={onTimePick}
                    sessionTime={scheduledSession?.session_time}
                    session={{ ...scheduledSession, ...scheduledSession?.session_type_pref_lang }}
                    sessionId={scheduledSession?.session_id}
                    scheduledSession={scheduledSession}
                />

                <MultiSessionScheduleModal
                    show={showMultipleSessionModal}
                    session={scheduledSession}
                    threeWaySession={threeWaySessionSchedule}
                    sessionCount={notScheduledSessions?.length - threeWaySessionsCount}
                    threeWaySessionsCount={threeWaySessionsCount}
                    onClose={onCloseMultiScheduleModal}
                    onSessionClick={onScheduleClick}
                />
            </ParticipantLayout>
            <PostReflectionModalWrapper
                show={reflectionModalData.show && isReflectionModalOpenableBasedOnProductTypeConfig}
                showOnlyOnTrigger={true}
                moduleId={reflectionModalData.moduleId}
                sessionId={reflectionModalData.sessionId}
                onClose={onCloseReflectionModal}
            />
        </>
    );
};

export default CoachinSessions;
