import React from "react";
import PageLoader from "main-app/shared/spinner/PageLoader";
import OnBoardingContainer from "./OnBoardingContainer";

const OnBoardingRedirect = () => {
    return (
        <OnBoardingContainer>
            <PageLoader />
        </OnBoardingContainer>
    );
};

export default OnBoardingRedirect;
