import React, { SVGProps } from "react";

interface IProps extends SVGProps<SVGSVGElement> {}

const OrangeStar: React.FC<IProps> = ({ height = 24, width = 24, ...rest }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <circle cx="12" cy="12" r="12" fill="#FD4D00" />
            <path
                d="M12 5L14.2496 9.16306L19 9.96556L15.64 13.341L16.3262 18L12 15.9231L7.67376 18L8.36 13.341L5 9.96556L9.75036 9.16306L12 5Z"
                fill="white"
            />
        </svg>
    );
};

export default OrangeStar;
