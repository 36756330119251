import React, { type FC, type ReactElement } from "react";

import Coach from "main-app/models/coach";
import type { IAvailableGuestSessionModel } from "main-app/api/use-get-available-guest-sessions";
import type { TEmptyCallback } from "main-app/shared/types/functions";

import type { IAvailableSessionDataGroupedByDay } from "../types";
import {
    GuestParticipantAccordionList as AccordionList,
    GuestParticipantAccordionHeading as AccordionHeading
} from ".";

type TGuestParticipantSlideProps = {
    title: string;
    sessions?: IAvailableSessionDataGroupedByDay[];
    coach?: Coach;
    handleSetSuggestedSession(session: IAvailableGuestSessionModel): void;
    handleOpenSwapSessionModal: TEmptyCallback;
    isPreferredWeek: boolean;
};

const GuestParticipantSlide: FC<TGuestParticipantSlideProps> = ({
    title,
    sessions,
    coach,
    handleSetSuggestedSession,
    handleOpenSwapSessionModal,
    isPreferredWeek
}: TGuestParticipantSlideProps): ReactElement => (
    <div className="swap-session__accordion__container">
        <AccordionHeading title={title} isPreferredWeek={isPreferredWeek} />
        <AccordionList
            handleSetSuggestedSession={handleSetSuggestedSession}
            handleOpenSwapSessionModal={handleOpenSwapSessionModal}
            sessions={sessions}
            coach={coach}
        />
    </div>
);

export { GuestParticipantSlide, type TGuestParticipantSlideProps };
