import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { AxiosError } from "axios";

import { isNullOrUndefined } from "common/utils/gates";

import { SimulabConversation, SimulabReport, SimulabStage } from "../models";
import { useSimulabConversation, useSimulabConversationFeedback } from "../api";
import { useSimulabMessages, useSimulabNewMessages } from "../api/use-simulab-conversation";
import { SimulabMessageData } from "../models/simulab-message";
import { SimulabSendMessageMutation, useSendSimulabMessage } from "../api/mutations/use-send-simulab-message";
import { defaultContextValues } from "../constants";
import { QueryObserverResult, RefetchOptions } from "@tanstack/react-query";

export interface SimulabContextType {
    simulabMessages: SimulabMessageData;
    conversation: SimulabConversation;
    hasError: boolean;
    newMessages: SimulabMessageData;
    sendMessage: SimulabSendMessageMutation;
    isLoading: boolean;
    selectedMessage: number | null;
    apiError: AxiosError | null;
    error: AxiosError | null;
    badMessage: boolean;
    isMessageUnselected: boolean;
    report: SimulabReport | null;
    showEndConversationModal: boolean;
    conversationStarted: boolean;
}

export interface SimulabActionContextType {
    selectMessage: (messageId: number) => void;
    onError: (error: AxiosError) => void;
    resetErrors: () => void;
    unselectMessage: (value: boolean) => void;
    setShowEndConversationModal: (value: boolean) => void;
    setReportData: (report: SimulabReport) => void;
    setConversationStarted: (value: boolean) => void;
    refetchConversation(
        opts?: RefetchOptions
    ): Promise<QueryObserverResult<SimulabConversation, AxiosError<unknown, any>>>;
}

export const SimulabContext = createContext<SimulabContextType>(defaultContextValues);
export const SimulabActionContext = createContext<SimulabActionContextType | null>(null);

export const SimulabContextProvider = ({ children, conversationId }) => {
    const {
        data: conversation,
        isLoading: isLoadingConversation,
        error: conversationError,
        isError: isErrorConversation,
        refetch: refetchConversation
    } = useSimulabConversation({ conversationId });
    const {
        data: simulabMessages,
        isLoading: isLoadingMessages,
        error: messagesError,
        isError: isErrorMessages
    } = useSimulabMessages({
        conversationId
    });
    const {
        data: newMessages,
        error: newMessagesError,
        isError: isErrorNewMessages
    } = useSimulabNewMessages({
        conversationId
    });

    const isInProgressReview = conversation?.status === "review_in_progress";
    const { data: feedbackData } = useSimulabConversationFeedback(conversationId, isInProgressReview);
    const sendMessage = useSendSimulabMessage(conversationId);
    const [error, setError] = useState(null);
    const [selectedMessage, setSelectedMessage] = useState<number | null>(null);
    const [isMessageUnselected, setIsMessageUnselected] = useState(false);
    const [currentStage, setCurrentStage] = useState<SimulabStage | null>(null);
    const [badMessage, setBadMessage] = useState(false);
    const [report, setReport] = useState(null);
    const [showEndConversationModal, setShowEndConversationModal] = useState(false);
    const [conversationStarted, setConversationStarted] = useState(false);
    const isLoading = isLoadingConversation || isLoadingMessages;

    useEffect(() => {
        if (feedbackData && Object.keys(feedbackData).length > 0 && isInProgressReview) {
            refetchConversation();
        }

        if (conversation?.feedback) {
            setSelectedMessage(null);
            setCurrentStage(null);
        }

        if (conversation?.messages?.length > 0) {
            setConversationStarted(true);
        }
    }, [feedbackData, isInProgressReview, conversation]);

    useEffect(() => {
        if (newMessages?.badRatingFeedbacks?.length > 0) {
            setBadMessage(true);
        }

        if (newMessages?.errors?.length > 0) {
            setError(newMessages?.errors?.at(-1));
        }

        if (newMessages?.isMakePlanMessage) {
            setShowEndConversationModal(true);
        }
    }, [newMessages]);

    const unselectMessage = useCallback((value: boolean) => {
        setIsMessageUnselected(value);
    }, []);

    const selectMessage = useCallback((messageId: number) => {
        setSelectedMessage(messageId);
    }, []);

    const onError = useCallback((error: AxiosError) => {
        setError(error);
    }, []);

    const resetErrors = useCallback(() => {
        setError(null);
        setBadMessage(null);
    }, []);

    const setReportData = useCallback((report: SimulabReport) => {
        setReport(report);
    }, []);

    const hasError = useMemo(() => {
        return !isNullOrUndefined(error) || isErrorConversation || isErrorMessages || isErrorNewMessages;
    }, [isErrorConversation, isErrorMessages, isErrorNewMessages, error]);

    const apiError = useMemo(() => {
        return conversationError ?? messagesError ?? newMessagesError;
    }, [conversationError, messagesError, newMessagesError]);

    const contextValues: SimulabContextType = useMemo(
        () => ({
            simulabMessages,
            conversation,
            hasError,
            isMessageUnselected,
            newMessages,
            sendMessage,
            currentStage,
            isLoading,
            apiError,
            conversationStarted,
            badMessage,
            showEndConversationModal,
            error,
            report,
            selectedMessage
        }),
        [
            simulabMessages,
            conversation,
            hasError,
            newMessages,
            sendMessage,
            badMessage,
            currentStage,
            showEndConversationModal,
            apiError,
            isMessageUnselected,
            conversationStarted,
            isLoading,
            report,
            selectedMessage,
            error
        ]
    );

    const contextActions: SimulabActionContextType = useMemo(
        () => ({
            selectMessage,
            setConversationStarted,
            resetErrors,
            unselectMessage,
            setShowEndConversationModal,
            setReportData,
            onError,
            refetchConversation
        }),
        [
            selectMessage,
            resetErrors,
            onError,
            setReportData,
            setShowEndConversationModal,
            unselectMessage,
            setConversationStarted,
            refetchConversation
        ]
    );

    return (
        <SimulabContext.Provider value={contextValues}>
            <SimulabActionContext.Provider value={contextActions}>{children}</SimulabActionContext.Provider>
        </SimulabContext.Provider>
    );
};

export const useSimulabContext = (): SimulabContextType & SimulabActionContextType => {
    const context = useContext(SimulabContext);
    const actions = useContext(SimulabActionContext);
    if (context === undefined || actions === undefined) {
        throw new Error("useSimulabContext can only be used inside SimulabContextProvider");
    }
    return { ...context, ...actions };
};
