import { useCallback } from "react";
import { useQuery } from "@tanstack/react-query";

import { http } from "common/http";
import { ServerStateKeys } from "main-app/constants";
import Coach, { CoachApi } from "main-app/models/coach";
import Urls from "main-app/api/urls";
import { isEmptyObject } from "main-app/utils/common";

const getCoach = async () => {
    const { data } = await http.get<{ coach_data: CoachApi }>(Urls.coach());
    return data.coach_data;
};

export default function useCoach() {
    return useQuery([ServerStateKeys.Coach], getCoach, {
        select: useCallback(data => {
            return isEmptyObject(data) ? null : new Coach(data);
        }, [])
    });
}
