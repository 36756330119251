import React, { useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import { useNavigate, useParams } from "react-router-dom";
import classNames from "classnames";

import { ComponentType, Status } from "main-app/constants";
import LearnIcon from "common/chapter-svgs/LearnIcon";
import CheckMark from "main-app/svgs/CheckMark";
import { Phase } from "main-app/models/phase";
import { getChapter } from "main-app/api/use-chapters";

interface IProps {
    components: any[];
    chapters: any[];
    currentChapter: any;
    currentComponentIndex: number;
    currentChapterIndex: number;
    currentComponent: any;
    questionsProgress: number;
    currentPhase: Phase;
}

const ICON_SIZE = 47;

const ChapterStepper: React.FC<IProps> = ({
    components,
    currentComponentIndex,
    chapters,
    currentChapterIndex,
    currentComponent,
    questionsProgress,
    currentPhase
}) => {
    const params = useParams();
    const navigate = useNavigate();
    const [inProgressChapter, setInProgressChapter] = useState(null);

    useEffect(() => {
        getChapterData();
    }, [chapters, params.id]);

    const getChapterData = async () => {
        const inProgressChapter = chapters?.find(chapter => chapter.status === Status.InProgress);
        if (!inProgressChapter) {
            return;
        }
        const data = await getChapter(inProgressChapter.id);
        setInProgressChapter(data);
    };

    const computeStepProgress = (index: number) => {
        const totalComponents = components?.length;
        const isPastChapter = currentChapterIndex > index;
        const isCurrentChapter = index === currentChapterIndex;
        const currentChapter = chapters?.[currentChapterIndex];

        if (
            (currentChapter?.status === Status.Completed && isCurrentChapter) ||
            chapters?.[index]?.status === Status.Completed
        ) {
            return 100;
        }

        if (inProgressChapter && inProgressChapter?.id === chapters?.[index]?.id && !isCurrentChapter) {
            const totalComponents = inProgressChapter?.components?.length;
            const completeAmount = inProgressChapter?.components?.reduce((progress, cur) => {
                if ("answer_data" in cur) {
                    return progress + 1;
                }
                return progress;
            }, 0);

            return (completeAmount / totalComponents) * 100;
        }

        if (currentComponent?.type === ComponentType["Question: Likert Group"]) {
            const isFirstComponent = currentComponentIndex === 0;
            const toFullFillPercent = ((currentComponentIndex + 1) / totalComponents) * 100;
            const componentPercent = (currentComponentIndex / totalComponents) * 100;
            const diff = toFullFillPercent - componentPercent;
            if (isFirstComponent) {
                const questionPercent = toFullFillPercent * (questionsProgress / 100);
                return isPastChapter ? 100 : isCurrentChapter ? questionPercent : 0;
            }
            const questionPercent = diff * (questionsProgress / 100) + componentPercent;
            return isPastChapter ? 100 : isCurrentChapter ? questionPercent : 0;
        }

        const progress = (currentComponentIndex / totalComponents) * 100;

        const finalProgress = isPastChapter ? 100 : isCurrentChapter ? progress : 0;

        return finalProgress;
    };

    const handleClickIcon = (chapter, chapterIndex) => {
        if (chapterIndex === +params.chapterIndex) {
            return;
        }

        const isFirstAvailableChapter =
            (chapter.status === Status.InProgress || chapter.status === Status.Open) && chapterIndex === 0;

        const previousChapter = chapters?.[chapterIndex - 1];

        const isOpenChapter =
            isFirstAvailableChapter ||
            (previousChapter?.status === Status.Completed &&
                (chapter.status === Status.Open || chapter.status === Status.InProgress));

        const isCompletedChapter = chapter.status === Status.Completed;

        if (isCompletedChapter || isOpenChapter) {
            navigate(`/chapter/learn/${params.moduleId}/${params.phaseIndex}/${chapterIndex}/${chapter.id}`);
        }
    };

    const startedStatus = (index: number) =>
        currentChapterIndex === index || index < currentChapterIndex || chapters[index].status === Status.Completed;

    const getProgressWidth = (index: number) => {
        const progress = computeStepProgress(index);
        return progress > 50 ? `calc(${progress}% - ${ICON_SIZE}px)` : `${progress}%`;
    };

    return (
        <footer className="stepper sticky-bottom-lg">
            <div className="container-fluid">
                <div className="stepper-progress">
                    <>
                        {chapters?.map((chapter, index) => (
                            <div
                                className={classNames("stepper-progress-step", {
                                    started: startedStatus(index),
                                    current: currentChapterIndex === index
                                })}
                                key={chapter.id}
                            >
                                <span className="stepper-progress-line-gray"></span>
                                <span
                                    className="stepper-progress-line-active"
                                    style={{
                                        width: getProgressWidth(index)
                                    }}
                                ></span>
                                <span className="stepper-progress-icon" onClick={() => handleClickIcon(chapter, index)}>
                                    {chapter.icon ? (
                                        <div
                                            className={classNames("stepper-progress-icon-container", {
                                                started: startedStatus(index),
                                                current: currentChapterIndex === index
                                            })}
                                        >
                                            <ReactSVG
                                                evalScripts="always"
                                                httpRequestWithCredentials={true}
                                                renumerateIRIElements={false}
                                                src={chapter.icon}
                                                fallback={() => (
                                                    <LearnIcon
                                                        isActive={startedStatus(index)}
                                                        width={40}
                                                        heigth={40}
                                                        className={classNames({
                                                            "active-icon-svg": currentChapterIndex === index
                                                        })}
                                                    />
                                                )}
                                                useRequestCache={true}
                                            />
                                        </div>
                                    ) : (
                                        <LearnIcon
                                            isActive={startedStatus(index)}
                                            className={classNames({
                                                "active-icon-svg": currentChapterIndex === index
                                            })}
                                        />
                                    )}
                                </span>
                            </div>
                        ))}
                        <div
                            className={classNames("stepper-progress-step check-mark-step", {
                                completed: currentPhase?.status === Status.Completed
                            })}
                        >
                            <span
                                className={classNames("stepper-progress-icon check-mark-icon", {
                                    completed: currentPhase?.status === Status.Completed
                                })}
                            >
                                <CheckMark />
                            </span>
                        </div>
                    </>
                </div>
            </div>
        </footer>
    );
};

export default ChapterStepper;
