import { useQuery } from "@tanstack/react-query";
import { http } from "common/http";
import { ServerStateKeys } from "main-app/constants";
import Urls from "main-app/api/urls";
import { useCallback } from "react";
import { useAuthContext } from "main-app/context/Auth";
import { NpsAPI } from "main-app/models/api/nps";
import { Nps } from "main-app/models/nps";

const getNps = async () => {
    const { data } = await http.get(Urls.npsQuestion());
    return data;
};

export default function useNps() {
    const { user } = useAuthContext();
    return useQuery<NpsAPI, Error, Nps>([ServerStateKeys.Nps], getNps, {
        select: useCallback(data => {
            return new Nps(data);
        }, []),
        enabled: !!user
    });
}
