import React from "react";
import { useFormContext } from "react-hook-form";

import ActiveCheckMarkSvg from "main-app/svgs/ActiveCheckMarkSvg";
import { CalendarAccount } from "../models";
import { CalendarPrimaryFormValues } from "../types";

type Props = {
    calendar: CalendarAccount;
};

export const CalendarPrimaryItem = ({ calendar }: Props) => {
    const { watch, setValue } = useFormContext<CalendarPrimaryFormValues>();
    const value = watch(`primaryCalendar`);

    const onClickPrimaryCalendar = (id: string) => {
        setValue("primaryCalendar", id, { shouldDirty: true });
    };

    return (
        <div className="calendar-primary__item">
            <div className="calendar-primary__item-main">
                <span className="font-extrabold">{calendar.profileName}</span>
            </div>
            {calendar?.profileCalendars?.map(profile => (
                <div
                    className="calendar-primary__item-select"
                    onClick={() => onClickPrimaryCalendar(profile.calendarId)}
                >
                    <div>{profile.calendarName}</div>
                    {value && value === profile?.calendarId && (
                        <div className="d-flex align-items-center">
                            <span className="color-brand font-extrabold mr-2">Selected</span>
                            <ActiveCheckMarkSvg />
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};
