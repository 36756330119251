import React, { type FC, type ReactElement } from "react";

import Coach from "main-app/models/coach";
import type { IAvailableSessionDataGroupedByDay } from "../types";
import type { IAvailableGuestSessionModel } from "main-app/api/use-get-available-guest-sessions";
import type { TEmptyCallback } from "main-app/shared/types/functions";

import { GuestParticipantSessionAccordion as Accordion } from ".";

type TGuestParticipantAccordionListProps = {
    sessions?: IAvailableSessionDataGroupedByDay[];
    coach?: Coach;
    handleSetSuggestedSession(session: IAvailableGuestSessionModel): void;
    handleOpenSwapSessionModal: TEmptyCallback;
};

const GuestParticipantAccordionList: FC<TGuestParticipantAccordionListProps> = ({
    sessions,
    handleSetSuggestedSession,
    handleOpenSwapSessionModal,
    coach
}: TGuestParticipantAccordionListProps): ReactElement => (
    <div className="swap-session__accordion__list">
        {sessions.map(
            (s: IAvailableSessionDataGroupedByDay): ReactElement => (
                <Accordion
                    key={`slide-accordion-${s.weekDay}`}
                    handleSetSuggestedSession={handleSetSuggestedSession}
                    handleOpenSwapSessionModal={handleOpenSwapSessionModal}
                    session={s}
                    coach={coach}
                />
            )
        )}
    </div>
);

export { GuestParticipantAccordionList, type TGuestParticipantAccordionListProps };
