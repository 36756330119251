import { AxiosError } from "axios";
import { useCallback } from "react";

import { useQuery } from "@tanstack/react-query";
import { http } from "common/http";
import { ServerStateKeys } from "main-app/constants";
import Urls from "./urls";
import { WaitilingListApi, WaitingList } from "main-app/models/waiting-list";

const getWaitingListText = async () => {
    const { data } = await http.get(Urls.onboardingWaitListText());
    return data;
};

export default function useWaitingListText() {
    return useQuery<WaitilingListApi, AxiosError, WaitingList>([ServerStateKeys.WaitingListText], getWaitingListText, {
        select: useCallback(data => new WaitingList(data), [])
    });
}
